import { useCallback, useState } from 'react';
import { Checked, CircledTick, Bell, menu, cart } from '../../../assets/images/icons/index';
import cls from './HeaderMenuButtons.module.scss';
import { CenteredLoadingIndicator, UnfocusableWrapper, Spinner, PermissionWrapper, po, Button, RenderYesNoSwitcher } from '../../common';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useMountedState } from 'react-use';
import classNames from 'classnames';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { useNotifications } from '../../../context/notifications.context';
import ModulesMenu from '../../SideMenuEntries/ModulesMenu';
import ProcessMenu from '../../SideMenuEntries/ProcessMenu';
import { useNavigate } from 'react-router-dom';
import { setCurrentTask, setProcessModule, setShowMenu, setShowSideMenu } from '../../../reduxStore/actions/flowActions';
import { processTaskAction, useProcessTaskContext } from '../../../context/processTask.context';
import { store } from '../../../reduxStore/store';
import { getCompanyDetails } from '../../../services/company.service';
import { first } from 'rxjs';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { APP_NAME } from '../../../shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faXmark } from '@fortawesome/free-solid-svg-icons';
import './HeaderMenuButtons.css';

const RenderNotification = props => {
	const isMounted = useMountedState();
	const [renderContext, , , , , onClickNotification] = useNotifications();
	const { notification } = props;
	const [reading, setReading] = useState(false);

	const onHrefClick = useCallback(
		async link => {
			const ackRead = await onClickNotification(notification.id);
			if (ackRead) {
				if (isMounted()) setReading(false);
				document.location.href = link;
			}
		},
		[notification.id, onClickNotification]
	);

	const onNotificationClick = async () => {
		setReading(true);
		if (notification.isUnread) {
			onClickNotification(notification.id);
		}
		if (isMounted()) setReading(false);
	};

	if (renderContext.renderUnreadOnly && !notification.isUnread) {
		return null;
	}
	return (
		<button className={classNames(cls.notification)} app-is-unread={notification.isUnread.toString()} onClick={onNotificationClick}>
			{parse(notification.message, {
				replace: domNode => {
					if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {
						const link = attributesToProps(domNode.attribs).href;
						delete domNode.attribs.href;
						return (
							<a
								onClick={event => {
									event.preventDefault();
									event.stopPropagation();
									setReading(true);
									onHrefClick(link);
								}}
							>
								{domToReact(domNode?.children)}
							</a>
						);
					}
				},
			})}

		</button>
	);
};
const RenderNotifications = () => {
	const [renderContext, loading, onReadAllPressed, onShowOnlyUnread, onLoadMore] = useNotifications();
	return (
		<div className={cls.renderNotificationsContainer}>
			<div className={cls.titleContainer}>
				<p className={cls.title}>{'Notifications'}</p>
				
			</div>
			<InfiniteScroll
				dataLength={renderContext.renderNotifications.length}
				next={onLoadMore}
				scrollThreshold="200px"
				initialScrollY={0}
				hasMore={renderContext.canFetchMore}
				height={300}
			>
				<div key={'notifContainer' + loading} style={{ flex: 1 }}>
					{renderContext.renderNotifications?.length > 0 && (
						renderContext.renderNotifications?.map(i => <RenderNotification key={i.id} notification={i} />)
					) }


				</div>
			</InfiniteScroll>

			<RenderYesNoSwitcher
				title={'Show only unread?'}
				style={{ margin: '50px 0px 20px 10px', width: 'auto' }}
				value={renderContext.renderUnreadOnly}
				toggleSelected={val => {
					onShowOnlyUnread({ target: { checked: val } })
				}}
			/>
			<RenderYesNoSwitcher
				title={'Mark all as read?'}
				style={{ margin: '20px 0px 20px 10px', width: 'auto' }}
				value={false}
				toggleSelected={val => {
					if (val) {
						onReadAllPressed();
					}

				}}
			/>

		</div>
	);
};
export const NotificationsMenuEntry = _props => {
	const [renderContext, , , , , , setListVisible] = useNotifications();
	const [show, setShow] = useState(false);

	return (
		<UnfocusableWrapper onMouseEnter={() => setShow(true)} onClickOutside={() => setShow(false)} className={cls.menuEntryHost} app-button-variant={show ? 'hovered' : ''}>
			<button type={'button'} className={cls.menuButton} onClick={() => setShow(!show)}>
				<FontAwesomeIcon icon={faBell} style={{ color: 'gray', width: '20px', height: '20px' }} />
				{renderContext.unreadNotificationsCount.counted > 0 && (
					<div className={cls.notificationsUnread}>
						<p>{renderContext.unreadNotificationsCount.counted}</p>
					</div>
				)}
				
			</button>
			<div className={`mobile-navbar ${show ? 'open-nav' : ''}`} style={{ width: '100%', backgroundColor: 'transparent' }}>
						<div className={`mobile-navbar ${show ? 'open-nav' : ''}`} style={{height: 'inherit', width: '100%', boxShadow: '0px 2px 20px 5px rgba(0, 0, 0, 0.1)', }}>
							<div onClick={()=>setShow(false)} className="mobile-navbar-close">
								<FontAwesomeIcon icon={faXmark} className="hamb-icon" />
							</div>

							<RenderNotifications />

						</div>

					</div>


		</UnfocusableWrapper>
	);
};

export const CartMenuEntry = props => {
	const { orders, companyData } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const [, setTaskProcessContext] = useProcessTaskContext();

	return (
		<div
			onMouseEnter={() => setHoover(true)}
			onMouseLeave={() => setHoover(false)}
			className={cls.modulesEntryHost}
			style={{ height: '40px' }}
			app-button-variant={hoover ? 'hovered' : ''}
			onClick={() => {
				companyData.start = false;
				getCompanyDetails().pipe(first()).subscribe({});
				store.dispatch(setCurrentTask(undefined));

				store.dispatch(setProcessModule({ ...orders.begunModule }));
				setTaskProcessContext({
					action: processTaskAction.setModuleItems,
					value: orders.items,
				});

				setTaskProcessContext({
					action: processTaskAction.setModuleNewStepComponents,
					value: orders.newStepComponents,
				});
				setTaskProcessContext({
					action: processTaskAction.setComponents,
					value: orders.components,
				});
				navigate('/begin-module');
			}}
		>
			<Button id={'mainC'} type={'button'} onClick={() => setHoover(c => !c)}>
				{orders?.items?.length}
				<img src={cart} style={{ padding: '0px 0 10px 0', width: '30px', height: '40px' }} alt="processmaker-menu" />
			</Button>
			<div className={cls.modulesContainer} style={{ fontSize: '14px', textAlign: 'left', paddingLeft: '10px' }}>
				There are {orders?.items?.length} item (s) on cart, click to continue this transaction.
			</div>
		</div>
	);
};
export const MainMenuEntry = props => {
	const { setUserLogout, onAboutClick } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModule, setShowModule] = useState(false);
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && !isMobile && <div className={cls.circle} />}
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					<img src={menu} style={{ padding: isMobile ? '0px' : '2px', width: '20px', height: '20px' }} alt="processmaker-menu" />
				</button>
			) : (
				<img src={menu} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} alt="processmaker-menu" onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer} style={{ textAlign: 'left', width: isMobile ? '360px' : '' }}>
				{isMobile && (
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(true));
							isMobile && setHoover(false);
							navigate('/dashboard');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '600' }}>{'Dashboard'}</p>
					</button>
				)}
				{isMobile && (
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							setShowModule(!showModule);
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Module'}</p>
					</button>
				)}

				{isMobile && showModule && (
					<>
						<hr />
						<ModulesMenu />
						<hr />
					</>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/my-profile');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Profile'}</p>
				</button>
				<PermissionWrapper permissions={[po.billing.view]}>
					<button
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(false));
							isMobile && setHoover(false);
							navigate('/users');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Users'}</p>
					</button>
				</PermissionWrapper>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/company');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Company'}</p>
				</button>
				{!isMobile && (
					<button
						style={{ display: '' }}
						type={'button'}
						className={cls.logoutButton}
						onClick={() => {
							dispatch(setShowSideMenu(false));
							navigate('/billing-info');
						}}
					>
						<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Billing'}</p>
					</button>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						onAboutClick();
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'About'}</p>
				</button>
				<hr />
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						setUserLogout(true);
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Logout'}</p>
				</button>
			</div>
		</div>
	);
};

export const MobileMainMenuEntry = props => {
	const { setUserLogout, onAboutClick, onTransactionsClick } = props;
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModule, setShowModule] = useState(false);
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && !isMobile && <div className={cls.circle} />}
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					<FontAwesomeIcon icon={faBars} />
				</button>
			) : (
				<FontAwesomeIcon icon={faBars} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer} style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left', width: isMobile ? '360px' : '' }}>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						onTransactionsClick();
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Transactions'}</p>
				</button>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						dispatch(setShowSideMenu(false));
						isMobile && setHoover(false);
						navigate('/my-profile');
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Profile'}</p>
				</button>
				{!isMobile && (
					<PermissionWrapper permissions={[po.billing.view]}>
						<button
							style={{ display: '' }}
							type={'button'}
							className={cls.logoutButton}
							onClick={() => {
								dispatch(setShowSideMenu(false));
								navigate('/billing-info');
							}}
						>
							<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Billing'}</p>
						</button>
					</PermissionWrapper>
				)}
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						onAboutClick();
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'About'}</p>
				</button>
				<hr />
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						setHoover(false);
						setUserLogout(true);
					}}
				>
					<p style={{ fontSize: '18px', fontWeight: 'normal' }}>{'Logout'}</p>
				</button>
			</div>
		</div>
	);
};
export const ModulesMenuEntry = () => {
	const [hoover, setHoover] = useState(false);

	return (
		<div onMouseEnter={() => document.getElementById('modulesB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && <div className={cls.circle} />}
			<button id={'modulesB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
				Modules
			</button>
			{hoover && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer}>
				<ModulesMenu />
			</div>
		</div>
	);
};

export const ProcessMenuEntry = () => {
	const [hoover, setHoover] = useState(false);

	return (
		<div onMouseEnter={() => document.getElementById('processB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && <div className={cls.circle} />}
			<button id={'processB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
				{'Workflow'}
			</button>
			{hoover && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer}>
				<ProcessMenu />
			</div>
		</div>
	);
};

export const DashboardMenuEntry = () => {
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();

	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} style={{ marginRight: '6px' }} app-button-variant={hoover ? 'hovered' : ''}>
			<button
				id={'dashboardB'}
				className={cls.dashboardButton}
				type={'button'}
				onClick={() => {
					navigate('/dashboard');
				}}
			>
				{'Dashboard'}
			</button>
			{hoover && <div className={cls.racordM}></div>}
		</div>
	);
};

export const HelloNameMenuEntry = props => {
	const [hoover, setHoover] = useState(false);

	const navigate = useNavigate();

	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			<button
				id={'HelloB'}
				className={cls.dashboardButton}
				type={'button'}
				onClick={() => {
					store.dispatch(setShowSideMenu(false));
					navigate('/my-profile');
				}}
			>
				{props.message}
			</button>
			{hoover && <div className={cls.racordM}></div>}
		</div>
	);
};

export const SingleNavigateModule = props => {
	const [hoover, setHoover] = useState(false);

	const navigate = useNavigate();
	const process = props.companyProcesses.find(pr => pr.id === props?.begunModule?.processModule.id);
	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			<button
				id={'ModuleSB'}
				className={cls.dashboardButton}
				type={'button'}
				onClick={() => {
					store.dispatch(setShowMenu(false));

					// const steps_for_use = process?.steps
					// 	.filter(step => step?.options?.showStepAll == undefined || step?.options?.showStepAll == true || (step?.options?.showStepAll == false && step?.options?.showOnly?.filter(a => props.user.positions.map(pos => pos.id).indexOf(a) > -1).length > 0))
					// 	.map((step, i) => {
					// 		return { ...step, rank: i + 1 };
					// 	}) ?? [];

					store.dispatch(
						setProcessModule({
							...(process ? process : props?.begunModule?.processModule),
							// completeSteps: process ? process.steps : props?.begunModule?.processModule?.steps,
							// steps: steps_for_use,
							stepIndex: 0,
							id: props.module.procedure.id,
							module_id: props.module.id,
							module: props.module,
							isUpdating: undefined,
						})
					);
					navigate('/begin-module');
				}}
			>
				{props.title}
			</button>
			{hoover && <div className={cls.racordM}></div>}
		</div>
	);
};

export const HomeBarMenu = props => {
	const [hoover, setHoover] = useState(false);

	const navigate = useNavigate();
	return (
		<div onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			<button id={'HelloB'} className={cls.dashboardButton} type={'button'} onClick={() => navigate(props.link)}>
				{props.message}
			</button>
			{hoover && <div className={cls.racordM}></div>}
		</div>
	);
};

export const MainHomeBarMenuEntry = props => {
	const [hoover, setHoover] = useState(false);
	const navigate = useNavigate();
	return (
		<div onMouseEnter={() => document.getElementById('mainB').click()} onMouseLeave={() => setHoover(false)} className={cls.modulesEntryHost} app-button-variant={hoover ? 'hovered' : ''}>
			{hoover && !isMobile && <div className={cls.circle} />}
			{!isMobile ? (
				<button id={'mainB'} className={cls.modulesButton} type={'button'} onClick={() => setHoover(c => !c)}>
					<img src={menu} style={{ padding: isMobile ? '0px' : '2px', width: '20px', height: '20px' }} alt="processmaker-menu" />
				</button>
			) : (
				<img src={menu} style={{ margin: '0 20px 0 20px', width: '20px', height: '20px' }} alt="processmaker-menu" onClick={() => setHoover(c => !c)} />
			)}
			{hoover && !isMobile && <div className={cls.racordM}></div>}
			<div className={cls.modulesContainer} style={{ textAlign: 'left', width: isMobile ? '360px' : '' }}>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Home'}</p>
				</button>

				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/flowhero');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{APP_NAME}</p>
				</button>
				<button
					type={'button'}
					className={cls.logoutButton}
					onClick={() => {
						navigate('/flowhero');
					}}
				>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>{'Register'}</p>
				</button>
			</div>
		</div>
	);
};
