import moment, { now } from 'moment';
import { Observable } from 'rxjs';
import { setCompletedTasks, setProcessTask, setTasks, taskDelete, setLastUpdate, addNewTask, editTask, setMessageBar } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

export const getTaskById = taskId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.tasks.getTaskById(last_access_token, taskId),
		handlerEnabled: true,
	};

	return new Observable(subscriber => {
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			});
	});
};
export const getAllTasksCreatedByUser = (userId, forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates, allTasks } = store.getState().flowReducer;

	const config = {
		...URLS.tasks.getAllTasksCreatedByUser(last_access_token, userId),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	//console.log('moment diff tasks', moment(now()).diff(moment(lastUpdates.tasks), 'minutes'), allTasks.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.tasks), 'minutes') > 10 || allTasks.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						const tasks = response?.data?.data;
						store.dispatch(setTasks(tasks));
						subscriber.next(tasks);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const getAllTasks = (userId, forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates, allTasks } = store.getState().flowReducer;

	const config = {
		...URLS.tasks.getAllTasks(last_access_token, userId),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	//console.log('moment diff tasks', moment(now()).diff(moment(lastUpdates.tasks), 'minutes'), allTasks.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.tasks), 'minutes') > 10 || allTasks.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						const tasks = response?.data?.data;
						store.dispatch(setTasks(tasks));
						store.dispatch(setLastUpdate({ ...lastUpdates, tasks: now() }));
						subscriber.next(tasks);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const getAllCompletedTasks = (page, userId, forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.tasks.getAllCompletedTasks(last_access_token, page, userId),
		handlerEnabled: true,
	};

	const { lastUpdates, allCompletedTasks } = store.getState().flowReducer;

	//console.log('moment diff allCompletedTasks', moment(now()).diff(moment(lastUpdates.allCompletedTasks), 'minutes'), allCompletedTasks.data.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.allCompletedTasks), 'minutes') > 10 || allCompletedTasks.data.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						const tasks = response?.data;
						store.dispatch(setCompletedTasks(tasks, page));

						subscriber.next(tasks);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const deleteTask = taskId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	const defaultConfig = { ...URLS.tasks.deleteTask(last_access_token, taskId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted the task`, color: 'red' }));
					store.dispatch(taskDelete(taskId));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createTask = taskData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('name', taskData.taskName);
	data.append('description', taskData.taskDescription);
	data.append('due_date', taskData.dueDate);
	data.append('assignee_id', taskData.newAssignee.assignee_id);
	if (taskData.taskProcess) data.append('procedure_id', taskData.taskProcess?.id);
	if (taskData.add_info) data.append('add_info', JSON.stringify(taskData.add_info));

	const config = {
		...URLS.tasks.createTask(last_access_token),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const task = response?.data?.data;
					store.dispatch(addNewTask(task));
					//store.dispatch(setProcessTask(task, false));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully created a new task` }));
					subscriber.next(task);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const startTask = taskId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.tasks.startTaskById(last_access_token, taskId),
		data: taskId,
		handlerEnabled: true,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					store.dispatch(editTask(response?.data?.data));

					subscriber.next();
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const completeTask = taskId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.tasks.endTaskById(last_access_token, taskId),
		data: taskId,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					store.dispatch(editTask(response?.data?.data));
					subscriber.next();
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateTask = (taskData, taskId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		name: taskData.name,
		description: taskData.description,
		due_date: taskData.due_date,
		assignee_id: taskData.assignee_id,
		status: taskData?.status ?? '',
	};

	if (taskData.add_info) data.add_info = JSON.stringify(taskData.add_info);

	const config = {
		...URLS.tasks.editTask(last_access_token, taskId),
		data: data,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const task = response?.data?.data;
					store.dispatch(editTask(task));
					subscriber.next(task);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getTaskStepsComponent = (taskId, processId, stepId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	stepId && data.append('step_id', stepId);
	processId && data.append('process_id', processId);

	const config = {
		data: data,
		...URLS.tasks.getStepComponents(last_access_token, taskId),
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getTaskInheritedComponents = (taskId, processId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();

	data.append('process_id', processId);

	const config = {
		data: data,
		...URLS.tasks.getInheritedComponents(last_access_token, taskId),
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
