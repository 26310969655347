import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, ModalR } from '../../../common';
import TaskForm from '../TaskForm/TaskForm';
import ModuleTaskForm from '../TaskForm/ModuleTaskForm';
import { isMobile } from 'react-device-detect';
const TaskModal = props => {
	const { isEdit, modalVisible, heading, onClose, isProcess, setIsProcessBegin, task, priority=true, shadowedContainerStyle, statuses, start_date, span } = props;
	return (
		<div>
			<ModalR scrollable={true} shadowedContainerStyle={shadowedContainerStyle ? shadowedContainerStyle : { width: isMobile ? '96%' : '35%' }} isOpen={modalVisible} onClose={() => { }} blured={false}>
				{isProcess ? <div>
					<p
						style={{
							fontFamily: 'var(--app-text-main-font)',
							fontSize: '2rem',
							color: '#4A4A4A',
							marginTop: '10px',
							marginBottom: '5px',
							textAlign: 'center',
						}}
					>
						{task?.completed_at ? `Completed Task` : heading}
					</p>

					{isEdit && (
						<p
							style={{
								fontFamily: 'var(--app-text-main-font)',
								fontSize: '15px',
								fontWeight: '700',
								color: '#4A4A4A',
								marginTop: '5px',
								marginBottom: '5px',
								textAlign: 'center',
							}}
						>
						</p>
					)}

					<ModuleTaskForm showPriority={priority} statuses={statuses} task={task} setIsProcessBegin={setIsProcessBegin} setDisplayModal={onClose} isProcess={isProcess} isEdit={isEdit} onClose={() => onClose()} start_date={start_date} span={span}/>
				</div>
:
				<div>
					<p
						style={{
							fontFamily: 'var(--app-text-main-font)',
							fontSize: '2rem',
							color: '#4A4A4A',
							marginTop: '10px',
							marginBottom: '5px',
							textAlign: 'center',
						}}
					>
						{task?.completed_at ? `Completed Task` : heading}
					</p>

					{isEdit && (
						<p
							style={{
								fontFamily: 'var(--app-text-main-font)',
								fontSize: '15px',
								fontWeight: '700',
								color: '#4A4A4A',
								marginTop: '5px',
								marginBottom: '5px',
								textAlign: 'center',
							}}
						>
							{`Task ID: ${task.id.slice(-6)}`}
						</p>
					)}

					<TaskForm showPriority={priority} statuses={statuses} task={task} setIsProcessBegin={setIsProcessBegin} setDisplayModal={onClose} isProcess={isProcess} isEdit={isEdit} onClose={() => onClose()} start_date={start_date} span={span}/>
				</div>}

			</ModalR>
		</div>
	);
};

export default TaskModal;
