import React, { useEffect, useState } from 'react';
import cls from './MenuBar.module.scss';
import { CartMenuEntry, DashboardMenuEntry, HelloNameMenuEntry, MainMenuEntry, MobileMainMenuEntry, ModulesMenuEntry, ProcessMenuEntry, SingleNavigateModule } from '../../TopBar/HeaderMenuButtons/HeaderMenuButtons';
import { LogoutModal, ModalR, PermissionWrapper, RenderTitle, po } from '../../common';
import { useSelector } from 'react-redux';
import { store } from '../../../reduxStore/store';
import { setCurrentTask, setProcessModule } from '../../../reduxStore/actions/flowActions';
import { isMobile } from 'react-device-detect';
import { APP_NAME, ipsum, uncachedUrl } from '../../../shared/utility';
import { useNavigate } from 'react-router-dom';
import HeaderTopBar from '../../common/HeaderTopBar/HeaderTopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ModulesMenu from '../../SideMenuEntries/ModulesMenu';

const MenuBar = () => {
	const [userLogout, setUserLogout] = useState(false);

	const { allModules, cart, companyData, begunModule, companyProcesses, showSideMenu } = useSelector(store => store.flowReducer);
	const { user } = useSelector(store => store.sessionReducer);
	const [showAbout, setShowAbout] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	const AboutR = () => {
		return (
			<div style={{ alignSelf: 'center', color: 'var(--app-color-gray-dark)' }}>
				<HeaderTopBar headerTitle={`${APP_NAME}`} hasHeaderImgX targetProcessing={false} />
				<p>{`© 2020-2024 ADPGxSoftware Solutions. All rights reserved.`}</p>
			</div>
		);
	};
	return (
		<div className={cls.menuBar}>
			<nav className={cls.nav} >
				<div className={cls.headerTopBarContainer} style={{ paddingLeft: '10px', display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={() => navigate('/home')}>
					{companyData?.logo ?
						<img alt="lg" src={companyData?.logo} className={isMobile ? cls.logoUrlImgMobile : cls.logoUrlImg} /> :
						<p style={{ fontSize: isMobile ? '18px' : '' }}>{companyData.status === 'superadmin' ? APP_NAME : companyData?.status === 'inactive' ? `${companyData?.display_name ?? APP_NAME} (${companyData?.status})` : (companyData?.display_name ?? APP_NAME)}</p>}
				</div>
				<ul className={cls.ul}>
					{!isMobile && <DashboardMenuEntry />}

					{allModules?.length > 0 &&
						!isMobile &&
						(allModules?.length === 1 ? (
							<li className={cls.li}>
								<SingleNavigateModule user={user} companyProcesses={companyProcesses} begunModule={begunModule} module={allModules[0]} title={allModules[0]?.name} />
							</li>
						) : (
							isMobile && <ModulesMenuEntry />
						))}
					<PermissionWrapper permissions={[po.blueprints.process.create]}>{(companyData.status === 'active' || companyData.status === 'on_trial' || companyData.status === 'superadmin') && !isMobile && <ProcessMenuEntry />}</PermissionWrapper>
					{cart?.items?.length > 0 ? <CartMenuEntry orders={cart} companyData={companyData} /> : !isMobile && <HelloNameMenuEntry message={`Hello, ${user?.first_name}`} />}
					<MainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} onAboutClick={() => setShowAbout(true)} />
				</ul>

				{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
				{showAbout && (
					<ModalR blured={true} hasCloseButton scrollable={true} isOpen={showAbout} onClose={() => setShowAbout(false)} shadowedContainerStyle={{ minWidth: '320px', width: '40%', height: 'auto' }}>
						<AboutR />
					</ModalR>
				)}
			</nav>
			{showSideMenu && <ModulesMenu />}
		</div>
	);
};

export default MenuBar;

export const TopMenu = () => {
	const [userLogout, setUserLogout] = useState(false);
	const { allModules, companyData } = useSelector(store => store.flowReducer);

	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	return (
		<nav className={cls.nav2}>
			<ul className={cls.ul}>
				<li className={cls.li}>
					<a className={cls.a} href="/dashboard">
						Dashboard
					</a>
				</li>
				{allModules?.length > 0 &&
					(allModules?.length === 1 ? (
						<li className={cls.li}>
							<a className={cls.a} href="/begin-module">
								{allModules[0]?.name}
							</a>
						</li>
					) : (
						<ModulesMenuEntry />
					))}
				<PermissionWrapper permissions={[po.blueprints.process.create]}>
					<ProcessMenuEntry />
				</PermissionWrapper>

				<MainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} />
			</ul>
			{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
		</nav>
	);
};

export const MobileMenuBar = props => {
	const [userLogout, setUserLogout] = useState(false);
	const { onTransactionsClick } = props;
	const { allModules, cart, companyData } = useSelector(store => store.flowReducer);
	const { user } = useSelector(store => store.sessionReducer);
	const [showAbout, setShowAbout] = useState(false);

	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	const AboutR = () => {
		return (
			<div style={{ alignSelf: 'center', color: 'var(--app-color-gray-dark)' }}>
				<HeaderTopBar headerTitle={`${APP_NAME}`} hasHeaderImgX targetProcessing={false} />
				<p>{`© 2020-2024 ADPGxSoftware Solutions. All rights reserved.`}</p>
			</div>
		);
	};
	return (
		<nav className={cls.nav} style={{ display: '', backgroundColor: 'white' }}>
			<ul className={cls.ul}>
				<MobileMainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} onAboutClick={() => setShowAbout(true)} onTransactionsClick={onTransactionsClick} />
			</ul>

			{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
			{showAbout && (
				<ModalR blured={true} hasCloseButton={true} scrollable={true} isOpen={showAbout} onClose={() => setShowAbout(false)} shadowedContainerStyle={{ minWidth: '320px', width: '40%', height: 'auto' }}>
					<AboutR />
				</ModalR>
			)}
		</nav>
	);
};
