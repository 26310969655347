import moment, { now } from 'moment';
import { Observable } from 'rxjs';
import { setLastUpdate, setAllModules, setMessageBar, setAllComponents, setComponentUpdate } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { APPEND_TO_DATA } from '../shared/utility';
const R = require('ramda');

export const getAllModules = (mode = APPEND_TO_DATA, forced = false) => {
	const { lastUpdates, allModules } = store.getState().flowReducer;
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.modules.getAllModules(last_access_token, moment.utc(forced == true ? 0 : lastUpdates.modules ?? 0).format('YYYY-MM-DD HH:mm:ss')),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.modules), 'minutes') > 10 || allModules.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						store.dispatch(setLastUpdate({ ...lastUpdates, modules: now() }));
						store.dispatch(setAllModules(response?.data?.data, mode));
						subscriber.next(response?.data?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		}
	});
};

export const createNewModule = (moduleName, procedure_id, options) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('name', moduleName);
	data.append('procedure_id', procedure_id);
	data.append('options', JSON.stringify(options));

	const config = {
		...URLS.modules.createModule(last_access_token),
		data: data,
		handlerEnabled: true,
		//forwardAllErrors: true,
	};
	const { allModules } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					const module = response?.data?.data;
					store.dispatch(setAllModules([...allModules, module]));
					subscriber.next(module);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const editModule = (moduleId, moduleName, procedure_id, options) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		name: moduleName,
		procedure_id: procedure_id,
		options: JSON.stringify(options),
	};
	const config = {
		...URLS.modules.editModuleById(last_access_token, moduleId),
		data: data,
	};
	const { allModules } = store.getState().flowReducer;
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const module = response?.data?.data;
					store.dispatch(setAllModules([...allModules.filter(mod => mod.id !== moduleId), module]));
					subscriber.next(module);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteModule = moduleId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.modules.deleteModule(last_access_token, moduleId),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	const { allModules } = store.getState().flowReducer;
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					const module = response?.data?.data;
					store.dispatch(setAllModules(allModules.filter(mod => mod.id !== moduleId)));
					subscriber.next(module);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const pinModule = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.modules.pinModuleById(last_access_token, id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const unpinModule = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.modules.unpinModuleById(last_access_token, id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const importModuleComponentsFromExcelFile = (excelFile, id) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('file', excelFile);

	const defaultConfig = { ...URLS.modules.importModules(last_access_token, data, id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200) {
					subscriber.next(response.data.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'Import was successful' }));
				}
			})
			.catch(error => {
				if (error?.name === 'UnsubscriptionError') {
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'Import was successful' }));
				} else {
					console.log('message error');
					store.dispatch(setMessageBar({ show: true, message: `Import was unsuccessful. ${error.data.data.message}`, color: 'red' }));
					subscriber.error(error);
				}
			})
	);
};

export const getAlComponentsByModuleId = (module, mode = APPEND_TO_DATA, top = 10, page = 1, list = false, assigned=false) => {
	const { allModules, blueprints, lastUpdates } = store.getState().flowReducer;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.modules.getAllComponentsByModuleId(last_access_token, module.id, moment.utc(lastUpdates.componentsByModule[module.name] ?? 0).format('YYYY-MM-DD HH:mm:ss'), top, page, list, assigned),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	//console.log('moment diff modules', moment(now()).diff(moment(lastUpdates.modules), 'minutes'), allModules.length);

	return new Observable(subscriber => {
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data?.current_page) {
					// data is paginated from API

					if (response?.data?.data?.current_page === 1) {
						store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), response?.data?.data?.data), mode, blueprints.map(bp => bp.fields).flat(1)));
					} else {
						store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), Object.values(response?.data?.data?.data)), mode, blueprints.map(bp => bp.fields).flat(1)));
						response.data.data.data = Object.values(response?.data?.data?.data);
					}

					subscriber.next(response?.data?.data);
					subscriber.complete();
				} else if (response?.status === 200 && response?.data && response?.data?.data && response?.data?.data?.data === undefined) {
					// data is non-paginated from API
					store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), response?.data?.data), mode, blueprints.map(bp => bp.fields).flat(1)));

					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			});
	});
};
