import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './appFiles/reduxStore/store';
import './index.scss';
import { HashRouter } from 'react-router-dom';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { APP_NAME } from './appFiles/shared/utility';
const app = (
	<React.Fragment>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<Suspense
					fallback={<p></p>}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignContent: 'center',
						alignItems: 'center',
						width: '100%',
						padding: '0 0 0 100px',
						position: 'fixed',
						top: '0',
						left: '0',
					}}
				>
					<HashRouter>
						<App />
					</HashRouter>
				</Suspense>
			</PersistGate>
		</Provider>
	</React.Fragment>
);

ReactDOM.render(app, document.getElementById('root'));
