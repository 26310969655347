import React, { useRef, useState, memo, Fragment } from 'react';
import Chevron from '../ModulesComp/Chevron';
import cls from './StaticAccordionModule.module.scss';
import { CheckedStyle, componentsGrey } from '../../../assets/images/icons';
import FieldRow from '../../common/UI/CustomFields/FieldRow/FieldRow';
import Loader from 'react-loader-spinner';
import { isMobile, isSafari } from 'react-device-detect';
import classNames from 'classnames';
import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
import 'react-phone-input-2/lib/bootstrap.css';
import { useProcessTaskContext } from '../../../context/processTask.context';
import ModuleLists from './ModuleLists';
import * as R from 'ramda';
import moment from 'moment';
import { Button } from '../../common';
import MapView from '../../common/Mapping/MapView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faEllipsisVertical, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import UserCard from '../../common/UserCard/UserCard';
import { useSelector } from 'react-redux';

const StaticAccordionModule = props => {
	const { component, blueprintIndex, isMandatoryError, nextButtonClicked, companyData, components, allComponents, companyUsersArray, createdBy } = props;
	const [processTaskContext] = useProcessTaskContext();
	const [setActive, setActiveState] = useState(cls.active);
	const [, setHeightState] = useState('0px');
	const [setRotate, setRotateState] = useState(cls.icon);
	const [loading] = useState(false);
	const [message] = useState('');
	const content = useRef(null);
	const indexInContextArray = processTaskContext.currentStep?.component_blueprints.findIndex(c => c.id === component.id);

	const toggleAccordion = () => {
		setActiveState(setActive === '' ? cls.active : '');
		setHeightState(setActive === cls.active ? '0px' : `${content.current.scrollHeight}px`);
		setRotateState(setActive === cls.active ? cls.icon : classNames(cls.icon, cls.rotate));
	};
	const hasMap = component.selected_fields.filter(sf => sf.data_type_name === 'Pin' || sf.data_type_name === 'Map').length > 0;
	return (
		<div id="accordion" className={cls.section} key={blueprintIndex}>
			<div className={classNames(cls.accordionContainer, setActive)} app-variant-has-error={message ? 'error' : ''} style={isMobile ? (hasMap ? { padding: '0', border: '0' } : { border: '0' }) : {}}>
				<div style={{ display: 'none' }}>
					<img src={componentsGrey} alt={'componentsGray'} />
					<p className={cls.title} style={{ color: isMandatoryError && nextButtonClicked ? 'var(--app-color-error)' : '' }}>
						{component?.name}
					</p>
					<div onClick={() => {}} className={cls.iconsContainer} style={{ display: 'none' }}>
						{loading ? (
							<div className={cls.loaderContainer}>
								<Loader type={isSafari ? 'Watch' : 'Puff'} color={'var(--app-color-gray-dark)'} /*  visible={props.loading} */ secondaryColor="gray" radius={32} height={32} width={'32px'} />
							</div>
						) : (
							<CheckedStyle />
						)}
					</div>
					<div className={`${setRotate}`} style={{ cursor: 'pointer', display: component.options === null ? '' : component.options.allowMultipleInputs === true ? 'none' : '' }} onClick={toggleAccordion}>
						<Chevron width={13} fill={'#4A4A4A'} />
					</div>
				</div>
				{!(hasMap && isMobile) && <p className={cls.cardTitle}>{component.name}</p>}
				<div ref={content} style={{ maxHeight: setActive === cls.active ? '' : '0px', display: 'flex' }} className={cls.content}>
					<RenderSelectedFields
						components={components}
						allComponents={allComponents}
						component={component}
						handleInputChange={() => {}}
						handlePrefixChange={() => {}}
						handleCurrencyChange={() => {}}
						checkIfAreMandatoryErrored={false}
						handleCheckbox={() => {}}
						isInherited={false}
						indexInContextArray={indexInContextArray}
						handleLocationChange={() => {}}
						processTaskContext={processTaskContext}
						companyData={companyData}
						companyUsersArray={companyUsersArray}
					/>
				</div>
			</div>
			{message && message?.errors?.title && <MessageContainer message={message.errors.title} />}
			{message && message?.message && <MessageContainer message={message.message} />}
		</div>
	);
};

const RenderSelectedFields = memo(props => {
	const { component, components, companyData, allComponents, companyUsersArray } = props;
	const { allModules } = useSelector(store => store.flowReducer);
	const getComponentsForListing = options => {
		return components
			.filter(component => component.id === options.component_id)
			.map(cmp => {
				let object = {};
				options.list.map(column => {
					object = { ...object, [column]: cmp.selected_fields.find(field => field.field_name === column).value };
				});
				return object;
			});
	};

	const allSelectedFields =
		component?.selected_fields?.length > 0 &&
		component?.selected_fields
			?.filter(sf => sf.value !== true || sf?.value !== undefined)
			.map((field, index) => {
				if (field?.options?.list?.length > 0 && field?.options?.component_id?.length > 0 && (field?.data_type_name === 'Step List' || field?.data_type_name === 'Component List')) {
					return (
						<div key={index} style={{ width: '100%' }}>
							<ModuleLists columns={field.options.list} list={getComponentsForListing(field.options)} />
						</div>
					);
				} else if (field.data_type === 'output' && field.data_type_name !== 'Formula' && field.data_type_name !== 'Record' && field.data_type_name !== 'Filters') {
					return null;
				} else if (field.data_type_name === 'Info') {
					return null;
				} else if (field.data_type_name === 'URL') {
					return null;
				} else if (field.data_type_name === 'Ratings') {
					return null;
				} else if (field.data_type_name === 'Rewards') {
					return null;
				} else if (field.data_type_name === 'Map') {
					return null;
				} else if (field.data_type_name === 'Lookup') {
					return null;
				} else if (field.data_type_name === 'Calendar') {
					return null;
				} else if (field.data_type_name === 'Summary') {
					return null;
				} else if (field.data_type_name === 'ID') {
					const value = R?.find(R?.propEq('field_name', field.field_name))(component.values) ?? null;

					return (
						<FieldRow
							key={index}
							width={isMobile ? '100%' : '50%'}
							fieldLabel={field.field_name}
							style={{ border: '0' }}
							fieldIcon={field.icon}
							isProcess
							visible={field?.value !== undefined ? true : field.visible}
							mandatory={false}
							error={field.errors}
							customIconBgColor={'white'}
						>
							<input disabled={true} type={field.data_type} onChange={() => {}} autoComplete="none" value={value === null ? '' : value.value === null ? '' : value?.value?.split(',')[0]} className={cls.fieldTypeInput} name={field.field_name} />
						</FieldRow>
					);
				} else {
					const cmp = R?.find(R?.propEq('field_name', field?.field_name))(component.values) ?? {};
					
					let value = cmp?.value;
					const infoField = component?.selected_fields?.find(sf => sf.data_type_name === 'Info');
					if (infoField) {
						const found = allComponents.find(c => c?.component_fields.filter(cf => cf.component_blueprint_id === infoField.options.component_id && cf.value === value).length > 0);
						if (found) {
							return infoField?.options?.list?.map((label, ix) => {
								const val = found.component_fields.find(cf => cf.field_name === label)?.value ?? '';
								return (
									<FieldRow width={isMobile ? '100%' : '50%'} key={ix} style={{ border: '0' }} fieldLabel={label} fieldIcon={field.icon} isProcess visible={field?.value !== undefined ? true : field.visible} mandatory={false} error={field.errors}>
										{val.length > 55 ? (
											<textarea className={cls.fieldTypeTextarea} type={field.data_type} value={val} onFocus={() => {}} autoComplete="none" onChange={e => {}} disabled style={{ backgroundColor: 'rgba(234, 231, 231, 0.91)', height: 'auto' }} />
										) : (
											<input disabled={true} autoComplete="none" value={val} className={cls.fieldTypeInput} name={field.field_name} />
										)}
									</FieldRow>
								);
							});
						}
					}
					if (field.data_type_name === 'Pin' && value) {
						const location = JSON.parse(value);
						return (
							<div key={index} style={{ display: '', width: '100%' }}>
								<MapView
									mapkey={companyData.misc_info?.map}
									pins={
										location?.realtime
											? [
													{ ...location.destination, type: 'destination' },
													{ ...location.source, type: 'source' },
													{ ...location.realtime, type: 'realtime' },
											  ]
											: [
													{ ...location.destination, type: 'destination' },
													{ ...location.source, type: 'source' },
											  ]
									}
								/>

								<div key={index} className={cls.filtersContainer} style={{ color: 'var(--app-color-gray-dark)', height: 'inherit', width: '100%', display: field?.visible ? '' : 'none' }}>
									<div className={cls.actionsContainer} style={{ fontSize: '12px' }}>
										<div style={{ width: '100px' }}>{'From'}:</div>
										<div>{location.source.address}</div>
									</div>
									<hr style={{ borderTop: '0.5px solid #D2D2D2' }} />
									<div className={cls.actionsContainer} style={{ fontSize: '12px' }}>
										<div style={{ width: '100px' }}>{'To'}:</div>
										<div>{location.destination.address}</div>
									</div>
								</div>
							</div>
						);
					}
					if (field?.data_type_name === 'Action Button' && field?.options?.action?.length > 0) {
						return (
							<FieldRow width={'100%'} key={index} style={{ border: '0' }} isProcess visible={field?.value !== undefined ? true : field.visible} mandatory={false} error={field.errors}>
								{field.options.action === 'user' &&
									companyUsersArray?.length > 0 &&
									companyUsersArray
										?.filter(user => user.id === value)
										.map((user, index) => {
											return (
												<div key={index} className={cls.centerDiv} style={{ flexDirection: 'column' }}>
													{field.options?.notes ? field.options?.notes : field.field_name}
													<UserCard
														photo={user.photo}
														firstName={user?.first_name}
														lastName={user?.last_name}
														position={user?.positions?.slice(0, 1)?.map(pos => {
															return pos.name;
														})}
														email={user?.email}
														cardClicked={() => {}}
														width={'300px'}
													/>
												</div>
											);
										})}
							</FieldRow>
						);
					}
					if (field.data_type_name === 'Link') {
						return (
							<FieldRow
								width={isMobile ? '100%' : '50%'}
								style={{ border: '0' }}
								key={index}
								//fieldLabel={field.field_name}
								fieldIcon={field.icon}
								isProcess
								visible={field?.value !== undefined ? true : field.visible}
								mandatory={false}
								error={field.errors}
								customIconBgColor={'white'}
							>
								{value?.includes('jpg') || value?.includes('jpeg') || value?.includes('png') ? (
									<div className={cls.accordionContainer} onClick={() => window.open(value, '_blank')}>
										<img alt="lg" src={value} style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
									</div>
								) : value?.includes('pdf') ? (
									<div className={cls.accordionContainer} onClick={() => window.open(value, '_blank')}>
										<embed onClick={() => window.open(value, '_blank')} src={`${value}#toolbar=0&navpanes=0&scrollbar=0`} width="400px" height="500px" />
									</div>
								) : (
									<div style={{ width: 'inherit', textDecoration: 'underline', textAlign: 'center', cursor: 'pointer' }} onClick={() => window.open(value, '_blank')}>
										{'Link'}
									</div>
								)}
							</FieldRow>
						);
					}
					if (field.data_type_name === 'Checkbox') {
						return (
							<FieldRow
								width={isMobile ? '100%' : '50%'}
								key={index}
								style={{ border: '0' }}
								fieldLabel={field.options.value}
								fieldIcon={field.icon}
								isProcess
								visible={field?.value !== undefined ? true : field.visible}
								mandatory={false}
								error={field.errors}
								customIconBgColor={'white'}
							>
								<input disabled={true} onChange={() => {}} autoComplete="none" value={value === undefined ? '' : value == true ? 'Yes' : 'No'} className={cls.fieldTypeInput} name={field.field_name} />
							</FieldRow>
						);
					}
					
					if (field.data_type_name === 'Upload') {
						return (
							<FieldRow key={index} fieldLabel={field.field_name} fieldIcon={field.icon} isProcess visible={value !== undefined ? true : value?.length > 0} mandatory={false} error={field.errors} customIconBgColor={'white'} style={{ border: '0', marginRight: '10px' }}>
								<a key={index} href={value} download="Example-PDF-document" target="_blank" rel="noreferrer">
									<Button clear title={'Download File'} />
								</a>
							</FieldRow>
						);
					}

					if (field.data_type_name === 'Dropdown') {
						//let mname ='';
						if (field.options === 'modules'){
							value = allModules.find(mod=>mod.id === value)?.name;
						}

						// return (
							
						// 	<FieldRow
						// 		key={index}
						// 		width={isMobile ? '100%' : '50%'}
						// 		fieldLabel={field.field_name}
						// 		style={{ border: '0' }}
						// 		fieldIcon={field.icon}
						// 		isProcess
						// 		visible={field?.value !== undefined ? true : field.visible}
						// 		mandatory={false}
						// 		error={field.errors}
						// 		customIconBgColor={'white'}
						// 		notes={mname}
						// 	>
								
						// 		<input disabled={false} type={field.data_type} onChange={() => {}} autoComplete="none" value={mname} className={cls.fieldTypeInput} name={field.field_name} />
						// 	</FieldRow>
						// );
					}
					return (
						<FieldRow width={isMobile ? '100%' : '50%'} key={index} style={{ border: '0' }} fieldLabel={field.field_name} fieldIcon={field.icon} isProcess visible={field.visible} mandatory={false} error={field.errors}>
							{value?.length > 55 ? (
								<textarea className={cls.fieldTypeTextarea} disabled={true} onFocus={() => {}} required={true} value={value === null || value === undefined ? '' : field.data_type_name === 'Date' ? moment(new Date(value)).format('DD MMM YYYY') : value} onChange={e => {}} />
							) : (
								<input
									disabled={true}
									onChange={() => {}}
									autoComplete="none"
									value={value === null || value === undefined ? '' : (field.data_type_name === 'Dropdown' && field.options?.type === 'modules') ? allModules.find(mod=> mod.id=== field.value)?.name: field.data_type_name === 'Date' ? (moment(new Date(value)).format('DD MMM YYYY') === 'Invalid date' ? '' : moment(new Date(value)).format('DD MMM YYYY')) : value}
									className={cls.fieldTypeInput}
									name={field.field_name}
								/>
							)}
						</FieldRow>
					);
				}
			});

	return (
		<div className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '' }}>
			{allSelectedFields}
		</div>
	);
});

export default StaticAccordionModule;

export const StaticAccordionCreator = props => {
	const [showId, setShowId] = useState(false);
	const { component } = props;

	return (
		<div className={cls.accordionContainer} style={{ border: isMobile ? '0' : '' }}>
			<div className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '100%' }} onClick={() => setShowId(!showId)} >
				<FieldRow width={isMobile ? '100%' : '50%'} fieldLabel={'Created By'} style={{ border: '0' }} isProcess visible={true}>
					<input disabled={true} onChange={() => {}} autoComplete="none" value={`${component.created_by?.first_name} ${component.created_by?.last_name}`} className={cls.fieldTypeInput} />
				</FieldRow>
				<FieldRow width={isMobile ? '100%' : '50%'} fieldLabel={'Created Date'} style={{ border: '0' }} isProcess visible={true}>
					<input disabled={true} onChange={() => {}} autoComplete="none" value={moment(new Date(component.created_date)).format('DD MMM YYYY')} className={cls.fieldTypeInput} name={'Created Date'} />
				</FieldRow>
				<FieldRow width={isMobile ? '100%' : '50%'} fieldLabel={'Transaction ID'} style={{ border: '0' }} isProcess visible={showId}>
					<input disabled={true} onChange={() => {}} autoComplete="none" value={component.id} className={cls.fieldTypeInput} name={'CID'} />
				</FieldRow>
			</div>
		</div>
	);
};

export const StaticAccordionPayments = props => {
	const { component } = props;

	return (
		<div className={cls.accordionContainer} style={{ border: isMobile ? '0' : '', display: component.payments ? '' : 'none' }}>
			<div className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '100%' }}>
				<FieldRow width={isMobile ? '100%' : '50%'} fieldLabel={'Payments'} style={{ border: '0' }} isProcess visible={true}>
					<input disabled={true} onChange={() => {}} autoComplete="none" value={`${component.payments?.stripe_product}, ID: ${component.payments?.stripe_id.substring(0, 13)}`} className={cls.fieldTypeInput} />
				</FieldRow>
				<FieldRow width={isMobile ? '100%' : '50%'} fieldLabel={'Amount Paid'} style={{ border: '0' }} isProcess visible={true}>
					<input disabled={true} onChange={() => {}} autoComplete="none" value={`${component.payments?.stripe_price}`} className={cls.fieldTypeInput} />
				</FieldRow>
			</div>
		</div>
	);
};
