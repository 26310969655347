import { connect } from 'react-redux';
import cls from './Modules.module.scss';
import { useNavigate } from 'react-router-dom';
import { store } from '../../reduxStore/store';
import { setProcessModule, setCurrentTask, setShowMenu } from '../../reduxStore/actions/flowActions';

import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import { APP_NAME } from '../../shared/utility';

function ModulesMenu(props) {
	const { allModules, fixed = false, user } = props;
	const navigate = useNavigate();
	return (
		<div className={cls.modulesMenu}>
			<div className={cls.listContainerFixed} style={{flexDirection: isMobile ? 'column':''}}>
				{R.sortWith([R.ascend(R.prop('name'))])(allModules.filter(mod => ((mod?.options?.hide === false || (mod.options.hide === true && user.is_admin === true) || mod?.options?.hide === undefined)))).map((item, index) => {
					return (
						<button
							className={cls.listButton}
							style={{margin: '0 20px 0 0px', paddingLeft: isMobile ? '50px' : '', color: 'var(--app-color-gray-light)' }}
							key={index}
							onClick={() => {
								document.title = `${item.name} | ${APP_NAME}`;
								store.dispatch(setShowMenu(false));
								store.dispatch(setCurrentTask(undefined));
								store.dispatch(
									setProcessModule({
										...item.procedure,
										stepIndex: 0,
										module_id: item.id,
										module: item,
									})
								);
								navigate('/begin-module');
							}}
						>
							{item.name.toUpperCase()}
						</button>
					);
				})}
			</div>
		</div>
	);
}
const mapStateToProps = store => {
	const { allModules } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { allModules, user };
};

export default connect(mapStateToProps)(ModulesMenu);
