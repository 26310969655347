import React, { useRef, useState, memo, useCallback, useEffect } from 'react';
import cls from './CustomTemplateModule.module.scss';
import classNames from 'classnames';
import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
import { Button, ModalR, RenderYesNoSwitcher, SelectD } from '../../common';
import { connect } from 'react-redux';
import { computeFormula } from '../../../shared/utility';
import { processTaskAction, useProcessTaskContext } from '../../../context/processTask.context';
import { generateNewUUID } from '../../../shared/utility';
import CustomTemplateModuleList from './CustomTemplateModuleList';
import { createComponent, createFavorite, removeFavorite, updateComponent, updateComponentItem } from '../../../services/components.service';
import * as R from 'ramda';
import 'react-phone-input-2/lib/bootstrap.css';
import { Eyeglass, reset2 } from '../../../assets/images/icons';
import { isMobile } from 'react-device-detect';
import { Checkbox } from '@material-ui/core';
import { RenderComponentDetailView } from './ModuleStep';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ModuleLists from './ModuleLists';
import moment from 'moment';
import AccordionModule from './AccordionModule';
import { faBasketballBall, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomTemplateModule = props => {
	const { component, blueprintIndex, setIsMandatoryError, companyData, components, favorites, products, authenticatedUser, begunModule, selectedComponent, templateName } = props;

	const {
		editMode,
		blueprints,
		allModules,
		companyUsersArray,
		companyPositionsArray,
		nextButtonClicked,
		isMandatoryError,
		index,
		component_blueprint,
	} = props;
	const [processTaskContext, setTaskProcessContext] = useProcessTaskContext();
	const [setActive] = useState(cls.active);
	const [message, setMessage] = useState('');
	const content = useRef(null);

	const indexInContextArray = processTaskContext.currentStep?.component_blueprints?.findIndex(c => c.id === component?.id);

	const checkIfAreMandatoryErrored = () => {
		if (component?.selected_fields?.some(elem => elem.mandatory === true && elem.visible === true && (!elem.value || elem?.value?.length < 1))) {
			setIsMandatoryError(true);
		} else setIsMandatoryError(false);
	};

	const updateFormula = new_component => {
		const formula_component = processTaskContext.components.find(com => com.selected_fields.filter(sf => sf.data_type_name === 'Formula').length > 0);

		if (formula_component !== undefined) {
			const found = formula_component.selected_fields.find(field => field.data_type_name === 'Formula');

			const value = computeFormula(
				found.options,
				component.selected_fields.filter(x => found.options.list.includes(x.field_name)),
				[...processTaskContext.components, new_component]
			);
			found.value = `${value.toString()}`;
		} else {
			const found_cb = processTaskContext.currentStep.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Formula').length > 0);
			if (found_cb !== undefined) {
				const found = found_cb.selected_fields.find(sf => sf.data_type_name === 'Formula');
				const value = computeFormula(
					found.options,
					component.selected_fields.filter(x => found.options.list.includes(x.field_name)),
					[...processTaskContext.components, new_component]
				);
				found.value = `${value.toString()}`;
				const new_formula_component = JSON.parse(JSON.stringify({ ...found_cb, temp_id: generateNewUUID(), selected_fields: found_cb.selected_fields }));
				setTaskProcessContext({ action: processTaskAction.addModuleComponents, value: new_formula_component });
			}
		}
	};
	const saveButtonAction = () => {
		const new_component = JSON.parse(JSON.stringify({ ...component, temp_id: generateNewUUID(), selected_fields: component.selected_fields }));
		updateFormula(new_component);
		setTaskProcessContext({ action: processTaskAction.addModuleComponents, value: new_component });

		setMessage('');

		component.selected_fields.map((field, index) => {
			handleInputChange('', index);
		});

		setTaskProcessContext({
			action: processTaskAction.setModuleNewStepComponents,
			value: [...R.reject(c => c.selected_fields.filter(field => field.value === '').length > 0, [...R.reject(R.propEq('id', component.id))(processTaskContext.newStepComponents)])],
		});
	};

	const handleInputChange = (value, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		if (selectedElement?.data_type_name === 'Number') {
			if (Number(+value)) {
				selectedElement.value = value;
			} else if (value === '') {
				selectedElement.value = '';
			}
		} else selectedElement.value = value;
		if (selectedElement.value && selectedElement.mandatory) selectedElement.errors = '';
		checkIfAreMandatoryErrored();

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	};

	const handleCheckbox = (value, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.value = value;
		if (selectedElement.value && selectedElement.mandatory) selectedElement.errors = '';

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });

		checkIfAreMandatoryErrored();
	};

	const handlePrefixChange = useCallback((data, index, value) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.options.country_code = data?.countryCode;
		selectedElement.options.country_name = data?.name;
		selectedElement.options.country_prefix = `+${data?.dialCode}`;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
		handleInputChange(value, index);
	}, []);

	const handleCurrencyChange = useCallback((data, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.options.currency_type = data?.label;
		selectedElement.options.currency_value = data?.value;
		selectedElement.options.symbol = data?.symbol;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	}, []);

	const handleLocationChange = (location, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.value = `${location.lat}, ${location.lng}`;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	};

	const componentsToRender = () => {
		const global = processTaskContext.components
			.find(com => com.selected_fields.filter(sf => sf.data_type_name === 'Global' && sf.visible == true && sf.field_name === component.selected_fields.find(sfd => (sfd.data_type_name === 'Filters' || sfd.data_type_name === 'Record') && sfd.visible == true)?.options?.global).length > 0)
			?.selected_fields.find(sf => sf.data_type_name === 'Global')?.value;
		return global ? components.filter(com => com.component_fields.filter(sf => sf?.value?.toUpperCase() === global?.toUpperCase() && sf.field_name === component.selected_fields.find(sf => (sf.data_type_name === 'Filters' || sf.data_type_name === 'Record'))?.options?.global).length > 0) : components;
	};

	useEffect(() => {


		setTaskProcessContext({
			action: processTaskAction.setModuleNewStepComponents,
			value: processTaskContext.currentStep?.component_blueprints,
		});
	}, [processTaskContext.currentStep]);

	return (
		<div id="accordion" className={cls.section} key={blueprintIndex}>
			<div className={classNames(cls.accordionContainer, setActive)} app-variant-has-error={message ? 'error' : ''}>
				<div ref={content} style={{ maxHeight: setActive === cls.active ? '' : '0px', display: 'flex' }} className={cls.content}>
					<RenderCustomTemplateModule
						templateName={templateName}
						component={component}
						handleInputChange={handleInputChange}
						handlePrefixChange={handlePrefixChange}
						handleCurrencyChange={handleCurrencyChange}
						checkIfAreMandatoryErrored={checkIfAreMandatoryErrored}
						handleCheckbox={handleCheckbox}
						isInherited={false}
						indexInContextArray={indexInContextArray}
						handleLocationChange={handleLocationChange}
						processTaskContext={processTaskContext}
						setTaskProcessContext={setTaskProcessContext}
						companyData={companyData}
						components={componentsToRender()}
						products={products}
						saveButtonAction={saveButtonAction}
						updateFormula={updateFormula}
						favorites={favorites}
						user={authenticatedUser}
						begunModule={begunModule}
						selectedComponent={selectedComponent}
						allComponents={components}
						nextButtonClicked={nextButtonClicked}
						isMandatoryError={isMandatoryError}
						index={index}
						component_blueprint={component_blueprint}
						setIsMandatoryError={setIsMandatoryError}
						editMode={editMode}
						blueprints={blueprints}
						allModules={allModules}
						authenticatedUser={authenticatedUser}
						companyUsersArray={companyUsersArray}
						companyPositionsArray={companyPositionsArray}

					/>
				</div>
			</div>

			{message && message?.message && <MessageContainer message={message.message} />}
		</div>
	);
};

const RenderCustomTemplateModule = memo(props => {
	const { component, processTaskContext, setTaskProcessContext, allComponents, components, favorites = [], user, companyData, selectedComponent, templateName, begunModule, handleCheckbox } = props;

	const {
		nextButtonClicked,
		isMandatoryError,
		index,
		component_blueprint,
		setIsMandatoryError,
		editMode,
		blueprints,
		allModules,
		authenticatedUser,
		companyUsersArray,
		companyPositionsArray,
		products
	} = props;

	const [selectedItems, setSelectedItems] = useState({});
	const [orderItems, setOrderItems] = useState([]);
	const [listItems, setListItems] = useState([]);
	const [generalArray, setGeneralArray] = useState([]);
	const [timer, setTimer] = useState(0);

	const [records,] = useState(component.selected_fields.filter(sf => sf.data_type_name === 'Record'));

	const [showFavorites, setShowFavorites] = useState(false);
	const [showSecond, setShowSecond] = useState();
	const [action, setAction] = useState();

	const field = component.selected_fields.find(sf => sf.data_type_name === 'Filters' || sf.data_type_name === 'Record');
	const items = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === field?.options?.component_id).length > 0);
	const creating = selectedComponent?.created_by?.id === user.id || selectedComponent?.created_by === undefined;
	const template = companyData?.misc_info?.templatesData.find(template => template.name === templateName);

	//console.log('processTaskContext', processTaskContext);
	// console.log('template', template);
	//console.log('orderItems', orderItems);
	//console.log('listItems', listItems, orderItems)
	// console.log('actions', action)

	useEffect(() => {
		if (templateName === 'Scoresheet Template') {

			const runningScore = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.scores?.find(cfg => cfg.type === 'Text' && cfg.key === 'Running Score')?.label) ?? {};

			if (!runningScore?.value) {
				runningScore.value = JSON.stringify([]);
			} else {
				let scArray = [];
				JSON.parse(runningScore.value).map(sc => {
					const key = Object.keys(sc)[0];
					scArray = [...scArray, { key: key, home: `${sc[key].h ?? ''}`, away: `${sc[key].a ?? ''}`, notesH: `${sc[key].nh ?? ''}`, notesA: `${sc[key].na ?? ''}` }]
				})
				setGeneralArray(scArray);
			}

			if (records.length == 2) {

				const recordsF = processTaskContext.components?.find(cmp => cmp.selected_fields?.filter(cf => (cf?.value && cf.data_type_name === 'Record' && (cf.field_name === template.config.find(sc => sc.key === 'home')?.label || cf.field_name === template.config.find(sc => sc.key === 'away')?.label))).length > 0);
				if (recordsF) {

					const recordA = recordsF.selected_fields.find(sf => sf.data_type_name === 'Record' && (sf.field_name === template.config.find(sc => sc.key === 'home')?.label));
					const recordB = recordsF.selected_fields.find(sf => sf.data_type_name === 'Record' && (sf.field_name === template.config.find(sc => sc.key === 'away')?.label));
					records[0].value = recordA?.value;
					records[1].value = recordB?.value;

				}

				if (records[0].value && records[1].value) {
					setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[0].value)?.length > 0).map(it => {

						const photo = allComponents.filter(co => co.component_fields.find(cf => cf.value === it.component_fields.find(cf => cf.field_name === 'Player ID')?.value))?.find(co => co.component_fields.find(cf => cf.data_type_name === 'Link'))?.component_fields?.find(cf => cf.data_type_name === 'Link')?.value ?? '';
						const scores = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
						const misc = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
						const fouls = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
						return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {}, photo: photo };
					})));
					setOrderItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[1].value)?.length > 0).map(it => {

						const photo = allComponents.filter(co => co.component_fields.find(cf => cf.value === it.component_fields.find(cf => cf.field_name === 'Player ID')?.value))?.find(co => co.component_fields.find(cf => cf.data_type_name === 'Link'))?.component_fields?.find(cf => cf.data_type_name === 'Link')?.value ?? '';

						const scores = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
						const misc = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
						const fouls = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
						return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {}, photo: photo };
					})));
				}
			}
			const timeouts = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Timeouts')?.label) ?? {};
			if (timeouts) {
				timeouts.value = timeouts?.value ?? JSON.stringify({ home: { first: 0, second: 0, ot: 0, ot2: 0 }, away: { first: 0, second: 0, ot: 0, ot2: 0 } })
			}

			const fouls = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Fouls')?.label) ?? {};
			if (fouls) {
				fouls.value = fouls?.value ?? JSON.stringify({ home: { first: 0, second: 0, third: 0, fourth: 0 }, away: { first: 0, second: 0, third: 0, fourth: 0 } })
			}
			const teamNotes = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Team Notes')?.label);
			if (!teamNotes?.value) {
				teamNotes.value = JSON.stringify({ home: '', away: '', p1: '', p2: '', p3: '', p4: '', p5: '' });
			}


			const scores = processTaskContext.newStepComponents?.find(cmp => cmp.selected_fields?.filter(cf => cf.field_name === template.scores.find(sc => sc.key === 'home')?.type).length > 0);

			const home = scores?.selected_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'home')?.label)
			if (home) {
				home.value = teamScore(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[0].value)?.length > 0).map(it => {
					const scores = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
					const misc = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
					const fouls = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
					return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
				})));
			}

			const away = scores?.selected_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'away')?.label)
			if (away) {
				away.value = teamScore(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[1].value)?.length > 0).map(it => {
					const scores = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
					const misc = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
					const fouls = JSON.parse(processTaskContext.items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
					return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
				})));
			}
		}
	}, [records]);

	if (templateName === 'Battery Order Template') {

		const quantity_key = template?.config.find(cfg => cfg.key === 'quantity')?.label ?? 'Quantity';
		const alternate_key = template?.config.find(cfg => cfg.key === 'alt_value')?.label ?? 'Actual';
		const item_code_key = template?.config.find(cfg => cfg.key === 'item_code')?.label ?? 'Product ID';
		const list = template?.list ?? [];

		const buildFavoritesList = list => {
			let favoritesList = [];

			list.filter(fave => {
				const found = items?.find(item => item.component_fields.filter(cf => cf.value === fave.component_id)?.length > 0);
				if (found) {
					favoritesList = [...favoritesList, found];
				}
			});

			setListItems(favoritesList);
			setOrderItems(
				favoritesList?.map(lineItem => {
					let object = {};
					component.selected_fields.filter(fd => {
						if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
							object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
						}
					});
					return object;
				})
			);
		};

		const computeBatteryOrderTemplate = (lineItem, index, price) => {
			const targetValue = lineItem.component_fields.find(cf => cf.field_name === component.options?.key);
			const found = processTaskContext.items?.find(item => item.item_code === targetValue.value);

			if (found === undefined) {
				const new_component = { alt_value: orderItems[index][alternate_key] ?? 0, quantity: orderItems[index][quantity_key] ?? 0, item_code: targetValue.value, id: generateNewUUID(), value: parseFloat(price * +orderItems[index][quantity_key]).toFixed(2) ?? 0 };
				setTaskProcessContext({ action: processTaskAction.addModuleItems, value: new_component });
			} else {
				if (+orderItems[index][quantity_key] == 0) {
					setTaskProcessContext({ action: processTaskAction.removeModuleItems, value: found });
				} else {
					found.quantity = orderItems[index][quantity_key];
					found.alt_value = orderItems[index][alternate_key];
					found.value = parseFloat(price * +orderItems[index][quantity_key]).toFixed(2) ?? 0;
					setTaskProcessContext({ action: processTaskAction.updateModuleItems, value: found });
				}
			}
		};

		return (
			<div style={{ width: '100%', justifyContent: 'center' }}>
				<div>
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: '50px', width: '100%' }}>
						<div style={{ disabled: true, width: '250px', display: isMobile ? 'none' : 'flex', flexDirection: 'column', marginRight: '20px', marginTop: `${280 + (processTaskContext?.items?.length ?? 1) * 60}px`, fontSize: '18px' }}>
							Filters:
							{field?.options?.filters?.length > 0 && (
								<div className={cls.dropdownContainer}>
									<SelectD
										items={
											items
												? R.uniqWith(R.eqProps('value'))(
													items.map(item => {
														const found = item.component_fields.find(sf => sf.field_name === field?.options?.filters[0]);
														return { label: found?.value, value: found?.value, component: item };
													})
												)
												: []
										}
										onChange={value => {
											setShowFavorites(false);
											setOrderItems([]);
											setSelectedItems({ [field?.options?.filters[0]]: value.value });
											setListItems([]);
											setShowSecond(false);
											setTimeout(() => setShowSecond(true), 10);
											setListItems(items?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0));
											setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
											setOrderItems(
												items
													?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0)
													.map(lineItem => {
														let object = {};
														component.selected_fields.filter(fd => {
															if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
																object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
															}
														});
														return object;
													})
											);
										}}
										placeholder={field?.options?.filters[0]}
										moliclassname={cls.componentsSelectMenuOverlayListItem}
										className={cls.currencyInput}
									/>
								</div>
							)}
							<div style={{ height: '10px' }} />
							{field?.options?.filters?.length > 1 && !isMobile && showSecond && (
								<div className={cls.dropdownContainer}>
									<SelectD
										items={
											selectedItems[field?.options?.filters[0]]
												? R.uniqWith(R.eqProps('value'))(
													items
														.map(item => {
															const found = item.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
															return { label: found?.value, value: found?.value, component: item };
														})
														.filter(found => found.component.component_fields.filter(cf => cf.value === selectedItems[field?.options?.filters[0]] && cf.field_name === field?.options?.filters[0]).length > 0)
												)
												: []
										}
										onChange={value => {
											setShowFavorites(false);
											setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
											setSelectedItems({ ...selectedItems, [field?.options?.filters[1]]: value.value });
											setOrderItems(
												items
													?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0)
													.filter(item => item.component_fields.filter(cf => cf.value === selectedItems[field?.options?.filters[0]])?.length > 0)
													.map(lineItem => {
														let object = {};
														component.selected_fields.filter(fd => {
															if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
																object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
															}
														});
														return object;
													})
											);
											setListItems(items?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0).filter(item => item.component_fields.filter(cf => cf.value === selectedItems[field?.options?.filters[0]])?.length > 0));
										}}
										placeholder={field?.options?.filters[1]}
										style={{ fontSize: '16px' }}
										moliclassname={cls.componentsSelectMenuOverlayListItem}
									/>
								</div>
							)}
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '80%' }}>
							Orders
							<CustomTemplateModuleList
								className={cls.listContainer}
								highlights={'var(--app-color-gray-lighter)'}
								columns={
									isMobile
										? companyData?.misc_info?.templatesData
											.find(template => template.name === templateName)
											.config?.filter(cfg => cfg.value === 'Text' || cfg.value === 'Number' || cfg.value === 'Connector')
											.map(cf => {
												return cf.label;
											})
											.slice(0, 2)
										: companyData?.misc_info?.templatesData
											.find(template => template.name === templateName)
											.config?.filter(cfg => cfg.value === 'Text' || cfg.value === 'Number' || cfg.value === 'Connector')
											.map(cf => {
												return cf.label;
											})
								}
								footer={
									isMobile
										? [`Totals`, `${R.reduce((a, b) => +a + +b.quantity ?? 0, 0, processTaskContext?.items ?? [])}`]
										: ['Totals:', `${R.reduce((a, b) => +a + +b.quantity ?? 0, 0, processTaskContext?.items ?? [])}`, `${R.reduce((a, b) => +a + (isNaN(+b.alt_value) ? 0 : +b.alt_value ?? 0), 0, processTaskContext?.items ?? [])}`]
								}
								list={processTaskContext?.items?.map(item => {
									const code = item.item_code;
									const qty = item.quantity ?? 0;
									const actual = item.alt_value ?? 0;
									return {
										List: (
											<div className={cls.templateInformation} style={{ display: 'flex', flex: '1' }}>
												<div style={{ width: '40%' }}>{code}</div>
												<div style={{ width: '20%' }}>{qty}</div>
											</div>
										),
										[component.options.key]: (
											<div className={cls.templateInformation}>
												<span>{code}</span>
											</div>
										),
										Quantity: (
											<div className={cls.templateInformation}>
												<span>{qty}</span>
											</div>
										),
										Actual: (
											<div className={cls.templateInformation}>
												<span>{actual}</span>
											</div>
										),
										id: item.id,
										code: code,
									};
								})}
								onListItemClick={item => {
									setOrderItems(
										items
											?.filter(it => it.component_fields.filter(cf => cf.value === item.code)?.length > 0)
											.map(lineItem => {
												let object = {};
												component.selected_fields.filter(fd => {
													if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
														object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
													}
												});

												const value = processTaskContext.items?.find(it => it.item_code === object[item_code_key]);

												object[alternate_key] = value.alt_value;
												object[quantity_key] = value.quantity;

												return object;
											})
									);
									setListItems(components?.filter(it => it.component_fields.filter(cf => cf.value === item.code)?.length > 0));
								}}
							/>
							<br />
							<div className={cls.searchContainer} style={{ display: showFavorites ? 'none' : '' }}>
								<Eyeglass />
								<input
									placeholder="Search"
									className={cls.searchInput}
									onChange={val => {
										if (val.target.value?.length > 1) {
											setOrderItems(
												components
													.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === field.options.component_id).length > 0)
													?.filter(item => item.component_fields.filter(cf => cf.value.includes(val.target.value))?.length > 0)
													.map(lineItem => {
														let object = {};
														component.selected_fields.filter(fd => {
															if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
																object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
															}
														});
														return object;
													})
											);
											setListItems(items?.filter(item => item.component_fields.filter(cf => cf.value.includes(val.target.value))?.length > 0));
										} else if (val.target.value?.length == 0) {
											setOrderItems([]);
											setListItems([]);
										}
									}}
								/>
							</div>
							<CustomTemplateModuleList
								className={cls.mobileTable}
								style={{ width: '100%' }}
								columns={isMobile ? [component?.name ?? 'Item'] : [...list]}
								list={listItems.map((lineItem, index) => {
									const name = `${lineItem.component_fields.find(cf => cf.field_name === 'Name')?.value}`;
									const range = `${lineItem.component_fields.find(cf => cf.field_name === 'Range')?.value}`;
									const application = `${lineItem.component_fields.find(cf => cf.field_name === 'Application')?.value}`;
									const link = lineItem.component_fields.find(cf => cf.data_type_name === 'Link' && cf.field_name !== 'Picture')?.value;
									const picture = lineItem.component_fields.find(cf => cf.data_type_name === 'Link' && cf.field_name === 'Picture')?.value;

									const keyObj = companyData?.misc_info?.templatesData.find(template => template.name === 'Battery Order Template').config.find(cfg => cfg.label === component.options.key);
									const value = processTaskContext.items?.find(it => it[keyObj.key] === orderItems[index][component.options.key]);

									const qty = value ? +value['quantity'] : 0;
									const actual = value ? +value['alt_value'] : 0;

									let object = {};
									list.filter(li => {
										object[li] = <div className={cls.templateInformation}>{`${lineItem.component_fields.find(cf => cf.field_name === li)?.value ?? ''}`}</div>;
									});

									object[component?.name ?? 'Item'] = (
										<div>
											<div style={{ display: 'flex' }}>
												<div style={{ display: '', width: '70%' }}>
													<div className={cls.templateInformation}>{name}</div>
												</div>
												<input
													autoComplete="off"
													className={cls.templateInput}
													style={{ border: '1px solid var(--app-color-gray-light)', width: '30%', height: '30px' }}
													placeholder={qty}
													onChange={e => {
														if (+e.target.value > -1) {
															orderItems[index][quantity_key] = +e.target.value;
														}
													}}
													onBlur={() => {
														setOrderItems([...orderItems]);
														computeBatteryOrderTemplate(lineItem, index, 0);
													}}
												/>
											</div>
										</div>
									);

									object.Quantity = creating ? (
										<div className={cls.templateAddBox}>
											<Button
												clear
												className={cls.templatePlus}
												clicked={() => {
													if (qty > 0) {
														orderItems[index][quantity_key] = qty - 1;
														orderItems[index][alternate_key] = actual - 1;
														setOrderItems([...orderItems]);
														computeBatteryOrderTemplate(lineItem, index, 0);
													}
												}}
											>
												-
											</Button>
											<input
												autoComplete="off"
												className={cls.templateInput}
												placeholder={value ? value['quantity'] : 0}
												onChange={e => {
													if (+e.target.value > -1) {
														orderItems[index][quantity_key] = +e.target.value;
														orderItems[index][alternate_key] = +e.target.value;
													}
												}}
												onBlur={() => {
													setOrderItems([...orderItems]);
													computeBatteryOrderTemplate(lineItem, index, 0);
												}}
											/>
											<Button
												clear
												className={cls.templatePlus}
												clicked={() => {
													orderItems[index][quantity_key] = qty + 1;
													orderItems[index][alternate_key] = actual + 1;
													setOrderItems([...orderItems]);
													computeBatteryOrderTemplate(lineItem, index, 0);
												}}
											>
												+
											</Button>
										</div>
									) : (
										<div className={cls.templateInput}>{value ? value['quantity'] : 0}</div>
									);

									object.Action = (
										<Button
											title={favorites.filter(fave => fave.component_id === name)?.length > 0 ? 'Remove from Favorites' : 'Add to Favorites'}
											clicked={() => {
												if (favorites.filter(fave => fave.component_id === name)?.length > 0) {
													removeFavorite(favorites.find(fave => fave.component_id === name).id).subscribe({
														next: () => {
															buildFavoritesList(favorites.filter(fave => fave.component_id !== name));
														},
													});
												} else {
													createFavorite(user.id, name, 'components').subscribe({});
												}
											}}
											titleStyle={{ fontSize: '12px' }}
											style={{ width: '160px', height: '30px' }}
										/>
									);
									object.Info = <img alt="lg" src={picture} className={cls.templatePicture} onClick={() => window.open(link, '_blank')} />;
									object.Name = <div className={cls.templateInformation}>{name}</div>;
									object.Application = <div className={cls.templateInformation}>{application}</div>;
									object.Range = <div className={cls.templateInformation}>{range}</div>;
									object.Actual = (
										<div className={cls.templateAddBox} style={{ padding: '0 10px 0 10px', border: creating ? '0' : '1px solid var(--app-color-gray-lighter)' }}>
											<input
												disabled={creating}
												autoComplete="off"
												className={cls.templateInput}
												placeholder={actual}
												onChange={e => {
													if (+e.target.value > -1) {
														orderItems[index][alternate_key] = +e.target.value;
														orderItems[index][quantity_key] = qty;
													}
												}}
												onBlur={() => {
													setOrderItems([...orderItems]);
													computeBatteryOrderTemplate(lineItem, index, 0);
												}}
											/>
										</div>
									);
									return object;
								})}
							/>
							{listItems?.length === 0 && <p>Please select an item</p>}
							<Button
								light
								title={showFavorites ? 'Hide Favorites' : 'Show Favorites'}
								clicked={() => {
									setShowFavorites(!showFavorites);
									if (showFavorites === false) {
										buildFavoritesList(favorites);
									} else {
										setOrderItems([]);
										setListItems([]);
									}
								}}
								style={{ width: '160px', display: isMobile ? 'none' : '' }}
							/>
						</div>
					</div>

					{isMobile && (
						<div className={cls.dropdownContainer}>
							<SelectD
								items={R.uniqWith(R.eqProps('value'))(
									items.map(item => {
										const found = item.component_fields.find(sf => sf.field_name === field?.options?.filters[0]);
										return { label: found?.value, value: found?.value, component: item };
									})
								)}
								onChange={value => {
									setShowFavorites(false);
									setOrderItems([]);
									setSelectedItems({ [field?.options?.filters[0]]: value.value });
									setListItems([]);
									setShowSecond(false);
									setTimeout(() => setShowSecond(true), 10);
									setListItems(items?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0));
									setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
									setOrderItems(
										items
											?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0)
											.map(lineItem => {
												let object = {};
												component.selected_fields.filter(fd => {
													if (fd.data_type_name === 'Text' || fd.data_type_name === 'Number') {
														object = { ...object, [fd.field_name]: lineItem.component_fields.find(li => li.field_name === fd.field_name)?.value ?? 0 };
													}
												});
												return object;
											})
									);
								}}
								style={{ fontSize: '16px' }}
								moliclassname={cls.componentsSelectMenuOverlayListItem}
							/>
						</div>
					)}
					<br />
				</div>

			</div>);
	}

	if (templateName === 'Order Template') {

		const record = component.selected_fields.find(sf => sf.data_type_name === 'Record');
		const itemsR = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === record?.options?.component_id)?.length > 0);

		const computeSalesOrderTemplate = (lineItem, index, price) => {
			const targetValue = lineItem.component_fields.find(cf => cf.field_name === template?.key?.label);
			const found = processTaskContext.items?.find(item => item[template?.key?.key] === targetValue.value);

			if (found === undefined) {
				if (+orderItems[index]?.quantity == undefined || +orderItems[index]?.quantity > 0) {
					let new_component = {};
					template?.slist.filter(sl => {
						new_component = { ...new_component, [sl.key]: orderItems[index][sl.key] ?? '', id: generateNewUUID(), options: sl?.options ?? {} };
					});
					setTaskProcessContext({ action: processTaskAction.addModuleItems, value: new_component });
				}
			} else {
				if (+orderItems[index]?.quantity == 0) {
					setTaskProcessContext({ action: processTaskAction.removeModuleItems, value: found });
				} else {
					template?.slist.filter(sl => {
						found[sl.key] = orderItems[index][sl.key];
					});
					setTaskProcessContext({ action: processTaskAction.updateModuleItems, value: found });
				}
			}
		};

		const checkHtmlElement = (value, type, index, lineItem, key, label) => {
			if (type === 'Number') {
				return creating ? (
					<input
						autoComplete="off"
						type="number"
						className={cls.templateIIInput}
						style={{ border: '1px solid var(--app-color-gray-light)', width: '50%', height: '30px' }}
						placeholder={value ?? '0'}
						onChange={e => {
							if (+e.target.value > -1) {
								orderItems[index][key] = +e.target.value;
								orderItems[index][label] = +e.target.value;
							}
						}}
						onBlur={() => {
							setOrderItems([...orderItems]);
							computeSalesOrderTemplate(lineItem, index, 0);
						}}
					/>
				) : (
					<div className={cls.templateInput}>{value.value ?? '0'}</div>
				);
			} else if (type === 'Action') {
				return (
					<div />
				);
			} else if (type === 'Link') {
				const img = `${value}`;
				if (img?.includes('.jpg') || img?.includes('.png') || img?.includes('.jpeg')) {
					return <img alt="lg" src={img} className={cls.templateIIPicture} />;
				} else {
					return <p className={cls.templateURL} onClick={() => window.open(img, '_blank')}>{`View`}</p>;
				}
			} else if (type === 'Info' || type === 'Text') {
				return (
					<div className={cls.templateInformation} style={{ textAlign: 'center' }}>
						{value ?? ''}
					</div>
				);
			} else {
				return <div>{value ?? ''}</div>;
			}
		};

		return (
			<div style={{ width: '100%', justifyContent: 'center' }}>

				<div style={{ margin: 'auto', width: 'inherit' }}>
					<div style={{ width: 'inherit' }}>
						<p>{template?.config?.find(option => option.key === 'tlist')?.label ?? 'ORDERS:'}</p>
						<CustomTemplateModuleList
							className={cls.itemsTable_ii}
							style={{ width: template?.tlist?.length > 4 ? '100%' : '70%' }}
							highlights={'var(--app-color-gray-lighter)'}
							columns={
								isMobile
									? template?.tlist
										.filter(sl => sl.value === 'Number' || sl.key === template?.key?.key || sl.value === 'Info' || sl.value === 'Text')
										.map(s => {
											return s.label;
										})
									: template?.tlist?.map(cf => {
										return cf.label;
									})
							}
							footer={[`Items: ${processTaskContext?.items?.length}`, `${R.reduce((a, b) => +a + +b.quantity ?? 0, 0, processTaskContext?.items ?? [])}`]}
							list={processTaskContext?.items?.map(item => {
								let object = { id: item.id };
								template?.tlist.filter(it => {
									object = { ...object, [it.label]: item[it.key] };
								});

								return object;
							})}
							onListItemClick={item => {
								setOrderItems(
									itemsR
										?.filter(it => it.component_fields.filter(cf => cf.value === item[template?.key?.label])?.length > 0)
										.map(lineItem => {
											let object = {};

											template?.slist.filter(s => {
												object = {
													...object,
													[s.label]: lineItem.component_fields.find(li => li.field_name === s.label)?.value ?? 0,
													[s.key]: lineItem.component_fields.find(li => li.field_name === s.label)?.value ?? 0,
												};
											});

											return object;
										})
								);
								setListItems(itemsR?.filter(it => it.component_fields.filter(cf => cf.value === item[template?.key?.label])?.length > 0));
							}}
						/>
						<br />

						<div style={{ width: '100%', display: isMobile ? '' : showFavorites ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '18px' }}>
							{record?.options?.filters?.length > 0 && (
								<div className={cls.dropdownContainer} style={{ border: '1px solid var(--app-color-gray-dark)', width: isMobile ? '100%' : '25%' }}>
									<SelectD
										items={
											itemsR
												? R.uniqWith(R.eqProps('value'))(
													itemsR.map(item => {
														const found = item.component_fields.find(sf => sf.field_name === record?.options?.filters[0]);
														return { label: found?.value, value: found?.value, component: item };
													})
												)
												: []
										}
										onChange={value => {
											setShowFavorites(false);
											setOrderItems([]);
											setSelectedItems({ [record?.options?.filters[0]]: value.value });
											setListItems([]);
											setShowSecond(false);
											setTimeout(() => setShowSecond(true), 10);
											setListItems(itemsR?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0));
											setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
											setOrderItems(
												itemsR
													?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0)
													.map(lineItem => {
														let object = {};

														template?.slist.filter(sl => {
															const val = lineItem.component_fields.find(li => li.field_name === sl.label)?.value ?? '';
															object = {
																...object,
																[sl.label]: val,
																[sl.key]: val,
															};
														});

														return object;
													})
											);
										}}
										placeholder={record?.options?.filters[0]}
										moliclassname={cls.componentsSelectMenuOverlayListItem}
										style={{ fontSize: '16px' }}
										className={cls.currencyInput}
									/>
								</div>
							)}

							{record?.options?.filters?.length > 1 && !isMobile && showSecond && (
								<div className={cls.dropdownContainer} style={{ marginLeft: '10px', border: '1px solid var(--app-color-gray-dark)', width: '25%' }}>
									<SelectD
										items={
											selectedItems[record?.options?.filters[0]]
												? R.uniqWith(R.eqProps('value'))(
													itemsR
														.map(item => {
															const found = item.component_fields.find(sf => sf.field_name === record?.options?.filters[1]);
															return { label: found?.value, value: found?.value, component: item };
														})
														.filter(found => found.component.component_fields.filter(cf => cf.value === selectedItems[record?.options?.filters[0]] && cf.field_name === record?.options?.filters[0]).length > 0)
												)
												: []
										}
										onChange={value => {
											setShowFavorites(false);
											setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
											setSelectedItems({ ...selectedItems, [record?.options?.filters[1]]: value.value });
											setOrderItems(
												itemsR
													?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0)
													.filter(item => item.component_fields.filter(cf => cf.value === selectedItems[record?.options?.filters[0]])?.length > 0)
													.map(lineItem => {
														let object = {};

														template?.slist.filter(sl => {
															const val = lineItem.component_fields.find(li => li.field_name === sl.label)?.value ?? 0;
															object = {
																...object,
																[sl.label]: val,
																[sl.key]: val,
															};
														});
														return object;
													})
											);
											setListItems(itemsR?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0).filter(item => item.component_fields.filter(cf => cf.value === selectedItems[record?.options?.filters[0]])?.length > 0));
										}}
										placeholder={record?.options?.filters[1]}
										style={{ fontSize: '16px' }}
										moliclassname={cls.componentsSelectMenuOverlayListItem}
									/>
								</div>
							)}
						</div>
						<div className={cls.searchContainer} style={{ display: showFavorites ? 'none' : '', width: isMobile ? '100%' : '400px' }}>
							<Eyeglass />
							<input
								placeholder="Search"
								className={cls.searchInput}
								onChange={val => {
									if (val.target.value?.length > 1) {
										setListItems(itemsR?.filter(item => item.component_fields.filter(cf => cf.value.includes(val.target.value))?.length > 0));
										setOrderItems(
											itemsR
												?.filter(item => item.component_fields.filter(cf => cf.value.includes(val.target.value))?.length > 0)
												.map(lineItem => {
													let object = {};

													template?.slist.filter(sl => {
														const val = lineItem.component_fields.find(li => li.field_name === sl.label)?.value ?? '';
														object = {
															...object,
															[sl.label]: val,
															[sl.key]: val,
														};
													});

													return object;
												})
										);
									} else if (val.target.value?.length == 0) {
										setOrderItems([]);
										setListItems([]);
									}
								}}
							/>
						</div>
						<CustomTemplateModuleList
							className={cls.mobileTable_ii}
							style={{ width: isMobile ? '100%' : template?.slist?.length > 4 ? '100%' : '70%' }}
							tableHostSimple={cls.tableHostSimple}
							columns={
								isMobile
									? template?.slist
										.filter(sl => sl.value === 'Number' || sl.key === template?.key?.key)
										.map(s => {
											return s.label;
										})
									: begunModule.processModule.module.options?.computes?.length > 0
										? [
											...(template?.slist.map(s => {
												return s.label;
											}) ?? []),

											...(begunModule.processModule.module.options?.computes?.map(rule => {
												const componentR = components.filter(com => com.module_id === rule.module_id)?.find(com => com.component_fields.filter(cf => cf.procedure_step_blueprint_field_id === rule.field_id).length > 0);
												const fieldR = componentR?.component_fields.find(cf => cf.procedure_step_blueprint_field_id === rule.field_id);
												return fieldR.field_name;
											}) ?? []),
										]
										: [
											...(template?.slist.map(s => {
												return s.label;
											}) ?? []),
										]
							}
							list={listItems.map((lineItem, index) => {
								let object = {};

								template?.slist?.filter(li => {
									object[li.label] = checkHtmlElement(`${lineItem.component_fields.find(cf => cf.field_name === li.label)?.value ?? ''}`, li.value, index, lineItem, li.key, li.label);
								});

								const value = processTaskContext.items?.find(it => it[template?.key?.key] === orderItems[index][template?.key?.key]) ?? undefined;
								if (value !== undefined) {
									Object.keys(value).filter(key => {
										const found = template?.slist?.find(sl => sl.key === key);
										if (found) {
											object[found.label] = checkHtmlElement(value[key], found.value, index, lineItem, found.key, found.label);
										}
									});
								}

								if (begunModule.processModule.module.options?.computes?.length > 0) {
									begunModule.processModule.module.options?.computes?.map(rule => {
										const componentR = components?.filter(com => com.module_id === rule.module_id)?.find(com => com.component_fields.filter(cf => cf.value === (lineItem.component_fields.find(cf => cf.field_name === template?.key?.label)?.value ?? '#$')).length > 0);
										const fieldR = componentR?.component_fields.find(cf => cf.procedure_step_blueprint_field_id === rule.field_id);
										if (fieldR) {
											object[fieldR.field_name] = fieldR?.value;
										}
									});
								}

								return object;
							})}
						/>
						{listItems?.length === 0 && <p>No Items Selected</p>}
					</div>
				</div>

				<br />
			</div>
		);
	}

	if (templateName === 'Scoresheet Template') {

		const itemsR0 = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0);
		const itemsR1 = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0);
		const playerKey = template?.key?.key ?? '-';
		const scores = processTaskContext.newStepComponents?.find(cmp => cmp.selected_fields?.filter(cf => cf.field_name === template.scores.find(sc => sc.key === 'home')?.type).length > 0);
		const home = scores?.selected_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'home')?.label)
		const away = scores?.selected_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'away')?.label)
		const period = component.selected_fields.find(sf => sf.data_type_name === 'Dropdown' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Status' && cfg.key === 'Period')?.label);
		const timeouts = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Timeouts')?.label);
		const fouls = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Fouls')?.label);
		const maxFouls = processTaskContext.components?.find(cmp => cmp.selected_fields?.filter(cf => cf.field_name === template.config.find(sc => sc.key === 'Max Fouls')?.label).length > 0)?.selected_fields?.find(cf => cf.field_name === template.config.find(sc => sc.key === 'Max Fouls')?.label)?.value ?? 5;
		const teamNotes = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Text' && cfg.key === 'Team Notes')?.label);
		const runningScore = component.selected_fields.find(sf => sf.data_type_name === 'Text' && sf.field_name === template?.scores?.find(cfg => cfg.type === 'Text' && cfg.key === 'Running Score')?.label);
		const startingMinutes = processTaskContext.components?.find(cmp => cmp.selected_fields?.filter(cf => cf.field_name === template.config.find(sc => sc.key === 'Time')?.label).length > 0)?.selected_fields?.find(cf => cf.field_name === template.config.find(sc => sc.key === 'Time')?.label)?.value ?? 10;
		let countdownTimerElementDisplay = document.getElementById('timer_count');

		const possession = (value) => {
			if (teamNotes?.value) {
				const notes = JSON.parse(teamNotes.value);
				notes.poss = value ? value : notes?.poss === 'home' ? 'away' : 'home';
				teamNotes.value = JSON.stringify(notes);
			}
		}

		const arrow = (value) => {
			if (teamNotes?.value) {
				const notes = JSON.parse(teamNotes.value);
				notes.arrow = value ? value : notes?.arrow === 'home' ? 'away' : 'home';
				teamNotes.value = JSON.stringify(notes);
			}
		}
		const validScore = (score) => {
			if (score.split('').filter(sc => (!(sc === 'X' || sc === 'x' || sc === '0') && (sc < 0 || sc > 3))).length > 0) {
				return false;
			}
			return true;
		}
		const checkHtmlElement = (value, type, index, player, score, label, items, setItems) => {

			const name = player.component_fields.find(cf => cf.field_name === 'Player')?.value;

			if (type === 'Fouls') {
				const textPeriod = period.value === '1st' ? 'first' : period.value === '2nd' ? 'second' : period.value === '3rd' ? 'third' : 'ot';

				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.fst ?? ''}
							placeholder='1'
							onChange={e => player.fouls.fst = e.target.value}
							onBlur={() => {
								if (player.fouls.fst?.length > 0) {

									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), fst: player.fouls.fst });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));


								}
							}}
						/>
						<input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.snd ?? ''}
							placeholder='2'
							onChange={e => player.fouls.snd = e.target.value}
							onBlur={() => {
								if (player.fouls.snd?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), snd: player.fouls.snd });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
						<input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.trd ?? ''}
							placeholder='3'
							onChange={e => player.fouls.trd = e.target.value}
							onBlur={() => {
								if (player.fouls.trd?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), trd: player.fouls.trd });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
						<input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.fth ?? ''}
							placeholder='4'
							onChange={e => player.fouls.fth = e.target.value}
							onBlur={() => {
								if (player.fouls.fth?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), fth: player.fouls.fth });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
						<input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.fif ?? ''}
							placeholder='5'
							onChange={e => player.fouls.fif = e.target.value}
							onBlur={() => {
								if (player.fouls.fif?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), fif: player.fouls.fif });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
						{maxFouls > 5 && <input autoComplete="off" type="text" className={cls.templateFLInput}
							defaultValue={player?.fouls?.sth ?? ''}
							placeholder='6'
							onChange={e => player.fouls.sth = e.target.value}
							onBlur={() => {
								if (player.fouls.sth?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.alt_value = JSON.stringify({ ...JSON.parse(found.alt_value), sth: player.fouls.sth });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>}
					</div>
				);
			} else if (type === 'firstQtr') {
				return (
					<div >
						<input id={`${player.id}1st`} autoComplete="off" type="text" className={cls.templateSCInput}
							readOnly={period ? period.value !== '1st' ? true : false : (listItems.filter(it => it?.scores?.snd).length > 0 || orderItems.filter(it => it?.scores?.snd).length > 0) ? true : false}
							defaultValue={player?.scores?.fst ?? ''}
							onChange={e => player.scores.fst = validScore(e.target.value) ? e.target.value : player.scores.fst}
							onBlur={() => {
								if (player.scores.fst?.length > 0) {
									const tot = teamScore([...items.filter(it => it.id !== player.id), player]);
									const diff = tot - (score?.value ?? 0);

									if (diff >= 0) {
										score.value = tot;
										const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
										slot[label] = player.key;

										if (showFavorites && diff > 0) {
											slot.notesH = countdownTimerElementDisplay.textContent;
										}

										setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);
										const rs = JSON.parse(runningScore?.value)
										if (rs.find(sc => sc[+tot])) {
											const sc = rs.find(scr => scr[+tot]);
											if (label === 'home') {
												sc[+tot].h = player.key;
											} else {
												sc[+tot].a = player.key;
											}
											runningScore.value = JSON.stringify(rs);
										} else {
											runningScore.value = JSON.stringify([...rs, { [+tot]: label === 'home' ? { h: player.key } : { a: player.key } }]);
										}
										let found = processTaskContext.items.find(it => it.item_code === player.id);
										if (!found) {
											found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.quantity = JSON.stringify({ ...JSON.parse(found.quantity), fst: player.scores.fst });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}


										setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
									}
								}
							}}
						/>

					</div>
				);
			} else if (type === 'secondQtr') {
				return (
					<div >
						<input id={`${player.id}2nd`} autoComplete="off" type="text" className={cls.templateSCInput}
							readOnly={period ? period.value !== '2nd' ? true : false : (listItems.filter(it => it?.scores?.trd).length > 0 || orderItems.filter(it => it?.scores?.trd).length > 0) ? true : false}
							defaultValue={player?.scores?.snd ?? ''}
							onChange={e => player.scores.snd = validScore(e.target.value) ? e.target.value : player.scores.snd}
							onBlur={() => {
								if (player.scores.snd?.length > 0) {
									const tot = teamScore([...items.filter(it => it.id !== player.id), player]);
									const diff = tot - (score?.value ?? 0);

									if (diff >= 0) {
										score.value = tot;
										const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
										slot[label] = player.key;

										if (showFavorites && diff > 0) {
											slot.notesH = countdownTimerElementDisplay.textContent;
										}

										setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);
										const rs = JSON.parse(runningScore?.value)
										if (rs.find(sc => sc[+tot])) {
											const sc = rs.find(scr => scr[+tot]);
											if (label === 'home') {
												sc[+tot].h = player.key;
											} else {
												sc[+tot].a = player.key;
											}
											runningScore.value = JSON.stringify(rs);
										} else {
											runningScore.value = JSON.stringify([...rs, { [+tot]: label === 'home' ? { h: player.key } : { a: player.key } }]);
										}
										let found = processTaskContext.items.find(it => it.item_code === player.id)
										if (!found) {
											found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.quantity = JSON.stringify({ ...JSON.parse(found.quantity), snd: player.scores.snd });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}


									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
								}
							}}
						/>
					</div>
				);

			} else if (type === 'thirdQtr') {
				return (
					<div >
						<input id={`${player.id}3rd`} autoComplete="off" type="text" className={cls.templateSCInput}
							readOnly={period ? period.value !== '3rd' ? true : false : (listItems.filter(it => it?.scores?.fth).length > 0 || orderItems.filter(it => it?.scores?.fth).length > 0) ? true : false}
							defaultValue={player?.scores?.trd ?? ''}
							onChange={e => player.scores.trd = validScore(e.target.value) ? e.target.value : player.scores.trd}
							onBlur={() => {
								if (player.scores.trd?.length > 0) {
									const tot = teamScore([...items.filter(it => it.id !== player.id), player]);
									const diff = tot - (score?.value ?? 0);

									if (diff >= 0) {
										score.value = tot;

										const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
										slot[label] = player.key;

										if (showFavorites && diff > 0) {
											slot.notesH = countdownTimerElementDisplay.textContent;
										}

										setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);
										const rs = JSON.parse(runningScore?.value)
										if (rs.find(sc => sc[+tot])) {
											const sc = rs.find(scr => scr[+tot]);
											if (label === 'home') {
												sc[+tot].h = player.key;
											} else {
												sc[+tot].a = player.key;
											}
											runningScore.value = JSON.stringify(rs);
										} else {
											runningScore.value = JSON.stringify([...rs, { [+tot]: label === 'home' ? { h: player.key } : { a: player.key } }]);
										}
										let found = processTaskContext.items.find(it => it.item_code === player.id)
										if (!found) {
											found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.quantity = JSON.stringify({ ...JSON.parse(found.quantity), trd: player.scores.trd });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}


										setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
									}
								}
							}}
						/>
					</div>
				);
			} else if (type === 'fourthQtr') {
				return (
					<div >
						<input id={`${player.id}4th`} autoComplete="off" type="text" className={cls.templateSCInput}
							readOnly={period ? period.value !== '4th' ? true : false : (listItems.filter(it => it?.scores?.ot).length > 0 || orderItems.filter(it => it?.scores?.ot).length > 0) ? true : false}
							defaultValue={player?.scores?.fth ?? ''}
							onChange={e => player.scores.fth = validScore(e.target.value) ? e.target.value : player.scores.fth}
							onBlur={() => {

								if (player.scores.fth?.length > 0) {
									const tot = teamScore([...items.filter(it => it.id !== player.id), player]);
									const diff = tot - (score?.value ?? 0);

									if (diff >= 0) {
										score.value = tot;

										const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
										slot[label] = player.key;

										if (showFavorites && diff > 0) {
											slot.notesH = countdownTimerElementDisplay.textContent;
										}

										setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);
										const rs = JSON.parse(runningScore?.value)
										if (rs.find(sc => sc[+tot])) {
											const sc = rs.find(scr => scr[+tot]);
											if (label === 'home') {
												sc[+tot].h = player.key;
											} else {
												sc[+tot].a = player.key;
											}
											runningScore.value = JSON.stringify(rs);
										} else {
											runningScore.value = JSON.stringify([...rs, { [+tot]: label === 'home' ? { h: player.key } : { a: player.key } }]);
										}
										let found = processTaskContext.items.find(it => it.item_code === player.id)
										if (!found) {
											found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.quantity = JSON.stringify({ ...JSON.parse(found.quantity), fth: player.scores.fth });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}


									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
								}
							}}
						/>

					</div>
				);
			} else if (type === 'overtime') {
				return (
					<div >
						<input id={`${player.id}OT`} autoComplete="off" type="text" className={cls.templateOTInput}
							defaultValue={player?.scores?.ot ?? ''}
							onChange={e => player.scores.ot = validScore(e.target.value) ? e.target.value : player.scores.ot}
							onBlur={() => {
								if (player.scores.ot?.length > 0) {
									const tot = teamScore([...items.filter(it => it.id !== player.id), player]);
									const diff = tot - (score?.value ?? 0);

									if (diff >= 0 && diff < 4) {
										score.value = tot;
										const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
										slot[label] = player.key;

										if (showFavorites && diff > 0) {
											slot.notesH = countdownTimerElementDisplay.textContent;
										}

										setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);
										const rs = JSON.parse(runningScore?.value)
										if (rs.find(sc => sc[+tot])) {
											const sc = rs.find(scr => scr[+tot]);
											if (label === 'home') {
												sc[+tot].h = player.key;
											} else {
												sc[+tot].a = player.key;
											}
											runningScore.value = JSON.stringify(rs);
										} else {
											runningScore.value = JSON.stringify([...rs, { [+tot]: label === 'home' ? { h: player.key } : { a: player.key } }]);
										}
										let found = processTaskContext.items.find(it => it.item_code === player.id)
										if (!found) {
											found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.quantity = JSON.stringify({ ...JSON.parse(found.quantity), ot: player.scores.ot });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}


									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
								}

							}}
						/>
					</div>
				);
			} else if (type === 'STR') {
				return (
					<Checkbox
						disabled={period?.value ? true : false}
						style={{ color: 'var(--app-color-gray-lighter)' }}
						defaultChecked={player.misc.str === 1 ? true : false}
						onChange={e => {

							player.misc.str = e.target.checked == true ? 1 : 0;
							player.misc.oc = e.target.checked == true ? 1 : 0;

							let found = processTaskContext.items.find(it => it.item_code === player.id)
							if (!found) {
								found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
								setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
							} else {
								found.value = JSON.stringify({ ...JSON.parse(found.value), str: player.misc.str, oc: player.misc.oc });
							}
							if (selectedComponent?.id) {
								updateComponentItem(found, selectedComponent.id).subscribe({});
							}
							setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
						}}
					/>
				);
			} else if (type === 'DREB') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.dreb ?? ''}
							onChange={e => {
								player.misc.dreb = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.dreb?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), dreb: player.misc.dreb });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));


								}
							}}
						/>
					</div>
				);
			} else if (type === 'OREB') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.oreb ?? ''}
							onChange={e => {
								player.misc.oreb = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.oreb?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), oreb: player.misc.oreb });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));


								}
							}}
						/>
					</div>
				);
			} else if (type === 'AST') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.ast ?? ''}
							onChange={e => {
								player.misc.ast = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.ast?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });

									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), ast: player.misc.ast });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
					</div>
				);
			} else if (type === 'BLK') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.blk ?? ''}
							onChange={e => {
								player.misc.blk = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.blk?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });

									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), blk: player.misc.blk });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]))

								}
							}}
						/>
					</div>
				);
			} else if (type === 'STL') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.stl ?? ''}
							onChange={e => {
								player.misc.stl = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.stl?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) };
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), stl: player.misc.stl });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
					</div>
				);
			} else if (type === 'TOV') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}${type}`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							defaultValue={player?.misc?.tov ?? ''}
							onChange={e => {
								player.misc.tov = e.target.value;
							}}
							onBlur={() => {
								if (player.misc.tov?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), tov: player.misc.tov });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));

								}
							}}
						/>
					</div>
				);
			} else if (type === 'MIN') {
				return (
					<div style={{ width: '' }}>
						<input id={`${player.id}MP`} autoComplete="off" type="number" min={0} className={cls.templateOthersInput}
							style={{ border: player.misc.oc === 1 ? '2px solid black' : '' }}
							defaultValue={Math.floor(+(player?.misc?.min ?? 0) / 60)}
							onChange={e => {
								player.misc.min = +(e.target.value) * 60;
							}}
							onBlur={() => {
								if (player.misc.min?.length > 0) {
									let found = processTaskContext.items.find(it => it.item_code === player.id)
									if (!found) {
										found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
										setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
									} else {
										found.value = JSON.stringify({ ...JSON.parse(found.value), min: player.misc.min });
									}
									if (selectedComponent?.id) {
										updateComponentItem(found, selectedComponent.id).subscribe({});
									}
									setItems(R.sortBy(R.prop('key'), [...items.filter(it => it.id !== player.id), player]));
								}
							}}
						/>
					</div>
				);
			} else if (type === 'TFG') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={((player.scores?.fst ?? '').split('').filter(sc => +sc > 1).length +
								(player.scores?.snd ?? '').split('').filter(sc => +sc > 1).length +
								(player.scores?.trd ?? '').split('').filter(sc => +sc > 1).length +
								(player.scores?.fth ?? '').split('').filter(sc => +sc > 1).length +
								(player.scores?.ot ?? '').split('').filter(sc => +sc > 1).length)}
						/>
					</div>
				);
			} else if (type === '3FG') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={((player.scores?.fst ?? '').split('').filter(sc => +sc == 3).length +
								(player.scores?.snd ?? '').split('').filter(sc => +sc == 3).length +
								(player.scores?.trd ?? '').split('').filter(sc => +sc == 3).length +
								(player.scores?.fth ?? '').split('').filter(sc => +sc == 3).length +
								(player.scores?.ot ?? '').split('').filter(sc => +sc == 3).length)}
						/>
					</div>
				);
			} else if (type === 'FT') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={((player.scores?.fst ?? '').split('').filter(sc => +sc == 1).length +
								(player.scores?.snd ?? '').split('').filter(sc => +sc == 1).length +
								(player.scores?.trd ?? '').split('').filter(sc => +sc == 1).length +
								(player.scores?.fth ?? '').split('').filter(sc => +sc == 1).length +
								(player.scores?.ot ?? '').split('').filter(sc => +sc == 1).length)}
						/>
					</div>
				);
			} else if (type === 'FTA') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={((player.scores?.fst ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(player.scores?.snd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(player.scores?.trd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(player.scores?.fth ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(player.scores?.ot ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length)}
						/>
					</div>
				);
			} else if (type === 'TF') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={Object.values(player?.fouls).filter(val => val.length > 0).length}
						/>
					</div>
				);
			} else if (type === 'TP') {
				return (
					<div style={{ width: '' }}>
						<input autoComplete="off" type="text" className={cls.templateOthersInput} readOnly
							value={playerScore(player)}
						/>
					</div>
				);
			} else if (type === 'Player') {
				return player.misc.str === 1 ? `${value ?? ''} *` : value ?? '';

			} else if (type === 'Jersey') {
				return value;

			} else {
				return <div style={{ fontFamily: 'var(--app-text-font-main)', fontSize: '16px', textAlign: 'left', minWidth: '120px' }}>{value ?? ''}</div>;
			}
		};

		const pauseClock = () => {
			const secondsElpased = countdownTimerElementDisplay.key - countdownTimerElementDisplay.value;

			listItems.filter(player => player?.misc?.oc === 1).map((player) => {

				player.misc.min = +(player?.misc.min ?? 0) + secondsElpased

				let found = processTaskContext.items.find(it => it.item_code === player.id)

				if (!found) {
					found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
					setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
				} else {
					found.value = JSON.stringify({ ...JSON.parse(found.value), min: player.misc.min });
				}

			})

			orderItems.filter(player => player?.misc?.oc === 1).map((player) => {

				player.misc.min = +(player?.misc.min ?? 0) + secondsElpased

				let found = processTaskContext.items.find(it => it.item_code === player.id)

				if (!found) {
					found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
					setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
				} else {
					found.value = JSON.stringify({ ...JSON.parse(found.value), min: player.misc.min });
				}
			})

			countdownTimerElementDisplay.key = countdownTimerElementDisplay.value;

			const notes = JSON.parse(teamNotes.value);
			notes.time = countdownTimerElementDisplay.value;
			teamNotes.value = JSON.stringify(notes);

			window.clearInterval(timer);
			setTimer(0);

			document.getElementById('restoreInput').value = countdownTimerElementDisplay.value ?? (startingMinutes * 60);
		}

		const MutipleInputs = props => {

			return <div style={{ display: 'flex' }}>

				{[...Array(props.cnt)].map((obj, i) => {

					return <input autoComplete="off" type="text" className={props?.className ?? cls.templateFLInput}
						style={{ fontSize: '10px' }}
						key={i}
						disabled={props?.disabled ?? false}
						defaultValue={props.target >= (i + 1) ? 'X' : undefined}
						placeholder={`${i + 1}`}
						onChange={e => props?.onChange?.(`${i + 1}`)}
						onBlur={e => props?.onBlur?.(e.target.value)}
					/>
				})}
			</div>
		};

		return (period && timeouts && fouls && runningScore && teamNotes ?
			<div id="Main Scoresheet Template" style={{ width: '100%' }} >

				<div id="Period Dropdown" style={{ display: timer === 0 ? 'flex' : 'none', flexDirection: isMobile ? 'column' : 'row', width: '100%', margin: 'auto', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
					<div className={cls.dropdownContainer} style={{ border: '1px solid var(--app-color-gray-dark)', width: '200px', display: period ? '' : 'none' }}>
						<SelectD
							defaultSelected={period?.options?.list?.map(obj => {
								return { label: obj, value: obj }
							}).find(it => it.value === period.value)}
							items={period?.options?.list?.map(obj => {
								return { label: obj, value: obj }
							})}
							onChange={value => {
								period.value = value.value;
								saveModuleOutputs(true, processTaskContext, selectedComponent, components, begunModule, companyData, true);
							}}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							className={cls.currencyInput}
						/>
					</div>
					<div style={{ width: '70%' }} />

					<RenderYesNoSwitcher
						{...{
							id: 'Show Scoreboard?',
							title: 'Show Scoreboard',
							ungrouped: true,
							withAckButton: false,
						}}
						style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
						toggleSelected={val => {

							const notes = JSON.parse(teamNotes.value);

							if (notes?.time && !countdownTimerElementDisplay?.value) {

								setShowFavorites(true);
								setTimeout(() => {

									let countdownTimerElementDisplay = document.getElementById('timer_count');
									countdownTimerElementDisplay.value = +(notes.time);
									document.getElementById('restoreInput').value = notes.time;
									countdownTimerElementDisplay.key = +(notes.time);
									const minute = Math.floor(+countdownTimerElementDisplay?.value / 60);
									let seconds = +countdownTimerElementDisplay?.value % 60;
									seconds = seconds < 10 ? '0' + seconds : seconds;
									countdownTimerElementDisplay.textContent = `${minute < 10 ? `0${minute}` : minute}:${seconds}`;
									setShowFavorites(val);

								}, 10);
							} else {
								setShowFavorites(val)
							}
						}}
					/>
				</div>

				<div id="Scoreboard" style={{ display: showFavorites ? 'flex' : 'none', flexDirection: 'column', width: isMobile ? '100%' : '40%', minWidth: isMobile ? '' : '500px', margin: 'auto', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: '30px', backgroundColor: '#F6F4EC', paddingTop: '10px' }}>

					<div style={{ display: 'flex', flexDirection: 'column' }} >

						<label className={cls.timer} id="timer_count">
							{`${startingMinutes}:00`}
						</label>

						<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
							<Button
								style={{ display: countdownTimerElementDisplay?.value === 0 ? 'none' : period?.value ? '' : 'none' }}
								title={timer == 0 ? 'Start Clock' : countdownTimerElementDisplay?.value !== 0 ? 'Pause Clock' : 'End'}
								clicked={() => {
									if (timer === 0) {
										setTimer(
											setInterval(() => {
												if (countdownTimerElementDisplay?.value === undefined) {
													countdownTimerElementDisplay.value = (period?.value === 'OT' ? 5 : startingMinutes) * 60;
													countdownTimerElementDisplay.key = countdownTimerElementDisplay.value;

													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: `${startingMinutes}:00`, score: `${home?.value ?? 0}-${away?.value ?? 0}` }), value: `START`, alt_value: period?.value }
													});

												}

												if (countdownTimerElementDisplay.value < 0 || countdownTimerElementDisplay.textContent === `00:00`) {
													countdownTimerElementDisplay.textContent = `00:00`;
													countdownTimerElementDisplay.value = 0;
													return
												}
												countdownTimerElementDisplay.value = +countdownTimerElementDisplay?.value - 1;

												const clock = document.getElementById("24timer").value;
												if (+clock !== 0) {
													document.getElementById("24timer").value = `${+(clock) - 1}`;
												}


												const minute = Math.floor(+countdownTimerElementDisplay?.value / 60);
												let seconds = +countdownTimerElementDisplay?.value % 60;
												seconds = seconds < 10 ? '0' + seconds : seconds;
												if (countdownTimerElementDisplay.value >= 0)
													countdownTimerElementDisplay.textContent = `${minute < 10 ? `0${minute}` : minute}:${seconds}`;

											}, 1000)
										);
									} else {
										pauseClock();
										if (countdownTimerElementDisplay?.value !== undefined) {
											saveModuleOutputs(true, processTaskContext, selectedComponent, components, begunModule, companyData, true);
										}
									}
								}}
							/>

							{countdownTimerElementDisplay?.textContent !== `${startingMinutes}:00` && showFavorites &&
								<Button
									light
									title={countdownTimerElementDisplay?.value !== 0 ? '' : home?.value !== away?.value && countdownTimerElementDisplay?.textContent === '00:00' && (period?.value === '4th' || period?.value === 'OT') ? 'End Match' : 'End Period'}
									style={{ display: countdownTimerElementDisplay?.value === 0 ? '' : timer !== 0 ? 'none' : '' }}
									clicked={() => {

										pauseClock();

										if (countdownTimerElementDisplay?.value === 0) {
											if (showFavorites) {
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}` }), value: `END`, alt_value: period?.value }
												});
											}
											saveModuleOutputs(true, processTaskContext, selectedComponent, components, begunModule, companyData, true);

											// if (home?.value!== away?.value && countdownTimerElementDisplay?.textContent === '00:00' && (period?.value === '4th' || period?.value === 'OT')){

											// }
										}
									}}
									icon={countdownTimerElementDisplay?.value !== 0 ? <img src={reset2} /> : ''}
								/>}

							<input id={'restoreInput'} autoComplete="off" type="number" className={cls.timeLabel}
								style={{ display: timer !== 0 ? 'none' : '' }}
								defaultValue={countdownTimerElementDisplay?.value ?? startingMinutes * 60}
								onChange={e => {
									countdownTimerElementDisplay.value = e.target.value;
									const minute = Math.floor(+countdownTimerElementDisplay?.value / 60);
									let seconds = +countdownTimerElementDisplay?.value % 60;
									seconds = seconds < 10 ? '0' + seconds : seconds;
									countdownTimerElementDisplay.textContent = `${minute < 10 ? `0${minute}` : minute}:${seconds}`;
								}}
							/>


						</div>

					</div>
					<div style={{ display: 'flex', marginTop: '20px' }} >


						{!isMobile && <p className={cls.teamsLabel}>
							{`Home`}
						</p>}
						<input autoComplete="off" type="text" className={isMobile ? cls.scoreLabelDetail : cls.scoreLabel} readOnly
							value={teamScore(listItems, true, true, true, true, true)}
						/>

						<input autoComplete="off" type="text" className={isMobile ? cls.scoreLabelDetail : cls.scoreLabel} readOnly
							value={teamScore(orderItems, true, true, true, true, true)}
						/>
						{!isMobile && <p className={cls.teamsLabel}>
							{`Away`}
						</p>}


					</div>
					<br />

					<div style={{ display: 'flex' }}>
						<FontAwesomeIcon icon={faCaretLeft} style={{ height: '30px', width: '30px', color: teamNotes?.value ? JSON.parse(teamNotes.value).arrow === 'home' ? 'red' : 'black' : 'transparent' }} />
						<FontAwesomeIcon icon={faBasketballBall} style={{ height: '30px', width: '30px', color: teamNotes?.value ? JSON.parse(teamNotes.value).poss === 'home' ? 'green' : 'black' : 'transparent' }} />
						<p className={cls.timeLabel}>
							{`${period?.value} ${period.value === 'OT' ? '' : 'Qtr'}`}
						</p>
						<FontAwesomeIcon icon={faBasketballBall} style={{ height: '30px', width: '30px', color: teamNotes?.value ? JSON.parse(teamNotes.value).poss === 'away' ? 'green' : 'black' : 'transparent' }} />
						<FontAwesomeIcon icon={faCaretRight} style={{ height: '30px', width: '30px', color: teamNotes?.value ? JSON.parse(teamNotes.value).arrow === 'away' ? 'red' : 'black' : 'transparent' }} />

					</div>
					{showFavorites && <input autoComplete="off" type="number" max="24" min="0" className={cls.timer} id="24timer" defaultValue={'24'} style={{ textAlign: 'center', fontSize: '50px' }} />}

					<hr />
				</div>

				{(action?.popup?.coach == true || action?.popup?.team == true) &&
					<ModalR id="Coach Popup" hasCloseIcon={isMobile} isOpen={(action?.popup?.coach == true || action?.popup?.team == true)}
						onClose={() => {

							setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} })
						}}
						shadowedContainerStyle={{ border: '5px black', backgroundColor: '', width: isMobile ? '100%' : '50%', minWidth: '200px', }}>
						<div className={cls.currencySymbol} style={{ display: 'flex', minWidth: '200px', flexDirection: 'column', margin: 'auto', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'white', padding: '30px', width: isMobile ? '100%' : '' }}>

							<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '20px' }}>
								<p className={cls.teamsLabel}>{`${action?.popup?.coach == true ? 'Coach - ' : ''}${action?.click?.team == 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value}`}</p>
							</div>
							<div className={cls.centerDiv} style={{ display: action?.click?.type ? 'none' : 'inline-flex', flexWrap: 'wrap' }}>
								{action?.popup?.coach == true && <Button
									title={'CHALLENGE'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'CHAL', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
								/>}
								<Button
									title={'TECHNICAL 1'}
									clicked={() => {

										setAction({ ...action, click: { ...action?.click, type: action?.popup?.coach == true ? 'CTECH1' : 'TTECH1' } });


									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
								/>
								<Button
									title={'TECHNICAL 2'}
									clicked={() => {
										setAction({ ...action, click: { ...action?.click, type: action?.popup?.coach == true ? 'CTECH2' : 'TTECH2' } });


									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
								/>
								{action?.popup?.team == true && <Button
									title={'SHOT CLOCK VIOLATION'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'CLK', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });


									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
								/>}
								<Button
									title={'WARNING'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'WARN', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });


									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
								/>
								{action?.popup?.team == true && <Button
									title={'DELAY OF GAME'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'DEL', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });


									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
								/>}
								{action?.popup?.team == true && <Button
									title={'GET BALL'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'POS', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
										document.getElementById("24timer").value = `24`;
										possession(action?.click?.team);
									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'blue', color: 'white' }}
								/>}
								{action?.popup?.team == true && <Button
									title={'SWITCH ARROW'}
									clicked={() => {
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click?.team }), value: 'ARR', alt_value: period?.value }
											});
										}
										setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
										arrow(action?.click?.team);
									}}
									style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'blue', color: 'white' }}
								/>}

								{action?.click?.team === 'away' ?
									<Button
										light
										title={'TIMEOUT'}
										clicked={() => {


											const timeoutX = JSON.parse(timeouts.value);

											if (period?.value === '1st' || period?.value === '2nd') {
												timeoutX.away.first = +timeoutX.away.first + 1;
											} else if (period?.value === '3rd' || period?.value === '4th') {
												timeoutX.away.second = +timeoutX.away.second + 1;
											} else if (period?.value === 'OT') {
												timeoutX.away.ot = +timeoutX.away.ot + 1;
											} else {
												timeoutX.away.ot2 = +timeoutX.away.ot2 + 1;
											}

											timeouts.value = JSON.stringify(timeoutX);

											if (showFavorites) {
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value ?? 'Away' }), value: 'TIME', alt_value: period?.value }
												});
											}

											saveModuleOutputs(true, processTaskContext, selectedComponent, components, begunModule, companyData, true);

											setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });

										}}
										style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
									/> :

									<Button
										light
										title={'TIMEOUT'}
										clicked={() => {

											const timeoutX = JSON.parse(timeouts.value);

											if (period?.value === '1st' || period?.value === '2nd') {
												timeoutX.home.first = +timeoutX.home?.first + 1;
											} else if (period?.value === '3rd' || period?.value === '4th') {
												timeoutX.home.second = +timeoutX.home?.second + 1;
											} else if (period?.value === 'OT') {
												timeoutX.home.ot = +timeoutX.home?.ot + 1;
											} else {
												timeoutX.home.ot2 = +timeoutX.home?.ot2 + 1;
											}

											timeouts.value = JSON.stringify(timeoutX);

											if (showFavorites) {
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay?.textContent ?? 'XX:XX', score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value ?? 'Home' }), value: 'TIME', alt_value: period?.value }
												});
											}

											saveModuleOutputs(true, processTaskContext, selectedComponent, components, begunModule, companyData, true);

											setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });

										}}
										style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
									/>}


							</div>
							{action?.click?.type &&

								<div id="freethrow types" className={cls.centerDiv}>
									<p className={cls.teamsLabel}>
										{'Freethrow awarded'}
									</p>
									<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

										<Button
											title={'1 FT'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click.team, type: '1FT' }), value: action?.click?.type, alt_value: period?.value, total: showSecond?.team }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'2 FT'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click.team, type: '2FT' }), value: action?.click?.type, alt_value: period?.value, total: showSecond?.team }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'NO FT'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: action?.click.team, }), value: action?.click?.type, alt_value: period?.value, total: showSecond?.team }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, coach: false, team: false }, click: {} });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>

									</div>
								</div>}
						</div>
					</ModalR>
				}
				{showSecond &&
					<ModalR id="Player Popup" hasCloseIcon={isMobile} isOpen={showSecond === false ? false : true}
						onClose={() => {
							setShowSecond(false)
							setAction({ ...action, popup: { jbw: false, jbl: false }, click: {} })
						}}
						shadowedContainerStyle={{ border: '5px black', backgroundColor: '', width: isMobile ? '100%' : '60%', minWidth: '200px', }}>
						<div className={cls.currencySymbol} style={{ display: 'flex', minWidth: '200px', flexDirection: 'column', margin: 'auto', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'white', padding: '30px', width: isMobile ? '100%' : '' }}>

							<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '20px' }}>
								<p className={cls.teamsLabel}>{`${showSecond.data.Player} #${showSecond.data.No} `}</p>
								{showSecond.player?.photo?.length > 0 && <img alt="lg" src={showSecond.player.photo} className={cls.profilePicture} onClick={() => { }} />}
							</div>


							{(action?.popup?.jbw == true || action?.popup?.jbl == true) &&
								<div id="Jumpball won">
									<p className={cls.teamsLabel}>
										{action?.popup?.jbw == true ? 'Jumpball lost by' : 'Jumpball won by'}
									</p>
									<div style={{ display: 'flex' }}>

										{(showSecond?.team === 'home' ? orderItems : listItems).filter(player => (player.misc?.oc === 1)).map((player, index) => {

											return <Button
												title={player.key} key={index}
												light
												style={{ height: '80px', width: isMobile ? '80px' : '160px' }}

												icon={player?.photo?.length > 0 && <img alt="lg" src={player.photo} className={cls.profilePicture} style={{ width: '60px', height: '60px' }} onClick={() => { }} />}
												clicked={() => {

													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: player.key, type: action?.popup?.jbw == true ? 'jpw' : 'jbl' }), value: 'JUMP', alt_value: period?.value, total: showSecond?.team }
														});
													}

													// ? 'jpw' : 'jbl')
													setAction({ ...action, popup: { jbw: false, jbl: false } });
													setShowSecond(false);
												}}
											/>;
										}
										).sort((a, b) => +a.No - +b.No)
										}
									</div>
								</div>
							}


							{(action?.popup?.shot == true) &&
								<div id="shot types" className={cls.centerDiv}>
									<p className={cls.teamsLabel}>
										{'Shot type:'}
									</p>
									<div id="shot types" className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

										{!(action?.click?.zone === 'lsr' || action?.click?.zone === 'rsr') &&
											<Button
												title={'JUMPSHOT'}
												clicked={() => {
													setAction({ ...action, click: { ...action?.click, type: 'js' }, popup: { ...action?.popup, assist: true, shot: false } });

												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
											/>}
										{!(action?.click?.zone === 'lsr' || action?.click?.zone === 'rsr') &&
											<Button
												title={'PULLUP JUMPSHOT'}
												clicked={() => {

													setAction({ ...action, click: { ...action?.click, type: 'pujs' }, popup: { ...action?.popup, assist: true, shot: false } });
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
											/>}
										{!(action?.click?.zone === 'lsr' || action?.click?.zone === 'rsr') &&
											<Button
												title={'STEPBACK JUMPSHOT'}
												clicked={() => {

													setAction({ ...action, click: { ...action?.click, type: 'sbjs' }, popup: { ...action?.popup, assist: true, shot: false } });
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
											/>}
										<Button
											title={'TURNAROUND JUMPSHOT'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'tujs' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'LAYUP'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'lay' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'DRIVING LAYUP'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'dlay' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'HOOKSHOT'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'hook' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'FLOATING JUMPSHOT'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'fljs' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'DUNK'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'dunk' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'ALLEY OOP'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'alley' }, popup: { ...action?.popup, assist: true, shot: false } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
									</div>
								</div>
							}
							{(action?.popup?.against == true || action?.popup?.steal == true) &&
								<div id="select players">
									<p className={cls.teamsLabel}>
										{action?.popup?.against == true ? 'Committed against' : 'Stole from'}
									</p>
									<div style={{ display: 'flex' }}>

										{(showSecond?.team === 'home' ? orderItems : listItems).filter(player => (player.misc?.oc === 1)).map((player, index) => {

											return <Button
												light
												style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: action?.click?.fouled === player.key ? 'yellow' : '', color: 'black' }}
												title={player.key} key={index}
												icon={player?.photo?.length > 0 && <img alt="lg" src={player.photo} className={cls.profilePicture} style={{ width: '60px', height: '60px' }} onClick={() => { }} />}
												clicked={() => {

													if (action?.popup?.against == true) {

														setAction({ ...action, click: { ...action?.click, fouled: player.key } });

													} else if (action?.popup?.steal == true) {

														player.misc.tov = +(player?.misc?.tov ?? 0) + 1;

														let found = processTaskContext.items.find(it => it.item_code === player.id)

														if (!found) {
															found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(player.scores), value: JSON.stringify(player.misc), alt_value: JSON.stringify(player.fouls) }
															setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
														} else {
															found.value = JSON.stringify({ ...JSON.parse(found.value), tov: player.misc.tov });
														}
														if (selectedComponent?.id) {
															updateComponentItem(found, selectedComponent.id).subscribe({});
														}

														if (showFavorites) {
															setTaskProcessContext({
																action: processTaskAction.addModuleItems, value:
																	{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: player.key, value: showSecond.player.misc.stl }), value: 'STL', alt_value: period?.value }
															});

															setTaskProcessContext({
																action: processTaskAction.addModuleItems, value:
																	{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: player?.team, p1: player.key, type: 'lost', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
															});
														}
														setAction({ ...action, popup: { ...action?.popup, steal: false } });
														setShowSecond(false);
													}
												}}
											/>;
										}).sort((a, b) => +a.No - +b.No)}

									</div>

									<div id="freethrow types" className={cls.centerDiv}>
										<p className={cls.teamsLabel}>
											{action?.popup?.against == true ? 'Free throws awarded' : ''}
										</p>


										<div className={cls.centerDiv} style={{ display: action?.popup?.against == true ? 'inline-flex' : 'none', flexWrap: 'wrap' }}>
											<Button
												title={'1 FT'}
												clicked={() => {
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: action?.click?.fouled, type: action?.click?.type, type2: '1FT', value: Object.values(showSecond.player?.fouls).filter(val => val.length > 0).length }), value: 'Fouls', alt_value: period?.value, total: showSecond?.team }
														});
													}
													setAction({ ...action, popup: { ...action?.popup, foul: false, against: false } });
													setShowSecond(false);
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											/>
											<Button
												title={'2 FT'}
												clicked={() => {
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: action?.click?.fouled, type: action?.click?.type, type2: '2FT', value: Object.values(showSecond.player?.fouls).filter(val => val.length > 0).length }), value: 'Fouls', alt_value: period?.value }
														});
													}
													setAction({ ...action, popup: { ...action?.popup, foul: false, against: false } });
													setShowSecond(false);
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											/>
											<Button
												title={'3 FT'}
												clicked={() => {
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: action?.click?.fouled, type: action?.click?.type, type2: '3FT', value: Object.values(showSecond.player?.fouls).filter(val => val.length > 0).length }), value: 'Fouls', alt_value: period?.value }
														});
													}
													setAction({ ...action, popup: { ...action?.popup, foul: false, against: false } });
													setShowSecond(false);
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											/>
											<Button
												title={'NO FT'}
												clicked={() => {
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: action?.click?.fouled, type: action?.click?.type, value: Object.values(showSecond.player?.fouls).filter(val => val.length > 0).length }), value: 'Fouls', alt_value: period?.value }
														});
													}
													setAction({ ...action, popup: { ...action?.popup, foul: false, against: false } });
													setShowSecond(false);
												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											/>

										</div>
									</div>
								</div>
							}

							{(action?.popup?.foul == true) && (!action?.popup?.against == true) &&
								<div id="foul types" className={cls.centerDiv}>
									<p className={cls.teamsLabel}>
										{'Select foul type:'}
									</p>
									<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

										<Button
											title={'PERSONAL'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'per' }, popup: { ...action?.popup, against: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'SHOOTING'}
											clicked={() => {

												setAction({ ...action, click: { ...action?.click, type: 'sho' }, popup: { ...action?.popup, against: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'OFFENSIVE'}
											clicked={() => {
												possession(showSecond.team === 'home' ? 'away' : 'home');
												setAction({ ...action, click: { ...action?.click, type: 'off' }, popup: { ...action?.popup, against: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'DOUBLE PERSONAL'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'dou' }, popup: { ...action?.popup, against: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'LOOSEBALL'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'los' }, popup: { ...action?.popup, against: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										/>
										<Button
											title={'FLAGRANT 1'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'fr1' }, popup: { ...action?.popup, against: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'FLAGRANT 2'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'fr2' }, popup: { ...action?.popup, against: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'TECHNICAL 1'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'tech1' }, popup: { ...action?.popup, against: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'TECHNICAL 2'}
											clicked={() => {
												setAction({ ...action, click: { ...action?.click, type: 'tech2' }, popup: { ...action?.popup, against: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
									</div>
								</div>
							}




							{(action?.popup?.turnover == true) &&
								<div id="turnover types" className={cls.centerDiv}>
									<p className={cls.teamsLabel}>
										{'Turnover type:'}
									</p>
									<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

										<Button
											title={'BAD PASS'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'bad', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'DRIBBLING'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'drb', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'FT LANE VIOLATION'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'ftv', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'LOST BALL'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'lost', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'3 SECS'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: '3sec', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										/>
										<Button
											title={'TRAVEL'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'trv', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'GOALTENDING'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'goal', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'BACKCOURT'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'back', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'BACKING'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'back', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'OUT OF BOUNDS'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: 'out', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'5 SECS INBOUND'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, type: '5sec', value: showSecond.player.misc.tov }), value: 'TOV', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, turnover: false } });
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										/>
									</div>
								</div>
							}
							{(action?.popup?.miss == true) &&
								<div id="miss shot options" className={cls.centerDiv}>
									<p className={cls.teamsLabel}>
										{'Missed shot options'}
									</p>

									<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

										<Button
											title={'OUTBOUNDS (OPP)'}
											clicked={() => {
												possession(showSecond.team === 'home' ? 'away' : 'home');
												document.getElementById("24timer").value = `24`;
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}` }), value: 'OUTOPP', alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, miss: false }, click: {} });
												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'OUTBOUNDS (RETAIN)'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}` }), value: 'OUTRET', alt_value: period?.value }
													});

												}
												setAction({ ...action, popup: { ...action?.popup, miss: false }, click: {} });
												document.getElementById("24timer").value = `24`;
												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>

										<Button
											title={'OTHER'}
											clicked={() => {
												setAction({ ...action, popup: { ...action?.popup, miss: false }, click: {} });
												//setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										/>

									</div>
								</div>
							}

							{(action?.popup?.assist == true) &&
								<div id="Assisted goal">
									<p className={cls.teamsLabel}>
										{'Point/s assisted by:'}
									</p>
									<div style={{ display: 'flex' }}>

										{(showSecond?.team === 'home' ? listItems : orderItems).filter(player => (player.misc?.oc === 1)).map((player, index) => {

											return <Button
												light
												style={{ height: '80px', width: isMobile ? '80px' : '160px', }}
												title={player.key} key={index}
												icon={player?.photo?.length > 0 && <img alt="lg" src={player.photo} className={cls.profilePicture} style={{ width: '60px', height: '60px' }} onClick={() => { }} />}
												clicked={() => {

													player.misc.ast = +(player?.misc?.ast ?? 0) + 1;

													let found = processTaskContext.items.find(it => it.item_code === player.id)
													if (!found) {
														found = { id: player.id, index: +player.key, item_code: player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
														setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
													} else {
														found.value = JSON.stringify({ ...JSON.parse(found.value), ast: player.misc.ast });
													}
													if (selectedComponent?.id) {
														updateComponentItem(found, selectedComponent.id).subscribe({});
													}

													//Assist
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: player.key, value: player.misc.ast }), value: 'AST', alt_value: period?.value }
														});
													}
													// Score
													if (showFavorites) {
														setTaskProcessContext({
															action: processTaskAction.addModuleItems, value:
																{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, p2: player.key, zone: action?.click?.zone, x: action?.click?.x, y: action?.click?.y, type: action?.click?.type ?? '', value: playerScore(showSecond.player) }), value: `${action?.click?.pt}PTM`, alt_value: period?.value }
														});
													}
													setAction({ ...action, popup: { ...action?.popup, assist: false }, click: {} });
													setShowSecond(false);
												}}
											/>;
										}).sort((a, b) => +a.No - +b.No)}
										<Button
											light
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											title={'No Assist'} key={index}
											clicked={() => {

												// Score
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, zone: action?.click?.zone, x: action?.click?.x, y: action?.click?.y, type: action?.click?.type ?? '', value: playerScore(showSecond.player) }), value: `${action?.click?.pt}PTM`, alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, assist: false }, click: {} });
												setShowSecond(false);
											}}
										/>

									</div>
								</div>
							}

							{(showSecond.column === 2 || showSecond.column === 1) && showSecond.player?.misc?.oc == 1 &&
								!(action?.popup?.jbw == true || action?.popup?.jbl == true || action?.popup?.assist == true || action?.popup?.shot == true ||
									action?.popup?.turnover == true || action?.popup?.foul == true || action?.popup?.steal == true || action?.popup?.miss == true) &&

								<div id="Main popup menus" style={{ display: maxFouls > 5 ? (showSecond.player.fouls?.sth ? 'none' : 'flex') : (showSecond.player.fouls?.fif ? 'none' : 'flex'), flexDirection: 'column' }}>
									<div className={cls.centerDiv} style={{ display: 'inline-flex', flexWrap: 'wrap', marginBottom: '50px' }} >

										{action?.click?.pt &&
											<Button
												title={`MADE (${action?.click?.pt}PTS)`}
												clicked={() => {

													document.getElementById("24timer").value = `24`;

													possession(showSecond.team === 'home' ? 'away' : 'home');

													if (period?.value === '1st') {
														showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}${action?.click?.pt}`
													} else if (period?.value === '2nd') {
														showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}${action?.click?.pt}`
													} else if (period?.value === '3rd') {
														showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}${action?.click?.pt}`
													} else if (period?.value === '4th') {
														showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}${action?.click?.pt}`
													} else {
														showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}${action?.click?.pt}`
													}

													let tot = 0;
													if (showSecond.team === 'home') {
														tot = teamScore(listItems);
														home.value = tot;
													} else {
														tot = teamScore(orderItems);
														away.value = tot;
													}
													const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
													slot[showSecond.team] = showSecond.player.key;

													if (showFavorites) {
														slot.notesH = action?.click?.time;
													}

													setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

													const rs = JSON.parse(runningScore?.value)
													if (rs.find(sc => sc[+tot])) {
														const sc = rs.find(scr => scr[+tot]);
														if (showSecond.team === 'home') {
															sc[+tot].h = showSecond.player.key;
														} else {
															sc[+tot].a = showSecond.player.key;
														}
														runningScore.value = JSON.stringify(rs);
													} else {
														runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
													}

													let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
													if (!found) {
														found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
														setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
													} else {
														found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
													}
													if (selectedComponent?.id) {
														updateComponentItem(found, selectedComponent.id).subscribe({});
													}

													setAction({ ...action, popup: { ...action?.popup, shot: true } });



												}}
												style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'blue', color: 'white' }}
											/>}

										{action?.click?.pt && <Button
											title={`MISS (${action?.click?.pt}PTS)`}
											clicked={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}${action?.click?.pt == 2 ? 'x' : action?.click?.pt == 3 ? 'X' : '0'}`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}${action?.click?.pt == 2 ? 'x' : action?.click?.pt == 3 ? 'X' : '0'}`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}${action?.click?.pt == 2 ? 'x' : action?.click?.pt == 3 ? 'X' : '0'}`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}${action?.click?.pt == 2 ? 'x' : action?.click?.pt == 3 ? 'X' : '0'}`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}${action?.click?.pt == 2 ? 'x' : action?.click?.pt == 3 ? 'X' : '0'}`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: action?.click?.time, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, zone: action?.click?.zone, x: action?.click?.x, y: action?.click?.y, value: playerScore(showSecond.player) }), value: `${action?.click?.pt}PTX`, alt_value: period?.value }
													});
												}
												setAction({ ...action, popup: { ...action?.popup, miss: true } });
												// setAction({ ...action, popup: { ...action?.popup, assist: false }, click: {} });
												// setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>}
										<Button
											title={'MADE FT'}
											clicked={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}1`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}1`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}1`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}1`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}1`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player) }), value: 'FTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'blue', color: 'white' }}
										/>
										<Button
											title={'MISS FT.'}
											clicked={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}0`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}0`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}0`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}0`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}0`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player) }), value: 'FTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>

										<Button
											title={'OFF REBOUND'}
											clicked={() => {

												showSecond.player.misc.oreb = +(showSecond.player?.misc?.oreb ?? 0) + 1;

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), oreb: showSecond.player.misc.oreb });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: showSecond.player.misc.oreb }), value: 'OREB', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'DEF REBOUND'}
											clicked={() => {

												document.getElementById("24timer").value = `24`;
												possession(showSecond.team);

												showSecond.player.misc.dreb = +(showSecond.player?.misc?.dreb ?? 0) + 1;

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), dreb: showSecond.player.misc.dreb });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: showSecond.player.misc.dreb }), value: 'DREB', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'BLOCK'}
											clicked={() => {

												showSecond.player.misc.blk = +(showSecond.player?.misc?.blk ?? 0) + 1;

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), blk: showSecond.player.misc.blk });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: showSecond.player.misc.blk }), value: 'BLK', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>

										<Button
											title={'STEAL'}
											clicked={() => {

												document.getElementById("24timer").value = `24`;
												possession(showSecond.team);

												showSecond.player.misc.stl = +(showSecond.player?.misc?.stl ?? 0) + 1;

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), stl: showSecond.player.misc.stl });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												setAction({ ...action, popup: { ...action?.popup, steal: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'TURNOVER'}
											clicked={() => {

												document.getElementById("24timer").value = `24`;
												possession(showSecond.team === 'home' ? 'away' : 'home');

												showSecond.player.misc.tov = +(showSecond.player?.misc?.tov ?? 0) + 1;

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), tov: showSecond.player.misc.tov });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												setAction({ ...action, popup: { ...action?.popup, turnover: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'FOUL'}
											clicked={() => {

												document.getElementById("24timer").value = `24`;

												const foulsX = JSON.parse(fouls.value);

												if (period.value === '1st') {
													foulsX[showSecond?.team].first = +foulsX[showSecond?.team]?.first + 1;
												} else if (period.value === '2nd') {
													foulsX[showSecond?.team].second = +foulsX[showSecond?.team]?.second + 1;
												} else if (period.value === '3rd') {
													foulsX[showSecond?.team].third = +foulsX[showSecond?.team]?.third + 1;
												} else if (period.value === '4th') {
													foulsX[showSecond?.team].fourth = +foulsX[showSecond?.team]?.fourth + 1;
												} else if (period.value === 'OT') {
													foulsX[showSecond?.team].fourth = +foulsX[showSecond?.team]?.fourth + 1;
												} else {
													foulsX[showSecond?.team].fourth = +foulsX[showSecond?.team]?.fourth + 1;
												}
												fouls.value = JSON.stringify(foulsX);


												if (showSecond.player.fouls?.fst == '') {
													showSecond.player.fouls.fst = `X`

												} else if (showSecond.player.fouls?.snd == '') {
													showSecond.player.fouls.snd = `X`

												} else if (showSecond.player.fouls?.trd == '') {
													showSecond.player.fouls.trd = `X`

												} else if (showSecond.player.fouls?.fth == '') {
													showSecond.player.fouls.fth = `X`

												} else if (showSecond.player.fouls?.fif == '') {
													showSecond.player.fouls.fif = `X`

												} else {
													showSecond.player.fouls.sth = `X`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.alt_value = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.fouls?.fst ?? '', snd: showSecond.player.fouls?.snd ?? '', trd: showSecond.player.fouls?.trd ?? '', fth: showSecond.player.fouls?.fth ?? '', ot: showSecond.player.fouls?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if ((maxFouls > 5 && showSecond.player.fouls.sth) || (maxFouls < 6 && showSecond.player.fouls.fif)) {
													pauseClock();
												}

												setAction({ ...action, popup: { ...action?.popup, foul: true } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>
										<Button
											title={'TIP OUT'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, }), value: 'TIP', alt_value: period?.value }
													});

												}
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'maroon', color: 'white' }}
										/>
										<Button
											title={'HELD BALL'}
											clicked={() => {
												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, }), value: 'HELD', alt_value: period?.value }
													});

												}
												setShowSecond(false);

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										/>
										<Button
											title={'JUMPBALL WON'}
											clicked={() => {

												arrow(showSecond?.team === 'home' ? 'away' : 'home');
												document.getElementById("24timer").value = `24`;
												possession(showSecond.team);
												setAction({ ...action, popup: { ...action?.popup, jbw: true, jbl: false } });

											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'green', color: 'white' }}
										/>
										<Button
											title={'JUMPBALL LOST'}
											clicked={() => {

												arrow(showSecond?.team);
												possession(showSecond.team === 'home' ? 'away' : 'home');
												document.getElementById("24timer").value = `24`;
												setAction({ ...action, popup: { jbw: false, jbl: true } });
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px', backgroundColor: 'red', color: 'white' }}
										/>

										<Button
											title={'SUB OUT'}
											clicked={() => {

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												showSecond.player.misc.oc = 0;

												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) };
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.value = JSON.stringify({ ...JSON.parse(found.value), oc: 0 });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}
												setShowSecond(false);

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key }), value: 'SUBOUT', alt_value: period?.value }
													});
												}
											}}
											style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
											disabled={timer !== 0}
										/>



									</div>
									<p className={cls.teamsLabel}>
										{'Select another player instead'}
									</p>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										{(showSecond?.team === 'home' ? listItems : orderItems).filter(player => (player.misc?.oc === 1 && +player.key !== +showSecond.player.key)).map((player, index) => {

											return <Button
												light
												style={{ height: '80px', width: isMobile ? '40px' : '80px' }}
												title={player.key} key={index}
												clicked={() => {
													setShowSecond({ ...showSecond, player: (showSecond?.team === 'home' ? listItems : orderItems).find(it => it.key === player?.key), data: { No: player?.key, Player: `${getPlayerName(player?.key, showSecond?.team === 'home' ? listItems : orderItems, 'Player')}` } })
												}}
											/>
										})}
										{(showSecond.column === 2 || showSecond.column === 1) && showSecond.player?.misc?.oc == 1 && showFavorites &&
											<Button
												title={timer == 0 ? 'Start Clock' : countdownTimerElementDisplay?.value !== 0 ? 'Pause Clock' : 'End'}

												style={{ display: countdownTimerElementDisplay?.value === 0 ? 'none' : period?.value ? '' : 'none', height: '80px' }}
												clicked={() => {
													if (timer === 0) {
														setTimer(
															setInterval(() => {

																if (countdownTimerElementDisplay?.value === undefined) {
																	countdownTimerElementDisplay.value = (period?.value === 'OT' ? 5 : startingMinutes) * 60;
																	countdownTimerElementDisplay.key = countdownTimerElementDisplay.value;

																	setTaskProcessContext({
																		action: processTaskAction.addModuleItems, value:
																			{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: `${startingMinutes}:00`, score: `${home?.value ?? 0}-${away?.value ?? 0}` }), value: `START`, alt_value: period?.value }
																	});

																}

																if (countdownTimerElementDisplay.value < 0 || countdownTimerElementDisplay.textContent === `00:00`) {
																	countdownTimerElementDisplay.textContent = `00:00`;
																	countdownTimerElementDisplay.value = 0;
																	return
																}
																countdownTimerElementDisplay.value = +countdownTimerElementDisplay?.value - 1;

																const minute = Math.floor(+countdownTimerElementDisplay?.value / 60);
																let seconds = +countdownTimerElementDisplay?.value % 60;
																seconds = seconds < 10 ? '0' + seconds : seconds;
																if (countdownTimerElementDisplay.value >= 0)
																	countdownTimerElementDisplay.textContent = `${minute < 10 ? `0${minute}` : minute}:${seconds}`;

															}, 1000)
														);
													} else {
														pauseClock();
													}
												}}
											/>}
									</div>
								</div>
							}

							{showSecond.column === 2 && showSecond.player?.misc?.oc == 1 &&
								<div className={cls.centerDiv} style={{ display: maxFouls > 5 ? (showSecond.player.fouls?.sth ? '' : 'none') : (showSecond.player.fouls?.fif ? '' : 'none') }} >
									<Button
										title={'SUB OUT (FOULED OUT)'}
										clicked={() => {

											let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
											showSecond.player.misc.oc = 0;

											if (!found) {
												found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) };
												setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
											} else {
												found.value = JSON.stringify({ ...JSON.parse(found.value), oc: 0 });
											}
											if (selectedComponent?.id) {
												updateComponentItem(found, selectedComponent.id).subscribe({});
											}


											if (showFavorites) {
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key }), value: 'FOULOUT', alt_value: period?.value }
												});
											}
											setShowSecond(false);
										}}
										style={{ height: '80px', width: isMobile ? '80px' : '160px' }}
										disabled={timer !== 0}
									/>

								</div>
							}

							{showSecond.column === 2 && timer == 0 && (orderItems.find(pl => pl.id === showSecond?.player?.id) ? orderItems.filter(pl => pl?.misc?.oc === 1).length < 5 : listItems.filter(pl => pl?.misc?.oc === 1).length < 5) && (showSecond?.player?.misc?.oc == 0 || !showSecond?.player?.misc?.oc) &&
								<Button
									title={'SUB IN'}
									clicked={() => {
										let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
										showSecond.player.misc.oc = 1;

										if (!found) {
											found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) };
											setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
										} else {
											found.value = JSON.stringify({ ...JSON.parse(found.value), oc: 1 });
										}
										if (selectedComponent?.id) {
											updateComponentItem(found, selectedComponent.id).subscribe({});
										}
										setShowSecond(false);
										if (showFavorites) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key }), value: 'SUBIN', alt_value: period?.value }
											});
										}
									}}
									style={{ height: '80px', width: '150px', backgroundColor: 'green', color: 'white' }}
								/>}
						</div>
						{showSecond.column === 2 && showSecond.player?.misc?.oc == 1 && showFavorites && <div className={cls.centerDiv} style={{ width: '100%' }}>

							<p className={cls.teamsLabelDetail}>{`Click zone to score made or miss`}</p>
							<Tabs style={{ width: '100%', fontSize: isMobile ? '16px' : '20px' }}

							>
								<TabList style={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '20px' }}>
									<Tab>
										{'Made'}
									</Tab>
									<Tab>
										{'Miss'}
									</Tab>

								</TabList>

								<TabPanel style={{ cursor: 'pointer' }}>

									<svg id="basketball" x="0px" y="0px" viewBox="0 0 1280 1024">
										<rect x="25" y="31" className={cls.court} width="614" height="771"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}3`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}3`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}3`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}3`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}3`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'M' }), value: '3PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="31" className={cls.court} width="614" height="771"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}3`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}3`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}3`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}3`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}3`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'R' }), value: '3PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="25" y="31" className={cls.zone} style={{ fill: '' }} width="180" height="426"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}3`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}3`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}3`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}3`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}3`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'A' }), value: '3PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="1073" y="31" className={cls.zone} width="180" height="426"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}3`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}3`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}3`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}3`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}3`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'F' }), value: '3PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="205" y="31" className={cls.zone} width="234" height="250"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'B' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="839" y="31" className={cls.zone} width="234" height="250"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'E' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="439" y="31" className={cls.zone} width="200" height="250"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'C' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="439" y="281" className={cls.zone} width="200" height="250"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'I' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}

										/>
										<path className={cls.zone} d="M439,687.6v114.5h400v-115c-61,28.4-128.9,44.3-200.5,44.3C567.3,731.5,499.7,715.8,439,687.6z"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}3`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}3`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}3`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}3`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}3`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'O' }), value: '3PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M439,531v156.6c60.7,27.8,128.3,43.4,199.5,43.4c71.6,0,139.5-15.7,200.5-43.8V531H439z"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'N' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M205,457h0.1c0-0.1-0.1-0.2-0.1-0.3V457z"

											onClick={() => console.log('where to?')}
										/>
										<path className={cls.zone} d="M205,281v175.7c0,0.1,0.1,0.2,0.1,0.3C253.5,558.7,336.5,640.7,439,687.6V531v-74V281H205z"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'H' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M1073,457v-2.4c-0.4,0.8-0.8,1.6-1.2,2.4H1073z" />
										<path className={cls.zone} d="M839,281v176v74v156.2c102-47,184.7-128.8,232.8-230.2c0.4-0.8,0.8-1.6,1.2-2.4V281H839z"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'K' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="281" className={cls.zone} width="200" height="250"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'J' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="31" className={cls.zone} width="200" height="250"

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}2`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}2`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}2`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}2`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}2`
												}

												let tot = 0;
												if (showSecond.team === 'home') {
													tot = teamScore(listItems);
													home.value = tot;
												} else {
													tot = teamScore(orderItems);
													away.value = tot;
												}
												const slot = generalArray.find(gen => +gen.key === +tot) ?? { home: '', away: '', key: tot };
												slot[showSecond.team] = showSecond.player.key;

												if (showFavorites) {
													slot.notesH = countdownTimerElementDisplay.textContent;
												}

												setGeneralArray([...generalArray.filter(gen => +gen.key !== +tot), slot]);

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[+tot])) {
													const sc = rs.find(scr => scr[+tot]);
													if (showSecond.team === 'home') {
														sc[+tot].h = showSecond.player.key;
													} else {
														sc[+tot].a = showSecond.player.key;
													}
													runningScore.value = JSON.stringify(rs);
												} else {
													runningScore.value = JSON.stringify([...rs, { [+tot]: showSecond.team === 'home' ? { h: showSecond.player.key } : { a: showSecond.player.key } }]);
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'D' }), value: '2PTM', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
									</svg>

								</TabPanel>
								<TabPanel style={{ cursor: 'pointer' }}>
									<svg id="basketball miss" x="0px" y="0px" viewBox="0 0 1280 1024">
										<rect x="25" y="31" className={cls.court} width="614" height="771"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}X`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}X`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}X`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}X`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}X`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'M' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="31" className={cls.court} width="614" height="771"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}X`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}X`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}X`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}X`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}X`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'R' }), value: '3PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="25" y="31" className={cls.zone} style={{ fill: 'red' }} width="180" height="426"
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}X`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}X`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}X`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}X`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}X`
												}


												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'A' }), value: '3PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="1073" y="31" className={cls.zone} width="180" height="426" style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}X`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}X`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}X`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}X`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}X`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'F' }), value: '3PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="205" y="31" className={cls.zone} width="234" height="250" style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'B' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="839" y="31" className={cls.zone} width="234" height="250" style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'E' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="439" y="31" className={cls.zone} width="200" height="250" style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}


												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'C' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="439" y="281" className={cls.zone} width="200" height="250" style={{ fill: 'red' }}

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'I' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}

										/>
										<path className={cls.zone} d="M439,687.6v114.5h400v-115c-61,28.4-128.9,44.3-200.5,44.3C567.3,731.5,499.7,715.8,439,687.6z"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}X`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}X`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}X`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}X`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}X`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'O' }), value: '3PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M439,531v156.6c60.7,27.8,128.3,43.4,199.5,43.4c71.6,0,139.5-15.7,200.5-43.8V531H439z"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'N' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M205,457h0.1c0-0.1-0.1-0.2-0.1-0.3V457z" />
										<path className={cls.zone} d="M205,281v175.7c0,0.1,0.1,0.2,0.1,0.3C253.5,558.7,336.5,640.7,439,687.6V531v-74V281H205z"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}


												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'H' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<path className={cls.zone} d="M1073,457v-2.4c-0.4,0.8-0.8,1.6-1.2,2.4H1073z" />
										<path className={cls.zone} d="M839,281v176v74v156.2c102-47,184.7-128.8,232.8-230.2c0.4-0.8,0.8-1.6,1.2-2.4V281H839z"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'K' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="281" className={cls.zone} width="200" height="250"
											style={{ fill: 'red' }}
											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}



												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'J' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
										<rect x="639" y="31" className={cls.zone} width="200" height="250" style={{ fill: 'red' }}

											onClick={() => {

												if (period?.value === '1st') {
													showSecond.player.scores.fst = `${(showSecond.player.scores?.fst ?? '')}x`
												} else if (period?.value === '2nd') {
													showSecond.player.scores.snd = `${(showSecond.player.scores?.snd ?? '')}x`
												} else if (period?.value === '3rd') {
													showSecond.player.scores.trd = `${(showSecond.player.scores?.trd ?? '')}x`
												} else if (period?.value === '4th') {
													showSecond.player.scores.fth = `${(showSecond.player.scores?.fth ?? '')}x`
												} else {
													showSecond.player.scores.ot = `${(showSecond.player.scores?.ot ?? '')}x`
												}

												let found = processTaskContext.items.find(it => it.item_code === showSecond.player.id)
												if (!found) {
													found = { id: showSecond.player.id, index: +showSecond.player.key, item_code: showSecond.player.id, quantity: JSON.stringify(showSecond.player.scores), value: JSON.stringify(showSecond.player.misc), alt_value: JSON.stringify(showSecond.player.fouls) }
													setTaskProcessContext({ action: processTaskAction.addModuleItems, value: found });
												} else {
													found.quantity = JSON.stringify({ ...JSON.parse(found.value), fst: showSecond.player.scores?.fst ?? '', snd: showSecond.player.scores?.snd ?? '', trd: showSecond.player.scores?.trd ?? '', fth: showSecond.player.scores?.fth ?? '', ot: showSecond.player.scores?.ot ?? '' });
												}
												if (selectedComponent?.id) {
													updateComponentItem(found, selectedComponent.id).subscribe({});
												}

												if (showFavorites) {
													setTaskProcessContext({
														action: processTaskAction.addModuleItems, value:
															{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, team: showSecond?.team, p1: showSecond.player.key, value: playerScore(showSecond.player), zone: 'D' }), value: '2PTX', alt_value: period?.value }
													});
												}

												setShowSecond(false);
											}}
										/>
									</svg>
								</TabPanel>
							</Tabs>


						</div>}

						{!showFavorites &&
							<p className={cls.searchInput}>
								{'Note: play by play display and saving is not available on hidden game clock'}
							</p>
						}
					</ModalR>
				}

				<div id='Main Scoring Sheet' style={{ display: 'flex', margin: '50px 0 50px', width: '100%', flexDirection: 'column' }}>


					<div id="Basketball court" className={cls.centerDiv} style={{ display: period?.value ? 'flex' : 'none', flexDirection: 'row', width: '100%' }}>


						<div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
							{!isMobile && <CustomTemplateModuleList
								className={cls.lineTeam}
								style={{ width: '100%' }}
								tableHostSimple={cls.boxCourtTable}
								columns={['No', 'Player', 'F']}
								list={((
									listItems.filter(player => (player.misc?.oc === 1))
								).map((player, index) => {
									let object = {};
									template?.slist?.filter(li => {
										if (li?.subColumns) {
											li?.subColumns.filter(sc => {
												object[sc.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? ''}`, sc.type, index, player, home, 'home', listItems, setListItems);
											})
										} else {
											object[li.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === li.key)?.value ?? ''}`, li.type, index, player, home, 'home', listItems, setListItems);
										}
									});
									object.enabled = player?.enabled ?? true;
									object.index = index;
									return object;
								})).sort((a, b) => +a.No - +b.No)}
								onCellClick={e => {
									if (period?.value) {
										e.player = listItems.find(it => it.key === e.data.No);
										e.team = 'home';
										setShowSecond(e);
									}
								}}
							/>}
							<Button light title={'Team'}
								clicked={() => {
									setAction({ ...action, popup: { ...action?.popup, team: true }, click: { team: 'home' } });
								}} />
							<Button light title={'Coach'}
								clicked={() => {
									setAction({ ...action, popup: { ...action?.popup, coach: true }, click: { team: 'home' } });
								}} />


						</div>
						<div style={{ width: '60%' }}>
							<FullCourt setAction={setAction} action={action} time={countdownTimerElementDisplay?.textContent} items={processTaskContext.items.filter(it => JSON.parse(it?.quantity)?.x && it.item_code === 'PBP' && it.alt_value === period?.value && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX'))} />
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
							{!isMobile && <CustomTemplateModuleList
								className={cls.lineTeam}
								style={{ width: '100%' }}
								tableHostSimple={cls.boxCourtTable}
								columns={['No', 'Player', 'F']}
								list={((
									orderItems.filter(player => (player.misc?.oc === 1))
								).map((player, index) => {
									let object = {};
									template?.slist?.filter(li => {
										if (li?.subColumns) {
											li?.subColumns.filter(sc => {
												object[sc.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? ''}`, sc.type, index, player, away, 'away', orderItems, setOrderItems);
											})
										} else {
											object[li.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === li.key)?.value ?? ''}`, li.type, index, player, away, 'away', orderItems, setOrderItems);
										}
									});
									object.enabled = player?.enabled ?? true;
									object.index = index;
									return object;
								})).sort((a, b) => +a.No - +b.No)}
								onCellClick={(e) => {
									if (period?.value) {
										e.player = orderItems.find(it => it.key === e.data.No);
										e.team = 'away';
										setShowSecond(e);
									}
								}}
							/>}
							<Button light title={'Team'}
								clicked={() => {
									setAction({ ...action, popup: { ...action?.popup, team: true }, click: { team: 'away' } });
								}} />
							<Button light title={'Coach'}
								clicked={() => {
									setAction({ ...action, popup: { ...action?.popup, coach: true }, click: { team: 'away' } });
								}} />


						</div>
					</div>
					<br />
					<div style={{ width: '100%' }}>
						<div style={{ width: '100%', display: 'flex' }}>
							<div style={{ width: isMobile ? '100%' : '70%' }}>
								{records?.length > 0 && records[0]?.options?.filters?.length > 0 && (
									records[0]?.value ?
										<p className={isMobile ? cls.teamsLabelDetail : cls.teamsLabel}>
											{itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value} ( Home )
										</p> :
										<div className={cls.dropdownContainer} style={{ margin: '10px 0 10px', border: '1px solid var(--app-color-gray-dark)', width: isMobile ? '100%' : '25%' }}>

											<SelectD
												defaultSelected={itemsR0.length > 0 ? { label: itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value } : {}}
												items={
													itemsR0
														? R.uniqWith(R.eqProps('value'))(
															itemsR0.map(item => {
																const found = item.component_fields.find(sf => sf.field_name === records[0]?.options?.filters[0]);
																return { label: found?.value, value: found?.value, component: item };
															})
														)
														: []
												}
												onChange={value => {
													setSelectedItems({ [records[0]?.options?.filters[0]]: value.value });
													setListItems([]);

													setListItems(R.sortBy(R.prop('key'), itemsR0?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0).map(it => {

														const new_item = { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === playerKey)?.value, fouls: { fst: '', snd: '', trd: '', fth: '', fif: '', sth: '' }, scores: { fst: '', snd: '', trd: '', fth: '', ot: '' }, misc: { oreb: '', dreb: '', ast: '', blk: '', stl: '', tov: '' } }
														if (processTaskContext?.items?.filter(it => it.id === new_item.id).length == 0)
															setTaskProcessContext({ action: processTaskAction.addModuleItems, value: { id: new_item.id, index: +new_item.key, item_code: new_item.id, quantity: JSON.stringify(new_item.scores), value: JSON.stringify(new_item.misc), alt_value: JSON.stringify(new_item.fouls) } });

														return new_item;
													})));

													setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
													records[0].value = itemsR0.find(c => c.component_fields.filter(cf => cf.value === value.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.output)?.value;
												}}
												placeholder={records[0]?.options?.filters[0]}
												moliclassname={cls.componentsSelectMenuOverlayListItem}
												style={{ fontSize: '16px' }}
												className={cls.currencyInput}
											/>
										</div>

								)}

							</div>
							{!isMobile && period?.value &&
								<CustomTemplateModuleList
									className={cls.lineTeam}
									tableHostSimple={cls.subTable}
									style={{ width: '30%' }}
									layeredColumns={[
										{
											key: 'scoring', label: 'Scoring By Periods', type: '',
											subColumns: [
												{ key: 'first', label: '1st Qtr', type: '' },
												{ key: 'second', label: '2nd Qtr', type: '' },
												{ key: 'third', label: '3rd Qtr', type: '' },
												{ key: 'fourth', label: '4th Qtr', type: '' },
												{ key: 'ot', label: 'Overtime', type: '' },
											]
										},
										{ key: 'total', label: 'Total', type: '' }
									]}
									list={[{
										['1st Qtr']: teamScore(listItems, true, false, false, false, false),
										['2nd Qtr']: listItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(listItems, false, true, false, false, false) : orderItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(listItems, true, false, false, false, false) : '',
										['3rd Qtr']: listItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(listItems, false, false, true, false, false) : orderItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(listItems, true, true, false, false, false) : '',
										['4th Qtr']: listItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(listItems, false, false, false, true, false) : orderItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(listItems, true, true, true, false, false) : '',
										['Overtime']: listItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(listItems, false, false, false, false, true) : orderItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(listItems, true, true, true, true, false) : '',
										['Total']: teamScore(listItems, true, true, true, true, true)
									}
									]}
								/>}
						</div>
						<CustomTemplateModuleList
							className={cls.lineTeam}
							style={{ width: '100%' }}
							tableHostSimple={cls.boxScoreTable}
							layeredColumns={period?.value ? !isMobile ? template?.slist.filter(li => li.type !== 'STR') : template?.slist.filter(li => (li.type === 'Player' || li.type === 'STR')) : template?.slist.filter(li => (li.type === 'STR' || li.type === 'Player'))}
							list={((
								(isMobile || selectedItems === true) ?
									listItems.filter(player => (player.misc?.oc === 1 || listItems.filter(pl => pl?.misc?.oc === 1).length < 5))
									: listItems).map((player, index) => {
										let object = {};
										template?.slist?.filter(li => {
											if (li?.subColumns) {
												li?.subColumns.filter(sc => {
													object[sc.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? ''}`, sc.type, index, player, home, 'home', listItems, setListItems);
												})
											} else {
												object[li.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === li.key)?.value ?? ''}`, li.type, index, player, home, 'home', listItems, setListItems);
											}
										});
										object.enabled = player?.enabled ?? true;
										object.index = index;
										return object;
									})).sort((a, b) => +a.No - +b.No)}
							onCellClick={e => {
								if (period?.value) {
									e.player = listItems.find(it => it.key === e.data.No);
									e.team = 'home';
									if (e.column == 0 || (e.column > 2 && e.column < 15)) {
										setTimeout(() => document.getElementById(`${e.player.id}${Object.keys(e.data)[e.column + 1]}`).focus(), 10);
									} else if (e.column === 2) {
										setShowSecond(e);
									} else if (e.column === 1 && isMobile) {
										setShowSecond(e);
									}
								}
							}}
						/>

						{!isMobile && period?.value && <CustomTemplateModuleList
							className={cls.lineTeam}
							tableHostSimple={cls.detailScoringTable}
							style={{ width: '100%' }}
							layeredColumns={[
								{
									key: 'scoring', label: 'Team Fouls', type: '',
									subColumns: [
										{ key: 'q1', label: 'Q1', type: '' },
										{ key: 'q2', label: 'Q2', type: '' },
										{ key: 'q3', label: 'Q3', type: '' },
										{ key: 'q4', label: 'Q4', type: '' },

									]
								},
								{
									key: 'timeouts', label: 'Timeouts', type: '',
									subColumns: [
										{ key: 'first', label: '1st Half', type: '' },
										{ key: 'second', label: '2nd Half', type: '' },
										{ key: 'ot1', label: 'OT 1', type: '' },
										{ key: 'ot2', label: 'OT 2', type: '' },


									]
								},
								{
									key: 'notes', label: 'Team', type: '',
									subColumns: [
										{ key: 'notes', label: 'Notes', type: '' },

									]
								},
							]}
							list={[{


								['1st Half']: <MutipleInputs cnt={4} target={timeouts?.value ? JSON.parse(timeouts?.value)?.home?.first ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.home.first = +e;
											timeouts.value = JSON.stringify(timeoutX);

										}
									}
									disabled={(period.value == '1st' || period.value == '2nd') ? false : true} />,
								['2nd Half']: <MutipleInputs cnt={4} target={timeouts?.value ? JSON.parse(timeouts?.value)?.home?.second ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.home.second = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={(period.value == '3rd' || period.value == '4th') ? false : true} />,
								['OT 1']: <MutipleInputs cnt={1} target={timeouts?.value ? JSON.parse(timeouts?.value)?.home?.ot ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.home.ot = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={period.value === 'OT' ? false : true} />,
								['OT 2']: <MutipleInputs cnt={1} target={timeouts?.value ? JSON.parse(timeouts?.value)?.home?.ot2 ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.home.ot2 = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={period.value == 'OT 2' ? false : true} />,

								['Q1']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.home?.first ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.home.first = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '1st') ? false : true} />,

								['Q2']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.home?.second ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.home.second = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '2nd') ? false : true} />,
								['Q3']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.home?.third ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.home.third = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '3rd') ? false : true} />,
								['Q4']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.home?.fourth ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.home.fourth = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '4th') ? false : true} />,
								['Notes']:
									<textarea autoComplete="off" type="text" className={cls.templateNotes}
										defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).home : ''}
										onChange={
											e => {
												const notes = JSON.parse(teamNotes.value);
												notes.home = e.target.value;
												teamNotes.value = JSON.stringify(notes);
											}
										}
										onBlur={e => {
											if (showFavorites) {
												const notes = JSON.parse(teamNotes.value);
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: notes.home, alt_value: period?.value }
												});
											}
										}}
									/>
							}]}
						/>}

						<br />
						<hr />
						<div style={{ width: '100%', display: 'flex' }}>
							<div style={{ width: isMobile ? '100%' : '70%' }}>
								{records?.length > 1 && records[1]?.options?.filters?.length > 0 && (
									records[1]?.value ?
										<p className={isMobile ? cls.teamsLabelDetail : cls.teamsLabel}>
											{itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ( Away )
										</p> :
										<div className={cls.dropdownContainer} style={{ margin: '30px 0 10px', border: '1px solid var(--app-color-gray-dark)', width: isMobile ? '100%' : '25%' }}>

											<SelectD
												defaultSelected={itemsR1.length > 0 ? { label: itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value } : {}}
												items={
													itemsR1
														? R.uniqWith(R.eqProps('value'))(
															itemsR1.map(item => {
																const found = item.component_fields.find(sf => sf.field_name === records[1]?.options?.filters[0]);
																return { label: found?.value, value: found?.value, component: item };
															})
														)
														: []
												}
												onChange={value => {
													setShowFavorites(false);
													setOrderItems([]);
													setOrderItems([]);

													setOrderItems(itemsR1?.filter(item => item.component_fields.filter(cf => cf.value === value.value)?.length > 0).map(it => {

														const new_item = { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === playerKey)?.value, fouls: { fst: '', snd: '', trd: '', fth: '', fif: '', sth: '' }, scores: { fst: '', snd: '', trd: '', fth: '', ot: '' }, misc: { oreb: '', dreb: '', ast: '', blk: '', stl: '', tov: '' } }
														if (processTaskContext?.items?.filter(it => it.id === new_item.id).length == 0)
															setTaskProcessContext({ action: processTaskAction.addModuleItems, value: { id: new_item.id, index: +new_item.key, item_code: new_item.id, quantity: JSON.stringify(new_item.scores), value: JSON.stringify(new_item.misc), alt_value: JSON.stringify(new_item.fouls) } });

														return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === playerKey)?.value, fouls: {}, scores: {}, misc: {}, others: {} };
													}));
													setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: processTaskContext.currentStep?.component_blueprints ?? [] });
													records[1].value = itemsR1.find(c => c.component_fields.filter(cf => cf.value === value.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.output)?.value;
												}}
												placeholder={records[1]?.options?.filters[0]}
												moliclassname={cls.componentsSelectMenuOverlayListItem}
												style={{ fontSize: '16px' }}
												className={cls.currencyInput}
											/>
										</div>
								)}
							</div>
							{!isMobile && period?.value && <CustomTemplateModuleList
								className={cls.lineScoring}
								style={{ width: '30%' }}
								tableHostSimple={cls.subTable}
								layeredColumns={[
									{
										key: 'scoring', label: 'Scoring by Periods', type: '',
										subColumns: [
											{ key: 'first', label: '1st Qtr', type: '' },
											{ key: 'second', label: '2nd Qtr', type: '' },
											{ key: 'third', label: '3rd Qtr', type: '' },
											{ key: 'fourth', label: '4th Qtr', type: '' },
											{ key: 'ot', label: 'Overtime', type: '' },
										]
									},
									{ key: 'total', label: 'Total', type: '' }
								]}
								list={[{
									['1st Qtr']: teamScore(orderItems, true, false, false, false, false),
									['2nd Qtr']: orderItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(orderItems, false, true, false, false, false) : listItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(orderItems, true, false, false, false, false) : '',
									['3rd Qtr']: orderItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(orderItems, false, false, true, false, false) : listItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(orderItems, true, true, false, false, false) : '',
									['4th Qtr']: orderItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(orderItems, false, false, false, true, false) : listItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(orderItems, true, true, true, false, false) : '',
									['Overtime']: orderItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(orderItems, false, false, false, false, true) : listItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(orderItems, true, true, true, true, false) : '',
									['Total']: teamScore(orderItems, true, true, true, true, true)
								}
								]}
							/>}
						</div>
						<CustomTemplateModuleList
							className={cls.lineTeam}
							style={{ width: '100%' }}
							tableHostSimple={cls.boxScoreTable}
							layeredColumns={period?.value ? !isMobile ? template?.slist.filter(li => li.type !== 'STR') : template?.slist.filter(li => (li.type === 'Player' || li.type === 'STR')) : template?.slist.filter(li => (li.type === 'STR' || li.type === 'Player'))}
							list={((
								(isMobile || selectedItems === true) ?
									orderItems.filter(player => (player.misc?.oc === 1 || orderItems.filter(pl => pl?.misc?.oc === 1).length < 5))
									: orderItems).map((player, index) => {
										let object = {};
										template?.slist?.filter(li => {
											if (li?.subColumns) {
												li?.subColumns.filter(sc => {
													object[sc.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? ''}`, sc.type, index, player, away, 'away', orderItems, setOrderItems);
												})
											} else {
												object[li.label] = checkHtmlElement(`${player.component_fields.find(cf => cf.field_name === li.key)?.value ?? ''}`, li.type, index, player, away, 'away', orderItems, setOrderItems);
											}
										});
										object.enabled = player?.enabled ?? true;
										object.index = index;
										return object;
									})).sort((a, b) => +a.No - +b.No)}
							onCellClick={(e) => {
								if (period?.value) {
									e.player = orderItems.find(it => it.key === e.data.No);
									e.team = 'away';
									if (e.column == 0 || (e.column > 2 && e.column < 15)) {
										setTimeout(() => document.getElementById(`${e.player.id}${Object.keys(e.data)[e.column + 1]}`).focus(), 10);
									} else if (e.column === 2) {
										setShowSecond(e);
									} else if (e.column === 1 && isMobile) {
										setShowSecond(e);
									}
								}
							}}
						/>
						{!isMobile && period?.value && <CustomTemplateModuleList
							className={cls.lineTeam}
							tableHostSimple={cls.detailScoringTable}
							style={{ width: '100%' }}
							layeredColumns={[
								{
									key: 'scoring', label: 'Team Fouls',
									subColumns: [
										{ key: 'q1', label: 'Q1' },
										{ key: 'q2', label: 'Q2', type: '' },
										{ key: 'q3', label: 'Q3', type: '' },
										{ key: 'q4', label: 'Q4', type: '' },
									]
								},
								{
									key: 'timeouts', label: 'Timeouts', type: '',
									subColumns: [
										{ key: 'first', label: '1st Half', type: '' },
										{ key: 'second', label: '2nd Half', type: '' },
										{ key: 'ot1', label: 'OT 1', type: '' },
										{ key: 'ot2', label: 'OT 2', type: '' },

									]
								},
								{
									key: 'notes', label: 'Team', type: '',
									subColumns: [
										{ key: 'notes', label: 'Notes', type: '' },
									]
								},
							]}
							list={[{


								['1st Half']: <MutipleInputs cnt={4} target={timeouts?.value ? JSON.parse(timeouts?.value)?.away?.first ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.away.first = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={(period.value == '1st' || period.value == '2nd') ? false : true} />,
								['2nd Half']: <MutipleInputs cnt={4} target={timeouts?.value ? JSON.parse(timeouts?.value)?.away?.second ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.away.second = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={(period.value == '3rd' || period.value == '4th') ? false : true} />,
								['OT 1']: <MutipleInputs cnt={1} target={timeouts?.value ? JSON.parse(timeouts?.value)?.away?.ot ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.away.ot = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={period.value == 'OT' ? false : true} />,
								['OT 2']: <MutipleInputs cnt={1} target={timeouts?.value ? JSON.parse(timeouts?.value)?.away?.ot2 ?? 0 : 0}
									onChange={
										e => {
											const timeoutX = JSON.parse(timeouts.value);
											timeoutX.away.ot2 = +e;
											timeouts.value = JSON.stringify(timeoutX);
										}
									}
									disabled={period.value == 'OT 2' ? false : true} />,
								['Q1']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.away?.first ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.away.first = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '1st') ? false : true} />,
								['Q2']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.away?.second ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.away.second = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '2nd') ? false : true} />,
								['Q3']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.away?.third ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.away.third = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '3rd') ? false : true} />,
								['Q4']: <MutipleInputs cnt={5} target={fouls?.value ? JSON.parse(fouls?.value)?.away?.fourth ?? 0 : 0}
									onChange={
										e => {
											const foulsX = JSON.parse(fouls.value);
											foulsX.away.fourth = +e;
											fouls.value = JSON.stringify(foulsX);
										}
									}
									disabled={(period.value == '4th') ? false : true} />,
								['Notes']:
									<textarea autoComplete="off" type="text" className={cls.templateNotes}
										defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).away : ''}
										onChange={
											e => {
												const notes = JSON.parse(teamNotes.value);
												notes.away = e.target.value;
												teamNotes.value = JSON.stringify(notes);
											}
										}
										onBlur={e => {
											if (showFavorites) {
												const notes = JSON.parse(teamNotes.value);
												setTaskProcessContext({
													action: processTaskAction.addModuleItems, value:
														{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: notes.away, alt_value: period?.value }
												});
											}
										}}
									/>
							}
							]}
						/>}
					</div>

					<div style={{ display: (isMobile || !period?.value) ? 'none' : 'flex', width: '100%' }}>
						<div style={{ width: '25%' }}>
							<RenderYesNoSwitcher
								{...{
									id: 'Show Activer?',
									title: 'Show players on court only',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ width: 'auto' }}
								toggleSelected={val => {
									setSelectedItems(val)
								}}
							/>
						</div>

						<CustomTemplateModuleList
							className={cls.lineTeam}
							tableHostSimple={cls.subTable}
							style={{ width: '70%' }}
							layeredColumns={[
								{
									key: 'scoring', label: 'Team Possesions and Jumpball Notes', type: '',
									subColumns: [
										{ key: '1', label: '1' },
										{ key: '2', label: '2' },
										{ key: '3', label: '3' },
										{ key: '4', label: '4' },
										{ key: '5', label: '5' },
									]
								},

							]}
							list={[{
								['1']: <textarea autoComplete="off" type="text" className={cls.templateNotes}
									defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).p1 : ''}
									onChange={
										e => {
											const notes = JSON.parse(teamNotes.value);
											notes.p1 = e.target.value;
											teamNotes.value = JSON.stringify(notes);
										}
									}
									onBlur={e => {
										const notes = JSON.parse(teamNotes.value);

										if (showFavorites && notes?.p1?.length > 1) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: `Possession arrow: ${notes.p1}`, alt_value: period?.value }
											});
										}
									}}
								/>,
								['2']: <textarea autoComplete="off" type="text" className={cls.templateNotes}
									defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).p2 : ''}
									onChange={
										e => {
											const notes = JSON.parse(teamNotes.value);
											notes.p2 = e.target.value;
											teamNotes.value = JSON.stringify(notes);
										}
									}
									onBlur={e => {
										const notes = JSON.parse(teamNotes.value);

										if (showFavorites && notes?.p2?.length > 1) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: `Possession arrow: ${notes.p2}`, alt_value: period?.value }
											});
										}
									}}
								/>,
								['3']: <textarea autoComplete="off" type="text" className={cls.templateSCInput}
									defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).p3 : ''}
									onChange={
										e => {
											const notes = JSON.parse(teamNotes.value);
											notes.p3 = e.target.value;
											teamNotes.value = JSON.stringify(notes);
										}
									}
									onBlur={e => {
										const notes = JSON.parse(teamNotes.value);

										if (showFavorites && notes?.p3?.length > 1) {
											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: `Possession arrow: ${notes.p3}`, alt_value: period?.value }
											});
										}
									}}
								/>,
								['4']: <textarea autoComplete="off" type="text" className={cls.templateNotes}
									defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).p4 : ''}
									onChange={
										e => {
											const notes = JSON.parse(teamNotes.value);
											notes.p4 = e.target.value;
											teamNotes.value = JSON.stringify(notes);
										}
									}
									onBlur={e => {
										const notes = JSON.parse(teamNotes.value);
										if (showFavorites && notes?.p4?.length > 1) {

											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: `Possession arrow: ${notes.p4}`, alt_value: period?.value }
											});
										}
									}}
								/>,
								['5']: <textarea autoComplete="off" type="text" className={cls.templateSCInput}
									defaultValue={teamNotes?.value ? JSON.parse(teamNotes.value).p5 : ''}
									onChange={
										e => {
											const notes = JSON.parse(teamNotes.value);
											notes.p5 = e.target.value;
											teamNotes.value = JSON.stringify(notes);
										}
									}
									onBlur={e => {
										const notes = JSON.parse(teamNotes.value);
										if (showFavorites && notes?.p5?.length > 1) {

											setTaskProcessContext({
												action: processTaskAction.addModuleItems, value:
													{ index: processTaskContext?.items?.length, item_code: 'PBP', quantity: JSON.stringify({ time: countdownTimerElementDisplay.textContent, score: `${home?.value ?? 0}-${away?.value ?? 0}`, player: '' }), value: `Possession arrow: ${notes.p5}`, alt_value: period?.value }
											});
										}
									}}
								/>

							}
							]}
						/>
					</div>
				</div>

				<hr />

				<div id='Play by Play' style={{ width: '100%', display: (period?.value) ? '' : 'none' }}>
					<p className={cls.teamsLabel}>
						{'Play by play:'}
					</p>
					<CustomTemplateModuleList
						className={cls.lineTeam}
						tableHostSimple={cls.playByPlayTable}
						style={{ width: '100%' }}
						columns={['Time', 'Description', 'Score'
						]}
						list={R.sortWith([R.descend(R.prop('index'))])(processTaskContext?.items?.filter(it => (it.item_code === 'PBP' && it.alt_value === period?.value))).map(it => {

							const found = JSON.parse(it.quantity)


							return {
								['Time']: <div>
									{found.time}
								</div>,

								['Description']: <div>
									{it.value === 'Fouls' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ${typename(found?.type ?? '')} Foul - ( ${found?.value ?? ''}PF ), committed against ${getPlayerName(found?.p2, found?.team == 'home' ? orderItems : listItems, 'Player')}, ${found?.type2 ? `awarded ${found?.type2}` : ''}` :
										it.value === 'TOV' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ${typename(found?.type ?? '')} turnover - ( ${found?.value ?? ''}TO )` :
											it.value === '3PTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 3PT shot missed` :
												it.value === '2PTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 2PT shot missed` :
													it.value === 'FTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} free throw missed` :

														it.value === '3PTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 3PT ${typename(found?.type ?? 'shot')} made - ( ${found?.value ?? ''}pts ) ${found?.p2 ? `Assisted by ${getPlayerName(found?.p2, found?.team == 'home' ? listItems : orderItems, 'Player')}` : ''}` :
															it.value === '2PTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 2PT ${typename(found?.type ?? 'shot')} made - ( ${found?.value ?? ''}pts ) ${found?.p2 ? `Assisted by ${getPlayerName(found?.p2, found?.team == 'home' ? listItems : orderItems, 'Player')}` : ''}` :
																it.value === 'FTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} free throw made - ( ${found?.value ?? ''}pts )` :

																	it.value === 'TIME' ? `Timeout - ${found?.team ?? 'xx'}` :
																		it.value === 'START' ? `START OF ${it.alt_value} PERIOD` :
																			it.value === 'END' ? `END OF ${it.alt_value} PERIOD` :
																				it.value === 'FINAL' ? `GAME ENDED` :

																					it.value === 'BLK' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} blocked the shot - ( ${found?.value ?? ''}blk )` :
																						it.value === 'AST' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} made the assist - ( ${found?.value ?? ''}ast )` :
																							it.value === 'STL' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} stole the ball from ${getPlayerName(found?.p2, found?.team == 'home' ? orderItems : listItems, 'Player')} - ( ${found?.value ?? ''}stl )` :
																								it.value === 'OREB' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} offensive rebound - ( ${found?.value ?? ''}rebs )` :
																									it.value === 'DREB' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} defensive rebound - ( ${found?.value ?? ''}rebs )` :

																										it.value === 'SUBOUT' ? `Substitution OUT - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')}` :
																											it.value === 'SUBIN' ? `Substitution IN - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ` :
																												it.value === 'FOULOUT' ? `Substitution OUT - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} FOULED OUT` :
																													it.value === 'JUMP' ? `Jumpball - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} v ${getPlayerName(found?.p2, found?.team == 'away' ? listItems : orderItems, 'Player')}, ${found?.type === 'jbw' ? getPlayerName(found?.p2, found?.team == 'away' ? listItems : orderItems, 'Player') : getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} wins tip` :
																														it.value === 'TIP' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} tips ball out of bounds, side line inbound` :
																															it.value === 'HELD' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} forces a held ball.` :
																																it.value === 'CHAL' ? `Coach challenge (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value})` :
																																	it.value === 'DEL' ? `Delay of game warning (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																		it.value === 'WARN' ? `Warning issued (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																			it.value === 'CLK' ? `Shot clock violation (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																				it.value === 'TTECH2' ? `Technical Foul Type 2 (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																					it.value === 'TTECH1' ? `Technical Foul Type 1 (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																						it.value === 'CTECH2' ? `Technical Foul - Coach (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																							it.value === 'CTECH1' ? `Technical Foul - Coach (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																								it.value === 'POS' ? `Change of possession - ${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} gets ball` :
																																									it.value === 'OUTOPP' ? `Out of bounds - ${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} gets possession` :
																																										it.value === 'OUTRET' ? `Out of bounds - ${found?.team === 'away' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} retains possession` :
																																											it.value === 'ARR' ? `Possession arrow to ${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value}` :
																																												it.value
									}
								</div>,
								['Score']: <div>
									{found.score}
								</div>,
								['Player']: <div>
									{getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')}
								</div>

							}
						})
						}
					/>

				</div>
				<div id='Running Score Bottom' style={{ width: '100%', display: (!isMobile && period?.value) ? '' : 'none' }}>

					<p className={cls.teamsLabel}>
						{'Running Score:'}
					</p>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<CustomTemplateModuleList
							hideHeaders={false}
							style={{ width: '25%' }}
							tableHostSimple={cls.endTable}
							columns={['Notes H', 'PSH', 'Home', 'Away', 'PSA', 'Notes A']}
							list={[...Array(40)].map((obj, i) => {
								return {
									['Notes H']:

										<input id={`rssHT${i + 1}`} className={cls.templateNotesInput}
											defaultValue={home?.value < (i + 1) ? '' : generalArray.find(it => +it.key === (i + 1))?.notesH ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 1));
												current.notesH = document.getElementById(`rssHT${i + 1}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 1])) {
													const sc = rs.find(scr => scr[i + 1]);
													sc[i + 1].nh = current.notesH;
													runningScore.value = JSON.stringify(rs);
												}

											}}
										/>,
									['PSH']:

										<input id={`rssH${i + 1}`} className={cls.templateOthersInput}
											disabled={home?.value < (i + 1) ? true : false}
											defaultValue={home?.value < (i + 1) ? '' : generalArray.find(it => +it.key === (i + 1))?.home ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 1)) ?? { home: '', key: i + 1 };
												if (listItems.filter(it => it.key === document.getElementById(`rssH${i + 1}`).value).length > 0) {
													current.home = document.getElementById(`rssH${i + 1}`)?.value;
													//adjustScore(listItems.find(it => it.key === current.home), listItems, setListItems, home, current.key)
												} else {
													if (document.getElementById(`rssH${i + 1}`).value === '') {
														current.home = '';
													} else {
														document.getElementById(`rssH${i + 1}`).value = current.home;
													}
												}
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Home']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: home?.value === (i + 1) ? 'yellow' : teamScore(listItems, true, false, false, false, false) == (i + 1) ? 'lightgreen' : teamScore(listItems, true, true, false, false, false) == (i + 1) ? 'pink' : teamScore(listItems, true, true, true, false, false) == (i + 1) ? 'orange' : teamScore(listItems, true, true, true, true, false) == (i + 1) ? 'lightblue' : teamScore(listItems, true, true, true, true, true) == (i + 1) ? 'lightgray' : '' }}>{`${i + 1}`}</div>,
									['Away']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: away?.value === (i + 1) ? 'yellow' : teamScore(orderItems, true, false, false, false, false) == (i + 1) ? 'lightgreen' : teamScore(orderItems, true, true, false, false, false) == (i + 1) ? 'pink' : teamScore(orderItems, true, true, true, false, false) == (i + 1) ? 'orange' : teamScore(orderItems, true, true, true, true, false) == (i + 1) ? 'lightblue' : teamScore(orderItems, true, true, true, true, true) == (i + 1) ? 'lightgray' : '' }}>{`${i + 1}`}</div>,
									['PSA']:

										<input id={`rssA${i + 1}`} className={cls.templateOthersInput}
											disabled={away?.value < (i + 1) ? true : false}
											defaultValue={generalArray.find(it => +it.key === (i + 1))?.away ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 1)) ?? { key: i + 1, away: '' };

												if (orderItems.filter(it => it.key === document.getElementById(`rssA${i + 1}`).value).length > 0) {
													current.away = document.getElementById(`rssA${i + 1}`)?.value;
													//adjustScore(orderItems.find(it => it.key === current.away), orderItems, setOrderItems, away, current.key)
												} else {
													if (document.getElementById(`rssA${i + 1}`).value === '') {
														current.away = '';
													} else {
														document.getElementById(`rssA${i + 1}`).value = current.away;
													}
												}

												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Notes A']:

										<input id={`rssAT${i + 1}`} className={cls.templateNotesInput}
											defaultValue={away?.value < (i + 1) ? '' : generalArray.find(it => +it.key === (i + 1))?.notesA ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 1));
												current.notesA = document.getElementById(`rssAT${i + 1}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 1])) {
													const sc = rs.find(scr => scr[i + 1]);
													sc[i + 1].na = current.notesA;
													runningScore.value = JSON.stringify(rs);
												}
											}}
										/>

								}
							})}
						/>
						<CustomTemplateModuleList
							hideHeaders={false}
							style={{ width: '25%' }}
							tableHostSimple={cls.endTable}
							columns={['Notes H', 'PSH', 'Home', 'Away', 'PSA', 'Notes A']}
							list={[...Array(40)].map((obj, i) => {
								return {
									['Notes H']:

										<input id={`rssHT${i + 41}`} className={cls.templateNotesInput}
											defaultValue={home?.value < (i + 41) ? '' : generalArray.find(it => +it.key === (i + 41))?.notesH ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 41));
												current.notesH = document.getElementById(`rssHT${i + 41}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 41])) {
													const sc = rs.find(scr => scr[i + 41]);
													sc[i + 1].nh = current.notesH;
													runningScore.value = JSON.stringify(rs);
												}

											}}
										/>,
									['PSH']:

										<input id={`rssH${i + 41}`} className={cls.templateOthersInput}
											disabled={home?.value < (i + 41) ? true : false}
											defaultValue={home?.value < (i + 41) ? '' : generalArray.find(it => +it.key === (i + 41))?.home ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 41)) ?? { home: '', key: i + 41 };
												if (listItems.filter(it => it.key === document.getElementById(`rssH${i + 41}`).value).length > 0) {
													current.home = document.getElementById(`rssH${i + 41}`)?.value;

												} else {
													if (document.getElementById(`rssH${i + 41}`).value === '') {
														current.home = '';
													} else {
														document.getElementById(`rssH${i + 41}`).value = current.home;
													}
												}
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Home']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: home?.value === (i + 41) ? 'yellow' : teamScore(listItems, true, false, false, false, false) == (i + 41) ? 'lightgreen' : teamScore(listItems, true, true, false, false, false) == (i + 41) ? 'pink' : teamScore(listItems, true, true, true, false, false) == (i + 41) ? 'orange' : teamScore(listItems, true, true, true, true, false) == (i + 41) ? 'lightblue' : teamScore(listItems, true, true, true, true, true) == (i + 41) ? 'lightgray' : '' }}>{`${i + 41}`}</div>,
									['Away']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: away?.value === (i + 41) ? 'yellow' : teamScore(orderItems, true, false, false, false, false) == (i + 41) ? 'lightgreen' : teamScore(orderItems, true, true, false, false, false) == (i + 41) ? 'pink' : teamScore(orderItems, true, true, true, false, false) == (i + 41) ? 'orange' : teamScore(orderItems, true, true, true, true, false) == (i + 41) ? 'lightblue' : teamScore(orderItems, true, true, true, true, true) == (i + 41) ? 'lightgray' : '' }}>{`${i + 41}`}</div>,
									['PSA']:

										<input id={`rssA${i + 41}`} className={cls.templateOthersInput}
											disabled={away?.value < (i + 41) ? true : false}
											defaultValue={generalArray.find(it => +it.key === (i + 41))?.away ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 41)) ?? { key: i + 41, away: '' };

												if (orderItems.filter(it => it.key === document.getElementById(`rssA${i + 41}`).value).length > 0) {
													current.away = document.getElementById(`rssA${i + 41}`)?.value;

												} else {
													if (document.getElementById(`rssA${i + 41}`).value === '') {
														current.away = '';
													} else {
														document.getElementById(`rssA${i + 41}`).value = current.away;
													}
												}

												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Notes A']:

										<input id={`rssAT${i + 41}`} className={cls.templateNotesInput}
											defaultValue={away?.value < (i + 41) ? '' : generalArray.find(it => +it.key === (i + 41))?.notesA ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 41));
												current.notesA = document.getElementById(`rssAT${i + 41}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 41])) {
													const sc = rs.find(scr => scr[i + 41]);
													sc[i + 41].na = current.notesA;
													runningScore.value = JSON.stringify(rs);
												}
											}}
										/>

								}
							})}
						/>

						<CustomTemplateModuleList
							hideHeaders={false}
							style={{ width: '25%' }}
							tableHostSimple={cls.endTable}
							columns={['Notes H', 'PSH', 'Home', 'Away', 'PSA', 'Notes A']}
							list={[...Array(40)].map((obj, i) => {
								return {
									['Notes H']:

										<input id={`rssHT${i + 81}`} className={cls.templateNotesInput}
											defaultValue={home?.value < (i + 81) ? '' : generalArray.find(it => +it.key === (i + 81))?.notesH ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 81));
												current.notesH = document.getElementById(`rssHT${i + 81}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 81])) {
													const sc = rs.find(scr => scr[i + 81]);
													sc[i + 81].nh = current.notesH;
													runningScore.value = JSON.stringify(rs);
												}

											}}
										/>,
									['PSH']:

										<input id={`rssH${i + 81}`} className={cls.templateOthersInput}
											disabled={home?.value < (i + 81) ? true : false}
											defaultValue={home?.value < (i + 81) ? '' : generalArray.find(it => +it.key === (i + 81))?.home ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 81)) ?? { home: '', key: i + 81 };
												if (listItems.filter(it => it.key === document.getElementById(`rssH${i + 81}`).value).length > 0) {
													current.home = document.getElementById(`rssH${i + 81}`)?.value;
													//adjustScore(listItems.find(it => it.key === current.home), listItems, setListItems, home, current.key)
												} else {
													if (document.getElementById(`rssH${i + 81}`).value === '') {
														current.home = '';
													} else {
														document.getElementById(`rssH${i + 81}`).value = current.home;
													}
												}
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Home']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: home?.value === (i + 81) ? 'yellow' : teamScore(listItems, true, false, false, false, false) == (i + 81) ? 'lightgreen' : teamScore(listItems, true, true, false, false, false) == (i + 81) ? 'pink' : teamScore(listItems, true, true, true, false, false) == (i + 81) ? 'orange' : teamScore(listItems, true, true, true, true, false) == (i + 81) ? 'lightblue' : teamScore(listItems, true, true, true, true, true) == (i + 81) ? 'lightgray' : '' }}>{`${i + 81}`}</div>,
									['Away']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: away?.value === (i + 81) ? 'yellow' : teamScore(orderItems, true, false, false, false, false) == (i + 81) ? 'lightgreen' : teamScore(orderItems, true, true, false, false, false) == (i + 81) ? 'pink' : teamScore(orderItems, true, true, true, false, false) == (i + 81) ? 'orange' : teamScore(orderItems, true, true, true, true, false) == (i + 81) ? 'lightblue' : teamScore(orderItems, true, true, true, true, true) == (i + 81) ? 'lightgray' : '' }}>{`${i + 81}`}</div>,
									['PSA']:

										<input id={`rssA${i + 81}`} className={cls.templateOthersInput}
											disabled={away?.value < (i + 81) ? true : false}
											defaultValue={generalArray.find(it => +it.key === (i + 81))?.away ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 1)) ?? { key: i + 81, away: '' };

												if (orderItems.filter(it => it.key === document.getElementById(`rssA${i + 81}`).value).length > 0) {
													current.away = document.getElementById(`rssA${i + 81}`)?.value;
													//adjustScore(orderItems.find(it => it.key === current.away), orderItems, setOrderItems, away, current.key)
												} else {
													if (document.getElementById(`rssA${i + 81}`).value === '') {
														current.away = '';
													} else {
														document.getElementById(`rssA${i + 81}`).value = current.away;
													}
												}

												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Notes A']:

										<input id={`rssAT${i + 1}`} className={cls.templateNotesInput}
											defaultValue={away?.value < (i + 81) ? '' : generalArray.find(it => +it.key === (i + 81))?.notesA ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 81));
												current.notesA = document.getElementById(`rssAT${i + 81}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 81])) {
													const sc = rs.find(scr => scr[i + 81]);
													sc[i + 1].na = current.notesA;
													runningScore.value = JSON.stringify(rs);
												}
											}}
										/>

								}
							})}
						/>

						<CustomTemplateModuleList
							hideHeaders={false}
							style={{ width: '25%' }}
							tableHostSimple={cls.endTable}
							columns={['Notes H', 'PSH', 'Home', 'Away', 'PSA', 'Notes A']}
							list={[...Array(40)].map((obj, i) => {
								return {
									['Notes H']:

										<input id={`rssHT${i + 121}`} className={cls.templateNotesInput}
											defaultValue={home?.value < (i + 121) ? '' : generalArray.find(it => +it.key === (i + 121))?.notesH ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 121));
												current.notesH = document.getElementById(`rssHT${i + 121}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 121])) {
													const sc = rs.find(scr => scr[i + 121]);
													sc[i + 121].nh = current.notesH;
													runningScore.value = JSON.stringify(rs);
												}

											}}
										/>,
									['PSH']:

										<input id={`rssH${i + 121}`} className={cls.templateOthersInput}
											disabled={home?.value < (i + 121) ? true : false}
											defaultValue={home?.value < (i + 121) ? '' : generalArray.find(it => +it.key === (i + 121))?.home ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 121)) ?? { home: '', key: i + 121 };
												if (listItems.filter(it => it.key === document.getElementById(`rssH${i + 121}`).value).length > 0) {
													current.home = document.getElementById(`rssH${i + 121}`)?.value;
													//adjustScore(listItems.find(it => it.key === current.home), listItems, setListItems, home, current.key)
												} else {
													if (document.getElementById(`rssH${i + 121}`).value === '') {
														current.home = '';
													} else {
														document.getElementById(`rssH${i + 121}`).value = current.home;
													}
												}
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Home']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: home?.value === (i + 121) ? 'yellow' : teamScore(listItems, true, false, false, false, false) == (i + 121) ? 'lightgreen' : teamScore(listItems, true, true, false, false, false) == (i + 121) ? 'pink' : teamScore(listItems, true, true, true, false, false) == (i + 121) ? 'orange' : teamScore(listItems, true, true, true, true, false) == (i + 121) ? 'lightblue' : teamScore(listItems, true, true, true, true, true) == (i + 121) ? 'lightgray' : '' }}>{`${i + 121}`}</div>,
									['Away']:
										<div className={cls.templateOthersInput} style={{ backgroundColor: away?.value === (i + 121) ? 'yellow' : teamScore(orderItems, true, false, false, false, false) == (i + 121) ? 'lightgreen' : teamScore(orderItems, true, true, false, false, false) == (i + 121) ? 'pink' : teamScore(orderItems, true, true, true, false, false) == (i + 121) ? 'orange' : teamScore(orderItems, true, true, true, true, false) == (i + 121) ? 'lightblue' : teamScore(orderItems, true, true, true, true, true) == (i + 121) ? 'lightgray' : '' }}>{`${i + 121}`}</div>,
									['PSA']:

										<input id={`rssA${i + 121}`} className={cls.templateOthersInput}
											disabled={away?.value < (i + 121) ? true : false}
											defaultValue={generalArray.find(it => +it.key === (i + 121))?.away ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 121)) ?? { key: i + 1, away: '' };

												if (orderItems.filter(it => it.key === document.getElementById(`rssA${i + 121}`).value).length > 0) {
													current.away = document.getElementById(`rssA${i + 121}`)?.value;
													//adjustScore(orderItems.find(it => it.key === current.away), orderItems, setOrderItems, away, current.key)
												} else {
													if (document.getElementById(`rssA${i + 121}`).value === '') {
														current.away = '';
													} else {
														document.getElementById(`rssA${i + 121}`).value = current.away;
													}
												}

												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])
											}}
										/>,
									['Notes A']:

										<input id={`rssAT${i + 121}`} className={cls.templateNotesInput}
											defaultValue={away?.value < (i + 121) ? '' : generalArray.find(it => +it.key === (i + 121))?.notesA ?? ''}
											onBlur={() => {
												const current = generalArray.find(it => +it.key === (i + 121));
												current.notesA = document.getElementById(`rssAT${i + 121}`)?.value;
												setGeneralArray([...generalArray.filter(it => +it.key !== current.key), current])

												const rs = JSON.parse(runningScore?.value)
												if (rs.find(sc => sc[i + 121])) {
													const sc = rs.find(scr => scr[i + 121]);
													sc[i + 121].na = current.notesA;
													runningScore.value = JSON.stringify(rs);
												}
											}}
										/>
								}
							})}
						/>
					</div>
				</div>

			</div>
			:
			<div>
				{`${template?.name} not properly set`}
			</div>
		);
	}

	return <div style={{ width: '100%' }}>
		<AccordionModule
			editMode={editMode}
			nextButtonClicked={nextButtonClicked}
			isMandatoryError={isMandatoryError}
			blueprintIndex={index}
			setIsMandatoryError={setIsMandatoryError}
			blueprints={blueprints}
			component={component_blueprint}
			companyData={companyData}
			components={components}
			allModules={allModules}
			products={products}
			authenticatedUser={authenticatedUser}
			selectedComponent={selectedComponent}
			companyUsersArray={companyUsersArray}
			companyPositionsArray={companyPositionsArray}
			begunModule={begunModule}
		/>
	</div>

});

const mapStateToProps = store => {
	const { authenticatedUser } = store.authReducer;
	const { favorites, begunModule } = store.flowReducer;
	return { authenticatedUser, favorites, begunModule };
};

export default connect(mapStateToProps)(CustomTemplateModule);

export const checkCustomTemplatesForComponentModal = (selection, companyData, componentTypes) => {
	if (selection[0].value === 'Battery Order Template') {
		return companyData?.misc_info?.templatesData
			.find(template => template.name === 'Battery Order Template')
			.config.map(temp => {
				return { value: componentTypes.find(ct => ct.name === temp.value).id, label: temp.label };
			});
	} else if (selection[0].value === 'Battery Template') {
		return companyData?.misc_info?.templatesData
			.find(template => template.name === 'Battery Template')
			.config.map(temp => {
				return { value: componentTypes.find(ct => ct.name === temp.value).id, label: temp.label };
			});
	} else {
		return (
			companyData?.misc_info?.templatesData
				.find(template => template.name === selection[0].value)
				?.config?.map(temp => {
					return { value: componentTypes.find(ct => ct.name === temp.value).id, label: temp.label };
				}) ?? []
		);
	}
};

export const RenderCustomComponentDetailView = React.forwardRef((props, ref) => {
	const { begunModule, allModules, companyData, selectedComponent, companyUsersArray, companyPositionsArray, components = [], step } = props;
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [selectedIndex, setSelectedIndex] = useState();
	const [selectedItems, setSelectedItems] = useState();
	const [orderItems, setOrderItems] = useState([]);
	const [listItems, setListItems] = useState([]);
	const [generalArray, setGeneralArray] = useState([]);


	const templateName = begunModule?.processModule?.completeSteps?.find(step => step?.component_blueprints?.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Template').length > 0).length > 0)?.component_blueprints.find(cb => cb.selected_fields.find(sf => sf.data_type_name === 'Template' && sf.visible == true))?.selected_fields.find(sf => sf.data_type_name === 'Template' && sf.visible == true)?.field_name;
	const template = companyData?.misc_info?.templatesData.find(template => template.name === templateName);

	const [records, setRecords] = useState(selectedComponent.component_fields.filter(sf => sf.data_type_name === 'Record'));


	useEffect(() => {
		if (templateName === 'Scoresheet Template') {

			setRecords(R.uniqWith(R.eqProps('field_name'))(selectedComponent.component_fields.filter(sf => sf.data_type_name === 'Record' && (sf.field_name === template.config.find(sc => sc.key === 'away')?.label || sf.field_name === template.config.find(sc => sc.key === 'home')?.label))))
			const recordH = selectedComponent.component_fields.find(sf => sf.data_type_name === 'Record' && (sf.field_name === template.config.find(sc => sc.key === 'home')?.label))
			const recordA = selectedComponent.component_fields.find(sf => sf.data_type_name === 'Record' && (sf.field_name === template.config.find(sc => sc.key === 'away')?.label))

			setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === recordH?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === recordH.value)?.length > 0).map(it => {
				const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
				const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
				const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
				return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
			})));

			setOrderItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === recordA?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === recordA.value)?.length > 0).map(it => {
				const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
				const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
				const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
				return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
			})));

			setSelectedIndex(selectedComponent.component_fields.find(sf => sf.data_type_name === 'Dropdown' && sf.field_name === template?.config?.find(cfg => cfg.value === 'Status' && cfg.key === 'Period')?.label)?.value ?? '1st')
		}

		if (templateName === 'Player Profile Template') {

			const games = selectedComponent.component_fields.find(sf => sf.data_type_name === 'Dropdown' && (sf.field_name === template.config.find(sc => sc.key === 'Games')?.label))
			const player = selectedComponent.component_fields.find(sf => sf.field_name === template.key.label);
			const roster = selectedComponent.component_fields.find(sf => sf.data_type_name === 'Dropdown' && (sf.field_name === template.config.find(sc => sc.key === 'Roster')?.label))

			const rosters = components.filter(c => c.module_id === roster?.value)?.filter(c => c.component_fields?.find(cf => cf.value === player?.value))
			const status = selectedComponent.component_fields.find(sf => sf.data_type_name === 'Dropdown' && (sf.field_name === template.config.find(sc => sc.key === 'Status')?.label))

			setListItems(
				R.flatten(components.filter(c => c.module_id === games?.value && c.component_items.find(it => rosters.map(ro => {
					return ro.id;
				}).indexOf(it.item_code) !== -1))

					.filter(co => co.component_fields.find(sf => (sf.field_name === template.config.find(sc => sc.key === 'Status')?.label))?.value === (status?.value ?? ''))

					.map(co => {
						return co.component_items;
					})).filter(it => rosters.map(ro => {
						return ro.id;
					}).indexOf(it.item_code) !== -1)

					.map(it => {
						const scores = JSON.parse(it.quantity);
						const misc = JSON.parse(it.value);
						const cmp = components.find(co => co.id === it.component_id);

						return {
							Date: moment(cmp.created_date).format('DD MMM YYYY'),
							League: cmp?.title ?? '',
							MIN: Math.floor(+(misc?.min ?? 0) / 60),
							PTS: playerScore({ scores: scores }),
							['2PT FG']: ((scores?.fst ?? '').split('').filter(sc => +sc == 2).length +
								(scores?.snd ?? '').split('').filter(sc => +sc == 2).length +
								(scores?.trd ?? '').split('').filter(sc => +sc == 2).length +
								(scores?.fth ?? '').split('').filter(sc => +sc == 2).length +
								(scores?.ot ?? '').split('').filter(sc => +sc == 2).length),
							['3PT FG']: ((scores?.fst ?? '').split('').filter(sc => +sc == 3).length +
								(scores?.snd ?? '').split('').filter(sc => +sc == 3).length +
								(scores?.trd ?? '').split('').filter(sc => +sc == 3).length +
								(scores?.fth ?? '').split('').filter(sc => +sc == 3).length +
								(scores?.ot ?? '').split('').filter(sc => +sc == 3).length),
							FTM: `${((scores?.fst ?? '').split('').filter(sc => +sc == 1).length +
								(scores?.snd ?? '').split('').filter(sc => +sc == 1).length +
								(scores?.trd ?? '').split('').filter(sc => +sc == 1).length +
								(scores?.fth ?? '').split('').filter(sc => +sc == 1).length +
								(scores?.ot ?? '').split('').filter(sc => +sc == 1).length)}`,
							FTA: `${((scores?.fst ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(scores?.snd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(scores?.trd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(scores?.fth ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
								(scores?.ot ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length)}`,

							OREB: misc?.oreb ?? '0',
							DREB: misc?.dreb ?? '0',
							REB: `${+(misc?.oreb ?? 0) + +(misc?.dreb ?? 0)}`,
							AST: misc?.ast ?? '0',
							TO: misc?.tov ?? '0',
							STL: misc?.stl ?? '0',
							BLK: misc?.blk ?? '0',

						}
					}));
		}
	}, []);

	if (templateName === 'Scoresheet Template') {
		const home = selectedComponent?.component_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'home')?.label)
		const away = selectedComponent?.component_fields?.find(sf => sf.field_name === template.scores.find(sc => sc.key === 'away')?.label)
		const status = selectedComponent?.component_fields?.find(sf => sf.field_name === 'Status');
		const itemsR0 = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0);
		const itemsR1 = components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0);
		const teamA = itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0]);
		const teamB = itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0]);

		return <div style={{ margin: isMobile ? '' : '20px', fontFamily: 'var(--app-text-main-font)' }}>
			<div className={cls.centerDiv} style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginBottom: '50px', width: '100%' }} >
				<p className={cls.teamsLabelDetail}>
					{teamA?.value}
				</p>
				<input autoComplete="off" type="text" className={cls.scoreLabelDetail} readOnly
					value={home?.value ?? 0}
				/>
				{status?.value === 'Final' && <p className={cls.teamsLabelDetail}>
					{'Final'}
				</p>}
				<input autoComplete="off" type="text" className={cls.scoreLabelDetail} readOnly
					value={away?.value ?? 0}
				/>
				<p className={cls.teamsLabelDetail}>
					{teamB?.value}
				</p>
			</div>
			<Tabs
				style={{ width: '100%', fontSize: isMobile ? '16px' : '20px' }}
				selectedIndex={selectedTabIndex}
				onSelect={index => {
					setSelectedTabIndex(index);
				}}
			>
				<TabList style={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}>
					<Tab>
						{'Overview'}
					</Tab>
					<Tab>
						{'Boxscore'}
					</Tab>
					<Tab>
						{'Play by Play'}
					</Tab>
					<Tab>
						{'Shot Chart'}
					</Tab>
				</TabList>

				<TabPanel>
					<div style={{ minWidth: isMobile ? '100%' : '600px', marginTop: '20px', width: '100%', fontFamily: "var(--app-text-main-font)" }}>

						<CustomTemplateModuleList
							className={cls.lineTeam}
							tableHostSimple={cls.detailScoringTable}
							style={{ width: '100%' }}
							layeredColumns={[
								{
									key: 'team', label: 'Team', type: '',
								},
								{
									key: 'scoring', label: 'Score By Period', type: '',
									subColumns:
										[
											{ key: 'first', label: 'Q1', type: '' },
											{ key: 'second', label: 'Q2', type: '' },
											{ key: 'third', label: 'Q3', type: '' },
											{ key: 'fourth', label: 'Q4', type: '' },
											{ key: 'ot', label: 'OT', type: '' },
										]
								}
							]}
							list={[{
								['Team']: teamA?.value,
								['Q1']: teamScore(listItems, true, false, false, false, false),
								['Q2']: listItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(listItems, true, true, false, false, false) : orderItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(listItems, true, false, false, false, false) : '',
								['Q3']: listItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(listItems, true, true, true, false, false) : orderItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(listItems, true, true, false, false, false) : '',
								['Q4']: listItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(listItems, true, true, true, true, false) : orderItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(listItems, true, true, true, false, false) : '',
								['OT']: listItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(listItems, true, true, true, true, true) : orderItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(listItems, true, true, true, true, false) : '',

							},
							{
								['Team']: teamB?.value,
								['Q1']: teamScore(orderItems, true, false, false, false, false),
								['Q2']: orderItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(orderItems, true, true, false, false, false) : listItems.filter(it => it?.scores?.snd).length > 0 ? teamScore(orderItems, true, false, false, false, false) : '',
								['Q3']: orderItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(orderItems, true, true, true, false, false) : listItems.filter(it => it?.scores?.trd).length > 0 ? teamScore(orderItems, true, true, false, false, false) : '',
								['Q4']: orderItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(orderItems, true, true, true, true, false) : listItems.filter(it => it?.scores?.fth).length > 0 ? teamScore(orderItems, true, true, true, false, false) : '',
								['OT']: orderItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(orderItems, true, true, true, true, true) : listItems.filter(it => it?.scores?.ot).length > 0 ? teamScore(orderItems, true, true, true, true, false) : '',

							}
							]}
						/>
						<p style={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}>
							{'General Information:'}
						</p>
						<ModuleLists
							modern={true}
							className={cls.listContainerDetail}
							isReport={false}
							app-variant-is-mobile={'true'}
							columns={begunModule.processModule.completeSteps.find(step => step.component_blueprints.find(cbp => cbp.name === 'Game Information'))?.component_blueprints.find(cbp => cbp.name === 'Game Information')?.selected_fields.map(field => {
								return field.field_name;
							})}
							list={[selectedComponent].map(comp => {
								let object = {};
								comp.component_fields.map(cf => {
									object[cf.field_name] = cf.data_type_name === 'Date' ? moment(new Date(cf.value)).format('Do MMMM YYYY') : cf.value;
								})
								return object;
							})}
						/>
					</div>
				</TabPanel>
				<TabPanel>
					<div style={{ minWidth: isMobile ? '110%' : '100%', marginTop: '20px', width: isMobile ? '100%' : '100%', fontFamily: "var(--app-text-main-font)" }}>
						<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
							<Button
								light
								title={teamA?.value}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}
								style={{ display: '', backgroundColor: (selectedItems === teamA?.value || !selectedItems) ? 'var(--app-color-blue)' : 'white' }}
								clicked={() => {
									setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[0].value)?.length > 0).map(it => {
										const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
										const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
										const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
										return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
									})));
									setSelectedItems(teamA?.value)
								}}
							/>
							<Button
								light
								title={teamB?.value}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}
								style={{ display: '', backgroundColor: selectedItems === teamB?.value ? 'var(--app-color-blue)' : 'white' }}
								clicked={() => {
									setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[1].value)?.length > 0).map(it => {
										const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
										const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
										const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
										return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
									})));
									setSelectedItems(teamB?.value)
								}}

							/>
						</div>
						<CustomTemplateModuleList
							className={cls.lineTeam}
							style={{ width: '100%' }}
							tableHostSimple={cls.detailBoxScoreTable}
							layeredColumns={[
								{
									key: 'player', label: 'Player',
									subColumns: [
										{ key: 'no', label: 'No' },
										{ key: 'player', label: 'Name' },
										{ key: 'min', label: 'MIN' },

									].filter(col => isMobile ? col.key !== 'no' : col.key)
								},
								{
									key: 'points', label: 'Points',
									subColumns: [
										{ key: 'pts', label: 'PTS' },
										{ key: '2fg', label: '2PT FG' },
										{ key: '3fg', label: '3PT FG' },
										{ key: 'ft', label: 'FT' },

									]
								},
								{
									key: 'others', label: 'Others',
									subColumns: [
										{ key: 'oreb', label: 'OREB' },
										{ key: 'dreb', label: 'DREB' },
										{ key: 'reb', label: 'REB' },
										{ key: 'ast', label: 'AST' },
										{ key: 'pf', label: 'PF' },
										{ key: 'to', label: 'TO' },
										{ key: 'stl', label: 'STL' },
										{ key: 'blk', label: 'BLK' },
									]
								}
							].filter(li => isMobile ? li.key !== 'others' : li.key)}
							list={(listItems.map((player, index) => {
								let object = {};
								template?.slist?.filter(li => {
									if (li?.subColumns) {
										li?.subColumns.filter(sc => {
											object[sc.label] = player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? '';
										})
									} else {
										object[li.label] = player.component_fields.find(cf => cf.field_name === li.key)?.value ?? '';
									}
								});

								return {
									No: player.key,
									Name: player.component_fields.find(cf => cf.field_name === 'Player')?.value ?? '',
									MIN: Math.floor(+(player?.misc?.min ?? 0) / 60),
									PTS: playerScore(player),
									['2PT FG']: ((player.scores?.fst ?? '').split('').filter(sc => +sc == 2).length +
										(player.scores?.snd ?? '').split('').filter(sc => +sc == 2).length +
										(player.scores?.trd ?? '').split('').filter(sc => +sc == 2).length +
										(player.scores?.fth ?? '').split('').filter(sc => +sc == 2).length +
										(player.scores?.ot ?? '').split('').filter(sc => +sc == 2).length),
									['3PT FG']: ((player.scores?.fst ?? '').split('').filter(sc => +sc == 3).length +
										(player.scores?.snd ?? '').split('').filter(sc => +sc == 3).length +
										(player.scores?.trd ?? '').split('').filter(sc => +sc == 3).length +
										(player.scores?.fth ?? '').split('').filter(sc => +sc == 3).length +
										(player.scores?.ot ?? '').split('').filter(sc => +sc == 3).length),
									FT: `${((player.scores?.fst ?? '').split('').filter(sc => +sc == 1).length +
										(player.scores?.snd ?? '').split('').filter(sc => +sc == 1).length +
										(player.scores?.trd ?? '').split('').filter(sc => +sc == 1).length +
										(player.scores?.fth ?? '').split('').filter(sc => +sc == 1).length +
										(player.scores?.ot ?? '').split('').filter(sc => +sc == 1).length)} / ${((player.scores?.fst ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
											(player.scores?.snd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
											(player.scores?.trd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
											(player.scores?.fth ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
											(player.scores?.ot ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length)}`,
									OREB: player?.misc?.oreb ?? '',
									DREB: player?.misc?.dreb ?? '',
									REB: `${+(player?.misc?.oreb ?? 0) + +(player?.misc?.dreb ?? 0)}`,
									AST: player?.misc?.ast,
									PF: Object.values(player?.fouls).filter(val => val.length > 0).length,
									TO: player?.misc?.tov,
									STL: player?.misc?.stl,
									BLK: player?.misc?.blk,
									['+/-']: ''
								};
							})).sort((a, b) => +a.No - +b.No)}
						/>

					</div>
				</TabPanel>

				<TabPanel>
					<div style={{ minWidth: isMobile ? '110%' : '100%', marginTop: '20px', width: isMobile ? '100%' : '70%', fontFamily: "var(--app-text-main-font)" }}>

						<div style={{ display: 'flex' }}>
							<Button
								light
								title={'Q1'}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '12px' }}
								style={{ display: '', width: '20%', backgroundColor: selectedIndex === '1st' ? 'var(--app-color-blue)' : '' }}
								clicked={() => {
									setSelectedIndex('1st');
								}}
							/>
							<Button
								light
								title={'Q2'}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '12px' }}
								style={{ display: '', width: '20%', backgroundColor: selectedIndex === '2nd' ? 'var(--app-color-blue)' : '' }}
								clicked={() => {
									setSelectedIndex('2nd');
								}}
							/>
							<Button
								light
								title={'Q3'}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '12px' }}
								style={{ display: '', width: '20%', backgroundColor: selectedIndex === '3rd' ? 'var(--app-color-blue)' : '' }}
								clicked={() => {
									setSelectedIndex('3rd');
								}}
							/>
							<Button
								light
								title={'Q4'}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '12px' }}
								style={{ display: '', width: '20%', backgroundColor: selectedIndex === '4th' ? 'var(--app-color-blue)' : '' }}
								clicked={() => {
									setSelectedIndex('4th');
								}}
							/>
							<Button
								light
								title={'OT'}
								titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '12px' }}
								style={{ display: '', width: '20%', backgroundColor: selectedIndex === 'OT' ? 'var(--app-color-blue)' : '' }}
								clicked={() => {
									setSelectedIndex('OT');
								}}
							/>
						</div>
						<CustomTemplateModuleList
							hideHeaders={true}
							className={cls.lineTeam}
							tableHostSimple={cls.playByPlayTable}
							style={{ width: '100%' }}
							columns={['Time', 'Description', 'Score'
							]}
							list={R.sortWith([R.descend(R.prop('index'))])(selectedComponent?.component_items?.filter(it => (it.item_code === 'PBP' && it.alt_value === selectedIndex))).map(it => {

								const found = JSON.parse(it.quantity)

								return {
									['Time']: <div>
										{found.time}
									</div>,

									['Description']: <div>
										{it.value === 'Fouls' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ${typename(found?.type ?? '')} Foul - ( ${found?.value ?? ''}PF ), committed against ${getPlayerName(found?.p2, found?.team == 'home' ? orderItems : listItems, 'Player')}, ${found?.type2 ? `,awarded ${found?.type2}` : ''}` :
											it.value === 'TOV' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ${typename(found?.type ?? '')} turnover - ( ${found?.value ?? ''}TO )` :
												it.value === '3PTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 3PT shot missed` :
													it.value === '2PTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 2PT shot missed` :
														it.value === 'FTX' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} free throw missed` :

															it.value === '3PTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 3PT ${typename(found?.type ?? 'shot')} made - ( ${found?.value ?? ''}pts ) ${found?.p2 ? `Assisted by ${getPlayerName(found?.p2, found?.team == 'home' ? listItems : orderItems, 'Player')}` : ''}` :
																it.value === '2PTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} 2PT ${typename(found?.type ?? 'shot')} made - ( ${found?.value ?? ''}pts ) ${found?.p2 ? `Assisted by ${getPlayerName(found?.p2, found?.team == 'home' ? listItems : orderItems, 'Player')}` : ''}` :
																	it.value === 'FTM' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} free throw made - ( ${found?.value ?? ''}pts )` :

																		it.value === 'TIME' ? `Timeout - ${found?.team ?? 'xx'}` :
																			it.value === 'START' ? `START OF ${it.alt_value} PERIOD` :
																				it.value === 'END' ? `END OF ${it.alt_value} PERIOD` :
																					it.value === 'FINAL' ? `GAME ENDED` :
																						it.value === 'BLK' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} blocked the shot - ( ${found?.value ?? ''}blk )` :
																							it.value === 'AST' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} made the assist - ( ${found?.value ?? ''}ast )` :
																								it.value === 'STL' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} stole the ball from ${getPlayerName(found?.p2, found?.team == 'home' ? orderItems : listItems, 'Player')} - ( ${found?.value ?? ''}stl )` :
																									it.value === 'OREB' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} offensive rebound - ( ${found?.value ?? ''}rebs )` :
																										it.value === 'DREB' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} defensive rebound - ( ${found?.value ?? ''}rebs )` :

																											it.value === 'SUBOUT' ? `Substitution OUT - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')}` :
																												it.value === 'SUBIN' ? `Substitution IN - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} ` :
																													it.value === 'FOULOUT' ? `Substitution OUT - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} FOULED OUT` :
																														it.value === 'JUMP' ? `Jumpball - ${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} v ${getPlayerName(found?.p2, found?.team == 'away' ? listItems : orderItems, 'Player')}, ${found?.type === 'jbw' ? getPlayerName(found?.p2, found?.team == 'away' ? listItems : orderItems, 'Player') : getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} wins tip` :
																															it.value === 'HELD' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} forces a held ball.` :
																																it.value === 'TIP' ? `${getPlayerName(found?.p1, found?.team == 'home' ? listItems : orderItems, 'Player')} tip out, sideline inbound` :
																																	it.value === 'CHAL' ? `Coach challenge (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value})` :
																																		it.value === 'DEL' ? `Delay of game warning (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																			it.value === 'WARN' ? `Warning issued (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																				it.value === 'CLK' ? `Shot clock violation (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} )` :
																																					it.value === 'TTECH2' ? `Technical Foul Type 2 (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																						it.value === 'TTECH1' ? `Technical Foul Type 1 (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																							it.value === 'CTECH2' ? `Technical Foul - Coach (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																								it.value === 'CTECH1' ? `Technical Foul - Coach (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value} ) ${found?.type ? `${found?.type} awarded` : ''}` :
																																									it.value === 'POS' ? `Change of possession (${found?.team === 'home' ? itemsR0.find(it => it.component_fields.filter(cf => cf?.value === records[0]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[0]?.options?.filters[0])?.value : itemsR1.find(it => it.component_fields.filter(cf => cf?.value === records[1]?.value).length > 0)?.component_fields.find(cf => cf.field_name === records[1]?.options?.filters[0])?.value})` :
																																										it.value
										}
									</div>,
									['Score']: <div>
										{found.score}
									</div>,
									['Player']: <div>
										{found?.p1 ?? 'xx'}
									</div>

								}
							})
							}
						/>
					</div>
				</TabPanel>
				<TabPanel>

					<FullCourt items={generalArray?.length > 0 ? generalArray : selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX'))} />

					<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
						<Button
							light
							title={teamA?.value}
							titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}
							style={{ display: '', backgroundColor: (selectedItems === teamA?.value) ? 'var(--app-color-blue)' : 'white' }}
							clicked={() => {

								setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[0].value)?.length > 0).map(it => {
									const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
									const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
									const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
									return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
								})));

								setSelectedItems(teamA?.value)
								setGeneralArray(selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'home' && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX')))

							}}
						/>
						<Button
							light
							title={teamB?.value}
							titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}
							style={{ display: '', backgroundColor: selectedItems === teamB?.value ? 'var(--app-color-blue)' : 'white' }}
							clicked={() => {
								setListItems(R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[1].value)?.length > 0).map(it => {
									const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
									const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
									const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
									return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
								})));
								setSelectedItems(teamB?.value);
								setGeneralArray(selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'away' && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX')))

							}}

						/>
						<Button
							light
							title={'All'}
							titleStyle={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '14px' }}
							style={{ display: '', backgroundColor: (!selectedItems || selectedItems === 'all') ? 'var(--app-color-blue)' : 'white' }}
							clicked={() => {
								setSelectedItems('all');

								setListItems([...R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[0]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[0].value)?.length > 0).map(it => {
									const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
									const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
									const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
									return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
								})),
								...R.sortBy(R.prop('key'), components.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === records[1]?.options?.component_id)?.length > 0)?.filter(item => item.component_fields.filter(cf => cf.value === records[1].value)?.length > 0).map(it => {
									const scores = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.quantity ?? JSON.stringify({}));
									const misc = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.value ?? JSON.stringify({}));
									const fouls = JSON.parse(selectedComponent.component_items.find(itm => itm.item_code === it.id)?.alt_value ?? JSON.stringify({}));
									return { id: it.id, component_fields: it.component_fields, key: it.component_fields.find(cf => cf.field_name === template?.key?.key)?.value, fouls: fouls, scores: scores, misc: misc, others: {} };
								}))

								]);
								setGeneralArray([...selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'home' && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX')), ...selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'away' && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX'))]);
							}}

						/>
					</div>
					<CustomTemplateModuleList
						className={cls.lineTeam}
						style={{ width: '100%' }}
						tableHostSimple={cls.detailBoxScoreTable}
						layeredColumns={[

							{
								key: 'player', label: 'Player',
								subColumns: [
									{ key: 'sel', label: 'Sel' },
									{ key: 'no', label: 'No' },
									{ key: 'player', label: 'Name' },

								].filter(col => isMobile ? col.key !== 'no' : col.key)
							},
							{
								key: 'points', label: 'Points',
								subColumns: [
									{ key: 'pts', label: 'PTS' },
									{ key: '2fg', label: '2PT FG' },
									{ key: '3fg', label: '3PT FG' },
								]
							},

						].filter(li => isMobile ? li.key !== 'others' : li.key)}
						list={(listItems.map((player, index) => {
							let object = {};
							template?.slist?.filter(li => {
								if (li?.subColumns) {
									li?.subColumns.filter(sc => {
										object[sc.label] = player.component_fields.find(cf => cf.field_name === sc.key)?.value ?? '';
									})
								} else {
									object[li.label] = player.component_fields.find(cf => cf.field_name === li.key)?.value ?? '';
								}
							});

							return {
								Sel: <Checkbox
									disabled={selectedItems === 'all' ? true : false}
									style={{ color: 'var(--app-color-gray-lighter)' }}
									defaultChecked={generalArray.find(gen => JSON.parse(gen.quantity)?.p1 === player.key) ? true : false}
									onChange={e => {

										if (selectedItems === teamA?.value) {
											if (e.target.checked) {
												const found = selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'home' && JSON.parse(it?.quantity)?.p1 === player.key && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX'));
												if (found)
													setGeneralArray([...generalArray.filter(it => JSON.parse(it.quantity)?.p1 !== player.key), ...found])
											} else {
												setGeneralArray(generalArray.filter(it => JSON.parse(it.quantity)?.p1 !== player.key))
											}
										} else {
											if (e.target.checked) {
												const found = selectedComponent.component_items.filter(it => JSON.parse(it?.quantity)?.x && JSON.parse(it?.quantity)?.team === 'away' && JSON.parse(it?.quantity)?.p1 === player.key && it.item_code === 'PBP' && (it.value === '2PTM' || it.value === '3PTM' || it.value === '2PTX' || it.value === '3PTX'));
												if (found)
													setGeneralArray([...generalArray.filter(it => JSON.parse(it.quantity)?.p1 !== player.key), ...found])
											} else {
												setGeneralArray(generalArray.filter(it => JSON.parse(it.quantity)?.p1 !== player.key))
											}
										}

									}}
								/>,
								No: player.key,
								Name: player.component_fields.find(cf => cf.field_name === 'Player')?.value ?? '',
								PTS: playerScore(player),
								['2PT FG']: ((player.scores?.fst ?? '').split('').filter(sc => +sc == 2).length +
									(player.scores?.snd ?? '').split('').filter(sc => +sc == 2).length +
									(player.scores?.trd ?? '').split('').filter(sc => +sc == 2).length +
									(player.scores?.fth ?? '').split('').filter(sc => +sc == 2).length +
									(player.scores?.ot ?? '').split('').filter(sc => +sc == 2).length),
								['3PT FG']: ((player.scores?.fst ?? '').split('').filter(sc => +sc == 3).length +
									(player.scores?.snd ?? '').split('').filter(sc => +sc == 3).length +
									(player.scores?.trd ?? '').split('').filter(sc => +sc == 3).length +
									(player.scores?.fth ?? '').split('').filter(sc => +sc == 3).length +
									(player.scores?.ot ?? '').split('').filter(sc => +sc == 3).length),
								FT: `${((player.scores?.fst ?? '').split('').filter(sc => +sc == 1).length +
									(player.scores?.snd ?? '').split('').filter(sc => +sc == 1).length +
									(player.scores?.trd ?? '').split('').filter(sc => +sc == 1).length +
									(player.scores?.fth ?? '').split('').filter(sc => +sc == 1).length +
									(player.scores?.ot ?? '').split('').filter(sc => +sc == 1).length)} / ${((player.scores?.fst ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
										(player.scores?.snd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
										(player.scores?.trd ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
										(player.scores?.fth ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length +
										(player.scores?.ot ?? '').split('').filter(sc => (+sc == 1 || sc == '0')).length)}`,

							};
						})).sort((a, b) => +a.No - +b.No)}
					/>


				</TabPanel>
				<br />
			</Tabs>
		</div>
	}
	if (templateName === 'Player Profile Template') {
		return <div style={{ margin: isMobile ? '' : '20px', fontFamily: 'var(--app-text-main-font)' }}>
			<div className={cls.centerDiv} style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginBottom: '50px', width: '100%' }} >
				<div className={cls.teamsLabel}>
					{selectedComponent?.title}
				</div>

			</div>
			<Tabs
				style={{ width: '100%', fontSize: isMobile ? '16px' : '20px' }}

			>
				<TabList style={{ fontFamily: 'var(--app-text-mobile-font)', fontSize: '18px' }}>
					<Tab>
						{'Info'}
					</Tab>
					<Tab>
						{'Stats'}
					</Tab>

				</TabList>

				<TabPanel>
					<div >
						<RenderComponentDetailView
							key={0}
							bypassCustom={true}
							ref={ref}
							begunModule={begunModule}
							allModules={allModules}
							companyData={companyData}
							components={components}
							selectedComponent={selectedComponent}
							companyUsersArray={companyUsersArray}
							companyPositionsArray={companyPositionsArray}
						/>
					</div>
				</TabPanel>
				<TabPanel>
					<div>

						<CustomTemplateModuleList
							className={cls.lineTeam}
							style={{ width: '100%' }}
							tableHostSimple={cls.detailBoxScoreTable}
							footer={['Total', 'Ave', 
								`${R.reduce((a, b) => +a + +b.MIN ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b.PTS ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b['2PT FG'] ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b['3PT FG'] ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b['FTM'] ?? 0, 0, listItems ?? []) }`,
								`${R.reduce((a, b) => +a + +b['FTA'] ?? 0, 0, listItems ?? []) }`,
								`${R.reduce((a, b) => +a + +b['OREB'] ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b['DREB'] ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +b['REB'] ?? 0, 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +(b['AST'] ?? 0), 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +(b['TO'] ?? 0), 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +(b['STL'] ?? 0), 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
								`${R.reduce((a, b) => +a + +(b['BLK'] ?? 0), 0, listItems ?? []) / (listItems.length < 1 ? 1 : listItems.length)}`,
							]}
							//columns={['League', 'PTS','2PT FG', '3PT FG', 'FT','OREB','DREB','REB', 'AST', 'PF', 'TO','STL', 'BLK']}
							layeredColumns={[
								{
									key: 'player', label: 'Game',
									subColumns: [
										{ key: 'date', label: 'Date' },
										{ key: 'player', label: 'League' },
										{ key: 'min', label: 'MIN' },


									].filter(col => isMobile ? col.key !== 'no' : col.key)
								},
								{
									key: 'points', label: 'Points',
									subColumns: [
										{ key: 'pts', label: 'PTS' },
										{ key: '2fg', label: '2PT FG' },
										{ key: '3fg', label: '3PT FG' },
										{ key: 'ftm', label: 'FTM' },
										{ key: 'fta', label: 'FTA' },

									]
								},
								{
									key: 'others', label: 'Others',
									subColumns: [
										{ key: 'oreb', label: 'OREB' },
										{ key: 'dreb', label: 'DREB' },
										{ key: 'reb', label: 'REB' },
										{ key: 'ast', label: 'AST' },
										{ key: 'to', label: 'TO' },
										{ key: 'stl', label: 'STL' },
										{ key: 'blk', label: 'BLK' },
									]
								}
							].filter(li => isMobile ? li.key !== 'others' : li.key)}
							list={listItems}

						/>

					</div>
				</TabPanel>
			</Tabs>
		</div>
	}
	if (templateName === 'League Template') {

		const teams = selectedComponent?.component_fields?.find(sf => sf.field_name === template.details?.find(sc => sc.value === 'Teams')?.label);
		const league = selectedComponent?.component_fields?.find(sf => sf.field_name === template.key.key);
		const games = selectedComponent?.component_fields?.find(sf => sf.field_name === template.details?.find(sc => sc.value === 'Games')?.label);
		const played = components.filter(com => (com.module_id === games?.value && com.component_fields.find(cf => (cf.field_name === template?.key?.label && cf.value === league?.value))))
		const status = selectedComponent?.component_fields?.find(sf => sf.field_name === template.details?.find(sc => sc.value === 'Status')?.label);

		const standings = selectedComponent.component_items.map(it => {

			const team = components?.find(com => com.module_id === teams?.value && com.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Team')?.key && cf.value === it[template.details?.find(sc => sc.value === 'Team')?.map]))?.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Team')?.label);
			const results = { win: 0, loss: 0 };
			played.filter(game => game.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Status')?.label && cf.value === status?.value)).filter(game => game.component_fields.find(cf => cf.value === it[template.details?.find(sc => sc.value === 'Team')?.map])).filter(game => {
				if (+game.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Score.Home')?.label)?.value > +game.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Score.Away')?.label)?.value) {
					if (game.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Home')?.label)?.value === it[template.details?.find(sc => sc.value === 'Team')?.map]) {
						results.win = results.win + 1;
					} else {
						results.loss = results.loss + 1;
					}

				} else {
					if (game.component_fields.find(cf => cf.field_name === template.details?.find(sc => sc.value === 'Home')?.label)?.value === it[template.details?.find(sc => sc.value === 'Team')?.map]) {
						results.loss = results.loss + 1;
					} else {
						results.win = results.win + 1;
					}
				}

			})

			return { Team: team?.value ?? it?.value, Win: it?.quantity ? it.quantity : results.win, Loss: it?.alt_value ? it?.alt_value : results.loss, id: it?.value };
		})
		// console.log('template', template, status, standings )

		return <div style={{ margin: isMobile ? '' : '20px', fontFamily: 'var(--app-text-main-font)' }}>
			<div style={{ minWidth: isMobile ? '100%' : '600px', marginTop: '20px', width: '100%', fontFamily: "var(--app-text-main-font)" }}>
				<p className={cls.teamsLabelDetail}>
					{'League Standings'}
				</p>
				<CustomTemplateModuleList
					className={cls.lineTeam}
					tableHostSimple={cls.detailScoringTable}
					style={{ width: '100%' }}
					columns={template?.slist?.map(li => {
						return li.label;
					})}
					list={standings}

				/>
			</div>
		</div>
	}

	// default to standard detail view
	return <div >
		<RenderComponentDetailView
			key={0}
			bypassCustom={true}
			ref={ref}
			begunModule={begunModule}
			allModules={allModules}
			companyData={companyData}
			components={components}
			selectedComponent={selectedComponent}
			companyUsersArray={companyUsersArray}
			companyPositionsArray={companyPositionsArray}
		/>
	</div>
})

const teamScore = (team = [], first = true, second = true, third = true, fourth = true, overtime = true) => {
	let points = 0;
	team.filter(player => {
		if (first)
			points = points + (player.scores?.fst ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
		if (second)
			points = points + (player.scores?.snd ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
		if (third)
			points = points + (player.scores?.trd ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
		if (fourth)
			points = points + (player.scores?.fth ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
		if (overtime)
			points = points + (player.scores?.ot ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
	})
	return points;
}

const playerScore = (player, first = true, second = true, third = true, fourth = true, overtime = true) => {
	let points = 0;
	if (first)
		points = points + (player.scores?.fst ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
	if (second)
		points = points + (player.scores?.snd ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
	if (third)
		points = points + (player.scores?.trd ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
	if (fourth)
		points = points + (player.scores?.fth ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);
	if (overtime)
		points = points + (player.scores?.ot ?? '').split('').filter(sc => !(sc === 'X' || sc === 'x' || sc === '0')).reduce((a, b) => a + parseInt(b), 0);

	return points;
}

const saveModuleOutputs = (isUpdating = true, taskProcessContext, selectedComponent, components, begunModule, companyData, isCompleted = true) => {


	const newStepComponents = taskProcessContext.newStepComponents.filter(c => {
		if (c.options === null) {
			return true;
		}
		return c.options?.allowMultipleInputs === false || c.options?.allowMultipleInputs === undefined;
	});

	const components_to_process = [...taskProcessContext.components.filter(c1 => newStepComponents.every(c2 => c2.id !== c1.id)), ...R.reject(c => c.selected_fields.filter(field => field.value === '').length > 0, newStepComponents)];
	let updateCompany = [];
	let selected_fields = [];
	let componentV = '';
	let status = isUpdating ? selectedComponent?.status : 'None';
	let assigned_to;
	const previous_status = selectedComponent?.status;

	const comObj = {};

	let new_group = isUpdating ? (R.apply(Math.max, R.pluck('group', R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []))) ?? -1) + 1 : 0;

	components_to_process.map((x, index) => {
		let group = index;

		const f1 = x.selected_fields.filter(sf => sf.data_type_name === 'Record' || sf.data_type_name === 'Filters')?.find(sf => +sf?.group >= 0);
		if (f1 && isUpdating) {
			group = f1.group;
		} else if (isUpdating) {
			group = new_group;
			new_group++;
		}
		/* Make sure components are retrieved first */
		x.selected_fields
			.filter(sf => sf.data_type_name === 'Record' || sf.data_type_name === 'Filters')
			.map((y, idx) => {
				if (!(y.value === null || y.value === undefined || y.options?.skip == true)) {
					selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
					componentV = y.value;
					comObj[y.field_name] = y.value;

				}
			});

		const f2 = x.selected_fields.filter(sf => !(sf.data_type_name === 'Record' || sf.data_type_name === 'Filters'))?.find(sf => +sf?.group >= 0);
		if (f2 && isUpdating) {
			group = f2.group;
		} else if (isUpdating) {
			group = new_group;
			new_group++;
		}
		x.selected_fields
			.filter(sf => !(sf.data_type_name === 'Record' || sf.data_type_name === 'Filters'))
			.map((y, idx) => {
				if (!(y.value === null || y.value === undefined || y.options?.skip == true)) {
					comObj[y.field_name] = y.value;
					if (y?.data_type_name === 'ID') {
						if (!begunModule?.processModule?.isUpdating) {
							selected_fields = [...selected_fields, { ...y, index: idx, group: group, value: `${y?.options.id}${companyData?.misc_info === null ? 1 : companyData?.misc_info[y?.field_name] ?? 1}` }];
							updateCompany = [...updateCompany, `${y.field_name}`];
						} else {
							selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
						}
					} else if (y?.data_type_name === 'Value') {
						selected_fields = [...selected_fields, { ...y, index: idx, value: y?.options?.value, group: group }];
					} else if (y?.data_type_name === 'Email Recipient') {
						selected_fields = [...selected_fields, { ...y, index: idx, value: y.value.replace(/ /g, ''), group: group }];
					} else if (y.data_type_name === 'Product Lookup') {
						selected_fields = [...selected_fields];
					} else if (y.data_type_name === 'Status' && y.value === 'Draft' && isCompleted == true) {

						selected_fields = [...selected_fields, { ...y, index: idx, value: 'New', group: group }];
						status = 'New';
					} else if (y.data_type_name === 'Status') {
						selected_fields = [...selected_fields, { ...y, index: idx, value: y?.value, group: group }];
						status = y?.value;
					} else if (y.data_type_name === 'Formula') {
						const value = begunModule?.processModule?.isUpdating
							? computeFormula(
								y?.options,
								selectedComponent.component_fields.filter(x => y.options?.list.includes(x.field_name)),
								components_to_process,
								taskProcessContext.items
							)
							: y.value;
						selected_fields = [...selected_fields, { ...y, index: idx, value: value.toString(), group: group }];
					} else if (y.data_type !== 'output') {

						selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
					}
				} else if (y?.data_type_name === 'Status') {
					if (isCompleted == true) {
						selected_fields = [...selected_fields, { ...y, index: idx, value: 'New', group: group }];
						status = 'New';
					} else {
						selected_fields = [...selected_fields, { ...y, index: idx, value: 'Draft', group: group }];
						status = 'Draft';
					}

					comObj[y.field_name] = y.value;
				} else if (y?.data_type_name === 'Action Button') {
					selected_fields = [...selected_fields];
					comObj[y.field_name] = y.value;
					if (y?.options?.action === 'status') {
						status = y.value;
					} else if (y.options?.action === 'user' || y.options?.action === 'creator') {
						selectedComponent.assigned_to = y?.value;
					}
				}
			});
	});

	let title = '';

	if (begunModule.processModule?.module?.options?.title?.length > 0) {
		begunModule.processModule?.module?.options?.title?.map(label => {
			if (label !== 'Info') {
				title = title === '' ? `${selected_fields.find(sf => sf.field_name === label)?.value ?? ''}` : `${title ?? ''}  ${selected_fields.find(sf => sf.field_name === label)?.value ?? ''}`;
			} else {
				const step = begunModule.processModule.steps.find(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info')?.length > 0)?.length > 0);

				if (step !== undefined) {
					const infoField = step.component_blueprints?.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info')?.length > 0).selected_fields?.find(sf => sf.data_type_name === 'Info');
					const found = components.find(c => c.component_fields.filter(cf => cf.component_blueprint_id === infoField.options.component_id && (cf.value === comObj[infoField.field_name] || cf.value === comObj[infoField.options?.key]))?.length > 0);
					if (found) {
						if (infoField?.options?.list?.length > 0) {
							title = title === '' ? `${found.component_fields.find(cf => cf.field_name === infoField?.options?.list[0])?.value ?? ''}` : `${title ?? ''}  ${found.component_fields.find(cf => cf.field_name === infoField?.options?.list[0])?.value ?? ''}`;
						}
					}

				}

			}
		});
		if (begunModule.processModule?.module?.options?.title.find(t => t === 'UUID')) {
			title = `${isUpdating ? selectedComponent.id : generateNewUUID()}`;
		}
	} else {
		title = isUpdating ? selectedComponent.id : generateNewUUID();
	}

	let options = selectedComponent?.options ?? {};

	if (selected_fields?.length > 0) {

		if (isUpdating) {
			selectedComponent.options = options;
			selectedComponent.status = status;
			updateComponent({ ...selectedComponent, selected_fields: [...selected_fields], component_items: taskProcessContext.items }, selectedComponent.id, [], title, isCompleted, []).subscribe({
				error: error => { },
				next: () => {
				},
				complete: () => { },
			});
		} else {
			createComponent(
				{
					id: components_to_process[0].id,
					selected_fields: [...selected_fields],
					component_items: taskProcessContext.items,
					options: options,
					status: status,
					assigned_to: assigned_to
				},
				'',
				begunModule.processModule.module_id,
				[],
				title,
				isCompleted,
				[]
			).subscribe({
				next: _data => { },
				error: data => { },
				complete: () => { },
			});
		}
	}
};

// const Court = () => {
// 	return (
// 		<svg id="basketball" x="0px" y="0px" viewBox="0 0 1280 1024">
// 			<rect x="25" y="31" className={cls.court} width="1228" height="771"></rect>
// 			<rect x="25" y="31" className={cls.zone} width="180" height="426" />
// 			<rect x="1073" y="31" className={cls.zone} width="180" height="426" />
// 			<rect x="205" y="31" className={cls.zone} width="234" height="250" />
// 			<rect x="839" y="31" className={cls.zone} width="234" height="250" />
// 			<rect x="439" y="31" className={cls.zone} width="200" height="250" />
// 			<rect x="439" y="281" className={cls.zone} width="200" height="250" />
// 			<path className={cls.zone} d="M439,687.6v114.5h400v-115c-61,28.4-128.9,44.3-200.5,44.3C567.3,731.5,499.7,715.8,439,687.6z" />
// 			<path className={cls.zone} d="M439,531v156.6c60.7,27.8,128.3,43.4,199.5,43.4c71.6,0,139.5-15.7,200.5-43.8V531H439z" />
// 			{/* <path className={cls.zone}  d="M205,457h0.1c0-0.1-0.1-0.2-0.1-0.3V457z" /> */}
// 			<path className={cls.zone} d="M205,281v175.7c0,0.1,0.1,0.2,0.1,0.3C253.5,558.7,336.5,640.7,439,687.6V531v-74V281H205z" />
// 			{/* <path className={cls.zone} d="M1073,457v-2.4c-0.4,0.8-0.8,1.6-1.2,2.4H1073z" /> */}
// 			<path className={cls.zone} d="M839,281v176v74v156.2c102-47,184.7-128.8,232.8-230.2c0.4-0.8,0.8-1.6,1.2-2.4V281H839z" />
// 			<rect x="639" y="281" className={cls.zone} width="200" height="250" />
// 			<rect x="639" y="31" className={cls.zone} width="200" height="250" />
// 		</svg>

// 	)
// }

const getPlayerName = (key, list = [], field_name) => {
	return list.find(it => it.key === key)?.component_fields?.find(cf => cf.field_name === field_name)?.value ?? 'Juan Dela Cruz';
}

const FullCourt = (props) => {
	const { setAction = () => { }, action, time, items = [] } = props;
	let point = { pt: action?.click?.pt ?? 0, zone: action?.click?.zone ?? '' };
	const bcourt = () => {
		return <svg style={{ width: '940px', height: '500px', position: 'relative', top: '0', left: '0' }}>

			{/* <!-- whole court rectangle --> */}
			<rect width="100%" height="100%" fill="#eac696" stroke="#5d5c63" stroke-width="2" />

			{/* <!-- half court line and circle --> */}
			<line x1="50%" y1="0" x2="50%" y2="100%" stroke="#5d5c63" stroke-width="1" />
			<circle cx="50%" cy="50%" r="12%" fill="none" stroke="#5d5c63" stroke-width="1" />

			{/* <!-- 3-point arc (left) --> */}
			<line x1="0%" y1="6%" x2="14.89%" y2="6%" stroke="#5d5c63" stroke-width="1" />
			<line x1="0%" y1="94%" x2="14.89%" y2="94%" stroke="#5d5c63" stroke-width="1" />
			<path d="M 138.97 470.2 A 237.5 237.5 0 0 0 138.97 29.79" fill="none" stroke="#5d5c63" stroke-width="1.1" />

			{/* <!-- shaded area (left) --> */}
			<rect y="170" width="190" height="160" fill="#eab696" stroke="#fff" stroke-width="1" />


			{/* <!-- board and rim (left) --> */}
			<line x1="40" y1="220" x2="40" y2="280" stroke="#b37336" stroke-width="1" />
			<circle cx="55" cy="250" r="15" fill="none" stroke="#b37336" stroke-width="1" />

			{/* <!-- restricted area (left) --> */}
			<path d="M 55 290 A 40 40 0 0 0 55 210" fill="none" stroke="#fff" stroke-width="1" />

			{/* <!-- free throw circle (left) --> */}
			<path d="M 190 190 A 60 60 0 0 0 190 310" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="10,10" />
			<path d="M 190 310 A 60 60 0 0 0 190 190" fill="none" stroke="#fff" stroke-width="1" />

			{/* <!-- 3-point arc (right) --> */}
			<line x1="85.11%" y1="6%" x2="100%" y2="6%" stroke="#5d5c63" stroke-width="1" />
			<line x1="85.11%" y1="94%" x2="100%" y2="94%" stroke="#5d5c63" stroke-width="1" />
			<path d="M 801.03 29.79 A 237.5 237.5 0 0 0 801.03 470.21" fill="none" stroke="#5d5c63" stroke-width="1.1" />

			{/* <!-- shaded area (right) --> */}
			<rect x="750" y="170" width="190" height="160" fill="#eab696" stroke="#fff" stroke-width="1" />

			{/* <!-- board and rim (right) --> */}
			<line x1="900" y1="220" x2="900" y2="280" stroke="#b37336" stroke-width="1" />
			<circle cx="885" cy="250" r="15" fill="none" stroke="#b37336" stroke-width="1" />

			{/* <!-- restricted area (right) --> */}
			<path d="M 885 210 A 40 40 0 0 0 885 290" fill="none" stroke="#fff" stroke-width="1" />

			{/* <!-- free throw circle (right) --> */}
			<path d="M 750 310 A 60 60 0 0 0 750 190" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="10,10" />
			<path d="M 750 190 A 60 60 0 0 0 750 310" fill="none" stroke="#fff" stroke-width="1" />



			{/* SCORING ZONES */}

			{/* HOME */}

			{/* <!-- 4-point areas (left) --> */}
			{/* <path d="M 80.97 500.2 A 237.5 237.5 0 0 0 80.97 0 L 80.97 29.79 L 150 29.79 M 138.97 470.2 A 237.5 237.5 0 0 0 138.97 29.79 M 138.97 470.2 L 80.97 470.2 L 80.97 500" fill="yellow" stroke="#5d5c63" stroke-width="1.1" />
			<path d="M 80.97 500.2 A 237.5 237.5 0 0 0 80.97 0  H 0 470 V 0 500 H 80.97 " fill="#5d5c63" stroke="#5d5c63" stroke-width="1.1" />
			<path d="M 80.97 500.2 A 237.5 237.5 0 0 0 80.97 0" fill="none" stroke="#5d5c63" stroke-width="1.1" /> */}
			{/* <!-- 4-point areas (left) --> */}

			{/* <!-- 3-point beyond (left) --> */}
			<path d="M 138.97 470.2 A 237.5 237.5 0 0 0 138.97 29.79 V 29.79 0 H 0 470 V 0 500 H 300 138.97 L 138.97 470.2" fill="transparent" stroke="#5d5c63" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'l3' }
				}}
			/>

			{/* <!-- top corner 3 (left) --> */}
			<rect y="0" width="138" height="28" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'lrc3' }
				}}
			/>

			{/* <!-- top corner 2 (left) --> */}
			<rect y="28" width="140" height="139" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'lrc2' }
				}}
			/>

			{/* <!-- top key (left) --> */}
			<path d="M 138.97 470.2 A 237.5 237.5 0 0 0 138.97 29.79 V 138.97 470.2" fill="transparent" stroke="#5d5c63" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'ltk' }
				}}
			/>

			{/* <!-- free throw zone (left) --> */}
			<rect y="170" x="192" width="80" height="160" fill="transparent" sfill="#116cb6" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'lft' }
				}}
			/>

			{/* <!-- ft shaded area (left) --> */}
			<rect y="170" x="95" width="95" height="160" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'lsft' }
				}}
			/>

			{/* <!-- ring shaded area (left) --> */}
			<rect y="170" width="95" height="160" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'lsr' }
				}}
			/>

			{/* <!-- lower corner 2 (left) --> */}
			<rect y="330" width="140" height="139" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'llc2' }
				}}
			/>

			{/* <!-- lower corner 3 (left) --> */}
			<rect y="472" width="138" height="28" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'llc3' }
				}}
			/>


			{/* AWAY */}

			{/* <!-- 4-point areas (right) --> */}
			{/* <path d="M 860.03 0 A 237.5 237.5 0 0 0 860.03 500 L 860.03 470.21 H 801.03 801.03 M 801.03 29.79 A 237.5 237.5 0 0 0 801.03 470.21 M 801.03 29.79 L 860.03 29.79 V 860.03 0" fill="green" stroke="#5d5c63" stroke-width="1.1" />
			<path d="M 860.03 0 A 237.5 237.5 0 0 0 860.03 500 L 470.21 500 V 470.21 0 L 860.03 0" fill="yellow" stroke="#5d5c63" stroke-width="1.1" />
			<path d="M 860.03 0 A 237.5 237.5 0 0 0 860.03 500" fill="none" stroke="#5d5c63" stroke-width="1.1" /> */}
			{/* <!-- 4-point areas (right) --> */}

			{/* <!-- 3-point beyond (right) --> */}
			<path d="M 801.03 29.79 A 237.5 237.5 0 0 0 801.03 470.21 V 801.03 500 H 470 470 L 470 0 L 801.03 0 L 801.03 29.79" fill="transparent" stroke="#5d5c63" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'r3' }
				}}
			/>

			{/* <!-- top corner 3 (right) --> */}
			<rect x="800" y="0" width="140" height="28" fill="transparent" sfill="#116cb6" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'rlc3' }
				}}
			/>

			{/* <!-- top corner 2 (right) --> */}
			<rect x="800" y="28" width="140" height="139" fill="transparent" sfill="#116cb6" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rlc2' }
				}}
			/>

			{/* <!--  top key(right) --> */}
			<path d="M 801.03 29.79 A 237.5 237.5 0 0 0 801.03 470.21 L 801.03 29.79" fill="transparent" stroke="#5d5c63" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rtk' }
				}}
			/>

			{/* <!-- freethrow zone (right) --> */}
			<rect y="170" x="670" width="80" height="160" fill="transparent" sfill="#116cb6" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rft' }
				}}
			/>

			{/* <!-- ft shaded area (right) --> */}
			<rect x="750" y="170" width="95" height="160" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rsft' }
				}}
			/>

			{/* <!-- ring shaded area (right) --> */}
			<rect x="845" y="170" width="95" height="160" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rsr' }
				}}
			/>

			{/* <!-- lower corner 2 (right) --> */}
			<rect x="800" y="330" width="140" height="139" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 2, zone: 'rrc2' }
				}}
			/>

			{/* <!-- lower corner 3 (right) --> */}
			<rect x="800" y="472" width="140" height="28" fill="transparent" stroke="#fff" stroke-width="0"
				onClick={() => {
					point = { pt: 3, zone: 'rrc3' }
				}}
			/>

		</svg>
	}
	return (<div
		id={"Basketball Court"}
		style={{
			width: '940px',
			height: '500px',
			position: 'relative',


		}}
		onClick={(e) => {
			const x = e.pageX - e.currentTarget.offsetLeft;
			const y = e.pageY - e.currentTarget.offsetTop;
			setAction({ ...action, click: { ...action?.click, x: x, y: y, ...point, time: time } });

		}}
	>
		<div style={{ display: 'flex', position: 'absolute', top: '0', left: '0' }}>
			{(bcourt())}
		</div>

		{items.map((it, index) => {
			const found = JSON.parse(it?.quantity);
			return <div key={index} style={{ fontWeight: 'bolder', fontSize: '14px', width: '10px', height: '10px', backgroundColor: 'transparent', color: found?.team === 'home' ? 'green' : 'red', position: 'absolute', top: found.y, left: found.x, pointerEvents: 'none' }}>
				{(it?.value === '2PTM' || it?.value === '3PTM') ? 'O' : 'X'}
			</div>
		})}

		{action?.click?.x && <div style={{ width: '15px', height: '15px', backgroundColor: 'white', position: 'absolute', top: action?.click?.y, left: action?.click?.x }} />}
	</div>)
}

export const typename = (value = '') => {
	if (value === 'js') {
		return 'jumpshot';
	} else if (value === 'pujs') {
		return 'pull-up jumpshot';
	} else if (value === 'sbjs') {
		return 'step-back jumpshot';
	} else if (value === 'lay') {
		return 'Layup';
	} else if (value === 'tujs') {
		return 'turnaround jumpshot';
	} else if (value === 'dlay') {
		return 'driving layup';
	} else if (value === 'hook') {
		return 'hookshot';
	} else if (value === 'fljs') {
		return 'floater';
	} else if (value === 'dunk') {
		return 'dunk';
	} else if (value === 'alley') {
		return 'alley oop';
	} else if (value === 'lost') {
		return 'lost ball';
	} else if (value === 'per') {
		return 'Personal';
	} else if (value === 'sho') {
		return 'Shooting';
	} else if (value === 'off') {
		return 'Offensive';
	} else if (value === 'dou') {
		return 'Double Personal';
	} else if (value === 'los') {
		return 'Looseball';
	} else if (value === 'fr1') {
		return 'Fragrant 1';
	} else if (value === 'fr2') {
		return 'Fragrant 2';
	} else if (value === 'tech1') {
		return 'Technical Type 1';
	} else if (value === 'tech2') {
		return 'Technical Type 2';
	} else if (value === 'bad') {
		return 'bad pass';
	} else if (value === 'drb') {
		return 'dribbling violation';
	} else if (value === 'ftv') {
		return 'free throw violation';
	} else if (value === '3sec') {
		return '3 second violation';
	} else if (value === 'trv') {
		return 'travelling';
	} else if (value === 'goal') {
		return 'goaltending violation';
	} else if (value === 'back') {
		return 'backcourt violation';
	} else if (value === 'out') {
		return 'out of bounds';
	} else if (value === '5sec') {
		return '5 second inbound violation';
	}
	return value;
}