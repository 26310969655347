import React, { useRef, useState, memo, useEffect, useCallback } from 'react';
import cls from './AccordionModule.module.scss';
import { Add, fileSymbol, pdfSymbol, reset2 } from '../../../assets/images/icons';
import FieldRow from '../../common/UI/CustomFields/FieldRow/FieldRow';
import { uploadFileComponent } from '../../../services/components.service';
import { geminiAI } from '../../../services/search.service';
import classNames from 'classnames';
import SelectAndMultiselect from '../ProcessesComps/SelectAndMultiselect/SelectAndMultiselect';
import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
import { getUsersByPositions, updateUserOptionsById } from '../../../services/user.service';
import { first, take } from 'rxjs/operators';
import { Button, DatePickerComp, ModalR, RangeDatePicker, SelectWithSearch } from '../../common';
import PhoneInput from 'react-phone-input-2';
import { currency_list, getDistanceBetweenPoints } from '../../../shared/utility';
import { processTaskAction, useProcessTaskContext } from '../../../context/processTask.context';
import MapPin from '../../common/Mapping/MapPin';
import MapRoutes from '../../common/Mapping/MapRoutes';
import ModuleLists from './ModuleLists';

import { SingleSelectWithCheckboxesAndNestedItems, SingleSelectWithTilesAndNestedItems } from '../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';
import { generateNewUUID, computeFormula } from '../../../shared/utility';
import { isMobile, isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import * as R from 'ramda';
import moment, { now } from 'moment';
import 'react-phone-input-2/lib/bootstrap.css';
import OpenSelectWithSearch from '../../common/UI/Select/OpenSelectWithSearch';
import CustomTemplateModuleList from './CustomTemplateModuleList';
import { Checkbox } from '../../Auth/LoginForm/FormElements/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleDot, faEllipsisVertical, faLocationCrosshairs, faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons';
import UserCard from '../../common/UserCard/UserCard';
import { useSelector } from 'react-redux';
import { calculateRoute } from '../../common/Mapping/MapView';
import { ButtonGroup } from '@material-ui/core';
import { purchase } from '../../../services/payments.service';
import { getAllCompanyPositionsAsArray } from '../../../services/company.service';
import { Autoplay, Pagination, Grid } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';
import 'swiper/css/effect-coverflow';
import { Scheduler } from "@aldabil/react-scheduler";
import TaskModal from '../TaskComps/TaskModal/TaskModal';
import { getAllTasksCreatedByUser } from '../../../services/tasks.service';

const clength = '23%';

const AccordionModule = props => {
	const { allModules, component, blueprintIndex, isMandatoryError, nextButtonClicked, setIsMandatoryError, companyUsersArray, companyData, components, products, authenticatedUser, begunModule, blueprints, selectedComponent, companyPositionsArray, editMode } = props;
	const [processTaskContext, setTaskProcessContext] = useProcessTaskContext();
	const [setActive, setActiveState] = useState(cls.active);
	const [setRotate, setRotateState] = useState(cls.icon);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const content = useRef(null);
	const indexInContextArray = processTaskContext.currentStep?.component_blueprints.findIndex(c => c.id === component.id);

	useEffect(() => {
		checkIfAreMandatoryErrored();
	}, [nextButtonClicked]);

	const checkIfAreMandatoryErrored = () => {
		if (component?.selected_fields?.some(elem => elem.mandatory === true && elem.visible === true && (!elem.value || elem?.value?.length < 1))) {
			setIsMandatoryError(true);
		} else setIsMandatoryError(false);
	};

	const checkMandatoryFields = useCallback(() => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElements = list[indexInContextArray]?.selected_fields;
		checkIfAreMandatoryErrored();
		list[indexInContextArray].selected_fields = selectedElements?.map(elem => ({
			...elem,
			errors: elem.mandatory === true && !elem.value ? 'Field is mandatory' : '',
		}));
		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	}, [processTaskContext.currentStep]);

	const saveButtonAction = () => {
		checkMandatoryFields();

		if (component?.selected_fields?.some(elem => elem.mandatory === true && elem.visible === true && (!elem.value || elem?.value?.length < 1))) {
			return;
		}

		const item = {};
		const selected_fields = component.selected_fields.map(sf => {
			if (sf.data_type_name === 'Formula') {
				const value = computeFormula(
					sf.options,
					component.selected_fields.filter(x => sf.options.list.includes(x.field_name)),
					processTaskContext.components,
					processTaskContext.items ?? []
				);

				if (sf?.options?.mapToItem) {
					item[sf?.options?.mapToItem] = `${value ?? ''}`;
				}

				return { ...sf, value: `${value ?? ''}` };
			}
			if (sf?.options?.mapToItem) {
				item[sf?.options?.mapToItem] = `${sf?.value ?? ''}`;
			}
			return sf;
		});
		if (Object.keys(item).length > 0) {
			const found = processTaskContext.items?.find(it => it.item_code === item.item_code);

			if (found === undefined) {
				setTaskProcessContext({ action: processTaskAction.addModuleItems, value: { ...item, id: generateNewUUID() } });
			} else {
				if (item.quantity == 0) {
					setTaskProcessContext({ action: processTaskAction.removeModuleItems, value: found });
				} else {
					setTaskProcessContext({ action: processTaskAction.updateModuleItems, value: { ...found, ...item } });
				}
			}
		} else {
			const new_component = JSON.parse(JSON.stringify({ ...component, temp_id: generateNewUUID(), selected_fields: selected_fields }));
			setTaskProcessContext({ action: processTaskAction.addModuleComponents, value: new_component });
		}

		setLoading(false);
		setMessage('');

		component.selected_fields.map((field, index) => {
			handleInputChange('', index);
		});

		setTaskProcessContext({
			action: processTaskAction.setModuleNewStepComponents,
			value: [...R.reject(c => c.selected_fields.filter(field => field.value === '').length > 0, [...R.reject(R.propEq('id', component.id))(processTaskContext.newStepComponents)])],
		});
	};

	const handleInputChange = (value, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		if (selectedElement?.data_type_name === 'Number') {
			if (Number(+value)) {
				selectedElement.value = value;
			} else if (value === '') {
				selectedElement.value = '';
			}
		} else if (selectedElement?.data_type_name) {
			selectedElement.value = value;
		}
		if (selectedElement?.value && selectedElement.mandatory) selectedElement.errors = '';
		checkIfAreMandatoryErrored();

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	};

	const handleCheckbox = (value, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		if (selectedElement) {
			selectedElement.value = value;
			if (selectedElement?.value && selectedElement.mandatory) selectedElement.errors = '';

			setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
			setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });

			checkIfAreMandatoryErrored();
		}
	};

	const handlePrefixChange = useCallback((data, index, value) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.options.country_code = data?.countryCode;
		selectedElement.options.country_name = data?.name;
		selectedElement.options.country_prefix = `+${data?.dialCode}`;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
		handleInputChange(value, index);
	}, []);

	const handleCurrencyChange = useCallback((data, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.options.currency_type = data?.label;
		selectedElement.options.currency_value = data?.value;
		selectedElement.options.symbol = data?.symbol;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	}, []);

	const handleLocationChange = (location, index) => {
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		const selectedElement = list[indexInContextArray]?.selected_fields[index];
		selectedElement.value = `${location.lat}, ${location.lng}`;

		setTaskProcessContext({ action: processTaskAction.setComponentBlueprints, value: list });
		setTaskProcessContext({ action: processTaskAction.setModuleNewStepComponents, value: list });
	};

	//console.log('ACCORDION MODULE processTaskContext', processTaskContext, begunModule, component, selectedComponent);
	return (
		<div
			id="accordion"
			className={cls.section}
			key={blueprintIndex}
			style={{
				display:
					component.options?.useCustomTemplate === false || component.options?.useCustomTemplate === undefined
						? component.options?.showOnly === undefined
							? ''
							: component.options?.showOnly?.length > 0
								? component.options?.showOnly?.filter(id => authenticatedUser?.positions?.filter(pos => pos.id === id).length > 0).length > 0
									? ''
									: 'none'
								: ''
						: 'none',
				pointerEvents:
					selectedComponent?.id === undefined
						? ''
						: selectedComponent?.created_by?.id === authenticatedUser.id
							? ''
							: begunModule?.processModule?.module?.options?.strict === undefined || begunModule?.processModule?.module?.options?.strict == false
								? ''
								: component.options?.showOnly === undefined
									? 'none'
									: component.options?.showOnly?.length > 0
										? component.options?.showOnly?.filter(id => authenticatedUser?.positions?.filter(pos => pos.id === id).length > 0).length > 0
											? ''
											: 'none'
										: 'none',
			}}
		>
			<div className={classNames(cls.accordionContainer, setActive)} app-variant-has-error={message ? 'error' : ''} style={{ border: isMobile ? '0' : '', padding: isMobile ? '0' : '', margin: isMobile ? '0' : '' }}>
				{/* {component.selected_fields.filter(sf => sf.data_type_name === 'Transaction List' || sf.data_type_name === 'Step List').length == 0 && (
					<p className={cls.title} >
						{component?.name}
					</p>
				)} */}
				<p className={cls.title} >
					{component?.name}
				</p>
				<div ref={content} style={{ maxHeight: setActive === cls.active ? '' : '0px', display: 'flex' }} className={cls.content}>
					<RenderSelectedFields
						blueprints={blueprints}
						component={component}
						selectedComponent={selectedComponent}
						handleInputChange={handleInputChange}
						handlePrefixChange={handlePrefixChange}
						handleCurrencyChange={handleCurrencyChange}
						checkIfAreMandatoryErrored={checkIfAreMandatoryErrored}
						handleCheckbox={handleCheckbox}
						isInherited={false}
						indexInContextArray={indexInContextArray}
						handleLocationChange={handleLocationChange}
						processTaskContext={processTaskContext}
						begunModule={begunModule}
						setTaskProcessContext={setTaskProcessContext}
						companyData={companyData}
						companyUsersArray={companyUsersArray}
						companyPositionsArray={companyPositionsArray}
						components={components}
						products={products}
						allModules={allModules}
						editMode={editMode}
					/>
				</div>
				<div style={{ paddingLeft: '10px' }}>
					<div onClick={saveButtonAction} className={cls.iconsContainer} style={{ display: component.options === null ? 'none' : component?.options?.allowMultipleInputs === true ? '' : 'none' }}>
						{loading ? (
							<div className={cls.loaderContainer}>
								<Loader type={isSafari ? 'Watch' : 'Puff'} color={'var(--app-color-gray-dark)'} /*  visible={props.loading} */ secondaryColor="gray" radius={32} height={32} width={'32px'} />
							</div>
						) : (
							<Button light>
								<Add />
							</Button>
						)}
					</div>
				</div>
			</div>
			{message && message?.errors?.title && <MessageContainer message={message.errors.title} />}
			{message && message?.message && <MessageContainer message={message.message} />}
		</div>
	);
};

function checkPrefixCurrency(options) {
	if (options?.country_prefix) return options?.country_prefix;
	else if (options?.currency_value) return options?.currency_value + ' ';
	else return '';
}

function isValidHttpUrl(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return url.protocol === 'http:' || url.protocol === 'https:';
}

const validate = (name, value, indexInContextArray, index, processTaskContext) => {
	const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
	const selectedElement = list[indexInContextArray]?.selected_fields[index];
	switch (name) {
		case 'Link':
			if (!isValidHttpUrl(value)) {
				selectedElement.errors = 'This field should contain a link!';
			} else selectedElement.errors = '';
			break;

		case 'E-mail':
			if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
				selectedElement.errors = 'This field should contain an valid email address!';
			} else selectedElement.errors = '';
			break;
		case 'Email':
			if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
				selectedElement.errors = 'This field should contain an valid email address!';
			} else selectedElement.errors = '';
			break;
		case 'Phone':
			if (!new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im).test(value)) {
				selectedElement.errors = 'This field should contain an valid phone number!';
			} else selectedElement.errors = '';
			break;
		case 'Email Recipient':
			if (!new RegExp(/^([a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*)(([, ]+[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])\.([a-z0-9]([a-z0-9-]*[a-z0-9]))*)?)*$/).test(value)) {
				selectedElement.errors = 'This field should contain an valid email address!';
			} else selectedElement.errors = '';
			break;
		default:
			break;
	}
};
const RenderSelectedFields = memo(props => {
	const {
		component,
		selectedComponent,
		handleInputChange,
		indexInContextArray,
		handleCheckbox,
		processTaskContext,
		setTaskProcessContext,
		isInherited,
		checkIfAreMandatoryErrored,
		handlePrefixChange,
		handleCurrencyChange,
		handleLocationChange,
		companyData,
		components,
		products,
		begunModule,
		blueprints,
		companyUsersArray,
		companyPositionsArray,
		allModules,
		editMode,
	} = props;

	const [users, setUsers] = useState(
		companyUsersArray.map(i => {
			return {
				label: i.first_name + ' ' + i.last_name,
				value: i.id,
				user: i,
			};
		})
	);
	const { authenticatedUser } = useSelector(store => store.authReducer);
	const { currentLocation, allTasks } = useSelector(store => store.flowReducer);
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(new Date());
	const [dateRange, setDateRange] = useState([new Date(), new Date(moment().add(7, 'days'))]);
	const [startDate, endDate] = dateRange;
	const [dropDownV, setDropdownV] = useState('');
	const [selectedItems, setSelectedItems] = useState([{ label: '', value: '' }]);
	const [showSecond, setShowSecond] = useState(true);
	const [showThird, setShowThird] = useState(true);

	const [modalOpen, setModalOpen] = useState({ source: false, destination: false });
	const [bottomMargin, setBottomMargin] = useState('50px');
	const [errors, setErrors] = useState(Array(12).fill(''));
	const [aiResponse, setAIResponse] = useState({ candidates: [] });
	const [taskModalVisible, setTaskModalVisible] = useState(false);
	const [targetTask, setTargetTask] = useState({});
	const [calendarView, setCalendarView] = useState('day');
	const ref = useRef();

	const dateChangeHandler = (dates, index) => {
		setDateRange(dates);
		handleInputChange(dates, index);
	};

	const singleDateChangeHandler = (date, index) => {
		setDate(date);
		handleInputChange(date, index);
	};

	const retrieveUsers = useCallback(positions => {
		if (positions === undefined) return;
		if (users.length == 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
		getUsersByPositions(positions)
			.pipe(first())
			.subscribe({
				next: data => {
					setUsers(data);
					setLoading(false);
				},
			});
	}, []);

	const retrievePositions = useCallback(() => {
		if (companyPositionsArray.length == 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
		getAllCompanyPositionsAsArray().pipe(first(1)).subscribe({});
	}, []);

	const postQuery = useCallback(options => {
		if (options?.analyze?.length == 0 || options?.analyze == undefined) {
			setAIResponse({ candidates: [{ content: { parts: [{ text: 'Enter a question that I could answer' }] } }] });
			return;
		}
		let analyze = {
			role: 'user',
			parts: [{ text: options.analyze }],
		};

		if (options?.current == true) {
			analyze.parts = [
				...analyze.parts,
				{
					text: JSON.stringify({
						table: 'Transaction',
						data: {
							...processTaskContext.components.map(com => {
								let details = {};
								com.selected_fields.filter(cf => (details = { ...details, [cf.field_name]: cf.value }));
								return details;
							}),
							...processTaskContext.newStepComponents.map(com => {
								let details = {};
								com.selected_fields.filter(cf => (details = { ...details, [cf.field_name]: cf.value }));
								return details;
							}),
							items: processTaskContext.items.map(it => {
								return {
									item: it.item_code,
									quantity: +it.quantity,
									value: it.value,
								};
							}),
						},
					}),
				},
			];
		} else {
			if (options.module_id !== undefined) {
				analyze.parts = [
					...analyze.parts,
					{
						text: JSON.stringify({
							table: allModules.find(mod => mod.id === options.module_id).name,
							data: components
								.filter(com => com.module_id === options.module_id)
								.map(com => {
									let details = {};
									com.component_fields.filter(cf => (details = { ...details, [cf.field_name]: cf.value }));
									return {
										...details,
										created_date: com.created_date,
										created_by: com.created_by.id,
										items: com?.component_items.map(it => {
											return {
												item: it.item_code,
												quantity: +it.quantity,
												value: it.value,
											};
										}),
									};
								}),
						}),
					},
				];
			}

			if (options.module2_id !== undefined) {
				analyze.parts = [
					...analyze.parts,
					{
						text: JSON.stringify({
							table: allModules.find(mod => mod.id === options.module2_id).name,
							data: components
								.filter(com => com.module_id === options.module2_id)
								.map(com => {
									let details = {};
									com.component_fields.filter(cf => (details = { ...details, [cf.field_name]: cf.value }));
									return {
										...details,
										created_date: com.created_date,
										created_by: com.created_by.id,
										items: com?.component_items.map(it => {
											return {
												item: it.item_code,
												quantity: +it.quantity,
												value: it.value,
											};
										}),
									};
								}),
						}),
					},
				];
			}

			if (options.module3_id !== undefined) {
				analyze.parts = [
					...analyze.parts,
					{
						text: JSON.stringify({
							table: allModules.find(mod => mod.id === options.module3_id).name,
							data: components
								.filter(com => com.module_id === options.module3_id)
								.map(com => {
									let details = {};
									com.component_fields.filter(cf => (details = { ...details, [cf.field_name]: cf.value }));
									return {
										...details,
										created_date: com.created_date,
										created_by: com.created_by.id,
										items: com?.component_items.map(it => {
											return {
												item: it.item_code,
												quantity: +it.quantity,
												value: it.value,
											};
										}),
									};
								}),
						}),
					},
				];
			}
		}

		setAIResponse({ candidates: [] });
		setLoading(true);
		geminiAI(JSON.stringify(analyze))
			.pipe(first())
			.subscribe({
				next: data => {
					setLoading(false);
					setAIResponse(data);
				},
				error: error => {
					setAIResponse({ candidates: [{ content: { parts: [{ text: JSON.stringify(error) }] } }] });
					setLoading(false);
				},
			});
	}, []);

	const getComponentsForListing = options => {
		return processTaskContext.components
			.filter(component => component.id === options.component_id)
			.map(cmp => {
				let object = {};
				options.list.map(column => {
					object = { ...object, [column]: cmp.selected_fields.find(field => field.field_name === column)?.value, temp_id: cmp.temp_id };
				});
				return object;
			});
	};

	const getItemsForListing = options => {
		return processTaskContext?.items?.map(item => {
			let object = {};
			Object.keys(options['map']).map(column => {
				object = { ...object, [options['map'][column]]: item[column], item: item };
			});
			console.log(object)
			return object;
		});
	};

	const checkIfFieldComponentExists = props => {
		const { field_name } = props;
		return component.selected_fields.findIndex(sf => sf.field_name === field_name);
	};

	const handleComponentInfoChecks = selection => {
		const infoArray = component.selected_fields.filter(sf => sf.data_type_name === 'Info');
		const found = components.find(c => c.id === selection);
		infoArray.map(info => {
			info?.options?.list.map(obj => {
				const comp = found.component_fields?.find(cf => cf.field_name === obj);
				const idx = checkIfFieldComponentExists({
					field_name: obj,
				});
				if (idx != -1 && found.component_fields?.length > 0) {
					component.selected_fields[idx].value = comp ? comp.value : '';
					info.value = selection;
				}
			});
		});

		const globalArray = component.selected_fields.filter(sf => sf.data_type_name === 'Global' && sf.visible == true);
		globalArray.map(global => {
			const comp = found.component_fields?.find(cf => cf.field_name === global.field_name);
			const idx = checkIfFieldComponentExists({
				field_name: global.field_name,
			});

			if (idx != -1 && found.component_fields?.length > 0) {
				component.selected_fields[idx].value = comp.value;
			}
		});
	};

	const getMultipleLookups = (id, common_field, target_id, sourceLabel, referenceLabel, list) => {
		const array = components.filter(c => c.component_fields?.filter(cf => cf.component_blueprint_id === target_id).length > 0);
		let mainArray = [];
		components
			.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === id).length > 0)
			.map(elem => {
				const x = elem.component_fields.find(e => e.field_name === common_field);
				const sourceTitle = elem.component_fields.find(e => e.field_name === sourceLabel)?.value;
				if (x !== undefined) {
					array
						.filter(a => a.component_fields.filter(cf => cf.value === x.value && cf.field_name === common_field).length > 0)
						.map(y => {
							const referenceTitle = y.component_fields.find(e => e.field_name === referenceLabel)?.value;

							let listObj = {};
							if (list.length > 0) {
								list.map(label => {
									const yy = y.component_fields.find(e => e.field_name === label);
									if (yy) listObj[label] = yy.value;
								});
							}
							mainArray = [...mainArray, { label: referenceTitle, value: y.id, field: y, grouping: sourceTitle, [sourceLabel]: sourceTitle, [referenceLabel]: referenceTitle, ...listObj }];
						});
				}
			});
		return mainArray;
	};

	const getMultipleLookupsv2 = (id, common_field, target_id, sourceLabel = [], list, referenceList = [], items = components) => {

		const array = items.filter(c => c.component_fields?.filter(cf => cf.component_blueprint_id === target_id).length > 0);
		let mainArray = [];
		items
			.filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === id).length > 0)
			.map(elem => {
				const sourceTitle = {};

				(sourceLabel).map(label => {
					sourceTitle[label] = elem.component_fields.find(e => e.field_name === label)?.value;
				});

				const x = elem.component_fields.find(e => e.field_name === common_field);

				if (x !== undefined) {
					array
						.filter(a => a.component_fields.filter(cf => cf.value === x.value && cf.field_name === common_field).length > 0)
						.map(y => {
							const referenceTitle = y.component_fields.find(e => referenceList.indexOf(e.field_name) !== -1)?.value ?? '';

							let refListObj = {};
							if (referenceList.length > 0) {
								referenceList.map(label => {
									const yy = y.component_fields.find(e => e.field_name === label);
									if (yy) {
										refListObj[label] = yy.value;
									}
								});
							}

							let listObj = {};
							if (list.length > 0) {
								list.map(label => {
									const yy = y.component_fields.find(e => e.field_name === label);
									if (yy) {
										listObj[label] = yy.value;
									} else {
										const yy = elem.component_fields.find(e => e.field_name === label);
										if (yy)
											listObj[label] = yy.value;
									}
								});
							}
							mainArray = [...mainArray, { label: referenceTitle, value: y.id, field: y, grouping: Object.values(sourceTitle)?.length > 0 ? Object.values(sourceTitle)[0] : 'Fields', ...sourceTitle, ...listObj, ...refListObj }];
						});
				}
			});
		return mainArray;
	};



	const componentsToRender = type => {
		let global = begunModule.processModule.module?.options?.global;

		if (!global) {
			global = processTaskContext.components.concat(processTaskContext.newStepComponents)
				.find(com => com.selected_fields.filter(sf => sf.data_type_name === 'Global' && sf.visible == true && sf.field_name === component.selected_fields.find(sfd => sfd.data_type_name === type && sfd.visible == true)?.options?.global).length > 0)
				?.selected_fields.find(sf => sf.data_type_name === 'Global')?.value;
		}

		if (!global) {
			global = R.pipe(R.pluck('component_blueprints'), R.flatten)(begunModule.processModule.completeSteps)
				.find(com => com.selected_fields.filter(sf => sf.field_name === component.selected_fields.find(sfd => sfd.data_type_name === type && sfd.visible == true)?.options?.global && sf.data_type_name === 'Value').length > 0)
				?.selected_fields.find(sf => sf.data_type_name === 'Value')?.options?.value;
		}
		return global ? components.filter(com => com.component_fields.filter(sf => sf.value === global && sf.field_name === component.selected_fields.find(sf => sf.data_type_name === type)?.options?.global).length > 0) : components;
	};

	const componentsToRenderV2 = (fieldName, items) => {

		let global = begunModule.processModule.module?.options?.global;

		if (!global) {
			global = processTaskContext.components
				.find(com => com.selected_fields.filter(sf => sf.visible == true && sf.field_name === fieldName).length > 0)
				?.selected_fields.find(sf => sf.field_name === fieldName)?.value;
		}
		if (!global) {
			global = R.pipe(R.pluck('component_blueprints'), R.flatten)(begunModule.processModule.completeSteps)
				.find(com => com.selected_fields.filter(sf => sf.field_name === fieldName && sf.data_type_name === 'Value').length > 0)
				?.selected_fields.find(sf => sf.field_name === fieldName)?.options?.value;
		}
		return global ? items.filter(it => it[fieldName] === global) : items;
	};

	const allSelectedFields =
		component?.selected_fields?.length > 0 &&
		component?.selected_fields
			?.sort((a, b) => a.index - b.index)
			.map((field, index) => {
				let enabled = '';

				if (field?.options?.fieldToMatch?.length > 0) {
					const found = component.selected_fields.find(sf => sf.field_name === field?.options?.fieldToMatch);
					if (found && field?.options?.valueToMatch?.length > 0 && field?.options?.actionToPerform?.length > 0 && field?.options?.initialState?.length > 0) {
						if (field?.options?.valueToMatch?.split(',').includes(found?.value)) {
							if (field?.options?.actionToPerform === 'hide') {
								field.visible = false;
							} else if (field?.options?.actionToPerform === 'visible') {
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'mandatory') {
								field.mandatory = true;
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'optional') {
								field.mandatory = false;
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'visman') {
								field.mandatory = true;
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'disable') {
								enabled = 'none';
							}
						} else {
							if (field?.options?.initialState === 'hidden') {
								field.visible = false;
							} else if (field?.options?.initialState === 'visible') {
								field.visible = true;
							} else if (field?.options?.initialState === 'mandatory') {
								field.mandatory = true;
								field.visible = true;
							} else if (field?.options?.initialState === 'optional') {
								field.mandatory = false;
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'visman') {
								field.mandatory = true;
								field.visible = true;
							} else if (field?.options?.actionToPerform === 'disable') {
								enabled = 'none';
							}
						}
					}

					if (found && field?.options?.valueToMatch2?.length > 0) {
						if (field?.options?.valueToMatch2?.split(',').includes(found?.value)) {
							if (field?.options?.value?.length > 0) {
								if (field?.data_type_name === 'Text') {
									field.value = field?.options?.value;
								} else if (field?.data_type_name === 'Date') {
									if (field?.value?.length == 0 || field?.value == undefined) {
										if (field?.options?.value === 'today') {
											field.value = new Date(moment(now()));
										} else if (field?.options?.value === 'tomorrow') {
											field.value = new Date(moment(now()).add(1, 'days'));
										} else if (field?.options?.value === 'week') {
											field.value = new Date(moment(now()).add(1, 'weeks'));
										} else if (field?.options?.value === 'month') {
											field.value = new Date(moment(now()).add(1, 'months'));
										}
										if (field?.options?.value === 'none') {
											field.value = undefined;
										}
									}
								}
							}

							if (field?.options?.actionToPerform2?.length > 0) {
								if (field?.options?.actionToPerform2 === 'hide') {
									field.visible = false;
								} else if (field?.options?.actionToPerform2 === 'visible') {
									field.visible = true;
								} else if (field?.options?.actionToPerform2 === 'mandatory') {
									field.mandatory = true;
									field.visible = true;
								} else if (field?.options?.actionToPerform2 === 'optional') {
									field.mandatory = false;
									field.visible = true;
								} else if (field?.options?.actionToPerform2 === 'visman') {
									field.mandatory = true;
									field.visible = true;
								} else if (field?.options?.actionToPerform2 === 'disable') {
									enabled = 'none';
								} else if (field?.options?.actionToPerform2 === 'enable') {
									enabled = '';
								}
							}
						}
					}
				}

				if (field?.data_type_name === 'Record' && field?.options?.component_id?.length > 0) {
					const items = (field?.options?.global ? componentsToRender(field?.data_type_name) : components).filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === field?.options?.component_id).length > 0);
					const components_found = items.map(elem => {
						let label = '';
						field?.options?.names?.map(name => (label = label === '' ? elem.component_fields.find(cf => cf.field_name === name)?.value : `${label} ${elem.component_fields.find(cf => cf.field_name === name)?.value}`));
						return { label: label === '' ? elem.title : label, value: elem.id, component: elem, [field?.options?.output]: elem.component_fields.find(cf => cf.field_name === field?.options?.output)?.value };
					});
					let found;
					if (field?.options?.filters?.length > 0) {
						if (items?.length > 0) {
							if (field.value) {
								found = items.find(item => item.component_fields.find(cf => cf.field_name === field?.options?.output && cf.value === field.value));
								if (found) {
									handleComponentInfoChecks(found?.id);
									const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
									if (list) {
										processTaskContext.newStepComponents = list;
									}
								}
							} else if (components_found.length == 1) {
								//auto select the only component available
								found = items[0];
								if (found) {
									handleComponentInfoChecks(found?.id);
									const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
									if (list) {
										processTaskContext.newStepComponents = list;
									}
								}
							}
						}
						if (components_found.length == 1 || field.value) {
							if (field?.options?.filters.length > 0) {
								selectedItems[field?.options?.filters[0]] = R.uniqWith(R.eqProps('value'))(
									items
										.map(item => {
											const x = item?.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
											return { label: x?.value, value: x?.value, component: item };
										})
										.filter(y => y.component?.component_fields.filter(cf => cf.value === found?.component_fields.find(cf => cf.field_name === field?.options?.filters[0])?.value).length > 0)
								);
							}
							if (field?.options?.filters.length > 1) {
								selectedItems[field?.options?.filters[1]] = R.uniqWith(R.eqProps('value'))(
									items
										.map(item => {
											const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[2]);
											return { label: x?.value, value: x?.value, component: item };
										})
										.filter(y => y.component?.component_fields.filter(cf => cf.value === found?.component_fields.find(cf => cf.field_name === field?.options?.filters[1])?.value).length > 0)
								);
							}
						}
					} else if (components_found.length > 0) {
						if (field.value) {
							found = components_found?.find(cf => cf[field?.options?.output] === field.value);
							if (found) {
								handleComponentInfoChecks(found.value);
								const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
								if (list) {
									processTaskContext.newStepComponents = list;
								}
							}
						} else if (components_found.length == 1 && field?.mandatory == true) {
							//auto select the only component available
							found = components_found[0];
							if (found) {
								handleComponentInfoChecks(found.value);
								const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
								if (list) {
									processTaskContext.newStepComponents = list;
								}
							}
						}
					}

					return !found && field?.value ? (
						<div key={index}>{`A record ( ${field?.value} ) was previously selected but record is not shared with your profile or could have been deleted. Please check with your admin.`}</div>
					) : field?.options?.filters?.length == 1 ? (
						<FieldRow
							enabled={enabled}
							width={component?.selected_fields?.length < 5 ? '50%' : '23%'}
							style={{ border: '0' }}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors?.length > 0 ? `${field.errors}. Try to select again` : ''}
							notes={field?.options?.notes}
						>
							<SelectAndMultiselect
								visible={field?.visible}
								mandatory={field?.options?.filters?.length == 1 ? field?.mandatory : false}
								items={R.uniqWith(R.eqProps('value'))(
									items.map(item => {
										const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[0]);
										return { label: x?.value, value: x?.value, component: item };
									})
								)}
								loading={loading}
								error={field?.options?.filters?.length == 1 ? field?.errors : ''}
								onChange={value => {
									if (field?.options?.filters?.length > 1) {
										handleCheckbox(undefined, index);
										setShowThird(false);
										setShowSecond(false);
										setTimeout(() => {
											setShowSecond(true);
											setShowThird(true);
										}, 10);

										setSelectedItems({
											[field?.options?.filters[0]]: R.uniqWith(R.eqProps('value'))(
												items
													.map(item => {
														const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
														return { label: x?.value, value: x?.value, component: item, selected: value };
													})
													.filter(y => y.component.component_fields.filter(cf => cf.value === value && cf.field_name === field?.options?.filters[0]).length > 0)
											),
										});
									} else {
										setBottomMargin('50px');
										handleCheckbox(items.find(c => c.component_fields.filter(cf => cf.value === value).length > 0)?.component_fields.find(cf => cf.field_name === field?.options?.output)?.value ?? null, index);
										handleComponentInfoChecks(items.find(c => c.component_fields.filter(cf => cf.value === value).length > 0)?.id);
									}
								}}
								placeholder={`Select...`}
								value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[0])?.value : ''}
							/>

						</FieldRow>
					) :
						field?.options?.filters?.length > 1 ? (
							<div
								key={index}
								className={cls.filtersContainer}
								style={{
									width: isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : '24%',
									display: field?.visible ? '' : 'none',
									pointerEvents: enabled,
								}}
							>
								{blueprints?.find(bp => bp.id === field?.options?.component_id)?.name}
								<br />
								<br />
								{field?.options?.filters?.length > 0 && (
									<SelectAndMultiselect
										visible={field?.visible}
										mandatory={field?.options?.filters?.length == 1 ? field?.mandatory : false}
										items={R.uniqWith(R.eqProps('value'))(
											items.map(item => {
												const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[0]);
												return { label: x?.value, value: x?.value, component: item };
											})
										)}
										loading={loading}
										error={field?.options?.filters?.length == 1 ? field?.errors : ''}
										onChange={value => {
											if (field?.options?.filters?.length > 1) {
												handleCheckbox(undefined, index);
												setShowThird(false);
												setShowSecond(false);
												setTimeout(() => {
													setShowSecond(true);
													setShowThird(true);
												}, 10);

												setSelectedItems({
													[field?.options?.filters[0]]: R.uniqWith(R.eqProps('value'))(
														items
															.map(item => {
																const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
																return { label: x?.value, value: x?.value, component: item, selected: value };
															})
															.filter(y => y.component.component_fields.filter(cf => cf.value === value && cf.field_name === field?.options?.filters[0]).length > 0)
													),
												});
											} else {
												setBottomMargin('50px');
												handleCheckbox(items.find(c => c.component_fields.filter(cf => cf.value === value).length > 0)?.component_fields.find(cf => cf.field_name === field?.options?.output)?.value ?? null, index);
												handleComponentInfoChecks(items.find(c => c.component_fields.filter(cf => cf.value === value).length > 0)?.id);
											}
										}}
										fieldLabel={field?.options?.filters[0]}
										placeholder={`Select...`}
										value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[0])?.value : ''}
									/>
								)}

								<div style={{ display: field?.visible ? '' : 'none', marginBottom: '10px' }}>
									{field?.options?.filters?.length > 1 && showSecond && (
										<SelectAndMultiselect
											visible={field?.visible}
											mandatory={field?.options?.filters?.length == 2 ? field?.mandatory : false}
											items={selectedItems[field?.options?.filters[0]] ?? []}
											loading={loading}
											onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
											onChange={value => {
												if (field?.options?.filters?.length > 2) {
													setShowThird(false);
													setTimeout(() => setShowThird(true), 10);

													setSelectedItems({
														...selectedItems,
														[field?.options?.filters[1]]:
															R.uniqWith(R.eqProps('value'))(
																items
																	.map(item => {
																		const found = item.component_fields.find(sf => sf.field_name === field?.options?.filters[2]);
																		return { label: found?.value, value: found?.value, component: item };
																	})
																	.filter(found => found.component.component_fields.filter(cf => cf.value === value && cf.field_name === field?.options?.filters[1]).length > 0)
																	.filter(
																		found =>
																			found.component.component_fields.filter(cf => cf.value === selectedItems[field?.options?.filters[0]][0].component.component_fields.find(cf => cf.field_name === field?.options?.filters[0]).value && cf.field_name === field?.options?.filters[0])
																				.length > 0
																	)
															) ?? [],
													});
												} else {
													setBottomMargin('50px');
													companyData.start = true;
													handleCheckbox(selectedItems[field?.options?.filters[0]]?.find(c => c.value === value)?.component.component_fields.find(sf => sf.field_name === field?.options?.output).value ?? null, index);
													handleComponentInfoChecks(selectedItems[field?.options?.filters[0]].find(x => x.value === value).component.id);

													setShowThird(false);
													setShowSecond(false);
													setTimeout(() => {
														setShowSecond(true);
														setShowThird(true);
													}, 10);
												}
											}}
											fieldLabel={field?.options?.filters[1]}
											placeholder={`Select...`}
											value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[1])?.value : ''}
										/>
									)}
								</div>
								<div style={{ display: field?.visible ? '' : 'none', marginBottom: '10px' }}>
									{field?.options?.filters?.length > 2 && showThird && (
										<SelectAndMultiselect
											visible={field?.visible}
											mandatory={field?.options?.filters?.length == 3 ? field?.mandatory : false}
											items={selectedItems[field?.options?.filters[1]] ?? []}
											loading={loading}
											onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
											onChange={value => {
												setBottomMargin('50px');
												companyData.start = true;
												handleCheckbox(selectedItems[field?.options?.filters[1]]?.find(c => c.value === value)?.component.component_fields.find(sf => sf.field_name === field?.options?.output).value ?? null, index);
												handleComponentInfoChecks(selectedItems[field?.options?.filters[1]].find(x => x.value === value).component.id);
											}}
											fieldLabel={field?.options?.filters[2]}
											placeholder={field?.value ?? `Select...`}
											value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[2])?.value : ''}
										/>
									)}
								</div>
								{field?.options?.notes}
							</div>
						) : field?.options?.selection === 'Tiles' ? (
							<FieldRow
								enabled={enabled}
								width={'100%'}
								style={{ border: '0' }}
								editMode={editMode}
								showWidth={width => {
									field.field_options = { ...field.field_options, width: `${width}px` };
								}}
								key={index}
								fieldLabel={field.field_name}
								fieldIcon={field.icon}
								isProcess
								visible={field.visible}
								mandatory={field.mandatory}
								error={field.errors?.length > 0 ? `${field.errors}. Try to select again` : ''}
								notes={field?.options?.notes}
							>
								<div style={{ width: isMobile ? '100%' : '50%' }}>
									<ModuleLists
										rowStyleSelected={{ border: '2px dashed black' }}
										initialSelected={components_found
											.map(com => {
												return com[field?.options?.output];
											})
											.indexOf(field?.value)}
										modern={true}
										isReport={false}
										app-variant-is-mobile={'true'}
										columns={field?.options?.names}
										list={components_found.map(com => {
											const object = com;
											com.component.component_fields.filter(field => {
												object[field.field_name] = field.value;
											});
											return object;
										})}
										onListItemClick={selection => {
											if (selection) {
												field?.options?.list.map(label => {
													const idx = checkIfFieldComponentExists({
														field_name: label,
													});
													if (idx != -1) {
														component.selected_fields[idx].value = selection.component.component_fields.find(cf => cf.field_name === label)?.value ?? '';
														component.selected_fields[idx].disabled = true;
													}
												});
											}

											handleComponentInfoChecks(selection);
											handleCheckbox(components_found?.find(c => c.value === selection.value)?.component.component_fields?.find(cf => cf.field_name === field?.options?.output)?.value ?? null, index);
										}}
									/>
								</div>
							</FieldRow>
						) : (
							//Other than tiles or dropdown
							<FieldRow
								enabled={enabled}
								width={component?.selected_fields?.length < 5 ? '50%' : '23%'}
								editMode={editMode}
								showWidth={width => {
									field.field_options = { ...field.field_options, width: `${width}px` };
								}}
								key={index}
								fieldLabel={blueprints.find(bp => bp.id === field?.options?.component_id).name}
								fieldIcon={field.icon}
								isProcess
								visible={field.visible}
								mandatory={field.mandatory}
								error={field.errors?.length > 0 ? `${field.errors}. Try to select again` : ''}
								notes={field?.options?.notes}
							>
								<div style={{ width: 'inherit', border: '1px solid gray' }}>
									<SelectAndMultiselect
										key={index}
										visible={field?.visible}
										mandatory={field?.mandatory}
										items={components_found}
										loading={loading}
										error={''}
										className={cls.dropdownClassContainer}
										onClicked={open => (open ? setBottomMargin(`100px`) : setBottomMargin('50px'))}
										onChange={selection => {

											if (selection) {
												field?.options?.list?.map(label => {
													const idx = checkIfFieldComponentExists({
														field_name: label,
													});
													if (idx != -1) {
														component.selected_fields[idx].value = components_found.find(cf => cf.value === selection).component.component_fields.find(cf => cf.field_name === label)?.value ?? '';
														component.selected_fields[idx].disabled = true;
													}
												});
											}


											companyData.start = true;
											setBottomMargin('50px');
											handleComponentInfoChecks(selection);
											handleCheckbox(components_found?.find(c => c.value === selection)[field?.options?.output] ?? null, index);
										}}
										selectionType={field?.options?.selection.length > 0 ? field?.options?.selection : field?.options?.type}
										value={found?.label ?? ''}
										placeholder={`Select...`}
									/>
								</div>
							</FieldRow>
						);
				} else if (field?.data_type_name === 'Filters' && field?.options?.component_id?.length > 0) {
					const items = (field?.options?.global ? componentsToRender(field?.data_type_name) : components).filter(c => c.component_fields.filter(cf => cf.component_blueprint_id === field?.options?.component_id).length > 0);
					const components_found = items.map(elem => {
						return { label: elem.title, value: elem.id, component: elem, [field?.options?.output]: elem.component_fields.find(cf => cf.field_name === field?.options?.output)?.value };
					});

					let found;

					if (items.length > 0) {
						if (field.value) {
							found = items.find(item => item.component_fields.find(cf => cf.field_name === field?.options?.output && cf.value === field.value));
							if (found) {
								handleComponentInfoChecks(found?.id);
								const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
								if (list) {
									processTaskContext.newStepComponents = list;
								}
							}
						} else if (components_found.length == 1 && field?.mandatory == true) {
							//auto select the only component available
							found = items[0];
							if (found) {
								handleComponentInfoChecks(found?.id);
								const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
								if (list) {
									processTaskContext.newStepComponents = list;
								}
							}
						}
						if (components_found.length == 1 || field.value) {
							if (field?.options?.filters.length > 0) {
								selectedItems[field?.options?.filters[0]] = R.uniqWith(R.eqProps('value'))(
									items.map(item => {
										const x = item?.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
										return { label: x?.value, value: x?.value, component: item };
									})
								);
							}
							if (field?.options?.filters.length > 1) {
								selectedItems[field?.options?.filters[1]] = R.uniqWith(R.eqProps('value'))(
									items.map(item => {
										const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[2]);
										return { label: x?.value, value: x?.value, component: item };
									})
								);
							}
						}
					}

					return !found && field?.value ? (
						<div key={index}>{`A record ( ${field?.value} ) was previously selected but record is not shared with your profile or could have been deleted. Please check with your admin.`}</div>
					) : (
						<div
							key={index}
							className={cls.filtersContainer}
							style={{
								width: isMobile ? '100%' : '32%',
								display: field?.visible ? '' : 'none',
								pointerEvents: enabled,
							}}
						>
							{blueprints.find(bp => bp.id === field?.options?.component_id).name}
							<br />
							<br />
							{field?.options?.filters?.length > 0 && (
								<SelectAndMultiselect
									visible={field?.visible}
									mandatory={field?.options?.filters?.length == 1 ? field?.mandatory : false}
									items={R.uniqWith(R.eqProps('value'))(
										items.map(item => {
											const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[0]);
											return { label: x?.value, value: x?.value, component: item };
										})
									)}
									loading={loading}
									error={field?.options?.filters?.length == 1 ? field?.errors : ''}
									onChange={value => {
										if (field?.options?.filters?.length > 1) {
											handleCheckbox(undefined, index);
											setShowThird(false);
											setShowSecond(false);
											setTimeout(() => {
												setShowSecond(true);
												setShowThird(true);
											}, 10);

											setSelectedItems({
												[field?.options?.filters[0]]: R.uniqWith(R.eqProps('value'))(
													items
														.map(item => {
															const x = item.component_fields.find(sf => sf.field_name === field?.options?.filters[1]);
															return { label: x?.value, value: x?.value, component: item, selected: value };
														})
														.filter(y => y.component.component_fields.filter(cf => cf.value === value && cf.field_name === field?.options?.filters[0]).length > 0)
												),
											});
										} else {
											setBottomMargin('50px');
											handleCheckbox(items.find(c => c.value === value)[field?.options?.output] ?? null, index);
											handleComponentInfoChecks(items.find(x => x.value === value).component.id);
										}
									}}
									fieldLabel={field?.options?.filters[0]}
									placeholder={`Select...`}
									value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[0])?.value : ''}
								/>
							)}

							<div style={{ display: field?.visible ? '' : 'none', marginBottom: '10px' }}>
								{field?.options?.filters?.length > 1 && showSecond && (
									<SelectAndMultiselect
										visible={field?.visible}
										mandatory={field?.options?.filters?.length == 2 ? field?.mandatory : false}
										items={selectedItems[field?.options?.filters[0]] ?? []}
										loading={loading}
										error={field?.options?.filters?.length == 2 ? field?.errors : ''}
										onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
										onChange={value => {
											if (field?.options?.filters?.length > 2) {
												setShowThird(false);
												setTimeout(() => setShowThird(true), 10);

												setSelectedItems({
													...selectedItems,
													[field?.options?.filters[1]]:
														R.uniqWith(R.eqProps('value'))(
															items
																.map(item => {
																	const found = item.component_fields.find(sf => sf.field_name === field?.options?.filters[2]);
																	return { label: found?.value, value: found?.value, component: item };
																})
																.filter(found => found.component.component_fields.filter(cf => cf.value === value && cf.field_name === field?.options?.filters[1]).length > 0)
																.filter(
																	found =>
																		found.component.component_fields.filter(cf => cf.value === selectedItems[field?.options?.filters[0]][0].component.component_fields.find(cf => cf.field_name === field?.options?.filters[0]).value && cf.field_name === field?.options?.filters[0])
																			.length > 0
																)
														) ?? [],
												});
											} else {
												setBottomMargin('50px');
												companyData.start = true;
												handleCheckbox(selectedItems[field?.options?.filters[0]]?.find(c => c.value === value)?.component.component_fields.find(sf => sf.field_name === field?.options?.output).value ?? null, index);
												handleComponentInfoChecks(selectedItems[field?.options?.filters[0]].find(x => x.value === value).component.id);
											}
										}}
										fieldLabel={field?.options?.filters[1]}
										placeholder={`Select...`}
										value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[1])?.value : ''}
									/>
								)}
							</div>
							<div style={{ display: field?.visible ? '' : 'none', marginBottom: '10px' }}>
								{field?.options?.filters?.length > 2 && showThird && (
									<SelectAndMultiselect
										visible={field?.visible}
										mandatory={field?.options?.filters?.length == 3 ? field?.mandatory : false}
										items={selectedItems[field?.options?.filters[1]] ?? []}
										loading={loading}
										error={field?.options?.filters?.length == 3 ? field?.errors : ''}
										onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
										onChange={value => {
											setBottomMargin('50px');
											companyData.start = true;
											handleCheckbox(selectedItems[field?.options?.filters[1]]?.find(c => c.value === value)?.component.component_fields.find(sf => sf.field_name === field?.options?.output).value ?? null, index);
											handleComponentInfoChecks(selectedItems[field?.options?.filters[1]].find(x => x.value === value).component.id);
										}}
										fieldLabel={field?.options?.filters[2]}
										placeholder={`Select...`}
										value={found ? found.component_fields.find(cf => cf.field_name === field?.options?.filters[2])?.value : ''}
									/>
								)}
							</div>
						</div>
					);
				} else if (field?.data_type_name === 'Global' || field?.data_type_name === 'Connector' || field?.data_type_name === 'Emails' || field?.data_type_name === 'Template') {
					<div />;
				} else if (field?.data_type_name === 'GoogleAI Assistant') {
					return (
						<div key={index} style={{ display: field.visible ? '' : 'none', color: 'var(--app-color-gray-dark)', height: 'inherit', width: isMobile ? '100%' : '80%', flexDirection: 'column', marginTop: '50px' }}>
							<FieldRow
								enabled={enabled}
								width={field?.field_options?.width ?? '90%'}
								editMode={editMode}
								showWidth={width => {
									field.field_options = { ...field.field_options, width: `${width}px` };
								}}
								key={index}
								fieldLabel={`${field?.options?.description}`}
								fieldIcon={field.icon}
								isProcess
								visible={field.visible}
								mandatory={field.mandatory}
								error={field.errors}
								notes={field?.options?.notes}
							>
								<textarea
									className={cls.fullLengthTextarea}
									type={field.data_type}
									onFocus={() => { }}
									disabled={field.options.fixed}
									autoComplete="none"
									onChange={e => {
										handleInputChange(e.target.value, index);
									}}
									placeholder={'Enter your question here'}
									value={field.options.fixed == true ? field?.options?.analyze : field.value}
								/>
							</FieldRow>
							<div style={{ display: 'flex' }}>
								<Button
									light
									loading={loading}
									title={'Ask Assistant '}
									clicked={() => {
										if (field.options.fixed == false) {
											field.options.analyze = field.value;
										}
										postQuery(field.options);
									}}
								>
									<img src={'https://ai.google/static/images/share.png'} height={'90px'} width={'140px'} />
								</Button>
								{aiResponse?.candidates.length > 0 && (
									<Button
										title={'Clear'}
										clicked={() => {
											setAIResponse({ candidates: [] });
											handleInputChange(undefined, index);
										}}
									/>
								)}
							</div>
						</div>
					);
				} else if (field?.data_type_name === 'Info' && field?.options?.list?.length > 0 && field?.options?.component_id?.length > 0) {
					const found = components.find(c => c.id === field?.value);
					const obj = {};
					if (found) {
						field?.options?.list.map((f, index) => {
							if (found.component_fields.length == 0) return <div key={index} />;
							const comp = found.component_fields.find(cf => cf.field_name === f);
							if (comp?.data_type_name === 'Link' && (comp?.value?.includes('jpg') || comp?.value?.includes('jpeg') || comp?.value?.includes('png'))) {
								obj[f] = (
									<div onClick={() => window.open(comp?.value, '_blank')}>
										<img alt="lg" src={comp?.value} style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
									</div>
								);
							} else {
								obj[f] = comp?.value ?? '';
							}
						});
					}
					return (
						found &&
						(field?.options?.orientation === 'rows' ? (
							<FieldRow style={{ border: '0' }} width={'100%'} key={index} fieldLabel={field.field_name} fieldIcon={field.icon} isProcess visible={true} mandatory={field.mandatory} error={field.errors} notes={field?.options?.notes}>
								{/* <div key={index} className={cls.filtersContainer} style={{ color: 'var(--app-color-gray-dark)', height: 'inherit', width: '100%', display: field?.visible ? '' : 'none', border: '0' }}> */}
								<CustomTemplateModuleList className={cls.mobileTable_ii} style={{ width: isMobile ? '100%' : '100%' }} tableHostSimple={cls.tableHostSimple} columns={field?.options?.list ?? []} list={[obj]} />
								{/* </div> */}
							</FieldRow>
						) : (
							<div key={index} className={cls.filtersContainer} style={{ color: 'var(--app-color-gray-dark)', height: 'inherit', width: isMobile ? '100%' : '32%', display: field?.visible ? '' : 'none' }}>
								{'Additional Information'}
								<hr style={{ borderTop: '1px solid #D2D2D2' }} />
								{Object.keys(obj).length > 0 &&
									field?.options?.list.map((f, index) => {
										if (found.component_fields.length == 0) return <div key={index} />;
										const comp = found.component_fields.find(cf => cf.field_name === f);
										return (
											<FieldRow style={{ border: '0' }} key={index} fieldLabel={f} fieldIcon={field.icon} isProcess visible={true} mandatory={field.mandatory} error={field.errors} notes={field?.options?.notes}>
												{comp?.data_type_name === 'Link' && (comp?.value?.includes('jpg') || comp?.value?.includes('jpeg') || comp?.value?.includes('png')) ? (
													<div onClick={() => window.open(comp?.value, '_blank')}>
														<img alt="lg" src={comp?.value} style={{ width: '50%', height: '100%', cursor: 'pointer' }} />
													</div>
												) : field?.value?.includes('pdf') ? (
													<div style={{ width: 'inherit' }} onClick={() => window.open(comp?.value, '_blank')}>
														<embed src={`${comp?.value}#toolbar=0&navpanes=0&scrollbar=0&zoom=100%`} width="100%" height="1000px" />
													</div>
												) : comp?.value?.length < 50 || comp === undefined ? (
													<input
														onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
														type={field.data_type}
														value={comp ? comp.value : ''}
														autoComplete="none"
														className={cls.fieldTypeInput}
														name={field.field_name}
														disabled
														style={{ border: '1px dashed rgba(234, 231, 231, 0.91)', height: '40px', fontSize: '18px', backgroundColor: 'rgba(234, 231, 231, 0.91)' }}
													/>
												) : (
													<textarea
														className={cls.fieldTypeTextarea}
														type={field.data_type}
														value={comp ? comp.value : ''}
														onFocus={() => { }}
														autoComplete="none"
														onChange={e => { }}
														disabled
														style={{ backgroundColor: 'rgba(234, 231, 231, 0.91)', height: 'auto', fontSize: '18px' }}
													/>
												)}
											</FieldRow>
										);
									})}
								{component.options?.allowMultipleInputs === true &&
									begunModule.processModule.module.options?.computes?.map((rule, index) => {
										const componentR = components?.filter(com => com.module_id === rule.module_id)?.find(com => com.component_fields.filter(cf => cf.value === (found.component_fields.find(cf => cf.field_name === field?.options?.key)?.value ?? '#$')).length > 0);
										const fieldR = componentR?.component_fields.find(cf => cf.procedure_step_blueprint_field_id === rule.field_id);

										return (
											<FieldRow style={{ display: fieldR ? '' : 'none', border: '0' }} key={index} fieldLabel={fieldR?.field_name} fieldIcon={field.icon} isProcess visible={true} mandatory={field.mandatory} error={field.errors} notes={field?.options?.notes}>
												<input
													onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
													type={field.data_type}
													value={fieldR?.value}
													autoComplete="none"
													className={cls.fieldTypeInput}
													name={field.field_name}
													disabled
													style={{ border: '1px dashed rgba(234, 231, 231, 0.91)', height: '40px', fontSize: '18px', backgroundColor: 'rgba(234, 231, 231, 0.91)' }}
												/>
											</FieldRow>
										);
									})}
							</div>
						))
					);
				} else if (field?.data_type_name === 'Calendar') {
					return (
						<FieldRow
							enabled={enabled}
							width={'100%'}
							style={{ width: '100%' }}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							notes={field?.options?.notes}
						>
							<div id={field.field_name} style={{ width: 'inherit' }}>
								<Button
									light
									clicked={() => {
										setLoading(true);
										getAllTasksCreatedByUser(selectedComponent?.created_by?.id ?? authenticatedUser.id, true)
											.pipe(take(1))
											.subscribe({
												next: () => {

													setLoading(false);
												}
											});
									}}
									loading={loading}
									icon={<img src={reset2} />}
								/>
								{!(taskModalVisible || loading) && <Scheduler
									events={allTasks.length > 0 ? allTasks?.filter(task => task?.add_info?.module_id === begunModule.processModule?.module_id)?.map(task => {
										return {
											event_id: task.id,
											title: task.description,
											start: new Date(Date.parse(task.add_info?.start)),
											end: task?.completed_at ? new Date(Date.parse(task.completed_at)) : new Date(Date.parse(task.add_info?.end)),
											admin_id: 2,
											color: "#50b500",
											editable: false,
											deletable: false,
										}
									}) : []}
									navigation={true}
									view={calendarView}
									agenda={selectedComponent?.created_by?.id === authenticatedUser.id ? false : selectedComponent?.created_by?.id ? true : false}
									day={{
										startHour: 0,
										endHour: 24,
										cellRenderer: ({ height, start, onClick, ...props }) => {
											const disabled = false;
											const restProps = disabled ? {} : props;
											return (
												<Button
													style={{
														height: "100%",
														background: disabled ? "#eee" : "transparent",
														cursor: disabled ? "not-allowed" : "pointer",
													}}
													clicked={() => {
														setTaskModalVisible(true);
														setTargetTask(undefined);
													}}
													disabled={disabled}
													{...restProps}
												></Button>
											);
										}
									}}
									month={(selectedComponent?.created_by?.id !== authenticatedUser.id && selectedComponent?.created_by !== undefined) ? null : {
										cellRenderer: ({ height, start, onClick, ...props }) => {
											const disabled = false;
											const restProps = disabled ? {} : props;
											return (
												<Button
													style={{
														height: "100%",
														background: disabled ? "#eee" : "transparent",
														cursor: disabled ? "not-allowed" : "pointer",
													}}
													clicked={() => {
														console.log('start', start)
														setTaskModalVisible(true);
														setTargetTask(undefined);
													}}
													disabled={disabled}
													{...restProps}
												></Button>
											);
										}
									}}
									week={(selectedComponent?.created_by?.id !== authenticatedUser.id && selectedComponent?.created_by !== undefined) ? null : {
										weekDays: [0, 1, 2, 3, 4, 5],
										weekStartOn: 6,
										startHour: 0,
										endHour: 25,
										step: 60,
										cellRenderer: ({ height, start, onClick, ...props }) => {
											const disabled = false;
											const restProps = disabled ? {} : props;
											return (
												<Button
													style={{
														height: "100%",
														background: disabled ? "#eee" : "transparent",
														cursor: disabled ? "not-allowed" : "pointer",
													}}
													clicked={() => {
														setDate(start);
														setTaskModalVisible(true);
														setTargetTask(undefined);
													}}
													disabled={disabled}
													{...restProps}
												></Button>
											);
										}
									}}
									onEventClick={(event) => {
										setTaskModalVisible(true);
										setTargetTask(allTasks?.find(task => task.id === event.event_id));
									}}
									onViewChange={view => setCalendarView(view)}
								/>}
								{taskModalVisible && <TaskModal
									modalVisible={taskModalVisible}
									heading={field?.options?.title ? field?.options?.title : targetTask ? 'Update Calendar' : 'Create New Calendar'}
									task={targetTask}
									isProcess={true}
									blured={false}
									onClose={() => setTaskModalVisible(false)}
									isEdit={targetTask ? true : false}
									statuses={field?.options?.list}
									priority={field?.options?.priority}
									span={field?.options?.span}
									start_date={date}
								/>}
							</div>
						</FieldRow>);
				} else if (field?.data_type_name === 'Lookup' && field?.options?.list?.length > 0 && field?.options?.source_id?.length > 0 && field?.options?.target_id?.length) {
					const items = componentsToRenderV2(field?.options?.global, getMultipleLookupsv2(field?.options?.source_id, field?.options?.common_field, field?.options?.target_id, field?.options?.sourceLabel, field?.options?.list, field?.options?.rlist ?? []))
					return (
						<FieldRow
							enabled={enabled}
							width={field?.options?.selection === 'Tiles' ? '50%' : isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							style={{ border: field?.options?.selection === 'Tiles' ? '0' : '' }}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							notes={field?.options?.notes}
						>
							<div id={field.field_name} style={{ width: '100%', border: field?.options?.selection === 'Dropdown' ? '1px solid var(--app-color-gray-light)' : 'none' }}>
								{field?.options?.selection === 'Dropdown' ? (
									<SingleSelectWithCheckboxesAndNestedItems
										items={items}
										groupedBy={'grouping'}
										groupname={'grouping'}
										triggerType={'chevron'}
										title={dropDownV}
										className={cls.dropdownClassContainer}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										notags={'true'}
										onChange={selection => {
											if (selection.length > 0) {
												field?.options?.list.map(label => {
													const idx = checkIfFieldComponentExists({
														field_name: label,
													});
													if (idx != -1) {
														const foundTargetComponent = components.find(c => c.id === selection[0].value);
														const value = foundTargetComponent.component_fields.find(cf => cf.field_name === label);
														if (value == undefined) {
															const common_field = foundTargetComponent.component_fields.find(cf => cf.field_name === field?.options?.common_field);
															const source_component = components.find(c => c.component_fields.filter(cf => cf.value === common_field.value && cf.component_blueprint_id === field?.options?.source_id).length > 0);
															if (source_component != undefined) {
																const value = source_component.component_fields.find(cf => cf.field_name === label);
																if (value != undefined) {
																	component.selected_fields[idx].value = value.value;
																	component.selected_fields[idx].disabled = true;
																} else {
																	component.selected_fields[idx].value = '';
																}
															} else {
																component.selected_fields[idx].value = '';
															}
														} else {
															component.selected_fields[idx].value = value.value;
															component.selected_fields[idx].disabled = true;
														}
													}
												});
												handleCheckbox(`${selection[0].label},${selection[0].value}`, index);
												setDropdownV(`${selection[0].grouping}`);
											}
										}}
									/>
								) : field?.options?.selection === 'Tiles' ? (
									<SingleSelectWithTilesAndNestedItems
										items={items}
										columns={field?.options?.rlist}
										columnsG={field?.options?.sourceLabel ?? []}
										initialSelected={field?.value}
										selectedItems={field?.options?.showAll ? items : selectedItems}
										showAll={field?.options?.showAll == true}
										groupedBy={'grouping'}
										groupname={'grouping'}
										className={cls.peoplePositionsSelect}
										onGroupChange={value => {
											setSelectedItems(items.filter(i => i.grouping === value));
										}}
										onChange={selection => {
											if (selection) {
												field?.options?.list.map(label => {
													const idx = checkIfFieldComponentExists({
														field_name: label,
													});
													if (idx != -1) {
														const foundTargetComponent = components.find(c => c.id === selection.value);
														const value = foundTargetComponent.component_fields.find(cf => cf.field_name === label);
														if (value == undefined) {
															const common_field = foundTargetComponent.component_fields.find(cf => cf.field_name === field?.options?.common_field);
															const source_component = components.find(c => c.component_fields.filter(cf => cf.value === common_field.value && cf.component_blueprint_id === field?.options?.source_id).length > 0);
															if (source_component != undefined) {
																const value = source_component.component_fields.find(cf => cf.field_name === label);
																if (value != undefined) {
																	component.selected_fields[idx].value = value.value;
																	component.selected_fields[idx].disabled = true;
																} else {
																	component.selected_fields[idx].value = '';
																}
															} else {
																component.selected_fields[idx].value = '';
															}
														} else {
															component.selected_fields[idx].value = value.value;
															component.selected_fields[idx].disabled = true;
														}
													}
												});
												handleCheckbox(`${selection.label},${selection.value}`, index);
											}
										}}
									/>
								) : (
									<div className={cls.openSelectWithSearchHost}>
										<OpenSelectWithSearch
											// onClick={() => (document.getElementById(field.field_name).style.height = `${items.length * 160}px`)}
											// onBlur={() => (document.getElementById(field.field_name).style.height = `80px`)}
											title={field?.options?.sourceLabel}
											triggerType={'eyeglass'}
											className={cls.openSelectWithSearch}
											molclassname={cls.menuOverlayList}
											moliclassname={cls.menuOverlayListItem}
											inputClassName={cls.menuOverlayListItemInput}
											inputContainerClassName={cls.menuOverlayListItemInputContainerClassName}
											items={R.uniqWith(R.eqProps('grouping'))(items).map(it => {
												return { label: it.grouping, value: it.grouping };
											})}
											onChange={value => {
												document.getElementById(field.field_name).style.height = `80px`;
												setSelectedItems(items.filter(i => i.grouping === value.value));
											}}
										/>
										<OpenSelectWithSearch
											title={field?.options?.referenceLabel}
											triggerType={'eyeglass'}
											className={cls.openSelectWithSearch}
											molclassname={cls.menuOverlayList}
											moliclassname={cls.menuOverlayListItem}
											inputClassName={cls.menuOverlayListItemInput}
											inputContainerClassName={cls.menuOverlayListItemInputContainerClassName}
											items={selectedItems}
											onChange={selection => {
												document.getElementById(field.field_name).style.height = `80px`;
												if (selection) {
													field?.options?.list.map(label => {
														const idx = checkIfFieldComponentExists({
															field_name: label,
														});
														if (idx != -1) {
															const foundTargetComponent = components.find(c => c.id === selection.value);
															const value = foundTargetComponent.component_fields.find(cf => cf.field_name === label);
															if (value == undefined) {
																const common_field = foundTargetComponent.component_fields.find(cf => cf.field_name === field?.options?.common_field);
																const source_component = components.find(c => c.component_fields.filter(cf => cf.value === common_field.value && cf.component_blueprint_id === field?.options?.source_id).length > 0);
																if (source_component != undefined) {
																	const value = source_component.component_fields.find(cf => cf.field_name === label);
																	if (value != undefined) {
																		component.selected_fields[idx].value = value.value;
																		component.selected_fields[idx].disabled = true;
																	} else {
																		component.selected_fields[idx].value = '';
																	}
																} else {
																	component.selected_fields[idx].value = '';
																}
															} else {
																component.selected_fields[idx].value = value.value;
																component.selected_fields[idx].disabled = true;
															}
														}
													});

													handleCheckbox(`${selection.label},${selection.value}`, index);
												}
											}}
										/>
									</div>
								)}
							</div>
						</FieldRow>
					);
				} else if ((field?.data_type_name === 'Step List' || field?.data_type_name === 'Component List') && field?.options?.list?.length > 0 && field?.options?.component_id?.length > 0) {
					return (
						<div key={index} style={{ width: isMobile ? '100%' : '70%', display: field.visible == true ? '' : 'none' }}>
							<ModuleLists
								columns={field?.options?.list}
								list={getComponentsForListing(field?.options)}
								onListItemClick={item => {
									setTaskProcessContext({ action: processTaskAction.removeModuleComponents, value: item.temp_id });
								}}
							/>
						</div>
					);
				} else if (field?.data_type_name === 'Checkbox') {
					return (
						<FieldRow
							enabled={enabled}
							border={{ border: '0', paddingTop: '40px' }}
							notes={field?.options?.notes}
							editMode={editMode}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							<Checkbox
								defaultChecked={field.value ?? false}
								checkboxLabel={field?.options?.value}
								onChange={e => {
									handleCheckbox(e.target.checked, index);
								}}
							/>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Transaction List' && field?.options?.map) {
					const orderedObj = {
						item_code: null,
						quantity: null,
						value: null,
						alt_value: null,
						total: null,
					};

					const columnsObj = Object.entries(Object.assign(orderedObj, field.options['map'])).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});

					let footerObj = Object.entries(Object.assign(orderedObj, field.options['map'])).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});

					Object.keys(columnsObj).map(key => {
						if (key === 'item_code') {
							footerObj.item_code = 'Totals:';
						} else {
							if (key === 'quantity') {
								footerObj[key] = `${R.reduce((a, b) => +a + +b[key] ?? 0, 0, processTaskContext?.items ?? [])}`;
							} else if (key === 'alt_value') {
								footerObj[key] = ``;
							} else if (key === 'value') {
								footerObj[key] = ``;
							} else if (key === 'total') {
								footerObj[key] = ``;
							}
						}
					});

					return (
						<div key={index} style={{ width: 'inherit', marginBottom: '50px' }}>
							<FieldRow
								enabled={enabled}
								width={field?.field_options?.width ?? 'inherit'}
								editMode={editMode}
								showWidth={width => {
									field.field_options = { ...field.field_options, width: `${width}px` };
								}}
								style={{ border: '0', padding: '0' }}
								key={index}
								visible={field.visible}
								notes={field?.options?.notes}
								error={field.errors?.length > 0 ? `${field.errors}` : ''}
							>
								<CustomTemplateModuleList
									key={index}
									style={{ width: Object.values(field.options['map']).length > 2 ? '100%' : '100%', marginBottom: '100px' }}
									title={isMobile ? '' : field.field_name}
									tableHostSimple={isMobile ? cls.tableHostMobile : cls.itemsContainer}
									columns={Object.values(columnsObj)}
									footer={field?.options?.footer ? Object.values(footerObj) : []}
									list={getItemsForListing(field.options)}
									onListItemClick={item => {
										setTaskProcessContext({ action: processTaskAction.removeModuleItems, value: item.item });
									}}
								/>
							</FieldRow>
						</div>
					);
				} else if (field?.data_type_name === 'ID' && field?.options?.id?.length > 0) {
					const id = (field.value === undefined || field.value === null) ? `${field?.options.id}${companyData?.misc_info === null ? 1 : companyData?.misc_info[field?.field_name] ?? 1}` : field.value;
					field.value = id;
					processTaskContext.id = id;
					return (
						<FieldRow
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							notes={field?.options?.notes}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							<input onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)} type={field.data_type} autoComplete="none" value={id} className={cls.fieldTypeID} name={field.field_name} disabled />
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Notes' && field?.options?.value?.length > 0) {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : field?.field_options?.width ?? '80%'}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							style={{ border: '0' }}
							key={index}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={false}
							error={field.errors}
						>
							<label key={index} className={cls.labelInput} style={{ display: field.visible ? '' : 'none' }}>
								{`${field.field_name}: ${field?.options?.value}`}
							</label>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'List Filter' && field?.options?.value?.length > 0) {
					return <></>;
				} else if (field?.data_type_name === 'Value' && field?.options?.module_id?.length > 0 && field?.options?.component_id?.length > 0) {
					return (
						<FieldRow
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							notes={field?.options?.notes}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							<input value={field?.options?.value} className={cls.fieldTypeInput} name={field.field_name} disabled />
						</FieldRow>
					);
				} else if (field.data_type_name === 'URL') {
					return (
						<FieldRow
							border={{ border: '0' }}
							width={isMobile ? '100%' : field?.field_options?.width ?? '80%'}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							notes={field?.options?.notes}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							{field?.options.value?.includes('jpg') || field?.options.value?.includes('jpeg') || field?.options.value?.includes('png') ? (
								<div onClick={() => window.open(field?.options.value, '_blank')}>
									<img alt="lg" src={field?.options.value} style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
								</div>
							) : field?.options.value?.includes('pdf') ? (
								<div style={{ width: 'inherit' }} onClick={() => window.open(field?.options.value, '_blank')}>
									{/* <object width="100%" height='1000px' data={field?.options.value} type="application/pdf">   </object> */}
									<embed src={`${field?.options.value}#toolbar=0&navpanes=0&scrollbar=0&zoom=100%`} width="100%" height="1000px" />
								</div>
							) : (
								<div style={{ width: 'inherit' }} onClick={() => window.open(field?.options.value, '_blank')}>
									<textarea disabled={true} onChange={() => { }} autoComplete="none" value={field?.options.value === null ? '' : field?.options.value} className={cls.fieldTypeInput} style={{ height: 'auto', width: 'inherit', cursor: 'pointer' }} name={field.field_name} />
								</div>
							)}
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Formula' && field?.options?.operator?.length > 0) {
					const value = computeFormula(
						field?.options,
						component.selected_fields.filter(x => field.options.list.includes(x.field_name)),
						processTaskContext.components,
						processTaskContext.items
					);
					if (value !== undefined) {
						field.value = value;
					}
					return (
						<FieldRow
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							<input value={value ? value : 0} className={cls.fieldTypeInput} name={field.field_name} disabled style={{ border: '1px solid var(--app-color-gray-light)' }} />
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Dropdown' && field?.options?.list?.length > 0) {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-light)' }}>
								<SelectAndMultiselect
									key={index}
									visible={field.visible}
									mandatory={field.mandatory}
									items={field?.options?.list.map(elem => {
										return { label: elem, value: elem };
									})}
									className={cls.dropdownClassContainer}
									error={field.errors}
									onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
									onChange={selection => {
										setBottomMargin('50px');
										handleCheckbox(selection, index);
									}}
									selectionType={field?.options?.type}
									fieldIcon={field.icon}
									value={field.value === undefined ? '' : field.value === null ? '' : field.value}
									placeholder={'Click to select option...'}
								/>
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Dropdown' && field?.options?.type === 'modules') {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-light)' }}>
								<SelectAndMultiselect
									key={index}
									visible={field.visible}
									mandatory={field.mandatory}
									items={allModules.map(elem => {
										return { label: elem.name, value: elem.id };
									})}

									className={cls.dropdownClassContainer}
									error={field.errors}
									onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
									onChange={selection => {
										setBottomMargin('50px');
										handleCheckbox(selection, index);
									}}
									selectionType={field?.options?.type}
									fieldIcon={field.icon}
									value={field.value === undefined ? '' : field.value === null ? '' : allModules.map(elem => {
										return { label: elem.name, value: elem.id };
									}).find(mod => mod.value === field.value)?.label}
									placeholder={'Click to select option...'}
								/>
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Payments' && field?.options?.list?.length > 0) {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={true}
							mandatory={field.mandatory}
							error={errors[index]}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-dark)' }}>
								{!selectedComponent?.payments?.stripe_id ? (
									<SelectAndMultiselect
										key={index}
										visible={field.visible}
										mandatory={field.mandatory}
										items={
											begunModule.processModule?.module?.options?.credits === true ?
												[...field?.options?.list.map(elem => {
													return { label: elem, value: elem };
												}) ?? [], { label: 'Wallet', value: 'Wallet' }] :
												field?.options?.list.map(elem => {
													return { label: elem, value: elem };
												})
										}
										// items={field?.options?.list.map(elem => {
										// 	return { label: elem, value: elem };
										// })}
										className={cls.dropdownClassContainer}
										error={field.errors}
										onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
										onChange={selection => {
											if (selection) {
												const found = processTaskContext.newStepComponents.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === field?.options?.paymentField).length > 0)?.selected_fields?.find(sf => sf.field_name === field?.options?.paymentField);
												const found2 = processTaskContext.components.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === field?.options?.paymentField).length > 0)?.selected_fields?.find(sf => sf.field_name === field?.options?.paymentField);

												errors[index] = '';

												if (+(found?.value ?? 0) > 0) {
													processTaskContext.total = found.value;
													processTaskContext.plan = found.value;
												} else if (+(found2?.value ?? 0) > 0) {
													processTaskContext.total = found2.value;
													processTaskContext.plan = found2.value;
												} else {
													errors[index] = 'Please enter a payment amount in the designated field';
													setErrors([]);
													setTimeout(() => setErrors(errors));
													return;
												}

												if (begunModule.processModule?.module?.options?.credits == true && selection === 'Wallet') {
													if (+(authenticatedUser.options?.credit ?? 0) < +processTaskContext.total) {
														errors[index] = 'Insufficient wallet balance';
														setErrors([]);
														setTimeout(() => setErrors(errors));
														return;
													}
												}
												setErrors([]);
												setTimeout(() => setErrors(errors));
												setBottomMargin('50px');
												handleCheckbox(selection, index);
											}
										}}
										selectionType={'Dropdown'}
										fieldIcon={field.icon}
										value={field.value === undefined ? '' : field.value === null ? '' : field.value}
										placeholder={''}
									/>
								) : (
									<div style={{ padding: '10px 5px 10px 5px', width: '100%', border: '1px solid var(--app-color-gray-lighter)' }}>{`${field?.value}, PID: ${selectedComponent?.payments?.stripe_id.slice(0, 6)}`}</div>
								)}
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Positions') {
					return (
						<FieldRow
							enabled={enabled}
							style={{ height: `${field?.value?.split(',').length > 2 ? '160' : '45'}px`, marginBottom: '50px' }}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							notes={field?.options?.notes}
						>
							<div style={{ width: '100%' }}>
								<SelectAndMultiselect
									onFocus={() => companyPositionsArray.length == 0 && retrievePositions()}
									onOpen={open => {
										setBottomMargin(bottomMargin === '50px' ? '180px' : '50px');
									}}
									key={index}
									visible={field?.visible}
									mandatory={field?.mandatory}
									className={cls.dropdownClassContainer}
									items={companyPositionsArray}
									value={field.value}
									loading={loading}
									error={field?.errors}
									onChange={selection => {
										handleCheckbox(selection, index);
									}}
									selectionType={'multiple'}
									fieldIcon={field?.icon}
									placeholder={''}
								/>
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'People' && field?.options?.position_id?.length > 0 && field?.options?.slider === true) {
					return (
						<FieldRow
							enabled={enabled}
							style={{ border: '0', alignItems: 'center' }}
							width={'100%'}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							dragEnd={rect => {
								const location = document.getElementById(component?.name)?.getBoundingClientRect();
								document.getElementById(field.field_name).style.position = `absolute`;
								document.getElementById(field.field_name).style.top = `${rect.top - location.top}px`;
								document.getElementById(field.field_name).style.left = `${rect.left - location.left}px`;
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<Swiper
								slidesPerView={1}
								centeredSlides={true}
								spaceBetween={10}
								initialSlide={1}
								pagination={{
									clickable: true,
									dynamicBullets: true,
								}}

								modules={[Autoplay, Pagination]}
								className="mySwiper"
							//style={{ marginLeft: '-10px' }}
							>
								{
									users.filter(usr => usr?.user?.positions?.filter(pos => field?.options?.position_id?.includes(pos?.id)).length > 0).map((user, index) => {

										const usr = companyUsersArray.find(u => u.id === user.value)
										const ratings = usr?.ratings?.map(rate => {
											return +rate.ratings;
										});

										return (
											<SwiperSlide key={index} className={cls.swiperslide}>
												<UserCard
													photo={usr?.photo}
													height={'300px'}
													width={'100%'}
													firstName={`${user.label}`}
													position={`${usr?.phone ?? ''}`}
													email={`${usr?.email ?? ''}`}

												>

													{ratings?.length > 0 &&
														<div className={cls.centerDiv}>

															{Array(Math.round(R.converge(R.divide, [R.sum, R.length])(ratings)))
																.fill(0)
																.map((obj, index) => {
																	return <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'var(--app-color-blue)' }} />;
																})}
															{Array(5 - Math.round(R.converge(R.divide, [R.sum, R.length])(ratings)))
																.fill(0)
																.map((obj, index) => {
																	return <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />;
																})}


														</div>
													}

												</UserCard>
											</SwiperSlide>
										);
									})}

							</Swiper>




						</FieldRow>
					);
				} else if (field?.data_type_name === 'People' && field?.options?.position_id?.length > 0) {
					return (
						<FieldRow
							enabled={enabled}
							style={{ height: `${field?.value?.split(',').length > 2 ? '160' : '45'}px`, marginBottom: '50px' }}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							dragEnd={rect => {
								const location = document.getElementById(component?.name)?.getBoundingClientRect();
								document.getElementById(field.field_name).style.position = `absolute`;
								document.getElementById(field.field_name).style.top = `${rect.top - location.top}px`;
								document.getElementById(field.field_name).style.left = `${rect.left - location.left}px`;
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-light)' }}>
								<SelectAndMultiselect
									onOpen={open => {
										setBottomMargin(bottomMargin === '50px' ? '180px' : '50px');
									}}
									onFocus={() => users.length == 0 && retrieveUsers(field?.options?.position_id)}
									key={index}
									visible={field?.visible}
									mandatory={field?.mandatory}
									className={cls.dropdownClassContainer}
									items={users.filter(usr => usr?.user?.positions?.filter(pos => field?.options?.position_id?.includes(pos?.id)).length > 0)}
									value={field?.options?.type === 'single' ? users.find(usr => usr.label === field?.value)?.label : field.value}
									loading={loading}
									error={field?.errors}
									onChange={selection => {
										if (field?.options?.type === 'single') {
											const us = users.find(usr => usr.value === selection);
											if (field?.options?.email == true) {
												field.email = us?.user?.email;
											}
											handleCheckbox(us?.label, index);
										} else {
											handleCheckbox(selection, index);
										}

										setBottomMargin('50px');
									}}
									onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
									selectionType={field?.options?.type}
									fieldIcon={field?.icon}
									placeholder={'Select user...'}
								/>
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'People' && field?.options?.componentF?.length > 0 && field?.options?.componentO?.length > 0) {
					const value = processTaskContext.components.find(comp => comp.selected_fields.filter(sf => sf.field_name === field?.options?.componentF).length > 0)?.selected_fields.find(sf => sf.field_name === field?.options?.componentF);
					const found = components.find(com => com.component_fields.filter(cf => cf.value === value?.value).length > 0 && com.module_id === field?.options?.module_id);
					const position = found?.component_fields.find(cf => cf.field_name === field?.options?.componentO);
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-light)' }}>
								<SelectAndMultiselect
									onFocus={() => {
										setBottomMargin('180px');
										users.length == 0 && retrieveUsers(field?.options?.position_id);
									}}
									key={index}
									visible={field?.visible}
									mandatory={field?.mandatory}
									className={field?.options?.type === 'multiple' ? cls.dropdownHeightClassContainer : cls.dropdownClassContainer}
									items={users.filter(usr => usr?.user?.positions?.filter(pos => position?.value?.split(',').includes(pos?.name)).length > 0)}
									value={field?.options?.type === 'single' ? users.find(usr => usr.label === field?.value)?.label : field.value}
									loading={loading}
									error={field?.errors}
									onChange={selection => {
										if (field?.options?.type === 'single') {
											const us = users.find(usr => usr.value === selection);
											if (field?.options?.email == true) {
												field.email = us?.user?.email;
											}
											handleCheckbox(us?.label, index);
										} else {
											handleCheckbox(selection, index);
										}

										setBottomMargin('50px');
									}}
									onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
									selectionType={field?.options?.type}
									fieldIcon={field?.icon}
									placeholder={'Select user...'}
								/>
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'People' && field?.options?.componentF?.length > 0 && field?.options?.componentUO?.length > 0) {
					const value = processTaskContext.components.find(comp => comp.selected_fields.filter(sf => sf.field_name === field?.options?.componentF).length > 0)?.selected_fields.find(sf => sf.field_name === field?.options?.componentF);
					const found = components.filter(com => com.module_id === field?.options?.module_id).find(com => com.component_fields.filter(cf => cf?.value === value?.value).length > 0);
					const object = found ? found?.component_fields.find(cf => cf.field_name === field?.options?.componentUO) : '';
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ width: '100%', border: '1px solid var(--app-color-gray-light)' }}>
								<SelectAndMultiselect
									onFocus={() => users.length == 0 && retrieveUsers(field?.options?.position_id)}
									key={index}
									visible={field?.visible}
									mandatory={field?.mandatory}
									className={field?.options?.type === 'multiple' ? cls.dropdownHeightClassContainer : cls.dropdownClassContainer}
									items={users.filter(usr => object?.value?.split(',').includes(usr?.label))}
									value={field?.options?.type === 'single' ? users.find(usr => usr.label === field?.value)?.label : field.value}
									loading={loading}
									error={field?.errors}
									onChange={selection => {
										if (field?.options?.type === 'single') {
											const us = users.find(usr => usr.value === selection);
											if (field?.options?.email == true) {
												field.email = us?.user?.email;
											}
											handleCheckbox(us?.label, index);
										} else {
											handleCheckbox(selection, index);
										}

										setBottomMargin('50px');
									}}
									onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
									selectionType={field?.options?.type}
									fieldIcon={field?.icon}
									placeholder={'Select user...'}
								/>
							</div>
						</FieldRow>
					);

				} else if (field?.data_type_name === 'Status') {
					let status_array =
						field.options?.list?.length > 0
							? field.options?.list?.map(st => {
								return { label: st, value: st };
							})
							: begunModule?.processModule?.module?.options?.asGraphical == true
								? [
									{ label: 'Show', value: 'Show' },
									{ label: 'Hide', value: 'Hide' },
								]
								: companyData?.misc_info?.status?.length > 0
									? companyData?.misc_info?.status
									: [
										{ label: 'Ongoing', value: 'Ongoing' },
										{ label: 'Pending', value: 'Pending' },
										{ label: 'Awaiting Payment', value: 'Awaiting Payment' },
										{ label: 'Awaiting Fulfillment', value: 'Awaiting Fulfillment' },
										{ label: 'Awaiting Shipment', value: 'Awaiting Shipment' },
										{ label: 'Awaiting Pickup', value: 'Awaiting Pickup' },
										{ label: 'Partially Shipped', value: 'Partially Shipped' },
										{ label: 'Completed', value: 'Completed' },
										{ label: 'Shipped', value: 'Shipped' },
										{ label: 'Cancelled', value: 'Cancelled' },
										{ label: 'Declined', value: 'Declined' },
										{ label: 'Refunded', value: 'Refunded' },
										{ label: 'Disputed', value: 'Disputed' },
										{ label: 'Done', value: 'Done' },
										{ label: 'Closed (Will lock Edit)', value: 'Closed' },
									];

					if (companyData?.misc_info?.rules?.length > 0) {
						companyData?.misc_info?.rules
							.filter(rule => rule.type === 'status')
							.filter(rule => {
								if (rule.valueCondition === field.value) {
									if (rule.allow === '0') {
										status_array = status_array.filter(status => rule.values.concat(field.options?.list).includes(status.value));
									} else {
										status_array = status_array.filter(status => !rule.values.concat(field.options?.list).includes(status.value));
									}
								}
							});
					}
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<SelectAndMultiselect
								key={index}
								visible={field.visible}
								mandatory={field.mandatory}
								value={field.value ?? ''}
								items={status_array}
								error={field.errors}
								className={cls.dropdownSelectComponent}
								onClicked={open => (open ? setBottomMargin('180px') : setBottomMargin('50px'))}
								onChange={selection => {
									companyData.start = true;
									setBottomMargin('50px');
									handleCheckbox(selection, index);
									const updateIfThereIsStatusDateField = component.selected_fields.find(sf => (sf.data_type_name === 'Date') & (sf.field_name === selection));
									if (updateIfThereIsStatusDateField) {
										updateIfThereIsStatusDateField.value = new Date(moment.now());
									}
								}}
								fieldIcon={field.icon}
								placeholder={'Select status...'}
							/>

						</FieldRow>
					);
				} else if (field?.data_type_name === 'Action Button' || field?.data_type_name === 'Ratings') {
					return <div key={index} />;
				} else if (field?.data_type_name === 'Profile' && field?.options?.type?.length > 0) {
					let user_id = authenticatedUser.id;
					if ((field.options.type === 'creator' || field.options.type === 'wallet') && selectedComponent?.created_by?.id) {
						user_id = selectedComponent.user_id;
					} else if ((field.options.type === 'creator' || field.options.type === 'wallet') && !selectedComponent?.created_by?.id) {
						user_id = authenticatedUser.id;
					} else if (field.options.type === 'owner') {
						user_id = selectedComponent?.options ? JSON.parse(selectedComponent?.options)?.owner : '';
					} else {
						return null;
					}

					return user_id?.length > 0
						? companyUsersArray?.length > 0 &&
						companyUsersArray
							?.filter(user => user.id === user_id)
							.map((user, index) => {
								return (
									<div className={cls.centerDiv} key={index}>
										{field.options.type === 'wallet' ? (
											<UserCard
												simple={true}
												height={'100px'}
												photo={user.photo}
												noPhoto={field?.options?.show == false}
												firstName={`${user?.first_name} ${user?.last_name}`}
												lastName={`(${(user.options?.credit ?? 0)?.toFixed(2)})`}
												position={user?.positions?.slice(0, 1)?.map(pos => {
													return pos.name;
												})}
												email={user?.email}
												cardClicked={() => { }}
												width={isMobile ? '100%' : ''}
											/>
										) : (
											<UserCard
												photo={user.photo}
												noPhoto={field?.options?.show == false}
												firstName={`${user?.first_name} ${user?.last_name}`}
												lastName={`${user?.last_name}`}
												position={user?.positions?.slice(0, 1)?.map(pos => {
													return pos.name;
												})}
												email={user?.email}
												cardClicked={() => { }}
												width={isMobile ? '100%' : ''}
											/>
										)}
									</div>
								);
							})
						: null;
				} else if (field?.data_type_name === 'Summary') {
					let obj = {};
					processTaskContext.components.map(co =>
						co.selected_fields
							.filter(sf => sf.data_type_name !== 'Pin' && sf.visible == true)
							.map(sf => {
								obj[sf.field_name] = `${sf?.value ?? ''}`;
							})
					);

					return (
						<FieldRow enabled={enabled} width={isMobile ? '100%' : '60%'} editMode={editMode} key={index} fieldLabel={field.field_name} notes={field?.options?.notes} fieldIcon={field.icon} isProcess visible={field.visible} mandatory={field.mandatory} error={field.errors}>
							<div key={index} style={{ width: '100%' }}>
								<ModuleLists modern={true} columns={Object.keys(obj)} list={[obj]} />
							</div>
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Pin' && field?.options?.pintype?.length > 0) {
					return (
						<FieldRow width={isMobile ? '100%' : '50%'} style={{ border: '0' }} enabled={enabled} key={index} fieldLabel={field.field_name} notes={field?.options?.notes} fieldIcon={field.icon} isProcess visible={field.visible} mandatory={field.mandatory} error={field.errors}>
							{field?.options?.source || field.options?.destination ? (
								<div key={index} style={{ color: 'var(--app-color-gray-dark)', height: 'inherit', width: 'inherit', display: field?.visible ? '' : 'none' }}>
									<div key={index} className={cls.filtersContainer} style={{ color: 'var(--app-color-gray-dark)', height: 'inherit', width: '100%', display: field?.visible ? '' : 'none' }}>
										<div
											className={cls.actionsContainer}
											style={{ fontSize: '12px' }}
											onClick={() => {
												setModalOpen({ source: true, destination: false });
											}}
										>
											<div style={{ width: '100px' }}>{field?.options?.destination == true ? 'From' : 'Pin'}:</div>

											<div>{field?.value ? JSON.parse(field.value)?.source?.address : ''}</div>
										</div>
										{field?.options?.destination == true && <hr style={{ borderTop: '0.5px solid #D2D2D2' }} />}
										{field?.options?.destination == true && <div
											className={cls.actionsContainer}
											style={{ fontSize: '12px' }}
											onClick={() => {
												setModalOpen({ source: false, destination: true });
											}}
										>
											<div style={{ width: '100px' }}>{'To'}:</div>
											<div>{field?.value ? JSON.parse(field.value)?.destination?.address : ''}</div>
										</div>}
									</div>

									<div style={{ display: field.options?.source ? 'flex' : 'none', width: '100%' }}>
										<Button
											icon={<FontAwesomeIcon icon={faCircleDot} style={{ width: '20px', height: '20px' }} />}
											clicked={() => {
												setModalOpen({ source: true, destination: false });
											}}
											style={{ backgroundColor: 'blue', width: '100%' }}
											title={field?.options?.destination == true ? "Pick up from" : "Pin location"}
										/>
									</div>
									{field?.options?.destination == true && <div style={{ display: field.options?.destination ? 'flex' : 'none', width: '100%' }}>
										<Button
											icon={<FontAwesomeIcon icon={faLocationDot} style={{ width: '20px', height: '20px' }} />}
											clicked={() => {
												setModalOpen({ source: false, destination: true });
											}}
											style={{ backgroundColor: 'green', width: '100%' }}
											title={`Destination...`}
										/>
									</div>}

									{modalOpen?.source && (
										<ModalR blured={false} scrollable={true} isOpen={modalOpen.source} shadowedContainerStyle={{ width: isMobile ? '100%' : '50%' }} shadowedContainerClassname={cls.mappopup}>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												{'Pick up from:'}
												<MapPin
													key={index}
													value={field?.value ? JSON.parse(field?.value)?.source : {}}
													onChange={val => {
														const value = field?.value ? JSON.parse(field?.value) : { source: { lat: 0, lng: 0, address: '' }, destination: { lat: 0, lng: 0, address: '' } };

														let foundD = component?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');

														if (!foundD) {
															foundD = processTaskContext.newStepComponents?.find(co => co.selected_fields?.filter(sf => sf?.options?.mapToItem === 'distance').length > 0)?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');
															if (!foundD) {
																foundD = processTaskContext.components?.find(co => co.selected_fields?.filter(sf => sf?.options?.mapToItem === 'distance').length > 0)?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');
																if (!foundD) {
																	foundD = begunModule.processModule?.steps[begunModule.processModule?.stepIndex ?? 1].component_blueprints?.find(co => co.selected_fields?.filter(sf => sf?.options?.mapToItem === 'distance').length > 0)?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');
																}
															}
														}
														value.source.lat = val.geometry.location.lat;
														value.source.lng = val.geometry.location.lng;
														value.source.address = val.formatted_address;

														if (value?.source && field.options?.compute && field.options?.destination == false) {

															value.distance = getDistanceBetweenPoints(value.source.lat, value.source.lng, currentLocation.latitude, currentLocation.longitude);
															value.duration = 0;
															if (foundD) {
																foundD.value = value.distance;
																foundD.disabled = true;
															}

															handleInputChange(JSON.stringify(value), index);

														} else if (value.source?.address && field.options?.compute) {
															calculateRoute(value.source.address, value.destination.address)?.subscribe({
																next: routes => {
																	if (routes?.routes?.length > 0) {
																		const route = routes.routes[0];
																		if (route?.legs?.length > 0) {
																			value.distance = route.legs[0]?.distance?.value / 1000;
																			value.duration = route.legs[0]?.duration?.value;
																			value.steps = route.legs[0].steps;
																			if (foundD) {
																				foundD.value = value.distance;
																				foundD.disabled = true;
																			}
																		}
																	}
																},
																error: error => {
																	value.distance = getDistanceBetweenPoints(value.source.lat, value.source.lng, value.destination.lat, value.destination.lng);
																	value.duration = 0;
																	if (foundD) {
																		foundD.value = value.distance;
																		foundD.disabled = true;
																	}
																},
															});
														} else if (value.source?.address) {
															value.distance = getDistanceBetweenPoints(value.source.lat, value.source.lng, value.destination.lat, value.destination.lng);
															value.duration = 0;
															if (foundD) {
																foundD.value = value.distance;
																foundD.disabled = true;
															}
														}

														handleInputChange(JSON.stringify(value), index);
														setModalOpen({ source: false, destination: false });
													}}
													mapkey={companyData.misc_info?.map}
												/>
												<br />
												<Button clicked={() => setModalOpen({ source: false, destination: false })} title={'Close'} style={{ width: '80%', backgroundColor: 'orange' }} />
											</div>
										</ModalR>
									)}
									{modalOpen?.destination && (
										<ModalR blured={false} scrollable={true} isOpen={modalOpen.destination} shadowedContainerStyle={{ width: isMobile ? '100%' : '50%' }} shadowedContainerClassname={cls.mappopup}>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												{'Where to:'}
												<MapPin
													key={index}
													value={field?.value ? JSON.parse(field?.value)?.destination : {}}
													onChange={val => {
														const value = field?.value ? JSON.parse(field?.value) : { destination: { lat: 0, lng: 0, address: '' }, source: { lat: 0, lng: 0, address: '' } };
														let foundD = component?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');

														if (!foundD) {
															foundD = processTaskContext.newStepComponents?.find(co => co.selected_fields?.filter(sf => sf?.options?.mapToItem === 'distance').length > 0)?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');
															if (!foundD) {
																foundD = processTaskContext.components?.find(co => co.selected_fields?.filter(sf => sf?.options?.mapToItem === 'distance').length > 0)?.selected_fields?.find(sf => sf?.options?.mapToItem === 'distance');
															}
														}
														value.destination.lat = val.geometry.location.lat;
														value.destination.lng = val.geometry.location.lng;
														value.destination.address = val.formatted_address;
														if (value.destination?.address && field.options?.compute) {
															calculateRoute(value.source.address, value.destination.address)?.subscribe({
																next: routes => {
																	if (routes?.routes?.length > 0) {
																		const route = routes.routes[0];
																		if (route?.legs?.length > 0) {
																			value.distance = route.legs[0]?.distance?.value / 1000;
																			value.duration = route.legs[0]?.duration?.value;
																			value.steps = route.legs[0].steps;

																			if (foundD) {
																				foundD.value = value.distance;
																				foundD.disabled = true;
																			}
																		}
																	}
																},
																error: error => {
																	value.distance = getDistanceBetweenPoints(value.source.lat, value.source.lng, value.destination.lat, value.destination.lng);
																	value.duration = 0;
																	if (foundD) {
																		foundD.value = value.distance;
																		foundD.disabled = true;
																	}
																},
															});
														} else if (value.destination?.address) {
															value.distance = getDistanceBetweenPoints(value.source.lat, value.source.lng, value.destination.lat, value.destination.lng);
															value.duration = 0;
															if (foundD) {
																foundD.value = value.distance;
																foundD.disabled = true;
															}
														}

														handleInputChange(JSON.stringify(value), index);
														setModalOpen({ source: false, destination: false });
													}}
													mapkey={companyData.misc_info?.map}
												/>
												<br />
												<Button clicked={() => setModalOpen({ source: false, destination: false })} title={'Close'} style={{ width: '80%', backgroundColor: 'orange' }} />
											</div>
										</ModalR>
									)}
								</div>
							) : (
								<MapPin key={index} value={component?.selected_fields[index].value} onLocationChange={location => handleLocationChange(location, index)} mapkey={companyData.misc_info?.map} />
							)}
						</FieldRow>
					);
				} else if (field?.data_type_name === 'Map' && field?.options?.maptype?.length > 0) {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<MapRoutes key={index} value={component?.selected_fields[index].value} onLocationChange={location => handleLocationChange(location, index)} />
						</FieldRow>
					);
				} else if (field?.data_type_name === 'User Wallet') {
					const target = companyUsersArray?.find(user => user.id === selectedComponent?.created_by?.id) ?? authenticatedUser;
					return (
						<div key={index} style={{ width: isMobile ? '100%' : '30%', border: '1px dashed gray', padding: '20px', marginTop: '10px' }}>
							<UserCard
								simple={true}
								height={'100px'}
								photo={target.photo}
								firstName={`${target?.first_name} ${target?.last_name}`}
								lastName={`(${(target.options?.credit ?? 0)?.toFixed(2)})`}
								position={target?.positions?.slice(0, 1)?.map(pos => {
									return pos.name;
								})}
								email={target?.email}
								cardClicked={() => { }}
								width={isMobile ? '100%' : ''}
							/>
							<FieldRow enabled={enabled} editMode={editMode} key={index} fieldLabel={field.field_name} notes={field?.options?.notes} fieldIcon={field.icon} isProcess visible={field.visible} mandatory={field.mandatory} error={field.errors}>
								<input
									type={'number'}
									autoComplete="off"
									value={field?.value ?? 0}
									className={cls.currencyInput}
									style={{ border: '1px solid black' }}
									onChange={e => {
										handleInputChange(e.target.value, index);
									}}
								/>
							</FieldRow>
							<Button
								loading={loading}
								clear
								title={'Release amount'}
								style={{ color: 'white', backgroundColor: 'green', display: field.visible && field?.value ? '' : 'none' }}
								clicked={() => {
									setLoading(true);
									purchase({
										user_id: target.id,
										id: generateNewUUID(),
										total: field?.value,
										product: `Withdrawal`,
										notes: target.id,
										quantity: 1,
									})
										.pipe(first())
										.subscribe({
											next: () => {
												updateUserOptionsById(target, { ...target?.options, credit: +(+target?.options?.credit - field?.value) })
													.pipe(first())
													.subscribe({
														next: data => {
															target.options = data.options;
															field.visible = false;
															setLoading(false);
														},
													});
											},
										});
								}}
							/>
						</div>
					);
				} else if (field?.data_type_name === 'Currency' && field?.options?.currency_type) {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
						>
							<div style={{ border: '1px solid var(--app-color-gray-light)', width: 'inherit', display: 'flex' }}>
								<div onClick={() => setLoading(!loading)} className={cls.currencySymbol}>
									{field?.options?.symbol ? field?.options?.symbol : ''}{' '}
								</div>

								{loading && (
									<SelectWithSearch
										items={currency_list}
										defaultSelectedItem={{
											label: field?.options?.currency_type,
											value: field?.options?.country_code,
										}}
										triggerType={'chevron'}
										placeholder={'Select curency...'}
										className={cls.currencySelectComponent}
										inputClassName={cls.currencySearchInput}
										inputContainerClassName={cls.currencyInputContainerClassName}
										clearControlsClassName={cls.currencyClearControlsClassName}
										molclassname={cls.currencySelectMenuOverlayList}
										moliclassname={cls.currencySelectMenuOverlayListItem}
										onChange={selection => {
											processTaskContext.currency = selection.value;
											handleCurrencyChange(selection, index);
											setLoading(false);
										}}
									/>
								)}
								{!loading && (
									<input
										type={'number'}
										autoComplete="off"
										value={component.selected_fields[index].value ?? 0}
										className={cls.currencyInput}
										onChange={e => {
											handleInputChange(e.target.value, index);
										}}
									/>
								)}
							</div>
						</FieldRow>
					);
				} else {
					return (
						<FieldRow
							enabled={enabled}
							width={isMobile ? '100%' : component?.selected_fields?.length < 5 ? '50%' : field?.field_options?.width ?? clength}
							editMode={editMode}
							showWidth={width => {
								field.field_options = { ...field.field_options, width: `${width}px` };
							}}
							key={index}
							fieldLabel={field.field_name}
							notes={field?.options?.notes}
							fieldIcon={field.icon}
							isProcess
							visible={field.visible}
							mandatory={field.mandatory}
							error={field.errors}
							customIconBgColor={'white'}
						>
							{field.data_type === 'file' ? (
								<FileUpload field_id={field.id} value={field?.value} isInherited={isInherited} checkIfAreMandatoryErrored={checkIfAreMandatoryErrored} options={field?.options} index={index} indexInContextArray={indexInContextArray} />
							) : field.data_type === 'date' || field?.options?.country_code || field?.options?.currency_type || field.data_type_name === 'Number' ? (
								<div className={cls.fieldTypeInput} style={{ border: '1px solid var(--app-color-gray-light)' }}>
									{field.data_type === 'date' ? (
										field?.options?.type === 'Range' ? (
											<RangeDatePicker inputContainerClassName={cls.inputContainerClassName} dateChangeHandler={dates => dateChangeHandler(dates, index)} startDate={startDate} endDate={endDate} />
										) : (
											<DatePickerComp
												toggleDP={open => setBottomMargin(open === true ? '240px' : '50px')}
												value={field.value == undefined ? '' : moment(new Date(field.value)).format('Do MMMM YYYY')}
												dateChangeHandler={date => singleDateChangeHandler(date, index)}
												selected={new Date(moment(date, 'YYYY-MM-DD'))}
												iconClassName={cls.calendar}
												inputContainerClassName={cls.singleInputContainer}
												inputStyle={{
													textAlign: 'left',
													border: 'none',
													height: 'auto',
													fontSize: '16px',
												}}
												hasCalendarIcon={true}
											/>
										)
									) : field?.options?.country_code ? (
										<PhoneInput
											value={component.selected_fields[index].value}
											country={field?.options?.country_code}
											onChange={(_changeValue, data, event) => {
												handlePrefixChange(data, index, event.target.value);
											}}
											inputClass={cls.phoneIInput}
										/>
									) : (
										<input
											onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
											type={field.data_type}
											onChange={e => {
												handleInputChange(e.target.value, index);
											}}
											autoComplete="none"
											value={component.selected_fields[index].value ? component.selected_fields[index].value : checkPrefixCurrency(field?.options) === '' ? '' : checkPrefixCurrency(field?.options)}
											placeholder={component.selected_fields[index].data_type_name === 'Number' ? '0' : ''}
											className={cls.fieldTypeInput}
											name={field.field_name}
											style={{ height: '33px' }}
											disabled={component.selected_fields[index].disabled ?? false}
										/>
									)}
								</div>
							) : field.data_type_name === 'Long Text' ? (
								<textarea
									className={cls.fieldTypeTextarea}
									style={{ border: '1px solid var(--app-color-gray-light)' }}
									onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
									type={field.data_type}
									value={component.selected_fields[index].value ? component.selected_fields[index].value : checkPrefixCurrency(field?.options) === '' ? '' : checkPrefixCurrency(field?.options)}
									onFocus={() => { }}
									disabled={component.selected_fields[index].disabled ?? false}
									autoComplete="none"
									onChange={e => {
										handleInputChange(e.target.value, index);
									}}
									placeholder={component.selected_fields[index].data_type_name === 'Number' ? '0' : ''}
									name={field.field_name}
								/>
							) : field.data_type_name === 'Link' ? (
								<div style={{ width: 'inherit', display: 'flex', flexDirection: 'column' }} >
									{field?.value && (field?.value?.includes('jpg') || field?.value?.includes('jpeg') || field?.value?.includes('png')) && <img alt="lg" src={field?.value} style={{ width: '100%', height: '100%', cursor: 'pointer' }} />}
									<input
										onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
										type={field.data_type}
										onChange={e => {
											handleInputChange(e.target.value, index);
										}}
										autoComplete="none"
										value={component.selected_fields[index].value ? component.selected_fields[index].value : checkPrefixCurrency(field?.options) === '' ? '' : checkPrefixCurrency(field?.options)}
										placeholder={component.selected_fields[index].data_type_name === 'Number' ? '0' : ''}
										className={cls.fieldTypeInput}
										style={{ border: '1px solid var(--app-color-gray-light)' }}
										name={field.field_name}
										disabled={component.selected_fields[index].disabled ?? false}
									/>
								</div>

							) : (
								<input
									onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
									type={field.data_type}
									onChange={e => {
										handleInputChange(e.target.value, index);
									}}
									autoComplete="none"
									value={component.selected_fields[index].value ? component.selected_fields[index].value : checkPrefixCurrency(field?.options) === '' ? '' : checkPrefixCurrency(field?.options)}
									placeholder={component.selected_fields[index].data_type_name === 'Number' ? '0' : ''}
									className={cls.fieldTypeInput}
									style={{ border: '1px solid var(--app-color-gray-light)' }}
									name={field.field_name}
									disabled={component.selected_fields[index].disabled ?? false}
								/>
							)}

						</FieldRow>
					);
				}
			});

	return (
		<div id={component.name} className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '', position: 'relative', marginBottom: bottomMargin }}>
			{allSelectedFields}
			{aiResponse?.candidates?.length > 0 && (
				<FieldRow width={'80%'} fieldLabel={`Response`} visible={true}>
					<textarea className={cls.fullLengthTextarea} type={'text'} onFocus={() => { }} disabled={true} value={aiResponse?.candidates[0]?.content.parts[0]?.text} />
				</FieldRow>
			)}
		</div>
	);
});

const FileUpload = props => {
	const { options, field_id, index, indexInContextArray, value, checkIfAreMandatoryErrored } = props;
	const [selectedFile, setSelectedFile] = useState();
	const [loading, setLoading] = useState(false);
	const [processTaskContext, setTaskProcessContext] = useProcessTaskContext();

	const changeAvatar = () => {
		document.getElementById(`file-change-${field_id}`)?.click();
	};

	const onSelectedAvatarChange = maxFileSize => {
		const file = document.getElementById(`file-change-${field_id}`).files[0];
		const filesize = (file?.size / 1024 / 1024).toFixed(4); // MB
		const maxSize = (maxFileSize / 1024).toFixed(4); // MB
		setLoading(true);
		const list = [...(processTaskContext.currentStep?.component_blueprints ?? [])];
		if (filesize > maxSize) {
			alert('File is too big!');
		} else
			uploadFileComponent(file, field_id).subscribe({
				next: data => {
					setSelectedFile(file);
					setLoading(false);
					list[indexInContextArray].selected_fields[index].value = data?.file_url;
					if (list[indexInContextArray].selected_fields[index].value && list[indexInContextArray].selected_fields[index].mandatory) list[indexInContextArray].selected_fields[index].errors = '';
					checkIfAreMandatoryErrored();

					setTaskProcessContext({
						action: processTaskAction.setComponentBlueprints,
						value: list,
					});
					setTaskProcessContext({
						action: processTaskAction.setModuleNewStepComponents,
						value: list,
					});
				},
			});
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				flex: '1 1',
				overflow: 'hidden',
				border: '1px solid black',
				height: '36px'
			}}
		>
			<u onClick={changeAvatar} style={{ cursor: 'pointer', marginLeft: '20px', wordWrap: 'break-word' }}>
				{selectedFile?.name || value ? selectedFile?.name || value : 'Click to upload file...'}
			</u>
			<input
				accept={'.' + options?.accepted_mimes?.replace(/,/g, ',.')}
				type={'file'}
				onChange={() => onSelectedAvatarChange(options?.max_file_size)}
				style={{ position: 'absolute', visibility: 'hidden', width: '0px' }}
				id={`file-change-${field_id}`}
				className={cls.fieldTypeInput}
			/>
			{loading && <Loader type={isSafari ? 'Watch' : 'Puff'} color="#4A4A4A" secondaryColor="gray" radius={22} height={22} width={'22px'} />}
			{selectedFile?.type && <CheckFileType fileType={selectedFile?.type} />}
		</div>
	);
};

const CheckFileType = props => {
	if (props?.fileType === 'application/pdf') {
		return <img alt={''} src={pdfSymbol} />;
	} else {
		return <img alt={''} src={fileSymbol} />;
	}
};

export default AccordionModule;
