import { now } from 'moment';

export const flowDefault = {
	serverFeatureNotReadyYet: false,
	onBoardingCompleted: false,
	companyData: {
		company_standards: [],
		core_values: [],
		departments_count: 3,
		display_name: '',
		employees_no: '',
		founding_date: null,
		id: '',
		industry: null,
		logo: null,
		mission_text: null,
		name: '',
		onboarding_status: false,
		positions_count: 0,
		slug: '',
		updated_by: {},
		users_count: 1,
		vision_text: '',
		billing_info: {},
		misc_info: {},
		currentLocation: { longitude: 0, latitude: 0 }
	},
	companyDepartments: [],
	companyPositionsArray: [],
	inviteColleguesList: [{ email: '', first_name: '', last_name: '', position: '' }],
	positionInfo: {
		name: '',
		standards: '',
		id: '',
		parent: null,
		department_id: null,
		is_managerial: '0',
		users: [],
		shared_users: [],
		ui_permissions: [],
	},
	duplicatePositionInfo: { valid: false, name: '', department_id: '', parent_id: '', is_managerial: 0 },
	companyOrgChart: {},
	allTasks: [],
	allCompletedTasks: { data: [], links: [], meta: [] },
	allCompletedTasksPaginated: [],
	companyUsersArray: [],
	companyProcesses: [],
	workflowTemplates: [],
	selectedProcess: { name: '', description: '', id: '', is_pinned: false, user_id: '', registered: false, type: 0 },
	selectedProStep: {
		isNewStep: false,
		id: '',
		name: '',
		guidance: '',
		duration: 0,
		rank: null,
		is_start: false,
		is_final: false,
		procedure_id: '',
		options: {
			is_import_in_next: false,
			is_start_next_process: false,
			allows_search_components: false,
			isMultipleInput: false,
		},
		parent_steps: [],
		child_steps: [],
		child_processes: [],
		component_blueprints: [],
	},
	begunProcess: { processTask: '', steps: [], isStarted: false },
	begunModule: { processModule: '' },
	blueprints: [], //component blueprints
	newComponentBlueprint: { componentName: '', componentIcon: '', fields: [] },
	selectedBlueprintComponent: { id: '', name: '', icon: '', fields: [] },
	allModules: [],
	components: [], // output components
	lastUpdates: {
		blueprints: now() - 660000,
		blueprinttypes: now() - 660000,
		favorites: now() - 660000,
		procedures: 0,
		components: 0,
		tasks: now() - 660000,
		modules: 0,
		users: now() - 660000,
		allCompletedTasks: now() - 660000,
		componentsByModule: {},
		proceduresById: {}
	},
	filters: {
		components: [],
		processes: [],
		users: [],
	},
	currentTask: {},
	products: [],
	transactions: [],
	favorites: [],
	showMessage: { show: false, message: '' },
	showMenu: false,
	showSideMenu: true,
	cart: {},
	billable: undefined,
	billProducts: [],
	componentTypes: [],
	payable: [],
	//axiosAbort: new AbortController()
};
