import { createReducerContext } from 'react-use';
import * as R from 'ramda';

export const processTaskAction = {
	setAllSteps: 'SET_CONTEXT_STEPS',
	setCurrentStep: 'SET_CURRENT_STEP',
	setCurrentStepIndex: 'SET_CURRENT_STEP_INDEX',
	editInputField: 'EDIT_INPUT_FIELD',
	addNewComponent: 'ADD_NEW_COMPONENT',
	setComponentBlueprints: 'SET_COMPONENT_BLUEPRINTS',
	addStepToHistory: 'ADD_STEP_TO_HISTORY',
	setStepsHistory: 'SET_STEPS_HISTORY',
	setInheritedComponents: 'SET_INHERITED_COMPONENTS',
	setComponentId: 'SET_COMPONENT_ID',
	setModuleNewStepComponents: 'SET_MODULE_NEW_COMPONENTS',
	addModuleComponents: 'ADD_MODULE_COMPONENTS',
	setModuleComponents: 'SET_MODULE_COMPONENTS',
	removeModuleComponents: 'REMOVE_MODULE_COMPONENTS',
	setComponents: 'SET_COMPONENTS',
	assembleModuleComponentForSaving: 'ASSEMBLE_MODULE_COMPONENT_FOR_SAVING',
	updateModuleNewStepComponents: 'UPDATE_MODULE_COMPONENTS',
	setModuleItems: 'SET_MODULE_ITEMS',
	addModuleItems: 'ADD_MODULE_ITEMS',
	updateModuleItems: 'UPDATE_MODULE_ITEMS',
	removeModuleItems: 'REMOVE_MODULE_ITEMS',
	clearOngoingTransaction: 'CLEAR_ONGOING_TRANSACTION',
};

const processTaskContextReducer = (state, payload) => {
	const { action, value, index } = payload;

	switch (action) {
		case processTaskAction.setAllSteps:
			return {
				...state,
				steps: value,
			};
		case processTaskAction.addNewComponent:
			return {
				...state,
				newStepComponents: state.newStepComponents.concat(value),
			};
		case processTaskAction.setInheritedComponents:
			return {
				...state,
				inheritedComponents: value,
			};
		case processTaskAction.setComponentBlueprints:
			return {
				...state,
				stepsHistory: state.stepsHistory.map(content => (content.id === state.currentStep.id ? state.currentStep : content)),
				currentStep: {
					...state.currentStep,
					component_blueprints: value,
				},
			};
		case processTaskAction.setCurrentStep:
			return {
				...state,
				currentStep: value,
			};
		case processTaskAction.setCurrentStepIndex:
			return {
				...state,
				currentStepIndex: value,
			};
		case processTaskAction.addStepToHistory:
			return {
				...state,
				stepsHistory:
					state?.stepsHistory[state?.stepsHistory?.length - 1]?.id !== state?.currentStep?.id
						? state?.stepsHistory?.concat({
								...value,
						  })
						: state.stepsHistory,
			};
		case processTaskAction.setStepsHistory:
			return {
				...state,
				stepsHistory: value,
			};
		case processTaskAction.setComponentId:
			return {
				...state,
				stepsHistory: state.stepsHistory.map(content =>
					content.id === state.currentStep.id
						? {
								...state.currentStep,
								component_blueprints: state.currentStep.component_blueprints.map((content, i) =>
									i === index
										? {
												...content,
												new_component_id: value,
										  }
										: content
								),
						  }
						: content
				),
				currentStep: {
					...state.currentStep,
					component_blueprints: state.currentStep.component_blueprints.map((content, i) =>
						i === index
							? {
									...content,
									new_component_id: value,
							  }
							: content
					),
				},
			};
		case processTaskAction.setModuleNewStepComponents:
			return {
				...state,
				newStepComponents: value,
			};
		case processTaskAction.updateModuleNewStepComponents:
			return {
				...state,
				newStepComponents: [...state.newStepComponents, value],
			};
		case processTaskAction.addModuleComponents:
			return {
				...state,
				components: state.components.concat(value),
			};
		case processTaskAction.setModuleComponents:
			return {
				...state,
				components: value,
			};
		case processTaskAction.setModuleItems:
			return {
				...state,
				items: value,
			};
		case processTaskAction.addModuleItems:
			return {
				...state,
				items: state?.items ? [...state.items, value] : [value],
			};
		case processTaskAction.updateModuleItems:
			return {
				...state,
				items: [...state.items.filter(item => item.id !== value.id), value],
			};
		case processTaskAction.removeModuleItems:
			return {
				...state,
				items: [...state.items.filter(item => item.id !== value.id)],
			};

		case processTaskAction.setComponents:
			return {
				...state,
				components: value,
			};
		case processTaskAction.clearOngoingTransaction:
			return {
				...state,
				components: [],
				items: [],
				newStepComponents: [],
			};
		case processTaskAction.removeModuleComponents:
			return {
				...state,
				components: [...R.reject(R.propEq('temp_id', value))(state.components)],
			};
		case processTaskAction.assembleModuleComponentForSaving:
			return {
				...state,
				moduleComponents: {
					title: value,
					task_id: '',
					procedure_step_id: '',
					is_complete: false,
					options: {},
					component_fields: [state.components],
				},
			};
		default:
			return { ...state };
	}
};

export const [useProcessTaskContext, TheProcessTaskContext] = createReducerContext(processTaskContextReducer, {
	steps: [],
	currentStep: { id: '', component_blueprints: [] },
	processTask: [],
	newStepComponents: [],
	inheritedComponents: [],
	stepsHistory: [],
	components: [],
	currentStepIndex: 0,
	moduleComponent: {},
	items: [],
});
