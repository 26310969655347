import * as R from 'ramda';
import { memo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { first } from 'rxjs/operators';

import { pat, useProcessContext, useProcessStepContext } from '../../../../context/processStep.context';
import { setCompanyProcesses, setSelectedProStep } from '../../../../reduxStore/actions/flowActions';
import { createNewProcessStepUsingRawJson, deleteStepById, getStepsByProcessId, updateProcessStepByJson } from '../../../../services/processes.service';
import { Button, ModalR } from '../../../common';
import { generateNewUUID } from '../../../../shared/utility';
import StepData from './StepComponents/StepData';
import { scrollableDivRef, stepSchema } from './stepManagement';
import cls from './StepModal.module.scss';
import { isMobile } from 'react-device-detect';
import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
import { store } from '../../../../reduxStore/store';
const AddNewStepModal = memo(props => {
	const { visible, onClose, onSave, selectedProcess, companyProcesses, shadowedContainerStyle = { width: isMobile ? '100%' : '30%' }, onBoardingCompleted, onEditChange = () => {} } = props;
	const [procContext, setInProcContext] = useProcessContext();
	const [selectedStep] = useState(procContext.selectedStep);
	const [, setProcessStepContext] = useProcessStepContext();
	const [init, setinit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [generalError, setGeneralError] = useState('');
	const [onEdit, setOnEdit] = useState('');
	const closingModal = useCallback(
		value => {
			setInProcContext({ type: pat.unsetSelectedStep });
			onClose && onClose(value);
		},
		[onClose, setInProcContext]
	);
	const setErrorsInContext = useCallback(
		(id, errorText) =>
			setProcessStepContext({
				open: true,
				activeElement: id,
				errors: { [id]: errorText },
			}),
		[setProcessStepContext]
	);
	const stepContentValidation = useCallback(
		step => {
			let isValid = true;
			let errors = [];
			// Check for parentSteps
			if (step.is_start == false && step?.parent_steps?.length == 0) {
				isValid = false;
				errors.push({ [stepSchema?.parent_steps?.id]: 'Invalid parent(s)' });
				setErrorsInContext(stepSchema?.parentSteps?.id, 'Invalid parent(s)');
			}
			if (step.is_start == true && step.parent_steps?.length > 0) {
				isValid = false;
				errors.push({ [stepSchema.parentSteps.id]: 'Invalid parent(s)' });
				setErrorsInContext(stepSchema.parentSteps.id, 'Invalid parent(s)');
			}
			if (step.name?.length < 1) {
				isValid = false;
				errors.push({ [stepSchema.stepName.id]: 'Invalid name' });
				setErrorsInContext(stepSchema.stepName.id), 'Invalid name';
			} else if (+step.guidance < 1) {
				isValid = false;
				errors.push({ [stepSchema.guidance.id]: 'Invalid guidance' });
				setErrorsInContext(stepSchema.guidance.id, 'Invalid guidance');
			}
			return isValid;
		},
		[procContext?.selectedStep?.component_blueprints, setErrorsInContext]
	);

	const retreiveStepsIntoContext = useCallback(() => {
		setLoading(true);
		getStepsByProcessId({ processId: selectedProcess?.id })
			.pipe(first())
			.subscribe({
				next: data => {
					if (data.length > 0) {
						const addUUID = obj => R.assoc('_uuid', generateNewUUID(), obj);
						setInProcContext({
							type: pat.setAllSteps,
							value: R.compose(R.sortBy(R.eqProps('rank')), R.map(R.over(R.lensProp('component_blueprints'), R.map(addUUID))))(data),
						});
					}
				},
				error: _error => {
					setLoading(false);
				},
			});
	}, [selectedProcess.id, setInProcContext]);
	const onSaveNewStep = useCallback(() => {
		const selectedStep = procContext.selectedStep;
		if (stepContentValidation(selectedStep)) {
			setLoading(true);
			const startStepId = R.compose(R.path(['id']), R.find(R.propEq('is_start', true)))(procContext.steps);
			const thisIsStartStep = startStepId?.length > 0 && startStepId == selectedStep?.id && selectedStep?.parent_steps?.length === 0;
			// const layout = selectedStep.component_blueprints.map((cb, index) => {
			// 	return {
			// 		x: 0,
			// 		y: 0,
			// 		w: 5,
			// 		h: 5,
			// 		i: index.toString(),
			// 		static: true,
			// 		resizeHandles: ['n', 's', 'e', 'w'],
			// 	};
			// });
			const sentData = {
				name: selectedStep?.name,
				guidance: selectedStep?.guidance,
				procedure_id: selectedStep?.procedure_id,
				duration: selectedStep?.duration,
				is_start: thisIsStartStep ? thisIsStartStep : selectedStep?.is_start,
				is_end: selectedStep.is_end,
				options: { ...selectedStep.options, layout: selectedStep?.layout ?? {} },
				parent_steps: [...R.map(R.path(['id']))(selectedStep?.parent_steps)],
				pathway_steps: selectedStep?.pathway_processes?.map(i => i.id) ?? [],
				pathway_processes: selectedStep?.pathway_processes?.map(i => i.id) ?? [],
				component_blueprints: [
					...selectedStep.component_blueprints.map(v => ({
						...R.omit(['_uuid'])(v),
						selected_fields: [...R.map(R.pick(['id', 'index', 'mandatory', 'visible', 'procedure_step_blueprint_field_id']))(v.selected_fields)],
					})),
				],
				rank: +(selectedStep?.parent_steps?.reduce((a, c) => (a <= +c.rank ? (a = +c?.rank) : null), 0) ?? 0) + 1, //or  Math.max(...selectedStep?.parent_steps?.map(o => +o?.rank))
			};
			createNewProcessStepUsingRawJson(sentData)
				.pipe(first())
				.subscribe({
					next: _data => {
						retreiveStepsIntoContext();
						setLoading(false);
						onBoardingCompleted ? closingModal(true) : onSave();
					},
					error: error => {
						setLoading(false);
					},
				});
		}
	}, [procContext, stepContentValidation, retreiveStepsIntoContext, closingModal]);

	const onUpdateExistingStep = useCallback(() => {
		const selectedStep = procContext.selectedStep;
		if (stepContentValidation(selectedStep)) {
			setLoading(true);
			const startStepId = R.compose(R.path(['id']), R.find(R.propEq('is_start', true)))(procContext.steps);
			const thisIsStartStep = startStepId?.length > 0 && startStepId == selectedStep?.id && selectedStep?.parent_steps?.length === 0;
			// const layout = selectedStep.component_blueprints?.map((cb, index) => {
			// 	return {
			// 		x: 0,
			// 		y: 0,
			// 		w: 5,
			// 		h: 5,
			// 		i: index.toString(),
			// 		static: true,
			// 		resizeHandles: ['n', 's', 'e', 'w'],
			// 	};
			// });
			const sentData = {
				name: selectedStep?.name?.trim(),
				guidance: selectedStep?.guidance,
				procedure_id: selectedStep?.procedure_id,
				duration: selectedStep?.duration,
				is_start: thisIsStartStep ? thisIsStartStep : selectedStep?.is_start,
				is_end: selectedStep.is_end,
				options: { ...selectedStep.options, layout: selectedStep?.layout ?? {} },
				parent_steps: R.uniq([...R.map(R.path(['id']))(selectedStep?.parent_steps)]),
				pathway_steps: selectedStep?.pathway_steps?.map(i => i.id) ?? [],
				pathway_processes: selectedStep?.pathway_processes?.map(i => i.id) ?? [],
				component_blueprints: [
					...selectedStep.component_blueprints.map(v => ({
						...R.omit(['_uuid'])(v),
						status: null,
						selected_fields: [...R.map(R.pick(['id', 'index', 'mandatory', 'visible', 'procedure_step_blueprint_field_id']))(v.selected_fields)],
					})),
				],
				rank: +selectedStep.rank,
				...R.applySpec({
					ranking_steps: R.map(
						R.applySpec({
							id: R.path(['id']),
							rank: R.prop('rank'),
						})
					).ranking_steps?.map(d => {
						return {
							id: d.id,
							rank: d.rank ?? 1000,
						};
					}),
				})(procContext.steps),
			};

			updateProcessStepByJson(sentData, selectedStep.id, true)
				.pipe(first())
				.subscribe({
					next: _data => {
						if (_data.length > 0) {
							const addUUID = obj => R.assoc('_uuid', generateNewUUID(), obj);
							setInProcContext({
								type: pat.setAllSteps,
								value: R.compose(R.sortBy(R.eqProps('rank')), R.map(R.over(R.lensProp('component_blueprints'), R.map(addUUID))))(_data),
							});
						}

						store.dispatch(setCompanyProcesses([...companyProcesses.filter(proc => proc.id !== selectedStep.procedure_id), { ...selectedProcess, steps: _data }], 1));
						setLoading(false);
						closingModal(true);
					},
					error: error => {
						setGeneralError(error.data);
						setLoading(false);
					},
				});
		}
	}, [closingModal, procContext.selectedStep, procContext.steps, retreiveStepsIntoContext, setInProcContext, stepContentValidation]);

	const validSections = useCallback(() => {
		const errors = {};
		const errorsString = {
			parentSteps: 'Please connect to a step',
			stepName: 'Step name is mandatory',
			guidance: 'Short description of step is mandatory',
		};

		// Check for parentSteps
		if (procContext?.steps.length > 0) {
			errors.parentSteps = !((!!R.find(R.propEq('is_start', true))(procContext?.steps) && procContext?.selectedStep?.parent_steps?.length > 0) || procContext?.selectedStep?.is_start == true) ? errorsString.parentSteps : '';
		}
		errors.stepName = !(typeof procContext?.selectedStep?.name == 'string' && procContext?.selectedStep?.name?.length > 0) ? errorsString.stepName : '';
		errors.guidance = !(typeof procContext?.selectedStep?.guidance == 'string' && procContext?.selectedStep?.guidance?.length > 0) ? errorsString.guidance : '';

		const isValid = Object.values(errors).every(x => x === '');

		// Check for parentSteps
		if (procContext?.steps.length > 0) {
			setErrorsInContext(stepSchema.parentSteps.id, errors.parentSteps);
		}

		setErrorsInContext(stepSchema.parentSteps.id, errors.parentSteps);
		setErrorsInContext(stepSchema.stepName.id, errors.stepName);
		setErrorsInContext(stepSchema.guidance.id, errors.guidance);
		setErrorsInContext(stepSchema.newComponents.id, errors.newComponents);
		if (!isValid) {
			setGeneralError(`${errors.stepName ?? ''} ${errors.guidance} ${errors.parentSteps}`);
		}
		return isValid;
	}, [
		init,
		procContext?.selectedStep?.pathway_processes,
		procContext?.selectedStep?.pathway_steps,
		procContext?.selectedStep?.component_blueprints,
		procContext?.selectedStep?.guidance,
		procContext?.selectedStep?.name,
		procContext?.selectedStep?.parent_steps?.length,
		procContext?.steps,
	]);
	const onSavePressed = useCallback(async () => {
		setinit(true);
		const validData = validSections();
		if (!validData) return;
		if (procContext?.selectedStep?.isNewStep) {
			onSaveNewStep();
		} else {
			onUpdateExistingStep();
		}
	}, [validSections, selectedStep, onSaveNewStep, onUpdateExistingStep]);

	const onDeleteStep = useCallback(() => {
		if (!(selectedStep?.id?.length > 0)) {
			return;
		}
		if (selectedStep?.id === 'newDefaultStep') {
			closingModal();
		}
		if (selectedStep.is_start == true) {
			setGeneralError('First step cannot be deleted. Delete the procedure instead');
			return;
		}

		deleteStepById(selectedStep?.id)
			.pipe(first())
			.subscribe({
				next: _data => {
					retreiveStepsIntoContext();
					setInProcContext({ type: pat.removeStepById, value: procContext.selectedStep?.id });
					closingModal(true);
				},
				error: () => {},
			});
	}, [closingModal, procContext.selectedStep?.id, retreiveStepsIntoContext, selectedStep?.id, selectedStep.is_start, setInProcContext]);

	return (
		<ModalR scrollable={true} shadowedContainerStyle={{ minWidth: '500px', width: onEdit === '' ? '30%' : '90%' }} isOpen={visible} blured={true}>
			<div style={{ display: 'flex' }}>
				<div style={{ width: onEdit === '' ? '100%' : '30%' }}>
					<div className={cls.host}>
						{props.children}
						<div id="scrollableDiv" ref={scrollableDivRef} className={cls.contentContainer}>
							{onBoardingCompleted && (
								<div className={cls.titleContainer}>
									<p>{procContext?.selectedStep?.id !== 'newDefaultStep' && procContext?.selectedStep?.id !== '' ? `Edit Step #${procContext?.selectedStep?.rank}` : 'New Step'}</p>
								</div>
							)}
							<div style={{ flex: 1 }}>
								<StepData onEditChange={(e, id) => onEditChange(e, id)} selectedProcess={selectedProcess} />
							</div>
							{generalError && <MessageContainer message={generalError} onClick={() => setGeneralError('')} />}

							<div className={onBoardingCompleted ? cls.saveButtonContainer : cls.controls2}>
								{!onBoardingCompleted && <Button clear title={''} clicked={() => {}} />}
								{onClose && !onBoardingCompleted && <Button light title={'Back'} clicked={closingModal} />}
								{onClose && onBoardingCompleted && <Button title={'Close'} clicked={closingModal} />}
								{procContext?.selectedStep?.name?.length > 3 && procContext?.selectedStep?.guidance?.length > 3 && !onBoardingCompleted && <Button title={procContext?.selectedStep?.isNewStep ? 'Save' : 'Update'} loading={loading} clicked={onSavePressed} />}
								{procContext?.selectedStep?.name?.length > 3 && procContext?.selectedStep?.guidance?.length > 3 && onBoardingCompleted && <Button light title={procContext?.selectedStep?.isNewStep ? 'Save' : 'Update'} loading={loading} clicked={onSavePressed} />}
								{procContext?.selectedStep?.id !== 'newDefaultStep' && procContext?.selectedStep?.id !== '' && <Button light title={'Delete'} clicked={onDeleteStep} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ModalR>
	);
});

const mapStateToProps = store => {
	const { allStatutes, selectedProcess, companyProcesses, onBoardingCompleted } = store.flowReducer;
	return { allStatutes, selectedProcess, companyProcesses, onBoardingCompleted };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetSelectedProStep: selectedStep => dispatch(setSelectedProStep(selectedStep)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewStepModal);
