require('dotenv').config();

//export const FRONTEND_URL = window.location.hostname === 'localhost' ? 'http://' + window.location.hostname + ':3000/' : 'https://adpgxsoftware.com/';
//export const BACKEND_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_DEV_API_BACKEND_URL : 'https://' + window.location.hostname + process.env.REACT_APP_PRODUCTION_API_BACKEND_URL;
//export const BACKEND_URL = 'http://192.168.68.253/api/'; //m1
//export const BACKEND_URL = 'http://192.168.68.248/api/'; //m1
//export const FRONTEND_URL = 'http://192.168.68.248:3000/';
//export const FRONTEND_URL = 'http://192.168.68.253:3000/'; //m1

// export const BACKEND_URL = 'http://13.54.225.217/api/';
//  export const BACKEND_URL = 'http://127.0.0.1/api/';
//export const BACKEND_URL = 'http://192.168.68.248/api/';
//export const FRONTEND_URL = 'http://localhost:3000/';

export const BACKEND_URL = 'https://flowhero.app/api/';
export const FRONTEND_URL = 'https://adpgxsoftware.com/';

export const PAYMENT_URL = 'https://api.paymongo.com/';
export const PAYMONGO_KEY = 'pk_test';
export const AI_KEY = '';

export const URLS = {
	BACKEND_URL: BACKEND_URL,
	auth: {
		login: {
			method: 'post',
			url: BACKEND_URL + 'auth/login',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		},
		getInvitationByHash: hash => ({
			method: 'get',
			url: BACKEND_URL + `invitations/hash/${hash}`,
			headers: {
				Accept: 'application/json',
			},
		}),
		getInvitationById: invitationId => ({
			method: 'get',
			url: BACKEND_URL + `invitations/${invitationId}`,
			headers: {
				Accept: 'application/json',
			},
		}),
		register: {
			method: 'post',
			url: BACKEND_URL + 'auth/register',
			headers: {
				Accept: 'application/json',
				Authorization: 'Bearer null',
				'Content-Type': 'multipart/form-data',
			},
		},
		forgotPassword: {
			method: 'post',
			url: BACKEND_URL + 'auth/password/forgot',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		},
		resetPassword: {
			method: 'post',
			url: BACKEND_URL + 'auth/password/reset',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		},
		refreshToken: refresh_token => ({
			method: 'post',
			url: BACKEND_URL + 'auth/refresh',
			data: { refresh_token: refresh_token },
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		}),
		logout: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'auth/logout',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	users: {
		getAllUsers: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'users',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createUser: (access_token, data) => ({
			method: 'post',
			url: BACKEND_URL + `users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
			data: data,
		}),
		checkUserEmail: (access_token, email) => ({
			method: 'get',
			url: BACKEND_URL + `users/check/${email}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getUsersInSeveralPositions: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'users/positions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getUnassignedUser: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'users/unassigned',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getAuthenticatedUser: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'users/my-profile',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		addUserToAPosition: (access_token, authUserId, data) => ({
			method: 'post',
			url: BACKEND_URL + `users / ${authUserId} /positions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
			data: data,
		}),
		deleteUserbyId: (access_token, userId) => ({
			method: 'delete',
			url: BACKEND_URL + `users/${userId}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		changePassword: (access_token, userId) => ({
			method: 'post',
			url: BACKEND_URL + `users/${userId}/change-password`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateUserById: (access_token, userId) => ({
			method: 'patch',
			url: BACKEND_URL + `users/${userId}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		rateUserById: (access_token, userId) => ({
			method: 'patch',
			url: BACKEND_URL + `users/${userId}/add-ratings`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateUserProfilePicture: (access_token, userId) => ({
			method: 'post',
			url: BACKEND_URL + `users/${userId}/update-photo`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getUsersFilter: access_token => ({
			method: 'get',
			url: BACKEND_URL + `users/filters`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getFilteredUsers: access_token => ({
			method: 'post',
			url: BACKEND_URL + `users/filtered`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
				'Content-Type': 'application/json',
			},
		}),
	},
	company: {
		getCompanyDetailsUsingSlug: (access_token, slug) => ({
			method: 'get',
			url: BACKEND_URL + `companies/check/${slug}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getCompanyDetails: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'company/details',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllCompanies: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'companies',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateCompanyById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `companies/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateCompanyDetails: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'company/details',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateCompanyLogo: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'company/logo',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateCompanyOptions: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'company/options',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		completeOnBoarding: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'company/completeOnBoarding',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		createNewCompany: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'auth/companies/register',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		createUserOpenInvite: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `companies/${id}/invite`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	departments: {
		getAllDepartments: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'departments',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getDepartmentById: id => ({
			method: 'get',
			url: BACKEND_URL + `departments/${id}`,
			headers: {
				Accept: 'application/json',
			},
		}),
		createNewDepartment: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'departments/',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateDepartmentById: (access_token, department_id) => ({
			method: 'patch',
			url: BACKEND_URL + `departments/${department_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		getAllDepartmentPositions: (access_token, department_id) => ({
			method: 'get',
			url: BACKEND_URL + `departments/${department_id}/positions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getDepartmentManagerialPositions: (access_token, department_id) => ({
			method: 'get',
			url: BACKEND_URL + `departments/${department_id}/managerial-positions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateDepartmentsOrder: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'departments/order',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		deleteDepartment: (access_token, department_id) => ({
			method: 'delete',
			url: BACKEND_URL + `departments/${department_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
	},
	invitations: {
		createInvitation: (access_token, sentFormData) => ({
			method: 'post',
			url: BACKEND_URL + 'invitations',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
			data: sentFormData,
		}),
		importInvitation: (access_token, sentFormData) => ({
			method: 'post',
			url: BACKEND_URL + 'invitations/import',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
			data: sentFormData,
		}),
		resendAnInvitation: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `invitations/resend/${id}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getInvitationById: (access_token, id) => ({
			method: 'get',
			url: BACKEND_URL + `invitations/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getInvitationByHash: (access_token, hash) => ({
			method: 'get',
			url: BACKEND_URL + `invitations/hash/${hash}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllInvitations: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'invitations/',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deleteInvitationById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `invitations/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	positions: {
		getAllPositions: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'positions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getPositionsOrgChart: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'positions/chart',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllPositionsAsArray: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'positions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		addUserToPosition: (access_token, positionId, data) => ({
			method: 'post',
			url: BACKEND_URL + `positions/${positionId}/users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
			data: data,
		}),
		deleteUserFromPosition: (access_token, positionId, data) => ({
			method: 'delete',
			url: BACKEND_URL + `positions/${positionId}/users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data: data,
		}),
		shareUserToPosition: (access_token, positionId, data) => ({
			method: 'post',
			url: BACKEND_URL + `positions/${positionId}/share-users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
			data: data,
		}),
		unshareUserFromPosition: (access_token, positionId, data) => ({
			method: 'delete',
			url: BACKEND_URL + `positions/${positionId}/share-users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data: data,
		}),
		getPositionById: (access_token, id) => ({
			method: 'get',
			url: BACKEND_URL + `positions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getPositionUsers: (access_token, id) => ({
			method: 'get',
			url: BACKEND_URL + `positions/${id}/users`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		createNewPosition: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'positions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		duplicatePosition: (access_token, positionId) => ({
			method: 'post',
			url: BACKEND_URL + `positions/${positionId}/duplicate`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updatePositionById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `positions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		deletePositionAndItsChildren: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `positions/${id}?delete_children=1`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deletePositionAndKeepChildren: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `positions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		addPermissionToPosition: (access_token, positionId, data) => ({
			method: 'post',
			url: BACKEND_URL + `positions/${positionId}/permissions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
			data: data,
		}),
		removePermissionToPosition: (access_token, positionId, data) => ({
			method: 'delete',
			url: BACKEND_URL + `positions/${positionId}/permissions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data: data,
		}),
	},
	tasks: {
		deleteTask: (access_token, department_id) => ({
			method: 'delete',
			url: BACKEND_URL + `tasks/${department_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		editTask: (access_token, task_id) => ({
			method: 'patch',
			url: BACKEND_URL + `tasks/${task_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllTasks: (access_token, userId) => ({
			method: 'get',
			url: BACKEND_URL + `tasks?assigned_to=${userId}&status=incomplete`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getAllTasksCreatedByUser: (access_token, userId) => ({
			method: 'get',
			url: BACKEND_URL + `tasks?created_by=${userId}&status=incomplete`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getTaskById: (access_token, taskId) => ({
			method: 'get',
			url: BACKEND_URL + `tasks/${taskId}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getAllCompletedTasks: (access_token, page, userId) => ({
			method: 'get',
			url: BACKEND_URL + `tasks?assigned_to=${userId}&status=complete&per_page=100&page=${page}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createTask: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'tasks',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		startTaskById: (access_token, task_id) => ({
			method: 'post',
			url: BACKEND_URL + `tasks/${task_id}/start`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		endTaskById: (access_token, task_id) => ({
			method: 'post',
			url: BACKEND_URL + `tasks/${task_id}/complete`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getStepComponents: (access_token, task_id) => ({
			method: 'post',
			url: BACKEND_URL + `tasks/${task_id}/step-components`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getInheritedComponents: (access_token, task_id) => ({
			method: 'post',
			url: BACKEND_URL + `tasks/${task_id}/inherited-components`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	components: {
		createComponent: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'components',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		editComponent: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		removeComponent: (access_token, component_id) => ({
			method: 'delete',
			url: BACKEND_URL + `components/${component_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		addNewField: (access_token, component_id) => ({
			method: 'post',
			url: BACKEND_URL + `components/${component_id}/add-field`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateField: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}/update-field`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateItem: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}/update-item`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getComponents: (access_token, sharedOnly = false, lastUpdates) => ({
			method: 'get',
			url: `${BACKEND_URL}components?table=components${sharedOnly === true && '&sharedOnly=true'}${lastUpdates && '&lastUpdate='}${lastUpdates && lastUpdates}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getComponentById: (access_token, component_id) => ({
			method: 'get',
			url: BACKEND_URL + `components/${component_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		duplicateComponent: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}/duplicate`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		assignComponent: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}/assign`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateStatusComponent: (access_token, component_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/${component_id}/status`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		fileUpload: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'components/upload',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getComponentsFilters: access_token => ({
			method: 'get',
			url: BACKEND_URL + `components/filters`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getFilteredComponents: access_token => ({
			method: 'post',
			url: BACKEND_URL + `components/filtered`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
				'Content-Type': 'application/json',
			},
		}),
		getComponentsByBlueprint: (access_token, id) => ({
			method: 'get',
			url: BACKEND_URL + 'components/blueprints/' + id + '/components',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	componentsBlueprint: {
		getBlueprints: (access_token, params) => ({
			method: 'get',
			url: BACKEND_URL + 'components/blueprints' + params,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		createComponentBlueprint: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'components/blueprints',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		updateComponentBlueprint: (access_token, component_blueprint_id) => ({
			method: 'patch',
			url: BACKEND_URL + `components/blueprints/${component_blueprint_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		removeComponentBlueprint: (access_token, component_blueprint_id) => ({
			method: 'delete',
			url: BACKEND_URL + `components/blueprints/${component_blueprint_id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		addNewField: (access_token, component_blueprint_id) => ({
			method: 'post',
			url: BACKEND_URL + `components/blueprints/${component_blueprint_id}/add-field`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateField: (access_token, component_blueprint_id) => ({
			method: 'post',
			url: BACKEND_URL + `components/blueprints/${component_blueprint_id}/update-field`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		duplicateComponent: (access_token, component_id) => ({
			method: 'post',
			url: BACKEND_URL + `components/blueprints/${component_id}/duplicate`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getComponentFieldTypes: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'components/blueprints/field-types',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getComponentBlueprintById: (access_token, id) => ({
			method: 'get',
			url: BACKEND_URL + `components/blueprints/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deleteComponentBlueprintFieldbyId: (access_token, field_id) => ({
			method: 'delete',
			url: BACKEND_URL + `components/${field_id}/delete-field`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
	},
	favorites: {
		getAllFavorites: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'favorites',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		createFavorite: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'favorites',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deleteFavorite: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `favorites/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
	},
	modules: {
		getAllModules: (access_token, lastUpdates) => ({
			method: 'get',
			url: BACKEND_URL + `modules${lastUpdates && '?lastUpdate='}${lastUpdates && lastUpdates}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		createModule: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'modules',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		editModuleById: (access_token, moduleId) => ({
			method: 'patch',
			url: BACKEND_URL + `modules/${moduleId}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deleteModule: (access_token, moduleId) => ({
			method: 'delete',
			url: BACKEND_URL + `modules/${moduleId}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		pinModuleById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `modules/${id}/pin`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		unpinModuleById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `modules/${id}/unpin`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		importModules: (access_token, sentFormData, id) => ({
			method: 'post',
			url: BACKEND_URL + `modules/${id}/import`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${access_token}`,
			},
			data: sentFormData,
		}),
		getAllComponentsByModuleId: (access_token, moduleId, lastUpdates, top = 0, page = 1, list = false, assigned=false) => ({
			method: 'get',
			url: BACKEND_URL + `modules/${moduleId}/componentsV2${lastUpdates && '?lastUpdate='}${lastUpdates && lastUpdates}${top > 0 ? `&per_page=${top}&page=${page}` : ''}${list == true ? `&list=true` : ''}${assigned == true ? '&assigned=true':''}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	payments: {
		getStripeProductsAndPrices: access_token => ({
			method: 'get',
			url: BACKEND_URL + `payments/products`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getProductsAndPrices: access_token => ({
			method: 'get',
			url: BACKEND_URL + `payments/subscriptions`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getCompanyInvoices: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'payments/invoices',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getCompanyPurchases: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'payments/purchases',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getCheckout: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'payments/checkout/services',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		createSubscription: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'payments/subscribe',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getCurrentSubscription: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'payments/subscription',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		subscriptionPayment: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'payments/subscribe',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}),
		servicesPayment: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'payments/services',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		unsubscribe: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'payments/unsubscribe',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		active: access_token => ({
			method: 'patch',
			url: BACKEND_URL + 'payments/active',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		purchase: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'purchase',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		startTrial: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'payments/start-trial',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		createIntent: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'payments/intent',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		paymentMethod: () => ({
			method: 'post',
			url: PAYMENT_URL + 'v1/payment_methods',
			headers: {
				Accept: 'application/json',
				Authorization: `Basic ${window.btoa(PAYMONGO_KEY)}`,
				'Content-Type': 'application/json',
			},
		}),
		attachIntent: id => ({
			method: 'post',
			url: PAYMENT_URL + 'v1/payment_intents/' + id + '/attach',
			headers: {
				Accept: 'application/json',
				Authorization: `Basic ${window.btoa(PAYMONGO_KEY)}`,
				'Content-Type': 'application/json',
			},
		}),
		checkout: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'payments/checkout/paymongo',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
	},
	processes: {
		getAllProcesses: (access_token, page, lastUpdates) => ({
			method: 'get',
			url: BACKEND_URL + `processes?per_page=24&page=${page}${lastUpdates && '&lastUpdate='}${lastUpdates && lastUpdates}&showAll=true`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllPinnedProcesses: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'processes/pinned',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		pinProcessById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `processes/${id}/pin`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		unpinProcessById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `processes/${id}/unpin`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getProcessById: (access_token, processId, simple = false) => ({
			method: 'get',
			url: BACKEND_URL + `processes/${processId}?simple=${simple}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		createNewProcess: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'processes',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateProcessById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `processes/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}),
		deleteProcessById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `processes/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		shareProcessToPosition: (access_token, process_Id) => ({
			method: 'post',
			url: BACKEND_URL + `processes/${process_Id}/share`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		shareProcessToSeveralPositions: (access_token, process_Id) => ({
			method: 'post',
			url: BACKEND_URL + `processes/${process_Id}/share`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		unshareProcessFromSeveralPositions: (access_token, process_Id) => ({
			method: 'delete',
			url: BACKEND_URL + `processes/${process_Id}/unshare`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		getProcessesFilters: access_token => ({
			method: 'get',
			url: BACKEND_URL + `processes/filters`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getFilteredProcesses: access_token => ({
			method: 'post',
			url: BACKEND_URL + `processes/filtered`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
				'Content-Type': 'application/json',
			},
		}),
	},
	processSteps: {
		createNewProcStepUsingRawJson: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'steps',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		getAllSteps: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'steps/',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllStepsByProcessId: (access_token, processId) => ({
			method: 'get',
			url: BACKEND_URL + 'processes/steps/' + processId,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getStepById: (access_token, stepId) => ({
			method: 'get',
			url: BACKEND_URL + 'steps/' + stepId,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getStepsByProcessId: (access_token, processId) => ({
			method: 'get',
			url: BACKEND_URL + 'processes/steps/' + processId,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		updateProcessStepByIdUsingJson: (access_token, stepId) => ({
			method: 'patch',
			url: BACKEND_URL + 'steps/' + stepId,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		attachStepToParent: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/parent/attach',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		detachStepFromParent: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/parent/detach',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		attachChildStepToAStep: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/child/attach',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		attachChildStepToAProcess: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/procedure/attach',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		detachChildStepFromStep: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/child/detach',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		deleteStepById: (access_token, stepId) => ({
			method: 'delete',
			url: BACKEND_URL + 'steps/' + stepId,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getAllInheritedStepsComponents: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'steps/components/inherited-v2',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
		getStepInheritedComponentsFromParent: (access_token, stepId) => ({
			method: 'post',
			url: BACKEND_URL + 'steps/' + stepId + '/parent-step-components',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	products: {
		getAllProducts: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'products',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getProductById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `products/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewProduct: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'products',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateProductById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `products/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteProductById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `products/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
	transactions: {
		getAllTransactions: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'transactions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getTransactionById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `transactions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewTransaction: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'transactions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateTransactionById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `transactions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteTransactionById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `transactions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
	search: {
		searchAll: (access_token, queryParams) => ({
			method: 'get',
			url: BACKEND_URL + `search${queryParams}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		searchUsers: (access_token, queryParams) => ({
			method: 'get',
			url: BACKEND_URL + `search/users${queryParams}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		searchTasks: (access_token, queryParams) => ({
			method: 'get',
			url: BACKEND_URL + `search/tasks${queryParams}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		searchProcesses: (access_token, queryParams) => ({
			method: 'get',
			url: BACKEND_URL + `search/processes${queryParams}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		searchComponents: (access_token, queryParams) => ({
			method: 'get',
			url: BACKEND_URL + `search/components${queryParams}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		geminiAI: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'search/ai',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
				'Content-Type': 'application/json',
			},
		}),
	},

	superAdmin: {
		impersonate: id => ({
			method: 'get',
			url: BACKEND_URL + `login / ${id} `,
			headers: {
				Accept: 'application/json',
				Authorization: 'Basic dWd1cjoxMjM=',
			},
		}),
	},
	notifications: {
		getAllNotifications: (access_token, page) => ({
			method: 'get',
			url: BACKEND_URL + 'notifications' + page,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getUnreadNotifications: (access_token, page) => ({
			method: 'get',
			url: BACKEND_URL + 'notifications/unread' + page,
			headers: {
				//	'Accept': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		getUnreadNotificationsCount: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'notifications/count',
			headers: {
				// 'Accept': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		markAsReadAllNotifications: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'notifications/read-all',
			headers: {
				//	'Accept': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
		markAsReadNotificationById: (access_token, notificationId) => ({
			method: 'post',
			url: BACKEND_URL + `notifications/${notificationId}/read`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}),
	},
	legal: {
		getAllLegals: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'legals',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getLegalById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `legals/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewLegal: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'legals',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateLegalById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `legals/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteLegalById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `legals/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
	faq: {
		getFaq: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'questions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getFaqById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `questions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewFaq: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'questions',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateFaqById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `questions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteFaqById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `questions/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
	documentation: {
		getAllDocumentations: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'documentations',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getDocumentationById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `documentations/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewDocumentation: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'documentations',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateDocumentationById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `documentations/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteDocumentationById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `documentations/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
	videos: {
		getAllVideos: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'videos',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getVideoById: (access_token, id) => ({
			method: 'post',
			url: BACKEND_URL + `videos/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createNewVideoTutorial: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'videos',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateVideoTutorialById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `videos/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteVideoTutorialById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `videos/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		uploadVideo: access_token => ({
			method: 'post',
			url: BACKEND_URL + `videos/upload`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		getAllVideoCategories: access_token => ({
			method: 'get',
			url: BACKEND_URL + 'video/categories',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		deleteVideoCategoryById: (access_token, id) => ({
			method: 'delete',
			url: BACKEND_URL + `video/categories/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		updateVideoCategoryById: (access_token, id) => ({
			method: 'patch',
			url: BACKEND_URL + `video/categories/${id}`,
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
		createVideoCategory: access_token => ({
			method: 'post',
			url: BACKEND_URL + 'video/categories',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${access_token} `,
			},
		}),
	},
};
