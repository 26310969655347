import classNames from 'classnames';
import { chunk } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Add, ArrowDown, CheckedStyle, closeIcon, CloseIcon2, DeleteBlackIco, DoneMarkIconDark, InformationIco, shareIconWhite, TaskBoardWhite, TaskBoard } from '../../../assets/images/icons';
import { OLContext, useOLContext } from '../../../context/appRender.context';
import { daysOptions, hoursOptions, minSecOptions, timeUnitsOptions, useStateRef } from '../../../shared/utility';
import { SelectWithSearch, ToggleSwitch, Spinner, Button } from '../../common';
import MultiselectWithSearchR from '../UI/Select/MultiselectWithSearchR';
import UnfocusableWrapper from '../UnfocusableWrapper/UnfocusableWrapper';
import cls from './FieldWrapper.module.scss';

export const FieldWrapper = props => {
	const { id, title, tooltipText, value, loading, useContext, readOnly, withAckButton, otherContent, defaultHighlighted, ungrouped, dispalysIconValue, placeholder, sectionError, onOpenChanged } = props;
	const [contextState, setContextState] = useContext();
	const [touched, setTouched] = useState(false);
	useEffect(() => {
		if (contextState.activeElement == id && !readOnly) {
			openingEditMode();
		} else {
			closingEditMode();
		}
	}, [id]);
	const closingEditMode = () => {
		setContextState({ open: false, errors: { [id]: false } });
		if (touched) {
			onOpenChanged?.(false);
		}
	};
	const openingEditMode = useCallback(() => {
		if (!readOnly) {
			setContextState({ open: true, activeElement: id, errors: { [id]: false } });
			setTouched(true);
			onOpenChanged?.(true);
		}
	}, [id, readOnly]);

	return contextState.activeElement === id ? (
		<UnfocusableWrapper
			onClickOutside={() => closingEditMode()}
			className={cls.fieldWrapperActive}
			id={id}
			app-field-wrapper-variant={ungrouped ? 'ungrouped' : ''}
			app-field-wrapper-active={contextState.activeElement == id ? 'active' : ''}
			app-variant-has-error={contextState?.errors?.[id] ?? sectionError?.length > 0 ? 'true' : 'false'}
		>
			<div className={cls.titleContainer}>
				<RenderTitle title={title} />
				{withAckButton && (
					<div onClick={() => closingEditMode()} className={cls.checkMarkContainer}>
						<CheckedStyle />
					</div>
				)}
			</div>
			<div className={cls.nonEditChildrenContainer}>{props.children}</div>
			{sectionError?.length > 0 && (
				<div className={cls.errorContainer}>
					<p>{sectionError}</p>
				</div>
			)}
		</UnfocusableWrapper>
	) : (
		<div
			className={cls.fieldWrapperInactive}
			onClick={() => openingEditMode()}
			app-variant-inactive-ungrouped={ungrouped ? 'true' : 'false'}
			app-variant-inactive-default-highlighted={defaultHighlighted ? 'true' : 'false'}
			app-variant-inactive-with-error={sectionError?.length > 0 ? 'true' : 'false'}
			app-variant-inactive-read-only={readOnly ? 'true' : 'false'}
			app-field-wrapper-variant={ungrouped ? 'ungrouped' : ''}
			app-field-wrapper-active={contextState.activeElement == id ? 'active' : ''}
			app-variant-has-error={sectionError?.length > 0 ? 'true' : 'false'}
		>
			<div
				className={cls.titleContainer}
				style={{
					marginRight: '10px',
					marginLeft: '10px',
				}}
			>
				<RenderTitle title={title} tooltipText={tooltipText} />
			</div>
			{!otherContent ? (
				typeof value === 'string' ? (
					<div className={cls.editParagraphContainer}>
						{dispalysIconValue && value?.length > 0 ? (
							<div className={cls.fieldWrapperInactiveImageContainer}>
								<img alt={'ico'} className={cls.fieldWrapperInactiveImage} src={value} />
								<ArrowDown className={cls.fieldWrapperInactiveSVG} />
							</div>
						) : loading ? (
							<span className={cls.fieldWrapperInactiveSpinnerContainer}>
								<Spinner size={30} type={'ThreeDots'} />
							</span>
						) : (
							<p className={cls.fieldWrapperInactiveText} app-variant-palceholder={value?.length > 0 ? 'true' : 'false'}>
								{value?.length > 0 ? value : placeholder}
							</p>
						)}
					</div>
				) : value ? (
					value
				) : loading ? (
					<span className={cls.fieldWrapperInactiveSpinnerContainer}>
						<Spinner size={30} type={'ThreeDots'} />
					</span>
				) : (
					<p className={cls.fieldWrapperInactiveText}>{placeholder}</p>
				)
			) : (
				props.children
			)}
			{sectionError?.length > 0 && <span className={cls.errorContainer}>{sectionError}</span>}
		</div>
	);
};
export const ContainerWrapper = forwardRef((props, ref) => {
	const { title, tooltipText, withAckButton, ungrouped, bordered, onAckPress, columned, style, required, containerError } = props;

	return (
		<div ref={ref} className={cls.containerWrapper} style={{ ...style }} app-variant-ungruouped={ungrouped ? 'true' : 'false'} app-variant-bordered={bordered ? 'true' : 'false'}>
			<div className={cls.containerWrapperTitleContainer}>
				<RenderTitle title={title} tooltipText={tooltipText} required={required} />
				{withAckButton && (
					<button type={'button'} className={cls.containerWrapperAckButton} onClick={onAckPress}>
						<CheckedStyle />
					</button>
				)}
			</div>
			<div className={cls.containerWrapperChildrenContainer} app-variant-columned={columned ? 'true' : 'false'}>
				{props.children}
			</div>
			{containerError?.length > 0 && <span className={cls.errorContainer}>{containerError}</span>}
		</div>
	);
});
export const RenderYesNoSwitcher = props => {
	const { title, tooltipText, withAckButton, ungrouped, bordered, onAckPress, value, toggleSelected, style } = props;

	return (
		<div className={cls.renderYesNoSwitcherWrapper} style={{ ...style }} app-variant-ungrouped={ungrouped ? 'true' : 'false'} app-variant-bordered={bordered ? 'true' : 'false'}>
			<div className={cls.renderYesNoSwitcherTitleContainer}>
				<RenderTitle title={title} tooltipText={tooltipText} />
				{withAckButton && (
					<div onClick={onAckPress} className={cls.renderYesNoSwitcherAckButton}>
						<CheckedStyle />
					</div>
				)}
			</div>
			<div className={cls.renderYesNoSwitcherToggleContainer}>
				<ToggleSwitch value={value} toggleSelected={toggleSelected} name={title} />
			</div>
		</div>
	);
};
export const RenderTitle = props => {
	const { title, tooltipText, required } = props;
	const tooltipDivRef = useRef(null);
	const [randomID] = useState(String(Math.random()));
	return title?.length > 0 ? (
		<div className={cls.renderTitle}>
			<p className={cls.title}>{title}</p>
			{required && <p className={cls.requiredTitle}>{' * '}</p>}
			{tooltipText?.length > 0 && (
				<div ref={tooltipDivRef} className={cls.tooltipContainer} data-tip={tooltipText} data-for={randomID}>
					<InformationIco className={cls.icon} />
					<ReactTooltip
						id={randomID}
						multiline
						effect={'float'}
						type={'dark'}
						clickable={true}
						textColor={'var(--app-color-gray-dark)'}
						backgroundColor={'var(--app-color-white-lightest)'}
						borderColor={'var(--app-color-gray-dark)'}
						className={cls.tooltip}
						place={tooltipDivRef?.current?.offsetTop < 50 ? 'bottom' : 'top'}
					/>
				</div>
			)}
		</div>
	) : null;
};
export const RenderTooltip = props => {
	const { tooltipText } = props;
	const tooltipDivRef = useRef(null);
	const [randomID] = useState(String(Math.random()));
	return tooltipText?.length > 0 ? (
		<div className={cls.renderTitle}>
			<div ref={tooltipDivRef} className={cls.tooltipContainer} data-tip={tooltipText} data-for={randomID}>
				<InformationIco className={cls.icon} />
				<ReactTooltip
					id={randomID}
					multiline
					effect={'float'}
					type={'dark'}
					clickable={true}
					textColor={'var(--app-color-gray-dark)'}
					backgroundColor={'var(--app-color-white-lightest)'}
					borderColor={'var(--app-color-gray-dark)'}
					className={cls.tooltip}
					place={tooltipDivRef?.current?.offsetTop < 50 ? 'bottom' : 'top'}
				/>
			</div>
		</div>
	) : null;
};
export const RenderDynamicTextInput = props => {
	const { placeholder, readonly, text, onTextChanged, onControlTouched, numberOfLines, maxLength, plcHoffset, style, color } = props;
	const textAreaRef = useRef();
	const [textAreaValue, setTextAreaValue] = useState(text);
	const textAreaLabelStyle = `position: absolute; top:${
		plcHoffset ? plcHoffset : '35%'
	} ; left: 10px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: var(--app-color-gray-light); fontFamily: Quicksand; font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;`;
	const textAreaOnChange = useCallback(
		newValue => {
			let text = newValue;
			if (maxLength && text.length > maxLength) return;
			if (numberOfLines) text = text?.split('\n').slice(0, numberOfLines ?? undefined);
			setTextAreaValue(text);
			onTextChanged(text);
		},
		[numberOfLines, maxLength]
	);
	useLayoutEffect(() => {
		// Reset height - important to shrink on delete
		textAreaRef.current.style.height = 'inherit';
		// Set height
		textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
	}, [textAreaValue]);
	return (
		<div className={cls.renderDynamicTextInputWrapper} style={{ ...style }}>
			<textarea
				ref={textAreaRef}
				type="text"
				name="textarea"
				readOnly={readonly}
				rows={numberOfLines ? numberOfLines : undefined}
				className={cls.renderDynamicTextInputTextarea}
				style={{ color: textAreaValue?.length > 0 ? (color ? color : '#4A4A4A') : 'var(--app-color-gray-light)' }}
				value={textAreaValue}
				onBlur={e => {
					if (textAreaValue?.length < 1) {
						if (e.target.value !== '') e.target.nextSibling.style = 'display:none;';
						else e.target.nextSibling.style = textAreaLabelStyle;
					}
					onControlTouched && onControlTouched();
				}}
				onFocus={e => {
					if (textAreaValue?.length < 1) e.target.nextSibling.style = `${'display:none;'}`;
				}}
				onChange={e => textAreaOnChange(e.target.value)}
			/>
			{textAreaValue?.length < 1 && (
				<label className={cls.renderDinamicTextInpuLabel} style={{ top: plcHoffset ? plcHoffset : '35%' }}>
					{placeholder}
				</label>
			)}
		</div>
	);
};
const transformSeconds = seconds => {
	const minutes = +seconds / 60;
	let hours = 0,
		days = 0;

	if (minutes > 60) hours = minutes / 60;
	if (hours > 24) days = hours / 24;

	if (isInt(days) && days > 0) return { value: days, unit: 'days' };
	else if (isInt(hours) && hours > 0) return { value: hours, unit: 'hours' };
	else if (isInt(minutes) && minutes > 0) return { value: minutes, unit: 'min' };
	else if (isInt(+seconds) && +seconds > 0) return { value: seconds, unit: 'sec' };
	else return { value: seconds ?? 0, unit: 'sec' };
};
export function isInt(n) {
	return Number(n) === n && n % 1 === 0;
}
export const RenderDoubleSelectFramedEntry = props => {
	const { seconds, onTextChanged } = props;
	const { value, unit } = transformSeconds(seconds);
	const [fieldValue, setFieldValue] = useState(value.toFixed(2));
	const [selectedMU, setSelectedMU] = useState(unit);
	const [fieldValueOptions, setFVOptions] = useState(minSecOptions);
	useEffect(() => {
		onTextChanged?.(transformDurationInSeconds());
	}, [fieldValue, selectedMU]);

	const transformDurationInSeconds = useCallback(() => {
		let multiplier = 1;
		switch (selectedMU) {
			case 'sec':
				multiplier = 1;
				break;
			case 'min':
				multiplier = 60;
				break;
			case 'hours':
				multiplier = 60 * 60;
				break;
			case 'days':
				multiplier = 60 * 60 * 24;
				break;
			default:
				return multiplier;
		}
		return +(+fieldValue * multiplier)?.toFixed(2);
	}, [selectedMU, fieldValue]);
	return (
		<UnfocusableWrapper className={cls.renderDoubleSelect}>
			<div className={cls.firstSelectContainer}>
				<SelectWithSearch
					id={'numeric-selection'}
					triggerType={'chevron'}
					itemType={'no-item-icons'}
					className={cls.doubleSelectFirstSelectContainer}
					inputContainerClassName={cls.doubleSelectFirstSelectInputContainer}
					inputClassName={cls.doubleSelectFirstSelectInput}
					molclassname={cls.doubleSelectFirstSelectMOL}
					moliclassname={cls.doubleSelectFirstSelectMOLI}
					title=""
					placeholder={'...'}
					onChange={val => {
						if (val?.label?.length > 0) {
							setFieldValue(val?.value);
						} else {
							setFieldValue('0');
						}
					}}
					defaultSelectedItem={fieldValueOptions?.find(i => i.value == transformSeconds(seconds).value)}
					items={fieldValueOptions}
				/>
			</div>
			<div className={cls.secondSelectContainer}>
				<SelectWithSearch
					id={'measure-unit-selection'}
					triggerType={'chevron'}
					className={cls.select}
					molclassname={cls.doubleSelectFirstSelectMOL}
					moliclassname={cls.doubleSelectFirstSelectMOLI}
					placeholder={selectedMU ?? 'Select...'}
					defaultSelectedItem={selectedMU ?? timeUnitsOptions[0]}
					onChange={opt => {
						if (opt?.label?.length > 0) {
							if (opt?.label == 'days') setFVOptions(daysOptions);
							if (opt?.label == 'hours') setFVOptions(hoursOptions);
							else setFVOptions(minSecOptions);

							setSelectedMU(opt?.label);
						} else {
							setSelectedMU(timeUnitsOptions[0]);
						}
					}}
					items={timeUnitsOptions}
				/>
			</div>
		</UnfocusableWrapper>
	);
};
export const RenderYesNoOptions = props => {
	const { onValueChanged, value } = props;
	const [selected, setSelected] = useState(value);
	return (
		<div className={cls.renderYesNoOptionsWrapper}>
			<button
				type={'button'}
				onClick={() => {
					setSelected(true);
					onValueChanged(true);
				}}
				className={cls.renderYesNoOptionsWrapperYesContainer}
				app-variant-selected={selected ? 'true' : 'false'}
			>
				<p className={cls.renderYesNoOptionsWrapperText}>{'YES'}</p>
				{selected && <DoneMarkIconDark className={cls.renderYesNoOptionsWrapperSVG} />}
			</button>
			<button
				type={'button'}
				onClick={() => {
					setSelected(false);
					onValueChanged(false);
				}}
				className={cls.renderYesNoOptionsWrapperNoContainer}
				app-variant-selected={selected ? 'false' : 'true'}
			>
				<p className={cls.renderYesNoOptionsWrapperText}>{'NO'}</p>
				{!selected && <DoneMarkIconDark className={cls.renderYesNoOptionsWrapperSVG} />}
			</button>
		</div>
	);
};
const RenderAddBulletField = props => {
	const { unfocused, previousValues, onChange } = props;
	const [editMode, setEditMode] = useState(false);
	const [inputValue, setInputValue, inputValueRef] = useStateRef('');
	const setingEditMode = val => setEditMode(val);
	useEffect(() => {
		setingEditMode(false);
	}, [unfocused]);
	const saveInputValue = useCallback(() => {
		if (inputValue?.length > 0 && onChange) {
			onChange(previousValues + `~${inputValue}`);
			setInputValue('');
		} else {
			onChange(previousValues + `~${inputValueRef?.current}`);
			setInputValue('');
		}
	}, [inputValue, previousValues]);

	return (
		<div onClick={() => setEditMode(true)} onMouseLeave={() => setEditMode(false)} className={cls.renderAddBulletFieldWrapper}>
			<div className={cls.renderAddBulletFieldInputContainer}>
				<div className={cls.renderAddBulletFieldAnnotationWrapper}>
					<button type={'button'} className={cls.renderAddBulletFieldSaveButton} onClick={saveInputValue}>
						<TaskBoardWhite />
						<p>{'ADD'}</p>
					</button>
				</div>
				<input
					className={cls.renderAddBulletFieldInputStyle}
					type="text"
					name={'addListItem'}
					style={{ height: !(inputValue?.length > 0) ? '30px' : '29px' }}
					placeholder={'(insert position specific and press ADD button or SHIFT+ENTER keys to add new item)'}
					value={inputValue}
					readOnly={!editMode}
					onChange={e => setInputValue(e.target.value)}
					onFocus={() => {}}
					onBlur={() => {
						/* setInputValue('') */
					}}
					onKeyDown={e => {
						if (e.key === 'Enter' && e.shiftKey /*|| && event.ctrlKey  */) {
							saveInputValue();
						}
					}}
				/>
				<div className={cls.renderAddBulletFieldAnnotationWrapper}>
					{inputValue?.length > 0 && (
						<button type={'button'} className={cls.renderAddBulletFieldCancelbutton} onClick={() => setInputValue('')}>
							<CloseIcon2 />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
const RenderBulletEntry = props => {
	const { id, value, index, readOnly, unfocused, onDeleteItem, onEditedItem } = props;
	const textareaRef = useRef(null);
	const [editState, setEditState] = useOLContext();
	const [editMode, setEditMode] = useState(false);
	const [showButtons, setShowButtons] = useState(false);
	const [inputValue, setInputValue] = useState(value);
	const [, setTextAreaTouched] = useState(false);

	useLayoutEffect(() => {
		// Reset height - important to shrink on delete
		textareaRef.current.style.height = 'inherit';
		// Set height
		textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 20)}px`;
	}, [inputValue]);

	useEffect(() => {
		if (editState.activeElement == id && !readOnly) {
			openingEditMode();
		} else {
			closingEditMode();
		}
	}, [id, editState.activeElement]);

	const openingEditMode = useCallback(() => {
		if (!readOnly) {
			setShowButtons(true);
			setEditMode(true);
			setEditState({ open: true, activeElement: id });
		}
	}, [id, readOnly]);

	const closingEditMode = () => {
		if (showButtons) {
			setShowButtons(false);
		}
		if (editMode) {
			setEditMode(false);
		}
		//setEditState({ activeElement: '' })
	};
	useEffect(() => closingEditMode(), [unfocused]);
	const textAreaLabelStyle =
		'position: absolute; top: 35%; left: 10px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: var(--app-color-gray-light); fontFamily: Quicksand; font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;';
	const textAreaOnChange = text => {
		setInputValue(text);
	};

	if (value?.length < 1) return null;
	return (
		<div className={cls.renderBulletEntryWrapper} onClick={openingEditMode}>
			<div className={cls.renderBulletEntryContainer} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
				<div className={cls.renderBulletEntryLabelContainer}>
					<label className={cls.renderBulletEntryItemNumber} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
						{+index + 1 + '.'}
					</label>
				</div>
				<div className={cls.renderBulletEntryTextareaContainer}>
					<textarea
						ref={textareaRef}
						type="text"
						name="textarea"
						className={cls.renderBulletEntryTextarea}
						style={{
							color: !showButtons ? '#4A4A4A' : '#4A4A4A',
							backgroundColor: !showButtons ? '#FFFFFF' : '#D1D1D1',
							padding: !showButtons ? '0px 0px 0 5px' : '5px 0px 0 0',
						}}
						value={inputValue}
						onBlur={e => {
							if (inputValue?.length < 1) {
								if (e.target.value !== '') e.target.nextSibling.style = 'display:none;';
								else e.target.nextSibling.style = textAreaLabelStyle;
							}
							setTextAreaTouched(true);
						}}
						onFocus={e => {
							if (inputValue?.length < 1) e.target.nextSibling.style = `${'display:none;'}`;
						}}
						onChange={e => textAreaOnChange(e.target.value)}
					/>
					{inputValue?.length < 1 && <label className={cls.renderBulletEntryTextareaLabel}>{'insert value for persistence'} </label>}
				</div>
				{showButtons && (
					<div className={cls.renderBulletEntryAnnotationWrapper}>
						<div
							className={cls.renderBulletEntrySaveButton}
							onClick={() => {
								setShowButtons(false);
								setEditMode(false);
								onEditedItem(inputValue);
							}}
						>
							<TaskBoard />
							<p>{'SAVE'}</p>
						</div>
						<div
							className={cls.renderBulletEntryCancelButton}
							onClick={() => {
								onDeleteItem(inputValue);
								// onSetPositionInfo({ [type]: positionInfo[type].replace('~' + inputValue, '') });
								setShowButtons(false);
								setEditMode(false);
								// onDelete()
							}}
						>
							<CloseIcon2 />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export const RenderListFramedEntry = props => {
	const { id, readOnly, useContext, customFormatStringArray, onChange } = props;
	const [editState, setEditState] = useContext();
	const [renderedValues, setRenderedValues] = useState();
	const settingRenderedValues = vals => setRenderedValues(vals);
	useEffect(() => {
		settingRenderedValues(customFormatStringArray?.split('~')?.filter(i => i?.trim()?.length > 0));
	}, [customFormatStringArray]);

	const reloadDatas = () => {
		setEditState({ open: true, activeElement: '' });
		setTimeout(() => setEditState({ open: true, activeElement: id }), 0);
	};
	const onEditedItem = useCallback(
		(editedItem, index) => {
			onChange && onChange([...renderedValues.slice(0, index), editedItem, ...renderedValues.slice(index + 1)]?.join('~'));
			reloadDatas();
		},
		[renderedValues, onChange]
	);

	const onDeleteItem = useCallback(
		index => {
			onChange && onChange([...renderedValues.slice(0, index), ...renderedValues.slice(index + 1)]?.join('~'));
			reloadDatas();
		},
		[renderedValues, onChange]
	);

	return editState.activeElement === id && !readOnly ? (
		<div className={cls.renderListFramedEntryActiveWrapper}>
			<OLContext key={id}>
				{renderedValues?.length > 0 ? (
					<div className={cls.renderListFramedEntryActiveItem}>
						{renderedValues.map((item, index) => {
							return <RenderBulletEntry key={index} id={item.toString()} index={index} unfocused={editState.activeElement == id && !readOnly} value={item} onDeleteItem={() => onDeleteItem(index)} onEditedItem={editedItem => onEditedItem(editedItem, index)} />;
						})}
					</div>
				) : (
					<p className={cls.renderListFramedEntryEmptyItem}>{'[Empty]'}</p>
				)}
			</OLContext>
			<div className={cls.renderListFramedEntryRenderAddBulletFieldWrapper}>
				<RenderAddBulletField previousValues={customFormatStringArray} onChange={newValue => onChange && onChange(newValue)} />
			</div>
		</div>
	) : (
		<div className={cls.renderListFramedEntryInactiveWrapper}>
			{renderedValues?.length > 0 ? (
				<div className={cls.renderListFramedEntryInactiveItemContainer}>
					{renderedValues?.map((item, index) => (
						<label key={index} className={cls.renderListFramedEntryInactiveItem}>
							{index + 1}
							{'. '}
							{item}
						</label>
					))}
				</div>
			) : (
				<p className={cls.renderListFramedEntryEmptyItem}>{'[Empty]'}</p>
			)}
		</div>
	);
};
const ButtonWithTooltipRender = props => {
	const { onClick, disabled, tooltipText, title } = props;
	const tooltipDivRef = useRef(null);
	const [showTooltip, setShowTooltip] = useState(false);
	const onButtonClick = useCallback(() => {
		if (disabled) {
			setShowTooltip(true);
			ReactTooltip.show(tooltipDivRef?.current);
		} else {
			if (onClick) {
				onClick();
			}
		}
	}, [disabled]);
	return (
		<div
			className={cls.buttonWithTooltipRenderWrapper}
			onMouseLeave={() => {
				setShowTooltip(false);
				ReactTooltip.hide(tooltipDivRef?.current);
			}}
		>
			<div className={cls.buttonWithTooltipRenderRemoveButton} ref={tooltipDivRef} onClick={onButtonClick} data-tip={tooltipText} id="tooltip">
				<DeleteBlackIco className={cls.buttonWithTooltipRenderRemoveButtonIcon} />
				<p className={cls.buttonWithTooltipRenderParagraph}>{title}</p>
			</div>
			{showTooltip && <ReactTooltip multiline effect={'float'} type={'dark'} clickable={true} textColor={'var(--app-color-gray-dark)'} backgroundColor={'#ffff'} borderColor={'var(--app-color-gray-dark)'} className={cls.tooltip} place={'top'} />}
		</div>
	);
};
export const RenderPositionsPeople = props => {
	const { id, readOnly, useContext, usersOnPosition, usersToAddOnPosition, onAddUsersOnPosition, loading = false } = props;
	const [editState] = useContext();

	const [peopleInThisPosition, setPeopleInThisPosition] = useState([]);
	const [peopleToAdd, setPeopleToAdd] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState();
	const [showAddEmployee, setShowAddEmployee] = useState(false);

	const [selectedToAdd, setSelectedToAdd] = useState({});
	const settingPeopleToAdd = val => setPeopleToAdd(val);
	const settingPeopleInThisPosition = val => setPeopleInThisPosition(val);
	useEffect(() => {
		const employees = usersToAddOnPosition?.map((i, _idx) => ({
			label: i.first_name + ' ' + i.last_name,
			value: i?.id,
		}));
		settingPeopleToAdd(employees?.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)));
	}, [usersToAddOnPosition]);
	useEffect(() => {
		settingPeopleInThisPosition(chunk(usersOnPosition?.sort(compareObjects), 2));
	}, [usersOnPosition]);
	var compareObjects = (a, b) => {
		if (a.first_name < b.first_name) {
			return -1;
		}
		if (a.first_name > b.first_name) {
			return 1;
		}
		if (a.last_name < b.last_name) {
			return -1;
		}
		if (a.last_name > b.last_name) {
			return 1;
		}

		return a.age - b.age;
	};

	const onSelectPerson = person => setSelectedEmployee(person);

	return editState.activeElement == id && !readOnly ? (
		<div className={cls.renderPositionsPeopleActiveWrapper}>
			<div className={cls.renderPositionsPeopleActiveAddEmployeeContainer}>
				{peopleInThisPosition?.length > 0 && (
					<div className={cls.renderPositionsPeopleInactiveContainer}>
						{peopleInThisPosition?.map((chunk, idx) => (
							<div className={cls.renderPositionsPeopleInactiveChunksContainer} key={idx}>
								{chunk?.map((item, index) => (
									<div
										className={cls.renderPositionsPeopleInactiveChunkWrapper}
										key={item?.first_name + index}
										onClick={() => {
											onSelectPerson(item);
										}}
									>
										<div className={cls.renderPositionsPeopleInactiveChunkContainer}>{selectedEmployee?.id?.indexOf(item?.id) > -1 && <p className={cls.renderPositionsPeopleInactiveItemCheckmark}>&#10003;</p>}</div>
										<p className={cls.renderPositionsPeopleInactiveItem}>
											{item?.first_name} {item?.last_name}
										</p>
									</div>
								))}
							</div>
						))}
					</div>
				)}
				<div className={cls.renderPositionsPeopleActiveAddEmployeeMultiselectContainer} style={{ display: '', marginRight: '20px' }}>
					<MultiselectWithSearchR
						tagEmplacement={'inlined'}
						itemType={'checkboxes'}
						checkboxEmplacement={'right'}
						initialSelectedItems={usersToAddOnPosition
							.map(user => {
								return { label: `${user.first_name} ${user.last_name}`, value: user.id };
							})
							.filter(user => usersOnPosition.map(user => user.id).indexOf(user.value) != -1)}
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						onChange={val => setSelectedToAdd(val)}
						items={usersToAddOnPosition.map(user => {
							return { label: `${user.first_name} ${user.last_name}`, value: user.id };
						})}
						className={cls.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearch}
						inputClassName={cls.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearchInput}
						molclassname={cls.renderPositionsPeopleActiveAddEmployeeMenuOverlay}
						moliclassname={cls.renderPositionsPeopleActiveAddEmployeeMenuItem}
					/>
				</div>
				<Button title={'UPDATE'} clicked={() => onAddUsersOnPosition(selectedToAdd)} loading={loading} />
			</div>
		</div>
	) : peopleInThisPosition?.length > 0 ? (
		<div className={cls.renderPositionsPeopleInactiveContainer}>
			{peopleInThisPosition?.map((chunk, idx) => (
				<div className={cls.renderPositionsPeopleInactiveChunksContainer} key={idx}>
					{chunk?.map((item, index) => (
						<div
							className={cls.renderPositionsPeopleInactiveChunkWrapper}
							key={item?.first_name + index}
							onClick={() => {
								onSelectPerson(item);
							}}
						>
							<div className={cls.renderPositionsPeopleInactiveChunkContainer}>{selectedEmployee?.id?.indexOf(item?.id) > -1 && <p className={cls.renderPositionsPeopleInactiveItemCheckmark}>&#10003;</p>}</div>
							<p className={cls.renderPositionsPeopleInactiveItem}>
								{item?.first_name} {item?.last_name?.substring(0, 1)?.toUpperCase() + '.'}
							</p>
						</div>
					))}
				</div>
			))}
		</div>
	) : (
		<div className={cls.editParagraphContainer}>
			<p className={cls.renderDynamicTextInputTextarea}>None</p>
		</div>
	);
};
export const RenderSharedResourcesForPosition = props => {
	const { id, readOnly, useContext, sharedUsersOnPosition, usersToShareOnPosition, onShareUserOnPosition, onUnshareUserFromPosition, loading = false } = props;
	const [editState] = useContext();
	const [sharedResources, setSharedResources] = useState({});
	const [selectedEmployee, setSelectedEmployee] = useState();
	const [showAddResources, setShowAddResources] = useState(false);

	const [peopleToAdd, setPeopleToAdd] = useState([]);
	const [selectedToAdd, setSelectedToAdd] = useState({});
	const settingPeopleToAdd = val => {
		setPeopleToAdd(val);
	};
	useEffect(() => {
		const employees = usersToShareOnPosition?.map((i, _idx) => ({
			label: i.last_name + ' ' + i.first_name,
			value: i?.id,
		}));
		settingPeopleToAdd(employees?.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)));
		const positions = [];
		sharedUsersOnPosition?.map(it => {
			if (it?.positions?.length > 0) {
				it?.positions?.map(pos => {
					if (positions?.map(i => i.id).indexOf(pos.id) === -1) {
						positions.push(pos);
					}
				});
			} else {
				if (positions?.map(i => i.id).indexOf('000') === -1) {
					positions.push({ id: '000', name: 'Unassigned' });
				}
			}
		});
		const jsonPos = {};
		positions?.map(i => {
			if (i.name === 'Unassigned') {
				return (jsonPos[i.name] = [...(sharedUsersOnPosition?.filter(u => u?.positions?.length === 0) ?? [])]);
			} else {
				return (jsonPos[i.name] = [...(sharedUsersOnPosition?.filter(u => u?.positions?.map(e => e?.id).indexOf(i.id) > -1) ?? [])]);
			}
		});
		setSharedResources(jsonPos);
	}, [usersToShareOnPosition, sharedUsersOnPosition]);
	const onSelectPerson = useCallback(person => {
		setSelectedEmployee(person);
	}, []);
	const onShareUser = useCallback(() => {
		if (selectedToAdd?.value?.length > 0 && onShareUserOnPosition) {
			onShareUserOnPosition(selectedToAdd);
		}
	}, [selectedToAdd, onShareUserOnPosition]);
	const onUnshareUser = useCallback(
		_selected => {
			if (selectedEmployee?.id?.length > 0 && onUnshareUserFromPosition) {
				onUnshareUserFromPosition(selectedEmployee);
				setSelectedEmployee();
			}
		},
		[selectedEmployee]
	);
	return editState.activeElement == id && !readOnly ? (
		<div className={cls.renderSharedResourcesForPositionActiveWrapper}>
			<div className={cls.renderSharedResourcesForPositionActiveContainer}>
				{Object.keys(sharedResources)?.map((key, idx) => (
					<div className={cls.renderSharedResourcesForPositionActiveColumn} key={idx}>
						<p className={cls.renderSharedResourcesForPositionActiveColumnTitle}>{key}</p>
						{sharedResources[key]?.map((item, index) => (
							<div className={cls.renderSharedResourcesForPositionActiveColumnItem} key={item?.first_name + index} onClick={() => onSelectPerson(item)}>
								<div className={cls.renderSharedResourcesForPositionActiveColumnItemText}>
									<p className={cls.renderSharedResourcesForPositionActiveColumnItemText}>
										{item?.first_name} {item?.last_name?.substring(0, 1)?.toUpperCase() + '.'}
									</p>
								</div>
								<button type={'button'} className={cls.renderSharedResourcesForPositionActiveColumnItemCloseButton} onClick={onUnshareUser}>
									<img alt="x" src={closeIcon} />
								</button>
							</div>
						))}
					</div>
				))}
			</div>

			<div className={cls.renderSharedResourcesForPositionActiveColumnAddResourcesContainer}>
				<div className={cls.renderSharedResourcesForPositionActiveColumnMultiSelectWrapper}>
					<SelectWithSearch
						triggerType={'none'}
						className={cls.renderSharedResourcesForPositionActiveColumnMultiselect}
						molclassname={cls.renderSharedResourcesForPositionActiveColumnMenuOverlay}
						moliclassname={cls.renderSharedResourcesForPositionActiveColumnMenuItem}
						title=""
						onChange={val => setSelectedToAdd(val)}
						items={peopleToAdd}
					/>
				</div>
				<div className={cls.renderSharedResourcesForPositionActiveColumnControls}>
					<div className={cls.renderSharedResourcesForPositionActiveColumnControlsAnnotationWrapper}>
						<Button clicked={onShareUser} title={'ADD'} loading={loading} />
					</div>
				</div>
			</div>
		</div>
	) : (
		<>
			{Object.keys(sharedResources)?.length > 0 ? (
				<div className={cls.renderSharedResourcesForPositionInactiveWrapper}>
					{Object.keys(sharedResources)?.map((key, idx) => (
						<div key={key + idx} className={cls.renderSharedResourcesForPositionInactiveColumn}>
							<p className={cls.renderSharedResourcesForPositionInactiveColumnTitle}>{key}</p>
							{sharedResources[key]?.map((item, index) => (
								<div className={cls.renderSharedResourcesForPositionInactiveColumnItemWrapper} key={item?.first_name + index} onClick={() => onSelectPerson(item)}>
									<div className={cls.renderSharedResourcesForPositionInactiveColumnMarkerContainer}>{selectedEmployee?.id?.indexOf(item?.id) > -1 && <p className={cls.renderSharedResourcesForPositionInactiveColumnMarker}>&#10003;</p>}</div>
									<p className={cls.renderSharedResourcesForPositionInactiveColumnItem}>
										{item?.first_name} {item?.last_name?.substring(0, 1)?.toUpperCase() + '.'}
									</p>
								</div>
							))}
						</div>
					))}
				</div>
			) : (
				<div className={cls.editParagraphContainer}>
					<p className={cls.renderDynamicTextInputTextarea}>None</p>
				</div>
			)}
		</>
	);
};
export const SimpleContainerWrapper = props => {
	const { title, columned } = props;

	return (
		<div className={cls.simpleContainerWrapperHost}>
			<div className={cls.simpleContainerWrapperTitleContainer}>
				<p className={cls.simpleContainerWrapperTitleText}>{title}</p>
			</div>
			<div className={cls.simpleContainerWrapperChildrenContainer} app-variant-columned={columned ? 'true' : 'false'}>
				{props.children}
			</div>
		</div>
	);
};
export const RenderBooleansOptions = props => {
	const { selected, setSelected, first, second, extraClassName } = props;
	return (
		<div className={classNames(cls.renderBooleansOptions, extraClassName)}>
			<div cont-variant={selected ? 'selected' : 'unselected'} onClick={() => setSelected(true)}>
				<p>{first}</p>
				{selected && <DoneMarkIconDark />}
			</div>
			<div cont-variant={!selected ? 'selected' : 'unselected'} onClick={() => setSelected(false)}>
				<p>{second}</p>
				{!selected && <DoneMarkIconDark />}
			</div>
		</div>
	);
};

export const ContainerWrapperWithValidation = memo(props => {
	const { title, tooltipText, withAckButton, ungrouped, bordered, onAckPress, columned, style, required, hasError } = props;
	return (
		<div
			className={cls.cw_withValidation}
			app-variant-ungrouped={ungrouped ? 'ungrouped' : null}
			app-variant-bordered={bordered ? 'bordered' : null}
			app-variant-columned={columned ? 'column' : null}
			app-variant-with-ack-button={withAckButton ? 'withAckButton' : null}
			app-variant-has-error={hasError ? 'error' : null}
			style={{ ...style }}
		>
			<div className={cls.header}>
				<RenderTitle title={title} tooltipText={tooltipText} required={required} />
				{withAckButton && (
					<div onClick={onAckPress} className={cls.acknowledgeContainer}>
						<CheckedStyle className={cls.acknowledgeIcon} />
					</div>
				)}
			</div>
			<div className={cls.childrenContainer}>{props.children}</div>
		</div>
	);
});
