import * as R from 'ramda';
import 'rc-datetime-picker/dist/picker.css';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SettingsWheel } from '../../../../assets/images/icons';
import { bcat, useBlueprintComponentsContext } from '../../../../context/blueprintsComponents.context';
import { setMessageBar, setSelectedProStep } from '../../../../reduxStore/actions/flowActions';
import { createBlueprintComponent, deleteBlueprintComponent, updateBlueprintComponent } from '../../../../services/components.service';
import { fileExtensions, DropdownIndicator } from '../../../../shared/utility';
import { Button, ModalR, TutorialRectangle, PermissionWrapper, po, RenderTitle } from '../../../common';
import { SingleSelectWithCheckboxesAndNestedItems } from '../../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';
import cls from './ComponentsModal.module.scss';
import { FieldOptionsModal, OptionSection, RenderTutorialRectangle } from './FieldOptionsModal';
import { isMobile } from 'react-device-detect';
import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
import { store } from '../../../../reduxStore/store';
import { customStyles } from '../../ModulesComp/ModulesOptionsModal';
import Select from 'react-select';
import { checkCustomTemplatesForComponentModal } from '../../ModulesComp/CustomTemplateModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEllipsisV, faGear } from '@fortawesome/free-solid-svg-icons';
import { FaEllipsisH } from 'react-icons/fa';

const ComponentsForm = memo(props => {
	const { visible, onUpdate, onClose, hasTutorial, allModules, blueprints, componentTypes, components, products, companyData, companyProcesses, showEdit = true, onBoardingCompleted } = props;
	const [bpContext, setBpContext] = useBlueprintComponentsContext();
	const [showTutorial, setShowTutorial] = useState(false);
	const [optionsModalVisible, setOptionsModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dLoading, setDLoading] = useState(false);
	const [processingUpdate, setProcessingUpdate] = useState(false);
	const [processingDelete, setProcessingDelete] = useState(false);
	const [sectionErrors, setSectionErrors] = useState({ name: '', fields: '', components: '' });
	const [showErrors, setShowErrors] = useState(false);
	const [generalError, setGeneralError] = useState('');
	const [isNew, setIsNew] = useState(true);
	const [isRename, setIsRename] = useState(false);
	const [templateMessage, setTemplateMessage] = useState('');
	useEffect(() => {
		setGeneralError('');
		setBpContext({
			type: bcat.setAllBlueprintsComponents,
			value: blueprints,
		});
	}, [bpContext.selectedBlueprintComponent.fields]);

	const onDeleteComponentBlueprint = () => {
		setDLoading(true);
		deleteBlueprintComponent(bpContext.selectedBlueprintComponent.id).subscribe({
			next: () => {
				store.dispatch(
					setMessageBar({
						show: true,
						message: `You have successfully deleted ${bpContext?.selectedBlueprintComponent.name ?? 'item'}`,
						color: 'red',
					})
				);
				onUpdate?.(true);
			},
			complete: () => {
				setDLoading(false);
				onClose?.(true);
			},
		});
	};
	const resetErrors = () => {
		setSectionErrors({ name: '', components: '' });
		setGeneralError('');
	};

	const callUpdateComponentBlueprint = useCallback(
		payload => {
			updateBlueprintComponent(payload.id, payload).subscribe({
				next: component => {
					setLoading(false);
					store.dispatch(
						setMessageBar({
							show: true,
							message: `You have successfully updated ${payload.name}`,
						})
					);
					onUpdate?.(component);
				},
				error: error => {
					if (error?.data?.errors) {
						setSectionErrors({
							name: error?.data?.errors?.name?.join('; '),
							components: error?.data?.errors?.components?.join('; '),
						});
					}
					if (error?.data) {
						setGeneralError(error?.data?.message ?? error?.data);
					}
					setLoading(false);
				},
			});
		},
		[onClose]
	);

	const callCreateComponentBlueprint = useCallback(
		payload => {
			createBlueprintComponent(payload).subscribe({
				next: data => {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully created ${payload.name}` }));
					setLoading(false);
					onClose?.(true);
					onUpdate?.(true);
				},
				error: error => {
					if (error?.data?.errors) {
						setSectionErrors({
							name: error?.data?.errors?.name?.join('; '),
							components: error?.data?.errors?.components?.join('; '),
						});
					}
					if (error?.data) {
						setGeneralError(error?.data?.message ?? error?.data);
					}
					setLoading(false);
				},
			});
		},
		[onClose]
	);

	const onNewBlueprintComponentPressed = () => {
		setIsNew(true);
		setBpContext({ type: bcat.resetSelectedBlueprintComponent });
	};
	const onSaveBlueprintComponentPressed = useCallback(() => {
		const { name, fields } = bpContext.validation;
		if (name || fields?.length > 0 || bpContext.selectedBlueprintComponent.fields?.length < 1) {
			let fieldErrorMessage = '';
			if (bpContext.selectedBlueprintComponent.fields?.length < 1) fieldErrorMessage = 'Component must have at least one field';
			if (fields?.length > 0) fieldErrorMessage = 'Check options of red fields';

			/* nested options valiadation */
			if (bpContext?.selectedBlueprintComponent?.fields?.some(i => i?.options?.list?.length == 0)) fieldErrorMessage = "Element's option list must have at least one entry";
			if (bpContext?.selectedBlueprintComponent?.fields?.some(i => i?.options?.type?.length == 0)) fieldErrorMessage = "Element's option type is mandatory";
			if (bpContext?.selectedBlueprintComponent?.fields?.some(i => i?.value?.length == 0)) fieldErrorMessage = 'Name is mandatory for each element';

			setSectionErrors({
				name: name ? 'Add a component name' : '',
				fields: fieldErrorMessage,
			});
			if (fieldErrorMessage?.length > 0) {
				const errorFields = document.querySelectorAll('[app-field-row-has-error="error"]');
				if (errorFields) {
					if (errorFields?.length > 0) {
						[...errorFields]?.reverse()[errorFields?.length - 1]?.scrollIntoView({
							block: 'start',
							behavior: 'smooth',
						});
					}
				}
			}
			if (fields?.length > 0) {
				setShowErrors(true);
			}
			return;
		}
		setLoading(true);
		resetErrors();
		const payload = {
			components: bpContext.selectedBlueprintComponent.components,
			fields: bpContext.selectedBlueprintComponent.fields.map(i => (i.id?.length > 12 ? { ...i } : R.dissoc('id')(i))),
			name: bpContext.selectedBlueprintComponent.name,
		};
		if (bpContext.selectedBlueprintComponent.id?.length > 0) {
			callUpdateComponentBlueprint({ ...payload, id: bpContext.selectedBlueprintComponent.id });
		} else {
			callCreateComponentBlueprint(payload);
		}
	}, [bpContext.selectedBlueprintComponent.components, bpContext.selectedBlueprintComponent.fields, bpContext.selectedBlueprintComponent.id, bpContext.selectedBlueprintComponent.name, bpContext.validation, callCreateComponentBlueprint, callUpdateComponentBlueprint]);

	const updateNameInContext = newName => {
		setBpContext({ type: bcat.setSelectedBlueprintComponentName, value: newName });
	};

	const onDeleteSelectedComponentBlueprintSelectedField = fieldId => {
		setProcessingDelete(true);
		setBpContext({ type: bcat.removeSelectedFieldFromSelectedBlueprintComponent, value: { id: fieldId } });
		setProcessingDelete(false);
		setOptionsModalVisible(false);
	};
	const onSaveComponentField = args => {
		setProcessingUpdate(true);
		setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: args });
		setProcessingUpdate(false);
		setOptionsModalVisible(false);
	};
	const onOptionsModalClose = () => {
		setBpContext({ type: bcat.setSelectedFieldFromSelectedBlueprintComponent, value: null });
		setProcessingUpdate(false);
		setProcessingDelete(false);
		setOptionsModalVisible(false);
	};

	return (
		<>
			{hasTutorial ? <RenderTutorialRectangle setShowTutorial={setShowTutorial} /> : null}
			<div className={cls.host}>
				{onBoardingCompleted && (
					<div className={cls.title}>
						<p>{isNew ? 'New Component' : 'Edit Component'}</p>
					</div>
				)}
				<OptionSection>
					{/* {blueprints.length > 0 && <RenderTitle title={'Select Component to Edit'} />}
					{blueprints.length > 0 && (
						<Select
							onFocus={() => { }}
							isSearchable={false}
							key={'Component'}
							styles={customStyles}
							placeholder={'Select a component to edit instead...'}
							components={{ DropdownIndicator }}
							options={R.sortWith([R.ascend(R.prop('name'))])(blueprints).map(component => {
								return { label: component?.name, value: component?.id };
							})}
							onChange={selection => {
								setBpContext({ type: bcat.setSelectedBlueprintComponent, value: blueprints.find(c => c.id === selection.value) });
								setIsNew(false);
							}}
						/>
					)} */}

					{isNew && (
						<div>
							<RenderTitle title={'Name'} />
							<input
								type="text"
								name="element-name"
								value={bpContext.selectedBlueprintComponent?.name}
								onChange={e => {
									updateNameInContext(e.target.value);
								}}
								onClick={() => {}}
								className={cls.elementTextInput}
							/>
						</div>
					)}
					{isRename && (
						<div>
							<RenderTitle title={'Name'} />
							<input
								type="text"
								name="element-name"
								value={bpContext.selectedBlueprintComponent?.name}
								onChange={e => {
									updateNameInContext(e.target.value);
								}}
								onClick={() => {}}
								className={cls.elementTextInput}
							/>
						</div>
					)}
					<br />

					{!isRename && (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxHeight: '100%', border: '0px solid var(--app-color-gray-lighter)' }}>
							<RenderTitle title={'Fields Selection'} />

							<div className={cls.fieldRow}>
								<SingleSelectWithCheckboxesAndNestedItems
									placeholder={'Click and select field to add ...'}
									items={
										componentTypes
											? [
													...R.sortWith([R.ascend(R.prop('name'))])(componentTypes.filter(type => type.io_type == 0 || type.io_type == 1)).map(component => {
														return { label: component.name, value: component.id, type: component.io_type == 0 ? 'Output' : 'Input' };
													}),
													...(companyData?.misc_info?.templates
														? companyData.misc_info.templates.map(template => {
																return { label: template, value: template, type: 'Templates' };
														  })
														: []),
											  ]
											: []
									}
									initialSelectedValues={[]}
									groupedBy={'type'}
									groupname={'type'}
									inputStyle={{ blank: true, cursor: 'pointer' }}
									className={cls.peoplePositionsSelect}
									inputClassName={cls.peoplePositionsSelectInput}
									molclassname={cls.peoplePositionsSelectMenuOverlay}
									moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
									onChange={selection => {
										if (selection.length > 0) {
											let selections = [selection[0]];

											selections = checkCustomTemplatesForComponentModal(selection, companyData, componentTypes);
											if (selections.length == 0) {
												setTemplateMessage('');
												selections = [selection[0]];
											}

											selections.map(sel => {
												const field = componentTypes.find(ct => ct.id === sel.value);
												let addingField = { field_type: { ...field } };
												if (field?.name?.toLowerCase() === 'number') {
													addingField = {
														field_type: { ...field },
														options: { type: 'numeric' },
													};
												}
												if (field?.name?.toLowerCase() === 'phone') {
													addingField = {
														field_type: { ...field },
														options: { country_prefix: '+44', country_code: 'gb', country_name: 'United Kingdom' },
													};
												}
												if (field?.name?.toLowerCase() === 'currency') {
													addingField = {
														field_type: { ...field },
														options: { currency_type: 'British Pound Sterling', currency_value: 'GBP', symbol: '£' },
													};
												}
												if (field?.name?.toLowerCase() === 'upload') {
													addingField = {
														field_type: { ...field },
														options: {
															accepted_mimes: fileExtensions.map(i => i.value?.replaceAll('.', '')).join(','),
															max_file_size: 50 * 1024,
														},
													};
												}
												if (field?.name?.toLowerCase() === 'people') {
													addingField = {
														field_type: { ...field },
														options: { position_id: [], type: 'single' },
													};
												}
												if (field?.name?.toLowerCase() === 'date') {
													addingField = {
														field_type: { ...field },
														options: { type: 'single' },
													};
												}
												if (field?.name?.toLowerCase() === 'dropdown') {
													addingField = {
														field_type: { ...field },
														options: {
															list: [],
															type: 'single',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'pin') {
													addingField = {
														field_type: { ...field },
														options: {
															pintype: 'single',
															realtime: true,
															destination: false,
															source: true,
														},
													};
												}
												if (field?.name?.toLowerCase() === 'record') {
													addingField = {
														field_type: { ...field },
														options: {
															component_id: [],
															type: 'single',
															selection: '',
															filters: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'list filter') {
													addingField = {
														field_type: { ...field },
														options: {
															field: '',
															value: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'id') {
													addingField = {
														field_type: { ...field },
														options: {
															id: '',
															value: 0,
														},
													};
												}
												if (field?.name?.toLowerCase() === 'list') {
													addingField = {
														field_type: { ...field },
														options: {
															module_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'module list') {
													addingField = {
														field_type: { ...field },
														options: {
															module_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'step list') {
													addingField = {
														field_type: { ...field },
														options: {
															component_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'component list') {
													addingField = {
														field_type: { ...field },
														options: {
															component_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'transaction list') {
													addingField = {
														field_type: { ...field },
														options: {
															module_id: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'lookup') {
													addingField = {
														field_type: { ...field },
														options: {
															source_id: '',
															target_id: '',
															common_field: '',
															list: [],
															selection: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'ratings') {
													addingField = {
														field_type: { ...field },
														options: {
															field: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'rewards') {
													addingField = {
														field_type: { ...field },
														options: {
															status: 'Closed',
															points: 1,
															equiv: 1,
															creator: true,
														},
													};
												}
												if (field?.name?.toLowerCase() === 'calendar') {
													addingField = {
														field_type: { ...field },
														options: {
															limit: true,
															creator: true,
															priority: true,
															span: false
														},
													};
												}
												if (field?.name?.toLowerCase() === 'connectors') {
													addingField = {
														field_type: { ...field },
														options: {
															component1_id: '',
															component2_id: '',
															common_field_12: '',
															component3_id: '',
															common_field_23: '',
															outputs: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'map') {
													addingField = {
														field_type: { ...field },
														options: {
															maptype: 'region',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'formula') {
													addingField = {
														field_type: { ...field },
														options: { operator: '', list: [] },
													};
												}
												if (field?.name?.toLowerCase() === 'emails') {
													addingField = {
														field_type: { ...field },
														options: { subject: '', message: '' },
													};
												}
												if (field?.name?.toLowerCase() === 'product') {
													addingField = {
														field_type: { ...field },
														options: { list: [] },
													};
												}
												if (field?.name?.toLowerCase() === 'product lookup') {
													addingField = {
														field_type: { ...field },
														options: {
															selection: '',
															component_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'filters') {
													addingField = {
														field_type: { ...field },
														options: {
															component_id: '',
															list: [],
															filters: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'info') {
													addingField = {
														field_type: { ...field },
														options: {
															component_id: '',
															list: [],
														},
													};
												}
												if (field?.name?.toLowerCase() === 'value') {
													addingField = {
														field_type: { ...field },
														options: {
															module_id: '',
															component_id: '',
															value: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'notes') {
													addingField = {
														field_type: { ...field },
														options: {
															value: '',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'url') {
													addingField = {
														field_type: { ...field },
														options: {
															type: 'image',
														},
													};
												}
												if (field?.name?.toLowerCase() === 'googleai assistant') {
													addingField = {
														field_type: { ...field },
														options: {
															description: '',
															fixed: true,
															current: false,
														},
													};
												}
												if (bpContext?.selectedBlueprintComponent?.fields.length < 12) {
													resetErrors();
													setBpContext({
														type: bcat.setSelectedBlueprintComponentNewField,
														value: { ...addingField, label: sel.label ?? '' },
													});
												} else {
													setGeneralError('You can only add a maximum of 12 fields per component. ');
												}
											});
										}
									}}
								>
									<FontAwesomeIcon className="fa-icon" icon={faChevronDown} style={{ marginRight: '20px', color: 'gray' }} />
								</SingleSelectWithCheckboxesAndNestedItems>
							</div>
							<br />
							<div className={cls.selectedFieldsContainer}>{isNew ? 'Selected Fields:' : 'Available Fields:'}</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{bpContext?.selectedBlueprintComponent?.fields?.map((item, index) => {
									return (
										<BPFieldRowWrapper
											key={index}
											id={item.id}
											name={item?.field_type?.io_type === 1 ? item?.field_type?.name : item?.field_type?.name === 'Template' ? item?.field_type?.name : `${item?.field_type?.name} (Out)`}
											settingsClicked={() => {
												setBpContext({
													type: bcat.setSelectedFieldFromSelectedBlueprintComponent,
													value: { ...item, index: item?.field_type?.index },
												});
												setOptionsModalVisible(true);
											}}
											renderError={showErrors}
											hasSettingsBtn
										>
											<RenderFieldContent {...item} />
										</BPFieldRowWrapper>
									);
								})}
							</div>
							<p className={cls.selectedFieldsContainer}>{bpContext?.selectedBlueprintComponent?.fields?.length == 0 ? 'No fields available. Select and add by clicking above' : 'You can add a maximum of 12 fields per component. '}</p>

							<br />
						</div>
					)}

					{generalError?.length > 0 && <MessageContainer message={generalError} onClick={() => setGeneralError('')} />}
					{templateMessage?.length > 0 && <MessageContainer withError={false} extraClassName={cls.warningContainer} message={templateMessage} onClick={() => setTemplateMessage('')} />}
				</OptionSection>

				<div className={onBoardingCompleted ? cls.controls : cls.controls2}>
					{onClose && <Button light title={'Back'} clicked={() => onClose()} style={{ width: '100px', display: isRename ? 'none' : '' }} />}
					{!onClose && <Button clear title={''} clicked={() => {}} style={{ width: '100px', display: isRename ? 'none' : '' }} />}

					<Button
						disabled={bpContext?.selectedBlueprintComponent?.fields.length > 0 && bpContext.selectedBlueprintComponent?.name ? false : true}
						title={isNew ? 'Save' : 'Update'}
						loading={loading}
						clicked={onSaveBlueprintComponentPressed}
						style={{
							width: '100px',
							display: bpContext?.selectedBlueprintComponent?.fields.length > 0 && bpContext.selectedBlueprintComponent?.name ? '' : 'none',
						}}
					/>
					{!onBoardingCompleted && blueprints.length > 0 && <Button light title={'Skip'} clicked={() => onUpdate()} style={{ width: '100px', display: '' }} />}
				</div>
			</div>

			{optionsModalVisible && (
				<FieldOptionsModal
					onClose={onOptionsModalClose}
					isOpen={optionsModalVisible}
					modalStyle={{ maxWidth: '500px', minWidth: '422px' }}
					leftControlAction={fieldId => onDeleteSelectedComponentBlueprintSelectedField(fieldId)}
					leftControlLoading={processingDelete}
					centralControlAction={onSaveComponentField}
					centralControlLoading={processingUpdate}
					allModules={allModules}
					blueprints={blueprints}
					components={components}
					companyProcesses={companyProcesses}
					products={products}
					selectedFields={bpContext.selectedBlueprintComponent.fields}
				/>
			)}
			{showTutorial && <TutorialRectangle onClick={() => setShowTutorial(false)} url={'https://www.youtube.com/watch?v=xV-bOHvfbPQ'} />}
		</>
	);
});
const RenderFieldContent = props => {
	const { id, value, field_type } = props;
	const [, setBpContext] = useBlueprintComponentsContext();

	switch (field_type?.data_type) {
		case 'file':
			return (
				<input
					type={'input'}
					placeholder={field_type?.name}
					className={cls.fieldTypeInput}
					value={value}
					name={field_type?.name}
					onChange={e => {
						setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: { id: id, value: e.target.value } });
					}}
				/>
			);
		case 'text':
			return (
				<input
					type={field_type?.data_type}
					placeholder={'Input name for ' + field_type?.name}
					className={cls.fieldTypeInput}
					value={value}
					name={field_type?.name}
					onChange={e => {
						let newNumber = e.target.value;
						setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: { id: id, value: newNumber } });
					}}
				/>
			);
		case 'output':
			return (
				<input
					type={field_type?.data_type}
					placeholder={'Insert value for ' + field_type?.name?.toLowerCase() + ' element...'}
					className={cls.fieldTypeInput}
					value={value}
					name={field_type?.name}
					readOnly={field_type?.name === 'Template' ? true : false}
					onChange={e => {
						let newNumber = e.target.value;
						setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: { id: id, value: newNumber } });
					}}
				/>
			);
		case 'date':
			return (
				<input
					type={'input'}
					placeholder={field_type?.name}
					className={cls.fieldTypeInput}
					value={value}
					name={field_type?.name}
					onChange={e => {
						setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: { id: id, value: e.target.value } });
					}}
				/>
			);
		default:
			return (
				<input
					type={field_type?.data_type}
					placeholder={'Insert value for ' + field_type?.name?.toLowerCase() + ' element...'}
					className={cls.fieldTypeInput}
					value={value}
					name={field_type?.name}
					onChange={e => {
						let newNumber = e.target.value;
						setBpContext({ type: bcat.setSelectedBlueprintComponentSpecificField, value: { id: id, value: newNumber } });
					}}
				/>
			);
	}
};
const BPFieldRowWrapper = props => {
	const { id, renderError, fieldIcon, children, hasSettingsBtn, settingsClicked, name } = props;
	const [bpContext] = useBlueprintComponentsContext();
	const [showErrorState, setShowErrorState] = useState(false);
	const settingErrorState = useCallback(() => setShowErrorState(renderError && bpContext.validation.fields?.some(i => i?.toString() === id?.toString())), [renderError, bpContext.validation.fields, id]);

	useEffect(() => {
		settingErrorState();
	}, [renderError, id, bpContext.validation.fields, settingErrorState]);
	return (
		<div className={cls.fieldRow} app-field-row-has-error={showErrorState ? 'error' : ''}>
			<div className={cls.fieldRowContent}>{children}</div>
			<p className={cls.typeText}>{name}</p>
			{hasSettingsBtn && (
				<button className={cls.fieldRowControls} onClick={() => settingsClicked()}>
					<FontAwesomeIcon icon={faGear} style={{width: '20px', height: '20px', color: 'gray'}}/>
				</button>
			)}
		</div>
	);
};
const mapStateToProps = store => {
	const { blueprints, allStatutes, selectedBlueprintComponent, allModules, componentTypes, components, products, companyData, companyProcesses, onBoardingCompleted } = store.flowReducer;
	return { blueprints, allStatutes, selectedBlueprintComponent, allModules, componentTypes, components, products, companyData, companyProcesses, onBoardingCompleted };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetSelectedProStep: selectedStep => dispatch(setSelectedProStep(selectedStep)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentsForm);
